import Axios from "axios";

const AUTH_DICTIONARIES = import.meta.env.VITE_AUTH_DICTIONARIES;

export function ActiveCityService(id, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.put(
        `${AUTH_DICTIONARIES}/api/locations/v1/cities/${id}/restore`,{},
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}