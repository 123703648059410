export enum AccompanimentStateCodes { 
    ALRT = 'ALRT',
    FNLD = 'FNLD',
    INIT = 'INIT',
    MGMT = 'MGMT',
    QLFD = 'QLFD',
    RACT = 'RACT',
    REGT = 'REGT',



}