import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { getToken } from "../../utils/GetToken";
import { ApproveDocumentService } from "../../services/DocumentsManagement/ApproveDocumentService";
import { RejectDocumentService } from "../../services/DocumentsManagement/RejectDocumentService";
import { useReviewDoc } from "../../context/ReviewDocumentContext";

const estados = [
  { id: 1, state: "Aprobado" },
  { id: 2, state: "Rechazado" },
];

export default function ReviewDocument({ doc, handleClose, documentListBy }) {
  const [file, setFile] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [documentState, setDocumentState] = useState(null);
  const [formValues, setFormValues] = useState({
    comment: "",
  });
  const { docApproved, docRejected, handleDocApproved, handleDocRejected } =
    useReviewDoc();

  const { t } = useTranslation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStateChange = (event, value) => {
    console.log("handle state change", value);
    if (value?.state) {
      setDocumentState(value.id);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("To submit", formValues);
    console.log("Doc state", documentState);
    {
      documentState === 1 && approveDocument();
    }
    {
      documentState === 2 && rejectDocument();
    }
  };

  const approveDocument = async () => {
    try {
      const token = await getToken();
      setLoading(true);
      const response = await ApproveDocumentService(
        token,
        file.id,
        formValues,
        documentListBy
      );
      const { docApproved, message, shopIsApproved } = response.data;
      setSuccessMessage(message);
      handleDocApproved();
      handleClose();
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const rejectDocument = async () => {
    try {
      const token = await getToken();
      setLoading(true);
      const response = await RejectDocumentService(
        token,
        file.id,
        formValues,
        documentListBy
      );
      const { docApproved, message, shopIsApproved } = response.data;
      setSuccessMessage(message);
      handleDocRejected();
      handleClose();
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFile(doc);
  }, [doc]);

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: "20px",
        mt: { xs: "2rem", md: 0 },
        maxWidth: "500px",
      }}
    >
      <CardContent>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        {file !== null && (
          <Stack mt={2} spacing={2}>
            <Link to={file.url} target="_blank">
              <Button
                variant="contained"
                size="large"
                color="secondary"
                sx={{ borderRadius: "20px" }}
                fullWidth
              >
                {t("openDoc.button")}
              </Button>
            </Link>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Typography variant="caption">
                      {t("reviewDoc.comments")}
                    </Typography>
                    <TextField
                      name="comment"
                      label={t("reviewDoc.textfield-1")}
                      size="small"
                      value={formValues.comment || ""}
                      onChange={handleInputChange}
                      required
                      fullWidth
                      multiline
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="stateSelect"
                    options={estados}
                    onChange={handleStateChange}
                    getOptionLabel={(option) => option.state}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("reviewDoc.textfield-2")}
                        size="small"
                        onChange={handleStateChange}
                        value={formValues.state}
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box mt={2} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color={"secondary"}
                  size="large"
                  startIcon={
                    loading && (
                      <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                    )
                  }
                >
                  {t("save.button")}
                </Button>
              </Box>
            </form>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
