import { createContext, useContext, useState } from "react";
import { LoginUser } from "../helpers/login_user";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isUserUpdated, setIsUserUpdated] = useState(false);

  const Login = async (formValue) => {
    try {
      const response = await LoginUser(formValue);
      if (response.data.status === 1) {
        login(response);
      } else if (response.data.status === 2) {
        ConfirmEmail(response);
      } else if (response.data.status === 3) {
        selectOtp(response);
      }
      return {
        status: response.data.status,
        message: response.data.message,
        load: false,
        token: response.data.access_token,
      };
    } catch (error) {
      return { error: error.response };
    }
  };

  const ConfirmEmail = (response) => {
    saveTokenTempo(response);
  };

  const selectOtp = (response) => {
    saveTokenTempo(response);
    setLoggedIn(false);
  };

  const login = (response) => {
    saveToken(response);
    setLoggedIn(true);
  };

  const logout = () => {
    removeToken();
    setLoggedIn(false);
  };

  const saveTokenTempo = (response) => {
    localStorage.setItem("refreshTokenTimeTempo", new Date().getTime());
    localStorage.setItem("refreshTokenTempo", response?.data?.access_token ?? '');
    localStorage.setItem("refreshTokenOtp", response?.data?.['X-auth-otp'] ?? '');
  };

  const saveToken = (response) => {
    localStorage.removeItem("refreshTokenTimeTempo");
    localStorage.removeItem("refreshTokenTempo");
    localStorage.removeItem("refreshTokenOtp");
    localStorage.setItem("refreshTokenTime", new Date().getTime());
    localStorage.setItem("refreshToken", response.data.access_token);
  };

  const removeToken = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("refreshTokenTime");
  };

  const redirectTo = (path) => {
    window.location.href = path;
  };
  const userUpdate = () => {
    setIsUserUpdated(true);
  };

  const resetUserUpdated = () => {
    setIsUserUpdated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        Login,
        token,
        isLoggedIn,
        setLoggedIn,
        login,
        logout,
        rememberMe,
        redirectTo,
        userUpdate,
        isUserUpdated,
        resetUserUpdated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
