import { createContext, useState, useContext } from "react";

export const CreateReviewSensitiveContext = createContext();

export const useReviewSensitiveChange = () =>
  useContext(CreateReviewSensitiveContext);

export const ReviewSensitiveChangeProvider = ({ children }) => {
  const [sensitiveChangeApproved, setSensitiveChangeApproved] = useState(false);
  const [sensitiveChangeRejected, setSensitiveChangeRejected] = useState(false);

  const handleSensitiveChangeApproved = () => {
    setSensitiveChangeApproved(true);
  };

  const handleSensitiveChangeRejected = () => {
    setSensitiveChangeRejected(true);
  };

  const resetSensitiveChangeStates = () => {
    setSensitiveChangeApproved(false);
    setSensitiveChangeRejected(false);
  };

  return (
    <CreateReviewSensitiveContext.Provider
      value={{
        sensitiveChangeApproved,
        sensitiveChangeRejected,
        handleSensitiveChangeApproved,
        handleSensitiveChangeRejected,
        resetSensitiveChangeStates,
      }}
    >
      {children}
    </CreateReviewSensitiveContext.Provider>
  );
};
