import { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Skeleton,
  Grid,
  ButtonBase,
  Modal,
  Box,
  Stack,
  CardContent,
  Divider,
  Avatar,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getToken } from "../../utils/GetToken";
import { styled } from "@mui/material/styles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import dayjs from "dayjs";
import BusinessIcon from "@mui/icons-material/Business";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import CategoryIcon from "@mui/icons-material/Category";
import DescriptionIcon from "@mui/icons-material/Description";

import { GetCompanyDetailService } from "../../services/Companies/GetCompanyDetailService";

export default function CompanyDetails() {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [companyDetail, setcompanyDetail] = useState({});
  const { t } = useTranslation();
  const { companyId } = useParams();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    //bgcolor: "background.paper",
    border: "2px ",
    //boxShadow: 20,
    p: 4,
  };

  const styleIcon = {
    fontSize: 20,
    color: "primary.light",
  };

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    width: 450,
    Height: 200,
  });

  useEffect(() => {
    const companyDetails = async () => {
      try {
        const token = await getToken();
        if (token) {
          const data = await GetCompanyDetailService(token, companyId);
          console.log(data);
          const createdAt = dayjs(data.createdAt).format("DD/MM/YYYY H:m:s A");
          const lastUpdate = dayjs(data.lastUpdate).format(
            "DD/MM/YYYY H:m:s A"
          );
          const company = {
            id: data.id,
            name: data.name,
            description: data.description,
            type: data.type.id,
            type_name: data.type.name,
            type_order: data.type.order,
            createdAt: createdAt,
            lastUpdate: lastUpdate,
            logo: data.logo,
          };
          setcompanyDetail(company);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    companyDetails();
  }, []);

  useEffect(() => {
    console.log(companyDetail);
  }, [companyDetail]);

  return (
    <>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={400}
          sx={{
            width: { xs: "320px", sm: "320px", md: "500px", xl: "872px" },
            borderRadius: "20px",
            mx: "1rem",
          }}
        />
      ) : (
        <Card
          elevation={5}
          sx={{
            borderRadius: "20px",
            mx: "1rem",
            height: "auto",
            p: { xs: "0.5rem", sm: "2rem" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase
                onClick={handleOpen}
                sx={{
                  width: 160,
                  height: 160,
                  top: "30%",
                  ml: "2rem",
                  mb: "2rem",
                  mr: "1rem",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  alt="plan image"
                  src={companyDetail.logo}
                  sx={{ width: "100%", height: "90%" }}
                >
                  <AssignmentIcon sx={{ width: "80%", height: "80%" }} />
                </Avatar>
              </ButtonBase>

              <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                  <Img src={companyDetail.logo} />
                </Box>
              </Modal>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Typography
                        variant="h6"
                        color="primary.light"
                        sx={{ ml: "1rem", pt: "1rem" }}
                      >
                        {t("companyDetails.title")}
                      </Typography>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                        sx={{
                          ml: "0.5rem",
                          pt: "1rem",
                        }}
                      ></Stack>
                    </Grid>

                    <Divider light sx={{ marginBlock: "1rem" }} />

                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <BusinessIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("companyDetails.id")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {companyDetail.id}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <CorporateFareIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("companyDetails.name")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {companyDetail.name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <CategoryIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("companyDetails.companyType")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {companyDetail.type_name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <WorkHistoryIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("companyDetails.created")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {companyDetail.createdAt}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <WorkHistoryIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("companyDetails.updated")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {companyDetail.lastUpdate}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Box alignItems="baseline">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <DescriptionIcon sx={styleIcon} />
                          <Typography variant="subtitle1">
                            {t("companyDetails.description")}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          sx={{ ml: "2rem" }}
                        >
                          <Typography variant="subtitle1" color="grays.darkest">
                            {companyDetail.description}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </CardContent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}
