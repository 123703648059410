import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import StadiumIcon from '@mui/icons-material/Stadium';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDelete from "../../components/city-management-components/ModalConfirmDelete";

const HandleCityActions = ({ userId , data, fetchCity }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenActive, setIsOpenActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsOpen(false);
    navigate(`/listManagement`);
  };

  const handleCloseModalActive = () => {
    setIsOpenActive(false);
    navigate(`/listManagement`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDelete = () => {
    handleClose();
    setIsOpen(true);
  };
  
  const handleClickAcive = () => {
    handleClose();
    setIsOpenActive(true);
  };

  const handleClickEdit = () => {
    handleClose();
    navigate(`/cityManagement`, { state: { mode: "edit", data: data, userId: userId } });
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 175,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("cityOptions.item-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            {data.active ? <HighlightOffIcon /> : <StadiumIcon />}
          </ListItemIcon>
          <ListItemText>{data.active ?t("cityOptions.item-2") : t("cityOptions.item-3")}</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmDelete isOpen={isOpen} handleClose={handleCloseModal} userId={userId} data={data} fetchCity={fetchCity}/> 
    </>
  );
};

const HeadersCity = (fetchCity) => {
  const { t } = useTranslation();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 100,
      renderCell: (params) => {
        const userId = params?.row?.id;
        return <HandleCityActions data={params?.row} userId={userId} fetchCity={fetchCity}/>;
      },
    },
    {
      field: "id",
      headerName: `ID`,
      width: 100,
    },
    {
      field: "name",
      headerName: t("roleList.headers-1"),
      width: 200,
    },
    {
      field: "active",
      headerName: t("roleList.headers-5"),
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value === true ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        );
      },
    }
  ];
  return columns;
};

export default HeadersCity;
