export const zoneDetailsMapping = (data) => {

  const invertedCoordinates = data?.geo_area?.coordinates[0].map(coord => [coord[1], coord[0]]);
  const invertedCenter = [data?.geo_area?.coordinates[0][0][1], data?.geo_area?.coordinates[0][0][0]];

  const zoneMapped = {
    id: data.id,
    name: data.name,
    location: data.location,
    country: data?.country?.name,
    department: data?.department?.name,
    city: data?.city?.name,
    center: invertedCenter,
    coordinates: invertedCoordinates,
  };
  
  return zoneMapped;
}