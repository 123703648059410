import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Alert,
  Card,
  CardContent,
  Stack,
  Grid,
  TextField,
  Autocomplete,
  Snackbar,
  Divider,
  Chip,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  Checkbox,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CreateAccountService } from "../../services/CreateAccountService";
import { ResumeRegisterService } from "../../services/ResumeRegisterService";
import { GetAccountByIdService } from "../../services/GetAccountByIdService";
import { UpdateAccountService } from "../../services/UpdateAccountService";
import { getCountryList } from "../../helpers/GetCountryList";
import { DocumentTypeListService } from "../../services/DocumentTypeListService";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ValidationStepOneModal from "./ValidationStepOneModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useForm } from "../../context/CreateAccountFormContext";
import dayjs, { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";
import { CollectionsBookmarkOutlined, Edit } from "@mui/icons-material";

const DICTIONARIES_URL = import.meta.env.VITE_DICTIONARIES_URL;
const label = { inputProps: { "aria-label": "Checkbox" } };

export default function PersonalInformationForm({
  isUserCreated,
  setIsUserCreated,
  email,
  mode,
  handleAccountUpdated
}) {
  // ... Estado y funciones existentes

  // Agregar campos para la aprobación de documentos y edición de información
  const [documentApproval, setDocumentApproval] = useState(false);
  const [editInformation, setEditInformation] = useState(false);

  // Función para manejar el cambio en la aprobación de documentos
  const handleDocumentApprovalChange = (event) => {
    setDocumentApproval(event.target.checked);
  };

  // Función para manejar el cambio en la edición de información
  const handleEditInformationChange = (event) => {
    setEditInformation(event.target.checked);
  };

  // ... Resto del código existente

  return (
    <Stack
      mt={{ xs: 0, md: 2 }}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
       <Card
    elevation={0}
    sx={{
      p: "1rem",
      mr: { xs: "0.4rem", sm: "10rem", md: "10rem" },
      mt: { xs: "2rem", md: "0" },
      maxWidth: "800px",
    }}
  >
    <CardContent>
      {/* ... Resto de los componentes del formulario */}
      <FormControlLabel
        control={
          <Checkbox
            checked={documentApproval}
            onChange={handleDocumentApprovalChange}
            name="documentApproval"
          />
        }
        label="Aprobar Documentos"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={editInformation}
            onChange={handleEditInformationChange}
            name="editInformation"
          />
        }
        label="Editar Información"
      />
      {/* Botón para enviar el formulario */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
      >
        Guardar
      </Button>
    </CardContent>
  </Card>
</Stack>
);
}