import { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Card,
  CardContent,
  Grid,
  Button,
  Autocomplete,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getToken } from "../../utils/GetToken";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { UpdateUserService } from "../../services/UpdateUserService";
import { GetUserData } from "../../services/GetUserService";
import UserAvatarInfo from "../common/UserAvatarInfo";
import { AuthContext } from "../../context/AuthContext";

export default function EditUserLayout() {
  const [reloadUserData, setReloadUserData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [reloadUserAvatarInfo, setReloadUserAvatarInfo] = useState(false);
  const { userUpdate } = useContext(AuthContext);

  const [user, setUser] = useState({});
  const [userId, setUserId] = useState({});
  const [formValues, setFormValues] = useState({
    forename: "",
    surname: "",
    date_of_birth: "",
  });
  const [isUserUpdated, setIsUserUpdated] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const getUserData = async () => {
    try {
      const token = await getToken();
      const data = await GetUserData(token);
      setUser(data);
      const formattedDate = dayjs(data.birthday).format("DD/MM/YYYY");
      const mappedItem = {
        forename: data.forename,
        surname: data.surname,
        date_of_birth: formattedDate,
      };
      setFormValues(mappedItem);
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    setUserId(user?.id);
  }, [user]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("This is data to submit:", formValues);
      const token = await getToken();
      setLoading(true);
      const response = await UpdateUserService(formValues, token, userId);
      console.log("Backend Response:", response);
      setSuccessMessage(response.data.messge);
      setIsUserUpdated(true);
      userUpdate();
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    } finally {
      setLoading(false);
    }
    console.log("Submit");
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    console.log("Formatted Day", formattedDate);
    setFormValues((prevValues) => ({
      ...prevValues,
      date_of_birth: formattedDate,
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsUserUpdated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (isUserUpdated) {
      const redirectTimer = setTimeout(() => {
        navigate(`/usersManagement?tab=0`);
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isUserUpdated, navigate]);

  return (
    <>
      <Snackbar
        open={isUserUpdated}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success"> {t("EditUserInfo.success.message")}</Alert>
      </Snackbar>
      {/* Error Response Alert*/}

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack>
        <Typography variant="subtitle-1" sx={{ textAlign: "center" }}>
          {t("updateUser.subtitle")}
        </Typography>
        <Stack
          mt={{ xs: 0, md: 2 }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            elevation={4}
            sx={{
              p: "1rem",
              mt: { xs: "2rem", md: "0" },
              maxWidth: "800px",
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="forename"
                      label={t("createAccount.form.forename")}
                      size="small"
                      value={formValues.forename || ""}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="surname"
                      label={t("createAccount.form.surname")}
                      size="small"
                      value={formValues.surname || ""}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={t("createAccount.form.dateOfBirth")}
                        slotProps={{
                          textField: { size: "small", fullWidth: true },
                        }}
                        format="DD/MM/YYYY"
                        disableFuture
                        value={
                          formValues.date_of_birth !== ""
                            ? dayjs(formValues.date_of_birth, "DD/MM/YYYY")
                            : null
                        }
                        onChange={handleDateChange}
                        autoFocus={false}
                        openTo="year"
                        views={["year", "month", "day"]}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container mt={2} sx={{ textAlign: "center" }} gap={2}>
                  <Grid item xs>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        type="submit"
                        variant="contained"
                        color={isUserUpdated ? "success" : "secondary"}
                        size="large"
                        disabled={isUserUpdated}
                        startIcon={
                          loading && (
                            <CircularProgress
                              size="1.5rem"
                              sx={{ color: "white" }}
                            />
                          )
                        }
                      >
                        {isUserUpdated ? t("saved.button") : t("update.button")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </>
  );
}
