import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Skeleton,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getToken } from "../../utils/GetToken";
import { GetTaxByIdService } from "../../services/Taxes/GetTaxByIdService";
import { useParams } from "react-router-dom";

export default function TaxDetails() {
  const [loading, setLoading] = useState(false);
  const [taxDetail, setTaxDetail] = useState({});
  // const [permissions, setPermissions] = useState([]);
  const { t } = useTranslation();
  const { taxId } = useParams();

  useEffect(() => {
    const taxDetails = async () => {
      try {
        const token = await getToken();
        if (token) {
          const response = await GetTaxByIdService(token, taxId);
          const { data } = response;
          console.log(data);
          const tax = {
            id: data?.id,
            tax_type: data?.tax_type?.name,
            title: data?.title,
            description: data?.description,
            value: data?.value,
            details: data?.details,
          };
          setTaxDetail(tax);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    taxDetails();
  }, []);

  // useEffect(() => {
  //   console.log(taxDetail);
  // }, [taxDetail]);

  return (
    <>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={400}
          sx={{
            width: { xs: "320px", sm: "320px", md: "500px", xl: "872px" },
            borderRadius: "20px",
            mx: "1rem",
          }}
        />
      ) : (
        <Card
          elevation={5}
          sx={{
            borderRadius: "20px",
            mx: "1rem",
            height: "auto",
            p: { xs: "0.5rem", sm: "2rem" },
          }}
        >
          <CardContent>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h6" color="primary.light">
                {t("taxes.details.title")}
              </Typography>
            </Stack>
            <Divider light sx={{ marginBlock: "1rem" }} />
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle1">
                  {`${t("taxes.details.title-1")}:`}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="subtitle1" color="grays.medium">
                    {taxDetail.id}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle1">
                  {`${t("taxes.details.title-2")}:`}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="subtitle1" color="grays.medium">
                    {taxDetail.title}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle1">
                  {`${t("taxes.details.title-3")}:`}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="subtitle1" color="grays.medium">
                    {taxDetail.description}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle1">
                  {`${t("taxes.details.title-4")}:`}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="subtitle1" color="grays.medium">
                    {taxDetail.tax_type}
                  </Typography>
                </Stack>
              </Stack>
              <Box
                sx={{
                  display:
                    taxDetail.tax_type === "Range and conditions"
                      ? "none"
                      : "block",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle1">
                    {`${t("taxes.details.title-5")}:`}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle1" color="grays.medium">
                      {taxDetail.value}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box
                sx={{
                  display:
                    taxDetail.tax_type === "Range and conditions"
                      ? "block"
                      : "none",
                }}
              >
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle1">
                      {`${t("taxes.details.title-7")}:`}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1" color="grays.medium">
                        {taxDetail?.details?.period_label}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle1">
                      {`${t("taxes.details.title-8")}:`}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1" color="grays.medium">
                        {taxDetail?.details?.min_sales}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle1">
                      {`${t("taxes.details.title-9")}:`}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1" color="grays.medium">
                        {taxDetail?.details?.tax_under_sales}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle1">
                      {`${t("taxes.details.title-10")}:`}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1" color="grays.medium">
                        {taxDetail?.details?.tax_over_sales}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
}
