import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Alert,
  Card,
  CardContent,
  Grid,
  TextField,
  Snackbar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { SuspendCommerceService } from "../../services/SuspendCommerceService";

export default function SuspendCommerce({
  id,
  handleCloseDialog,
  handleSuspend,
}) {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState(false);
  const [isCommerceSuspend, setIsCommerceSuspend] = useState(false);
  const [formValues, setFormValues] = useState({
    observation: "",
  });

  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    suspendCommerce();
  };

  const suspendCommerce = async () => {
    try {
      const token = await getToken();
      setLoading(true);
      const response = await SuspendCommerceService(token, id, formValues);
      console.log(response);
      // handleSuspend();
      setIsCommerceSuspend(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const setResponseStatus = (response) => {
  //   if (response.status === 200) {
  //     setIsCommerceUpdated(true);
  //     setLoading(false);
  //   } else if (response.status === 422) {
  //     setError(true);
  //     setLoading(false);
  //   } else {
  //     setError(true);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if (isCommerceSuspend) {
      handleCloseDialog();
      const redirectTimer = setTimeout(() => {
        window.location.reload();
      }, 1500);
      return () => clearTimeout(redirectTimer);
    }
  }, [isCommerceSuspend]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertError(false);
  };

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: "20px",
        mt: { xs: "2rem", md: 0 },
      }}
    >
      <CardContent>
        {/* Invalid Form Alert */}
        {/* <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" onClose={() => setShowAlert(false)}>
            {t("updateAccount.form.invalidForm")}
          </Alert>
        </Snackbar> */}
        {/* User Created Alert*/}
        <Snackbar
          open={isCommerceSuspend}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{t("suspendAccount.success")}</Alert>
        </Snackbar>
        {/* Error Response Alert*/}
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">Error en la suspensión del comercio</Alert>
        </Snackbar>
        {/* Form Create New Account */}
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <TextField
                name="observation"
                label={t("suspendAccount.observation")}
                value={formValues.observation}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Button
              type="submit"
              variant="contained"
              color={isCommerceSuspend ? "success" : "secondary"}
              size="large"
              sx={{ width: "100%" }}
              startIcon={
                loading ? (
                  <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                ) : (
                  <CheckCircleIcon sx={{ color: "white" }} />
                )
              }
            >
              {t("update.button")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
