import {
  Card,
  CardContent,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Divider,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetBusinessStatsServices } from "../../services/Stats/GetBusinessStatsServices.js";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getToken } from "../../utils/GetToken.js";
import { Pie } from "react-chartjs-2";
import BusinessIcon from "@mui/icons-material/Business";

export default function BusinessStatsByType() {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState("");

  const getBusinessStatsByType = async () => {
    const token = await getToken();
    const response = await GetBusinessStatsServices(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["byType"],
    queryFn: () => getBusinessStatsByType(),
  });

  const totalTypeOfBusiness = response?.byType || [];

  const capitalLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const countOfBusinessType = totalTypeOfBusiness?.reduce((types, business) => {
    const typeName = business.type?.name.es;
    const capitalizedTypeName = capitalLetter(typeName);
    if (!types[capitalizedTypeName]) {
      types[capitalizedTypeName] = 0;
    }
    types[capitalizedTypeName] += business.count;
    return types;
  }, {});

  const businessTypes = Object.keys(countOfBusinessType);

  const handleBusinessTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const getCountForSelectedType = () => {
    return countOfBusinessType[selectedType] || 0;
  };

  const typeColors = {
    Empresa: "#1D417A",
    "Empresa de seguridad": "#6BE58A",
    "Persona natural": "#FF6660",
  };

  const data = {
    labels: businessTypes,
    datasets: [
      {
        data: businessTypes.map((type) => countOfBusinessType[type]),
        backgroundColor: businessTypes.map(
          (type) => typeColors[type] || "#A054D2"
        ),
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          padding: 15,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("businessResumen.title-2")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("businessCard.subtitle-4")}</Typography>
        <Stack
          display="flex"
          flexDirection={"row"}
          justifyContent="center"
          alignItems="center"
          mt={1}
        >
          <FormControl sx={{ m: 1, minWidth: 175, mt: "1rem" }} size="small">
            <InputLabel>{t("businessType.title")}</InputLabel>
            <Select
              value={selectedType}
              onChange={handleBusinessTypeChange}
              displayEmpty
              label={t("businessType.title")}
            >
              {businessTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {capitalLetter(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedType && (
            <Stack direction="row" spacing={1} alignItems="center">
              <BusinessIcon color="success" />
              <Typography variant="h5" color={"success.main"}>
                {isLoading || isError ? "-" : getCountForSelectedType()}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Stack sx={{ height: "240px", alignItems: "center", mt: "2rem" }}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Pie data={data} options={options} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
