import { useState, useEffect, forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Slide,
  Typography,
} from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ShowDocumentService } from "../../services/DocumentsManagement/ShowDocumentService";
import { getToken } from "../../utils/GetToken";
import ReviewDocument from "../documents-components/ReviewDocument";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReviewDocumentLayout({
  docId,
  setClickReview,
  documentListBy,  
}) {
  const [openDialog, setOpenDialog] = useState(true);

  const [doc, setDoc] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
      setClickReview(false);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getDocument();
  }, []);

  // useEffect(() => {
  //   documentStateApproved && handleDocApproved();
  // }, [documentStateApproved])

  const getDocument = async () => {
    try {
      const token = await getToken();
      if (token) {
        const data = await ShowDocumentService(token, docId, documentListBy);
        setDoc(data);
        // setLoading(false);
        console.log(data);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
    } finally {
    }
  };

  return (
    <>
      {doc !== null && (
        <Dialog
          disableEscapeKeyDown
          open={openDialog}
          TransitionComponent={Transition}
          onClose={handleCloseDialog}
        >
          <DialogTitle>{doc && doc.description}</DialogTitle>
          <DialogContent>
            {doc !== null && (
              <ReviewDocument
                doc={doc}
                handleClose={handleClose}
                documentListBy={documentListBy}
              />
            )}
          </DialogContent>
          <DialogActions sx={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                handleCloseDialog();
              }}
            >
              {t("cancel.button")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
