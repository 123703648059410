// ImageUploadModal.js

import { useState, useEffect } from "react";
import { getToken } from "../../utils/GetToken";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import UploadImagePlanService from "../../services/Plans/UploadImagePlanService";
import { useNavigate } from "react-router-dom";
import { usePlan } from "../../context/PlanContext";
//import { usePlan } from "../../context/PlanContext";

export default function ImageUploadModal({ planId }) {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  // const [isPlanImageUpload, setIsPlanImageUpload] = useState(false);
  const { handleIsPlanImageUpload, isPlanImageUpload } = usePlan();

  //const { createdPlanId} = usePlan();
  const navigate = useNavigate();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleUploadImage = async (e) => {
    // console.log("Nueva imagen seleccionada:", file);
    setLoading(true);
    if (selectedImage) {
      if (selectedImage.size > 5 * 1024 * 1024) {
        alert("El tamaño de la imagen no puede ser mayor a 5MB");
        return;
      } else {
        const allowedFormats = [".png", ".jpg", ".jpeg", ".svg"];
        const fileExtension = selectedImage.name
          .substring(selectedImage.name.lastIndexOf("."))
          .toLowerCase();

        if (!allowedFormats.includes(fileExtension)) {
          alert("El formato del archivo no es válido");
          return;
        } else {
          try {
            const token = await getToken();
            if (token && selectedImage) {
              const response = await UploadImagePlanService(
                token,
                planId,
                selectedImage
              );
              console.log(response, "imagen subida");
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        }
      }
    }
    handleCloseDialog();
    handleIsPlanImageUpload();
  };

  const handleCloseImage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleIsPlanImageUpload();
  };

  useEffect(() => {
    if (selectedImage) {
      const redirectTimer = setTimeout(() => {
        navigate(`/plansManagement?tab=0`);
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
  }, [selectedImage]);

  return (
    <>
     <Snackbar
        open={isPlanImageUpload}
        autoHideDuration={6000}
        onClose={handleCloseImage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {t("UploadImagenPlan.success.message")}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle> {t("plan.createNew.imageUpload")}</DialogTitle>
        <DialogContent>
          <TextField
            type="file"
            onChange={handleImageChange}
            variant="outlined"
            sx={{ mt: "1rem" }}
          />
          <Button
            variant="contained"
            sx={{ mt: "1rem", padding: "10px", ml: "1rem" }}
            onClick={handleUploadImage}
          >
            Cargar Imagen
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
