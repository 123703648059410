import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const ShowDocumentService = (token, docId, documentListBy) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "";
      if (documentListBy === "client") {
        url = `${URL_AUTH}/api/admin/v1/client/document/${docId}`;
      } else if (documentListBy === "agent") {
        url = `${URL_AUTH}/api/admin/v1/agent/document/${docId}`;
      }
      const doc = await axios.get(
        url,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(doc);
      resolve(doc.data.data);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};