import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDelete from "../../components/admin-management-components/ModalConfirmDelete";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const HandleUserActions = (props) => {
  const [clickUserDetails, setClickUserDetails] = useState(false);
  const [clickEditRoles, setClickEditRoles] = useState(false);
  const [clickDeleteRole, setClickDeleteRole] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { userId, accountId, data } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickRoleDetails = () => {
    handleClose();
    setClickUserDetails(true);
  };

  const handleClickEditRoles = () => {
    handleClose();
    setClickEditRoles(true);
  };

  const handleClickDelete = () => {
    handleClose();
    setIsOpen(true);
  };

  const handleClickEdit = () => {
    handleClose();
    navigate(`/AdminAccount`, { state: { mode: "edit", data: data, userId: userId } });
  };

  useEffect(() => {
    clickEditRoles && navigate(`/rolesByUser/${userId}`,{ state: { data: data} });
      
  }, [clickUserDetails, clickEditRoles, clickDeleteRole]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 175,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClickEditRoles}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText>{t("usersOptions.item-2")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("userOptions.item-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("userOptions.item-2")}</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmDelete isOpen={isOpen} handleClose={handleCloseModal} userId={userId} data={data}/> 
    </>
  );
};

const HeadersUsers = () => {
  const { t } = useTranslation();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 100,
      renderCell: (params) => {
        const userId = params?.row?.id;
        return <HandleUserActions data={params?.row} userId={userId} />;
      },
    },
    {
      field: "id",
      headerName: `ID`,
      width: 100,
    },
    {
      field: "forename",
      headerName: t("superadminList.headers-1"),
      width: 200,
    },
    {
      field: "surname",
      headerName: t("superadminList.headers-2"),
      width: 200,
    },
    {
      field: "email",
      headerName: t("superadminList.headers-3"),
      width: 250,
    },
    // {
    //   field: "phone",
    //   headerName: t("superadminList.headers-4"),
    //   width: 250,
    // },
    // {
    //   field: "document",
    //   headerName: t("superadminList.headers-5"),
    //   width: 250,
    // },
  ];
  return columns;
};

export default HeadersUsers;
