import axios from "axios";

const URL_DICTIONARIES = import.meta.env.VITE_AUTH_DICTIONARIES;

export function UpdateZoneService(token, data, zoneId){
  return new Promise(async (resolve, reject) => {
    try {
      // console.log(data);
      const response = await axios.put(
        `${URL_DICTIONARIES }/api/locations/v1/zones/${zoneId}`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}