import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function GetAllClientsService(token, page, query, pagination) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("Query from datagrid", query);
  
      let url;
   
      if (query === "") {
        console.log("URL without params inside");
        url = `${URL_AUTH}/api/admin/v1/client?page=${page}&pp=${pagination}`;
      } else {
        console.log("URL with params inside", query);
        url = `${URL_AUTH}/api/admin/v1/client?${query}&page=${page}&pp=${pagination}`;
      }
      const response = await axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
       
      });
      // console.log("request from service getClients:", response.data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
