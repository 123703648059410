import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";

const TokenExpirationChecker = () => {
  const navigate = useNavigate();
  const [tokenExpired, setTokenExpired] = useState(false);
  const { t } = useTranslation();
  const { rememberMe } = useContext(AuthContext);

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        const expirationTime = rememberMe
          ? 7 * 24 * 60 * 60 * 1000
          : 240 * 60 * 1000;
        const storedTime = localStorage.getItem("refreshTokenTime");
        const currentTime = new Date().getTime();
        if (currentTime - storedTime > expirationTime) {
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("refreshTokenTime");
          setTokenExpired(true);
        }
//        console.log(expirationTime, rememberMe);
      }
    }, 1000);

    return () => clearInterval(checkTokenExpiration);
  }, [rememberMe]);

  useEffect(() => {
    if (tokenExpired) {
      const redirectTimer = setTimeout(() => {
        window.location.reload();
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
  }, [navigate, tokenExpired]);

  return (
    <>
      <Snackbar
        open={tokenExpired}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          {t("expiredSession")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TokenExpirationChecker;
