import { createContext, useState, useContext } from "react";

export const MapContext = createContext();

export const useMarkers = () => useContext(MapContext);

export const MapProvider = ({ children }) => {
  const [activeMarkers, setActiveMarkers] = useState({});
  const [clientInfo, setClientInfo] = useState(null);
  const [clientModalOpen, setClientModalOpen ] = useState(false);

  const handleActiveMarkers = (markers) => {
    setActiveMarkers(markers);
  }

  const handleClientInfo = (data) => {
    setClientInfo(data);
  }

  const handleClientModalOpen = () => {
    resetData();
    setClientModalOpen(!clientModalOpen);
  }

  const resetData = () => {
    setActiveMarkers({});
    setClientInfo(null);
    setClientModalOpen(false);
  }

  return (
    <MapContext.Provider
      value={{
        handleActiveMarkers,
        activeMarkers,
        handleClientInfo,
        clientInfo,
        resetData,
        clientModalOpen,
        handleClientModalOpen,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
