import { useState, useEffect } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TaskIcon from "@mui/icons-material/Task";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import MenuIcon from "@mui/icons-material/Menu";
import SuspendCommerceLayout from "../../components/layout/SuspendCommerceLayout";
import StatusTranslator from "../StatusTranslator";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import EditIcon from "@mui/icons-material/Edit";

const HandleCommerceActions = (props) => {
  const [clickTransactions, setClickTransactions] = useState(false);
  const [clickApproveDocuments, setClickApproveDocuments] = useState(false);
  const [clickSuspendCommerce, setClickSuspendCommerce] = useState(false);
  const [clickContinueReg, setClickContinueReg] = useState(false);
  const [clickEditCommerce, setClickEditCommerce] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { state, shopId } = props;
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickTransactions = () => {
    setClickTransactions(true);
  };

  const handleClickDocuments = () => {
    setClickApproveDocuments(true);
  };

  const handleClickSuspend = () => {
    handleClose();
    setClickSuspendCommerce(true);
  };

  const handleClickContinueReg = () => {
    handleClose();
    setClickContinueReg(true);
  };

  const handleClickEdit = () => {
    handleClose();
    setClickEditCommerce(true);
  };

  useEffect(() => {
    clickTransactions &&
      navigate(`/transactionsByShop/${shopId}`);
    clickApproveDocuments && navigate(`/documentList/${shopId}`);
    clickContinueReg &&
      navigate(`/resumeCommerceRegister/${shopId}`, {
        state: { mode: "continueReg" },
      });
      clickEditCommerce &&
      navigate(`/editCommerce/${shopId}`, {
        state: { mode: "edit" },
      });
  }, [clickTransactions, clickApproveDocuments, clickContinueReg, clickEditCommerce]);

  // useEffect(() => {
  //   console.log("suspend", shopId);
  // }, [clickSuspendCommerce]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 218,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClickTransactions}>
          <ListItemIcon>
            <PaidIcon />
          </ListItemIcon>
          <ListItemText>{t("commercesOptions.item-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickDocuments}>
          <ListItemIcon>
            <TaskIcon />
          </ListItemIcon>
          <ListItemText>{t("commercesOptions.item-2")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickSuspend}>
          <ListItemIcon>
            <DoDisturbIcon />
          </ListItemIcon>
          <ListItemText>{t("commercesOptions.item-3")}</ListItemText>
        </MenuItem>
        {(state === "NEW" || state === "APPR") && <MenuItem onClick={handleClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("commercesOptions.item-4")}</ListItemText>
        </MenuItem>}
        {state === "REGN" && (
          <MenuItem onClick={handleClickContinueReg}>
            <ListItemIcon>
              <ContactPageIcon />
            </ListItemIcon>
            <ListItemText>{t("commercesOptions.item-5")}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      {clickSuspendCommerce && (
        <SuspendCommerceLayout
          shopId={shopId}
          clickSuspendCommerce={clickSuspendCommerce}
          setClickSuspendCommerce={setClickSuspendCommerce}
        />
      )}
    </>
  );
};

const HeadersCommerceList = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("i18nextLng");

  const columns = [
    {
      field: "actions",
      headerName: t("header.actions"),
      type: "actions",
      // width: 80,
      renderCell: (params) => (
        <HandleCommerceActions
          state={params.row.state}
          shopId={params.row.id}
        />
      ),
    },
    { field: "id", headerName: t("commerceList.headers-1"), width: 150 },
    {
      field: "accountId",
      headerName: t("commerceList.headers-2"),
      width: 200,
    },
    {
      field: "state",
      headerName: t("commerceList.headers-8"),
      width: 140,
      renderCell: (params) => {
        const value = params.value;
        const translatedValue = StatusTranslator(value, currentLanguage);
        return (
          <Chip
            label={translatedValue}
            variant="outlined"
            color={
              value === "APPR"
                ? "success"
                : value === "SUSP"
                ? "error"
                : value === "NEW"
                ? "info"
                : value === "REGN"
                ? "primary"
                : "warning"
            }
          />
        );
      },
    },
    {
      field: "name_shop",
      headerName: t("commerceList.headers-3"),
      width: 250,
    },
    {
      field: "countryId",
      headerName: t("commerceList.headers-4"),
      sortable: false,
      width: 150,
    },
    {
      field: "email",
      headerName: t("commerceList.headers-5"),
      sortable: false,
      width: 220,
    },
    {
      field: "web",
      headerName: t("commerceList.headers-6"),
      sortable: false,
      width: 300,
    },
    {
      field: "description",
      headerName: t("commerceList.headers-7"),
      sortable: false,
      width: 300,
    },
  ];
  return columns;
};

export default HeadersCommerceList;
