import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDeleteZone from "../../components/zones-components/ConfirmDeleteZone";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteCompanies from "../../components/companies-management-components/ConfirmDeleteCompanies";


const HandleActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickSeeMore, setClickSeeMore] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { companyId, data } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeeMore = () => {
    setClickSeeMore(true);
    setAnchorEl(null);
  };
  const handleClickDelete = () => {
    handleClose();
    setIsOpen(true);
  };

  const handleClickEdit = () => {
    handleClose();
    navigate(`/editCompany/${companyId}`, {
      state: { mode: "edit", data: data },
    });
  };
 

  useEffect(() => {
    if (clickSeeMore) {
      navigate(`/companyDetails/${companyId}`);
    }
  }, [clickSeeMore]);


  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 184,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleSeeMore}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>{t("companies.table.option-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("companies.table.option-2")}</ListItemText>
        </MenuItem> 

        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("companies.table.option-3")}</ListItemText>
        </MenuItem> 
        <ConfirmDeleteCompanies
          isOpen={isOpen}
          handleClose={handleCloseModal}
          companyId={companyId}
          data={data}
        />
      </Menu>
    </>
  );
};

export const HeadersCompanies = () => {
  const currentLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 60,
      renderCell: (params) => <HandleActions companyId={params.row.id} />,
    },
    {
      field: "id",
      headerName: "Id",
      width: 100,
    },
    {
      field: "name",
      headerName: t("companies.table.header-1"),
      width: 200,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", overflowWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "description",
      headerName: t("companies.table.header-2"),
      width: 200,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", overflowWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "type_name",
      headerName: t("companies.table.header-3"),
      width: 200,
   
    },
    {
      field: "type_active",
      headerName: t("companies.table.header-4"),
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return params.value === true ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        );
      },
    },
    {
      field: "type_order",
      headerName: t("companies.table.header-5"),
      sortable: false,
      width: 160,
    },
    {
      field: "createdAt",
      headerName: t("companies.table.header-6"),
      width: 200,
    },
    {
      field: "lastUpdate",
      headerName: t("companies.table.header-7"),
      width: 200,
    },
    
  ];
  return columns;
};
