import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const UpdateDocumentService = (
  token,
  data,
  docReference,
  uploadBy,
  userId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "";
      if (uploadBy === "client") {
        url = `${URL_AUTH}/api/admin/v1/client/${userId}/document/${docReference}/update`;
      } else if (uploadBy === "agent") {
        url = `${URL_AUTH}/api/admin/v1/agent/${userId}/document/${docReference}/update`;
      }
      const response = await axios.post(url, data, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      resolve(response);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};
