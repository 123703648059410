import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  Skeleton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Box,
  ButtonBase,
  Avatar,
  Modal,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { useParams } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import { GetAccompanimentDetailsService } from "../../services/Accompaniment/GetAccompanimentDetailsService";
import dayjs from "dayjs";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PlaceIcon from '@mui/icons-material/Place';

export default function AccompanimentDetails() {
  const [loading, setLoading] = useState(true);
  const [elementDetail, setElementDetail] = useState({});
  const { t } = useTranslation();
  const { elementId } = useParams();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCOnXy5-8ziMuM8W0fi5TCkPJWe3PtXNeI",
  });
  useEffect(() => {
    const elementDetails = async () => {
      try {
        const token = await getToken();
        if (token) {
          const data = await GetAccompanimentDetailsService(token, elementId);
          const createdAt = !!data?.createdAt ? dayjs(data.createdAt).format("DD/MM/YYYY H:m:s A") : "";
          const startDate = !!data?.startDate ? dayjs(data.startDate).format("DD/MM/YYYY H:m:s A") : "";
          const endDate = !!data?.endDate ? dayjs(data?.endDate).format("DD/MM/YYYY H:m:s A") : "";
          const coordinates = data?.endPoint?.coordinates;       
          const element = {
            id: data.id,
            state: data?.state,
            title: data?.type?.name,
            duration: data?.duration,
            contacts: data?.contacts,
            description: data?.description,
            endDate: endDate,
            startDate: startDate,
            createdAt: createdAt,
          };
          if (Array.isArray(coordinates) && coordinates.length === 2 && typeof coordinates[0] === "number" && typeof coordinates[1] === "number") {
            element.yCoordinate = coordinates[0];
            element.xCoordinate = coordinates[1];
          }
          setElementDetail(element);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    elementDetails();
  }, []);


  return (
    <>
      {loading ? (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
         <CircularProgress />
        </div>
      ) : (
        <Card
          loading={loading}
          elevation={5}
          sx={{
            borderRadius: "20px",
            mx: "1rem",
            height: "auto",
            p: { xs: "0.5rem", sm: "2rem" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6" color="primary.light">
                          {t("accompaniment.details.title")}
                        </Typography>
                      </Grid>
                      
                    </Grid>

                    <Divider light sx={{ marginBlock: "1rem" }} />

                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AssignmentIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("accompaniment.name") + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.title}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <EventAvailableRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("accompaniment.creation_date") + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.createdAt}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AppsRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("accompaniment.status") + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest" className="capitalize">
                            {elementDetail?.state}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <EventRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("accompaniment.start_date") + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.startDate}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <EventRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("accompaniment.end_date") + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.endDate}
                          </Typography>
                        </Stack>
                      </Stack>
                    
                      <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                        >
                          <DescriptionIcon
                            fontSize="medium"
                            sx={{ color: "primary.light" }}
                          />
                          <Typography variant="subtitle1">
                            {t("accompaniment.description") + ':'}
                          </Typography>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1" color="grays.darkest">
                              {elementDetail?.description}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                        >
                          <PlaceIcon
                            fontSize="medium"
                            sx={{ color: "primary.light" }}
                          />
                          <Typography variant="subtitle1">
                            {t("accompaniment.location") + ':'}
                          </Typography>
                        </Stack>
                        {(isLoaded && !!elementDetail?.xCoordinate && !!elementDetail?.yCoordinate) 
                          ? <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <CardContent>
                                {/* First map */}
                                <GoogleMap
                                  mapContainerStyle={{ height: "400px", width: "100%" }}
                                  center={{lat: elementDetail?.xCoordinate, lng: elementDetail?.yCoordinate}} // Set your coordinates here
                                  zoom={10} // Set initial zoom level
                                >
                                  <Marker position={{lat: elementDetail?.xCoordinate, lng: elementDetail?.yCoordinate}} /> {/* Marker for first coordinates */}
                                </GoogleMap>
                              </CardContent>
                            </Grid>
                          </Grid>
                          : <Typography variant="subtitle1" color="grays.darkest">
                            {t("accompaniment.location_not_found")}
                          </Typography>
                        }

                    </Stack>
                    
                  </CardContent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Card>
      )}
    </>
  );
}
