import Axios from "axios";

const URL_CLIENT = import.meta.env.VITE_AUTH_CLIENT;

export function CreateAlertService(data, token) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(data);
      const response = await Axios.post(
        `${URL_CLIENT}/api/admin/v1/alert/type/create`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
}
