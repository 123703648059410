import axios from "axios";

const URL_CLIENT = import.meta.env.VITE_AUTH_CLIENT;

export function GetAlertStatsServices(token){
  return new Promise(async (resolve, reject) => {
    try {
  
      const response = await axios.get(`${URL_CLIENT}/api/admin/v1/stats/monthly-alerts`, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(response);
      console.log(response);
      console.log("Alert Stats", response.data);
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
}