import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import BadgeIcon from "@mui/icons-material/Badge";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

export default function BuyerPayerInfoCard(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState();

  useEffect(() => {
    setTransaction(props.transaction);
    setLoading(props.loading);
  }, [props]);

  return (
    <>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={400}
          sx={{
            width: { xs: "320px", sm: "320px", md: "500px", xl: "872px" },
            borderRadius: "20px",
          }}
        />
      ) : (
        <Card
          elevation={5}
          sx={{
            borderRadius: "20px",
            mx: { xs: "0rem", sm: "1rem" },
            height: "auto",
          }}
        >
          <CardContent>
            <Stack direction="row" alignItems="center" spacing={1}>
              <PermIdentityIcon sx={{ color: "primary.light" }} />
              <Typography variant="h6" color="primary.light">
                {t("buyerAndPayerCard.buyer.title")}
              </Typography>
            </Stack>
            <Divider light sx={{ marginBlock: "1rem" }} />
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <PersonIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("buyerAndPayerCard.fullname")}:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.buyerInfo.fullName}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <EmailIcon sx={{ color: "primary.light" }} />
                <Typography variant="subtitle1">Email:</Typography>
                <Link>{transaction.buyerInfo.email}</Link>
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <BadgeIcon sx={{ color: "primary.light" }} />
                  <Stack direction="row" spacing={1} alignItems="baseline">
                    <Typography variant="subtitle1">
                      {t("createAccount.form.document.type")}:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      color="grays.medium"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {transaction.buyerInfo.document_type}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <BadgeIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("createAccount.form.document.number")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.buyerInfo.document_number}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <PhoneIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("accountsAndMerchants.table.header-7")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.buyerInfo.telephone}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Divider sx={{ marginBlock: "1rem" }} />
            <Stack direction="row" alignItems="center" spacing={1}>
              <PermIdentityIcon sx={{ color: "primary.light" }} />
              <Typography variant="h6" color="primary.light">
                {t("buyerAndPayerCard.payer.title")}
              </Typography>
            </Stack>
            <Divider light sx={{ marginBlock: "1rem" }} />
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <PersonIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("buyerAndPayerCard.fullname")}:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.payerInfo.fullName}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <EmailIcon sx={{ color: "primary.light" }} />
                <Typography variant="subtitle1">Email:</Typography>
                <Link>{transaction.payerInfo.email}</Link>
              </Stack>

              <Stack direction="row" spacing={1} alignItems="center">
                <BadgeIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("createAccount.form.document.type")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {transaction.payerInfo.tDoc}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <BadgeIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("createAccount.form.document.number")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.payerInfo.numDoc}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <PhoneIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("accountsAndMerchants.table.header-7")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.payerInfo.numPhone}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
}
