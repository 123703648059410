import { Content } from "../../utils/Content";
import { useMenu } from "../../context/MenuOpenContext";
import UploadDocuments from "../documents-components/UploadDocuments";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

export default function UploadDocumentLayout() {
  const { isOpen } = useMenu();
  const { state } = useLocation();
  const { mode } = state !== null ? state : { mode: "new" };
  const { t } = useTranslation();
  const { uploadBy } = state !== null ? state : null;
  const { userId } = state !== null ? state : null;

  return (
    <Content open={isOpen}>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={
            uploadBy === "client"
              ? "/usersManagement?tab=1"
              : "/usersManagement?tab=2"
          }
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <UploadDocuments mode={mode} uploadBy={uploadBy} userId={userId} />
    </Content>
  );
}
