import {
  Card,
  CardContent,
  Typography,
  Stack,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import { getToken } from "../../utils/GetToken";
import { GetPlansStatsServices } from "../../services/Stats/GetPlansStatsService";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Line } from "react-chartjs-2";

export default function PlansStatsByPrice() {
  const { t } = useTranslation();
  const [pricesData, setPricesData] = useState([]);
  const theme = useTheme();

  const getPlanStatsByPrice = async () => {
    const token = await getToken();
    const response = await GetPlansStatsServices(token);
    console.log(response);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["byPrice"],
    queryFn: () => getPlanStatsByPrice(),
  });

  const prices = response?.byPrice || [];
  const names = [
    t("PlansName.title-1"),
    t("PlansName.title-2"),
    t("PlansName.title-3"),
    t("PlansName.title-4"),
  ];

  const formatPrice = (price) => {
    return new Intl.NumberFormat().format(price);
  };

  useEffect(() => {
    if (prices && prices.length > 0) {
      const sorted = [...prices].sort((a, b) => b.price - a.price);
      setPricesData(sorted);
    }
  }, [prices]);

  const data = {
    labels: pricesData.map((price) => names[prices.indexOf(price)]),
    datasets: [
      {
        label: t("planPrice.title"),
        data: pricesData.map((price) => price.price),
        tension: 0.4,
        borderColor: theme.palette.primary.light,
        pointRadius: 4,
        pointBackgroundColor: theme.palette.primary.main,
        backgroundColor: "transparent",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("plansCard.title-2")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        {/* <TimeFilter />
         */}

        <Typography>{t("plansCard.subtitle-3")}</Typography>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            {prices.map((price, index) => (
              <div key={index}>
                <Stack direction="row" alignItems="center" sx={{ mb: "-1rem" }}>
                  <ArrowDropUpIcon sx={{ fontSize: 55 }} color="success" />
                  <Typography variant="body1">
                    {index + 1}. {names[index]} - ${formatPrice(price.price)}
                  </Typography>
                </Stack>
              </div>
            ))}
          </>
        )}
        <Stack sx={{ height: "220px", alignItems: "center", mt: "1.5rem" }}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Line data={data} options={options} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
