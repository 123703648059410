import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const GetDocumentListByClientsService = (token, clientId) => {
  return new Promise(async (resolve, reject) => {
    console.log(clientId);
    try {
      const documentList = await axios.get(
        `${URL_AUTH}/api/admin/v1/client/${clientId}/document/list`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log("Doc List", documentList);
      resolve(documentList.data.data);
    } catch (error) {
      console.log("Doc List", error);
      reject(error);
    }
  });
};