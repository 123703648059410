import { useState, useEffect } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const HandleClientActions = ({ id, onCellClick }) => {
  // const [clickedTransactions, setClickedTransactions] = useState(false);
  // const [clickedAddCommerce, setClickedAddCommerce] = useState(false);
  // const navigate = useNavigate();
  // const accountId = id;

  // const handleClickTransactions = () => {
  //   setClickedTransactions(true);
  // };

  // const handleClickAddCommerce = () => {
  //   setClickedAddCommerce(true);
  //   onCellClick({ accountId });
  // };

  
  // useEffect(() => {
  //   if (clickedTransactions) {
  //     navigate(`/transactions`, {
  //       state: { type: "byAccount", accountId: { accountId } },
  //     });
  //   }
  // }, [clickedTransactions]);

  return (
    <>
      {/* <IconButton onClick={handleClickTransactions} size="small">
        <PaidIcon />
      </IconButton>
      <IconButton onClick={handleClickAddCommerce} size="small">
        <AddBusinessIcon />
      </IconButton> */}
    </>
  );
};

const ClientsColumns = () => {
  const { t } = useTranslation();

  const columns = [
    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 100,
    //   renderCell: (params) => (
    //     <HandleClientActions id={params.row.id} onCellClick={onCellClick} />
    //   ),
    // },
    {
      field: "id",
      headerName: t("AgentsList.headers-1"),
      width: 80,
    },
    {
      field: "forename",
      headerName: t("AgentsList.headers-2"),
      width: 160,
    },
    {
      field: "surname",
      headerName: t("AgentsList.headers-3"),
      width: 160,
    },

    {
      field: "email",
      headerName: t("AgentsList.headers-4"),
      width: 250,
    },
    {
      field: "document_type",
      headerName: t("AgentsList.headers-5"),
      sortable: false,
      width: 180,
    },
    {
      field: "document_number",
      headerName: t("AgentsList.headers-6"),
      sortable: false,
      width: 160,
    },
    {
      field: "movil",
      headerName: t("AgentsList.headers-7"),
      sortable: false,
      width: 160,
    },
    {
      field: "person_type",
      headerName: t("AgentsList.headers-8"),
      sortable: false,
      width: 160,
    },
    // {
    //   field: "plan",
    //   headerName: t("clientList.headers-9"),
    //   sortable: false,
    //   width: 160,
    // },
   
  ];

  return columns;
};

export default ClientsColumns;
