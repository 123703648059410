import { useState, useEffect, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getToken } from "../../utils/GetToken";
import assignDataGridLanguage from "../../utils/AssignDatagridLanguage";
import { useSearchParams } from "react-router-dom";
import { GetAllCityService } from "../../services/GetAllCityService";
import HeadersCity from "../../utils/TableHeaders/CityHeaders";

export default function CityList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [languageDatagrid, setLanguageDatagrid] = useState({});
  const [queryOptions, setQueryOptions] = useState({});
  const [query, setQuery] = useState(searchParams ? searchParams : {});
  const pageUrl = searchParams.get("page");
  const paginationUrl = searchParams.get("pagination");
  const [pageFromUrl, setPageFromUrl] = useState(
    pageUrl !== null && pageUrl !== 0
      ? parseInt(searchParams.get("page"), 10)
      : 0
  );
  const [paginationFromUrl, setPaginationFromUrl] = useState(
    paginationUrl !== null && paginationUrl !== 0
      ? parseInt(searchParams.get("pagination"), 10)
      : 100
  );
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: paginationFromUrl,
    page: pageFromUrl,
  });
  const currentLanguage = localStorage.getItem("i18nextLng");
  console.log("page State", pageState);

  const handlePageChange = (params) => {
    setPageState((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
    setPaginationModel((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
  };

  useEffect(() => {
    setLanguageDatagrid(assignDataGridLanguage(currentLanguage));
    extractParamsAndAssignState();
  }, [currentLanguage]);

  const onFilterChange = useCallback(
    (filterModel) => {
      setQueryOptions({ filterModel: { ...filterModel } });
    },
    [query]
  );

  useEffect(() => {
    query !== undefined && getCity();
  }, [pageState.page, pageState.pageSize, queryOptions, query]);

  useEffect(() => {
    searchParams.set("page", `${pageState.page}`);
    searchParams.set("pagination", `${pageState.pageSize}`);
    setSearchParams(searchParams);
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    const _query = queryOptions?.filterModel?.items[0];
    if (_query === undefined) {
      extractParamsAndAssignState();
    } else {
      const { field, value } = _query;
      if (field !== undefined && value !== undefined) {
        setQuery(`${field}=${value}`);
        searchParams.set("page", 0);
        searchParams.set("pagination", 100);
        searchParams.set(`${field}`, `${value}`);
        setSearchParams(searchParams);
      } else {
        setQuery({});
        searchParams.delete(`${field}`);
        setSearchParams(searchParams);
      }
    }
  }, [queryOptions]);

  const fetchCity = ()=>{
    getCity();
  }

  const getCity = async () => {
    try {
      const token = await getToken();
      if (token) {
        setPageState((old) => ({ ...old, isLoading: true }));
        const currentPage = pageState.page + 1;
        const pagination = pageState.pageSize;
        const response = await GetAllCityService(
          token,
          currentPage,
          query,
          pagination
        );
        console.log("Users response", response);
        const { data } = response;
        
        if (Array.isArray(data) && data.length !== 0) {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: data,
            total: 1,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: [],
            total: 0,
          }));
        }
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const extractParamsAndAssignState = () => {
    const currentURL = window.location.href;
    const urlSearchParams = new URLSearchParams(currentURL.split("?")[1]);
    const paramsArray = [];

    urlSearchParams.forEach((value, key) => {
      paramsArray.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    });

    const state = paramsArray.join("&");
    setQuery(state);

    return state;
  };

  return (
    <>
      <DataGrid
        columns={HeadersCity(fetchCity)}
        rows={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page}
        filterMode="server"
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(params) => handlePageChange(params)}
        onFilterModelChange={onFilterChange}
        localeText={languageDatagrid}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        slots={{
          toolbar: GridToolbar,
        }}
        sx={{
          boxShadow: "0 1px 12px rgba(149, 157, 165, 0.2)",
          borderRadius: "25px",
          borderColor: "transparent",
          backgroundColor: "white",
          paddingX: "1.5rem",
          "& .MuiDataGrid-cell": {
            borderColor: "gray.light",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          "& .super-app-theme--header": {
            backgroundColor: "white",
          },
        }}
      />
    </>
  );
}
