import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  Grid,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PersonalInformationForm from "../create-admin-account-components/PersonalInformationForm";
import ConfirmEmailAndPhone from "../create-admin-account-components/ConfirmEmailAndPhone";
import ConfirmationAccounCreated from "../create-admin-account-components/ConfirmationAccountCreated";
import { useTranslation } from "react-i18next";
import { FormProvider } from "../../context/CreateAccountFormContext";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useLocation } from "react-router-dom";

export default function CreateAdminAccountLayout() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [userCreated, setUserCreated] = useState(false);
  const [isVerificationFinalized, setIsVerificationFinalized] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { isOpen } = useMenu();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { state } = useLocation();
  const { mode, email } = state !== null ? state : { mode: "new", email: "" };
  const [isRegistrationFinalized, setIsRegistrationFinalized] = useState(false);
  const [isAccountUpdated, setIsAccountUpdated] = useState(false);

  console.log("state", state);

  const { t } = useTranslation();
  
  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (!isRegistrationFinalized) {
        e.preventDefault();
        e.returnValue =
          "¿Seguro que deseas abandonar esta página? Los cambios no guardados se perderán.";
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isRegistrationFinalized]);

  const steps = [
    {
      label: `${t("step.one.label")}`,
      description: ``,
    },
    {
      label: `${t("step.two.label")}`,
      description: ``,
    },
  ];

  const handleRegistrationFinalized = () => {
    setIsRegistrationFinalized(true);
  };

  const handleAccountUpdated = () => {
    setIsAccountUpdated(true);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const handleCloseConfirmation = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowConfirmation(false);
    }
  };

  const handleCloseConfirmationUserUpdated = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAccountUpdated(false);
  };

  useEffect(() => {
    setShowConfirmation(true);
  }, [isVerificationFinalized]);

  useEffect(() => {
    {
      userCreated && setActiveStep(1);
    }
    {
      mode === "continueReg" && setActiveStep(1);
    }
  }, [userCreated, mode]);

  useEffect(() => {
    console.log(isAccountUpdated);
  }, [isAccountUpdated])

  return (
    <FormProvider>
      <Content open={isOpen}>
        <Snackbar
          open={isAccountUpdated}
          autoHideDuration={6000}
          onClose={handleCloseConfirmationUserUpdated}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">Se ha actualizado la cuenta correctamente</Alert>
        </Snackbar>
        <Grid container>
          <Grid item xs={3}>
            <Box sx={{ maxWidth: 400, ml: "4rem" }}>
              <Stepper
                activeStep={activeStep}
                orientation={"vertical"}
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "primary.main", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "greys.dark", // Just text label (COMPLETED)
                    },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "primary.main", // circle color (ACTIVE)
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                      color: "greys.lightest", // Just text label (ACTIVE)
                    },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "white", // circle's number (ACTIVE)
                  },
                }}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <Typography variant="caption">
                        {step.description}
                      </Typography>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {index !== steps.length - 1 ? (
                            <Button
                              variant="contained"
                              color="success"
                              onClick={handleNext}
                              size="small"
                              disabled={!userCreated && mode !== "continueReg" && mode !== "edit"}
                              sx={{ mt: 1, mr: 1, color: "white" }}
                            >
                              {`${t("next.button")}`}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="success"
                              onClick={handleNext}
                              size="small"
                              disabled={!isVerificationFinalized}
                              sx={{ mt: 1, mr: 1, color: "white" }}
                            >
                              {`${t("finish.button")}`}
                            </Button>
                          )}
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            size="small"
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {t("back.button")}
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    Has finalizado el registro correctamente
                  </Typography>
                  {isVerificationFinalized && (
                    <ConfirmationAccounCreated
                      isOpen={showConfirmation}
                      handleClose={handleCloseConfirmation}
                      handleRegistrationFinalized={handleRegistrationFinalized}
                    />
                  )}
                </Paper>
              )}
            </Box>
          </Grid>
          <Grid item xs={9}>
            {activeStep === 0 && (
              <PersonalInformationForm
                isUserCreated={userCreated}
                setIsUserCreated={setUserCreated}
                email={state !== null ? email : null}
                mode={state !== null ? mode : null}
                handleAccountUpdated={handleAccountUpdated}
              />
            )}
            {activeStep === 1 && (
              <ConfirmEmailAndPhone
                isVerificationFinalized={isVerificationFinalized}
                setIsVerificationFinalized={setIsVerificationFinalized}
              />
            )}
          </Grid>
        </Grid>
      </Content>
    </FormProvider>
  );
}
