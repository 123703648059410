import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import SmsIcon from "@mui/icons-material/Sms";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getToken } from "../../utils/GetToken";
import { TakeAlertService } from "../../services/Alerts/TakeAlertService";
import { useAlert } from "../../context/AlertOptionsContext";
import { ValidateAlertService } from "../../services/Alerts/ValidateAlertService";
import { FinalizeAlertService } from "../../services/Alerts/FinalizeAlertService";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";

export default function AlertOptions() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { alertId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const {
    alertOptions,
    handleAlertOptions,
    resetAlertOptions,
    handleIsValidateAlert,
    validateAlert,
    handleIsFinalizeAlert,
    finalizeAlert,
  } = useAlert();

  const styleButton = {
    pl: "1rem",
    color: "secondary.main",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleConfirmAction = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      await TakeAlertService(token, alertId);
      setSelectedOption(null);
      handleClose();
    } catch (error) {
      console.error("Error al enviar la alerta:", error);
    } finally {
      setLoading(false);
    }
    handleAlertOptions();
  };

  const handleValidateAlert = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      await ValidateAlertService(token, alertId);
      setSelectedOption(null);
      handleClose();
    } catch (error) {
      console.error("Error al validar la alerta:", error);
    } finally {
      setLoading(false);
    }
    handleIsValidateAlert();
  };

  const handleFinalizeAlert = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      await FinalizeAlertService(token, alertId);
      console.log("Alerta finalizada");
      setSelectedOption(null);
      handleClose();
    } catch (error) {
      console.error("Error al finalizar la alerta:", error);
    } finally {
      setLoading(false);
    }
    handleIsFinalizeAlert();
  };

  const handleTakeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleAlertOptions();
    resetAlertOptions();
  };

  const handleValidatedAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleIsValidateAlert();
    resetAlertOptions();
  };

  const handleFinalizedAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleIsFinalizeAlert();
    resetAlertOptions();
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (alertOptions || validateAlert || finalizeAlert) {
      const redirectTimer = setTimeout(() => {
        navigate(`/alerts?tab=0`);
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
  }, [alertOptions || validateAlert || finalizeAlert]);

  return (
    <>
      <Snackbar
        open={alertOptions}
        autoHideDuration={5000}
        onClose={handleTakeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {t("alert.activation.success.message")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={validateAlert}
        autoHideDuration={5000}
        onClose={handleValidatedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {t("alert.validation.success.message")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={finalizeAlert}
        autoHideDuration={5000}
        onClose={handleFinalizedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("alert.finalize.success.message")}</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error"> {t("alert.activation.error.message")}</Alert>
      </Snackbar>

      <Tooltip title="Opciones" arrow>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 1,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",

                right: 35,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
              "@media (max-width: 600px)": {
                "&:before": {
                  right: 5,
                },
              },
            },
          },
        }}
        MenuListProps={{
          sx: {
            "& .MuiMenuItem-root": {
              paddingLeft: "8px",
            },
          },
        }}
        sx={{
          marginTop: "5px",
          marginLeft: "-20px",
        }}
      >
        <MenuItem onClick={() => handleOptionClick("SMS")}>
          <ListItemIcon>
            <SmsIcon  />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-1")}</ListItemText>
          {selectedOption === "SMS" && (
            <Button sx={styleButton} onClick={handleConfirmAction}>
              {t("alert.activation.button-1")}
            </Button>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("Email")}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-2")}</ListItemText>
          {selectedOption === "Email" && (
            <Button sx={styleButton} onClick={handleConfirmAction}>
              {t("alert.activation.button-1")}
            </Button>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("WhatsApp")}>
          <ListItemIcon>
            <WhatsAppIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-3")}</ListItemText>
          {selectedOption === "WhatsApp" && (
            <Button sx={styleButton} onClick={handleConfirmAction}>
              {t("alert.activation.button-1")}
            </Button>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("Call")}>
          <ListItemIcon>
            <PhoneCallbackIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-4")}</ListItemText>
          {selectedOption === "Call" && (
            <Button sx={styleButton} onClick={handleConfirmAction}>
              {t("alert.activation.button-1")}
            </Button>
          )}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleOptionClick("Validate")}>
          <ListItemIcon>
            <MobileFriendlyIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-5")}</ListItemText>
          {selectedOption === "Validate" && (
            <Button sx={styleButton} onClick={handleValidateAlert}>
              {t("alert.activation.button-2")}
            </Button>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("Finalize")}>
          <ListItemIcon>
            <NotificationsOffIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-6")}</ListItemText>
          {selectedOption === "Finalize" && (
            <Button sx={styleButton} onClick={handleFinalizeAlert}>
              {t("alert.activation.button-3")}
            </Button>
          )}
        </MenuItem>
      </Menu>
    </>
  );
}
