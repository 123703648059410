import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Container,
  Tab,
  Tabs,
} from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ZonesList from "../zones-components/ZonesList";
import { useSearchParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box sx={{ p: { xs: "0.2rem", sm: 3 }, mt: 2, mb: 8 }}>
            {children}
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MapsLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabUrl = searchParams.get("tab");
  const [currentTab, setCurrentTab] = useState(
    tabUrl !== null && tabUrl !== 0 ? parseInt(tabUrl, 10) : 0
  );
  const [value, setValue] = useState(parseInt(currentTab, 10));
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();

  const handleShowDialog = (id) => {
    setShowDialog(!showDialog);
  };

  const handleButtonClickZone = () => {
    navigate("/availableZones/new", {
      state: { mode: "available" },
    });
  };

  const handleButtonNewZoneClick = () => {
    navigate("/zones/new");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const tabParam = extractTabParamFromURL();
    if (tabParam) {
      setValue(tabParam);
    }
  }, []);

  useEffect(() => {
    setCurrentTab(value);
  }, [value]);

  useEffect(() => {
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  }, [currentTab]);

  const extractTabParamFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam !== null) {
      const tabValue = parseInt(tabParam, 10);
      if ([0, 1].includes(tabValue)) {
        // console.log("tab param", tabParam);
        return tabValue;
      }
    }
    return 0;
  };

  return (
    <Content open={isOpen}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pl: 0 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t("zonesManagement.tabTitle-1")} {...a11yProps(0)} />
          <Tab label={t("zonesManagement.tabTitle-2")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("zonesManagement.tabTitle-1")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("zones.subtitle")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<AddIcon sx={{ color: "white" }} />}
                onClick={handleButtonNewZoneClick}
              >
                {t("zones.button.createNew")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box
          mt={2}
          sx={{
            height: 600,
            width: "100%",
          }}
        >
          <ZonesList />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("zonesManagement.tabTitle-2")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("zones.available.subtitle")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<AddCircleIcon sx={{ color: "white" }} />}
                onClick={handleButtonClickZone}
              >
                {t("createNewAvailableZone.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <ZonesList mode="available" />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Content>
  );
}
