import Axios from "axios";

const URL_AGENTS = import.meta.env.VITE_AGENTS;

export function GetBusinessTypeListService(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `${URL_AGENTS}/api/admin/v1/business/type`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}