import axios from "axios";

const URL_AUTH= import.meta.env.VITE_AUTH_URL;

export const UpdatePlanService = (token, planId,formValues) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(URL_AUTH);
      const response = await axios.put(
        `${URL_AUTH}/api/admin/v1/plan/${planId}`,
        formValues,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response);
      resolve(response);
    } catch (error) {
      
      reject(error);
    }
  });
};