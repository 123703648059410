import Pusher from "pusher-js";

const KEY_WS = import.meta.env.VITE_KEY_WS;
const CLUSTER_WS = import.meta.env.VITE_CLUSTER_WS;
const AUTH_URL = import.meta.env.VITE_AUTH_URL;

export const WebsocketSuscriptionService = (token) => {
  try {
    const pusher = new Pusher(KEY_WS, {
      cluster: CLUSTER_WS,
      forceTLS: true,
      enabledTransports: ["ws"],
      userAuthentication: {
        endpoint: `${AUTH_URL}/ws/admins/broadcasting/auth`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      channelAuthorization: {
        forceTLS: true,
        enabledTransports: ["ws"],
        endpoint: `${AUTH_URL}/ws/admins/broadcasting/auth`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      // authEndpoint: `${AUTH_URL}/ws/admins/broadcasting/auth`,
    });
  
    pusher.connection.bind("connected", function () {
      console.log("Conexión establecida");
    });

    // const channel = pusher.subscribe("presence-admin.location");
    // console.log("channel", channel);

    // channel.bind("pusher:subscription_succeeded", function (members) {
    //   console.log("successfully subscribed!", members);
    // });

    // channel.bind("client.location", function (data) {
    //   console.log("Location", data);
    // });

    return pusher;

  } catch (error) {
    console.log("WebsocketSuscriptionService:", error);
  }
};
