import { useState, useEffect } from "react";
import CreateNewRoleForm from "../roles-and-permissions-components/CreateNewRoleForm";
import { Content } from "../../utils/Content";
import { Typography, Grid, Box, Skeleton } from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function CreateNewRoleLayout() {
  const { isOpen } = useMenu();
  const { pathname } = useLocation();
  const {roleId} = useParams();
  const [mode, setMode] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    pathname.includes("editRole") ? setMode("edit") : setMode("new");
  }, [pathname]);

  return (
    <Content open={isOpen}>
     <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={"/usersManagement?tab=3"} 
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box px={{ xs: 0, sm: 2 }}>
            <Typography
              variant="h4"
              color={"primary"}
              textAlign={"flex-start"}
              sx={{ textTransform: "uppercase" }}
              fontWeight={800}
            >
              {mode === "edit" ? t("editRole.title") : t("createNewRole.title")}
            </Typography>
          </Box>
          <Box my={2} px={{ xs: 0, sm: 2 }}>
            <Typography variant="body1" textAlign={"flex-start"}>
              {mode === "edit" ? t("editRole.description") : t("createNewRole.description")}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {/* <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<SupervisedUserCircleIcon sx={{ color: "white" }} />}
                onClick={handleButtonNewRoleClick}
              >
                {t("roles.createNewRole.button")}
              </Button>
            </Box> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={{ xs: 0, sm: 2 }}
            mt={2}
            sx={{
              width: "100%",
            }}
          >
            <CreateNewRoleForm mode={mode} roleId={roleId}/>
          </Box>
        </Grid>
      </Grid>
    </Content>
  );
}
