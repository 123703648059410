import { Stack, Typography, IconButton, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { CurrentDate } from "../../utils/CurrentDate";
import SyncIcon from "@mui/icons-material/Sync";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";

export default function TitleAndDate() {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { isLoggedIn } = useContext(AuthContext);

  const handleRefresh = () => {
    setLoading(true);
    setDisabled(true);

    setTimeout(() => {
      setLoading(false);
      setDisabled(false);
    }, 3000);
  };

  useEffect(() => {
    return () => clearTimeout();
  }, []);

  return (
    <Stack
      spacing={0}
      direction={{ xs: "row", sm: "column" }}
      sx={{ alignItems: { xs: "center", sm: "flex-start" } }}
    >
      <Typography
        variant="h6"
        fontWeight={700}
        noWrap
        mx={1}
        sx={{
          flexGrow: 1,
          // display: { xs: "none", sm: "block" },
        }}
      >
        Admin
      </Typography>
      {isLoggedIn && (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          gap={0.5}
        >
          {loading ? (
            <Box mx={1}>
              <CircularProgress size="1.5rem" sx={{ color: "primary.light" }} />
            </Box>
          ) : (
            <IconButton
              size="small"
              sx={{ color: "white" }}
              disabled={loading}
              onClick={handleRefresh}
            >
              <SyncIcon fontSize="small" />
            </IconButton>
          )}
          <Typography
            variant="caption"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
            }}
          >
            {t("lastUpdate")}:
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <CurrentDate />
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
