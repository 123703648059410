import React from "react";
import TabsHomeLayout from "../home-components/home-layouts/TabsHomeLayout";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";

export default function HomeLayout() {
  const {isOpen} = useMenu();

  return (
    <Content open={isOpen}>
      <TabsHomeLayout />
    </Content>
  );
}
