import { Content } from "../../utils/Content";
import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Alert,
  Card,
  CardContent,
  Stack,
  Divider,
  Link,
  Grid,
  Snackbar,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SyncIcon from "@mui/icons-material/Sync";
import { getToken } from "../../utils/GetToken";
import { GetChangePasswordCodeService } from "../../services/GetChangePasswordCodeService";
import { ChangePasswordService } from "../../services/ChangePasswordService";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { useMenu } from "../../context/MenuOpenContext";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export default function ChangePasswordLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [showCodeSendedMessage, setShowCodeSendedMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [passwordError, setPasswordError] = useState("");
  const { logout, redirectTo } = useContext(AuthContext);
  const { isOpen } = useMenu();

  const [formValue, setFormValue] = useState({
    code: "",
    oldPassword: "",
    password: "",
    password_confirmation: "",
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setFormValue((prevState) => ({
      ...prevState,
      password: value,
      password_confirmation: value,
    }));
    validatePassword(value);
  };

  const validatePassword = (value) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    if (!passwordRegex.test(value)) {
      setPasswordError(`${t("createAccount.form.passwordValidation-1")}`);
    } else {
      setPasswordError("");
    }
  };

  const onTaskChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleGetVerificationCode = async () => {
    setLoadingCode(true);
    try {
      const token = await getToken();
      console.log("Token de Acceso:", token);
      const response = await GetChangePasswordCodeService(token);
      console.log(response.data.message);
      setVerificationCodeSent(true);
      setShowCodeSendedMessage(true);
      // setLoadingCodeDisable(false);
      // setLoadingCodeEnable(false);
    } catch (error) {
      setErrorMessage(error.message);
      setError(true);
      setLoadingCode(false);
    } finally {
    }
  };

  const handleChangePassword = async () => {
    setLoadingCode(true);
    try {
      const token = await getToken();
      const response = await ChangePasswordService(token, formValue);
      console.log("Respuesta del Servidor:", response);
      setPasswordChanged(true);
      logout();
      redirectTo("/");
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      setErrorMessage(error.message);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmiCode = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (verificationCodeSent) {
      handleChangePassword();
    } else {
      handleGetVerificationCode();
    }
  };

  const handleCloseCodeSendedMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowCodeSendedMessage(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (error) {
      const redirectTimer = setTimeout(() => {
        setError(false);
      }, 8000);

      return () => clearTimeout(redirectTimer);
    }
  }, [error]);

  return (
    <Content open={isOpen} className="content">
      <Box>
        <Stack
          mt={{ xs: 0, md: 2 }}
          sx={{
            justifyContent: "center",

            alignItems: { xs: "center" },
          }}
        >
          <Snackbar
            open={showCodeSendedMessage}
            autoHideDuration={6000}
            onClose={handleCloseCodeSendedMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">
              {t("changePassword.getCode.success.message")}
            </Alert>
          </Snackbar>
          <Snackbar
            open={error}
            autoHideDuration={6000}
            onClose={handleCloseError}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>

          <Card
            elevation={4}
            sx={{
              borderRadius: "20px",
              p: "1rem",
              mx: { xs: "0.4rem", sm: "10rem", md: "0" },
              mt: { xs: "2rem", md: 0 },
              maxWidth: "500px",
            }}
          >
            <Link href="/settings" className="nav-link_back" underline="none">
              <ArrowBackIcon sx={{ marginRight: "5px" }} />
              {t("back.button")}
            </Link>
            <CardContent>
              <form onSubmit={(e) => handleSubmiCode(e)}>
                <Box my={2}>
                  {passwordChanged && (
                    <Alert severity="success">
                      {t("changePassword.alert.success")}
                    </Alert>
                  )}
                  {error && (
                    <Alert severity="error">
                      {t("changePassword.alert.error")}
                    </Alert>
                  )}
                </Box>
                <Typography variant="h6" fontWeight={600} textAlign={"center"}>
                  {t("changePassword.title")}
                </Typography>
                <Divider sx={{ my: "1rem" }} />
                <Typography variant="p" fontWeight={400} textAlign={"center"}>
                  {t("changePasswordverify.text")}
                </Typography>

                <Stack
                  mt={4}
                  mb={2}
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: { xs: "center" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      borderRadius: "20px",
                    }}
                    onClick={handleGetVerificationCode}
                  >
                    {t("changePassword.getCode")}
                  </Button>
                </Stack>

                <FormControl sx={{ m: 1, width: "98%" }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("setting.edit2FA.input")}
                  </InputLabel>
                  <Input
                    name="code"
                    onChange={(e) => onTaskChange(e)}
                    value={formValue.code}
                    required
                  />
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: "98%" }}
                  variant="standard"
                  fullWidth
                >
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("changePassword.oldPassword")}
                  </InputLabel>
                  <Input
                    name="oldPassword"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => onTaskChange(e)}
                    value={formValue.oldPassword || ""}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: "98%" }}
                  variant="standard"
                  fullWidth
                >
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("changePassword.new")}
                  </InputLabel>
                  <Input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={handlePasswordChange}
                    value={formValue.password || ""}
                    error={passwordError !== ""}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {passwordError && (
                    <FormHelperText error id="passwordError-error">
                      {passwordError}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: "98%" }}
                  variant="standard"
                  fullWidth
                >
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("changePassword.confirm")}
                  </InputLabel>
                  <Input
                    name="password_confirmation"
                    type={showPassword ? "text" : "password"}
                    onChange={handlePasswordChange}
                    value={formValue.password_confirmation || ""}
                    error={passwordError !== ""}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Stack
                  mt={4}
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: { xs: "center" },
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ borderRadius: "20px" }}
                    startIcon={
                      loading ? (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <CheckCircleIcon />
                      )
                    }
                    type="submit"
                  >
                    {t("changePassword.button")}
                  </Button>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </Content>
  );
}
