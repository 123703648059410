import { Grid, Typography, Stack, Box, Divider } from "@mui/material";
import React from "react";
import AmountPerPeriod from "../AmountPerPeriod";
import PaymentMethodsStats from "../PaymentMethodsStats";
import ResumeCard from "../ResumeCard";
import { useTranslation } from "react-i18next";
import AlertStatsPerPeriod from "../AlertStatsPerPeriod";
import AccompanimentStats from "../AccompanimentStats";

export default function GeneralStatistics() {
  const { t } = useTranslation();

  return (
    <Box className="content" sx={{ ml: { xs: "0", sm: "1rem" }, mt: "0" }}>
      <Typography component="div" variant="h4" fontWeight={600}>
        {t("generalStatistics")}
      </Typography>
      <Divider sx={{ my: "1rem" }} />
      <Grid container spacing={3} justifyContent="center">
        <Grid itme>
          <Stack direction={{ xs: "columns", md: "row" }} mt={2} gap={"3rem"}>
            <ResumeCard />
            <AmountPerPeriod />
          </Stack>
          <Stack direction={{ xs: "columns", md: "row" }} mt={2} gap={"3rem"}>
            <AlertStatsPerPeriod />
            <AccompanimentStats />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
