// UploadImagePlanService.js

import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export default  function UploadImagePlanService(token, planId, imageFile) {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("image", imageFile);

      const response = await axios.post(
        `${URL_AUTH}/api/admin/v1/plan/${planId}/image/upload`,
       formData,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
  
          },
        }
      );
      console.log("Imagen cargada correctamente");
      resolve(response);
    } catch (error) {
      reject (error);
    } 
  });
}
