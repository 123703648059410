import { AccompanimentStateCodes } from "../constants/accompaniment.constansts";

export const parseStateCodeToTag = (stateCode: string) => {
    switch (stateCode) {
        case AccompanimentStateCodes.ALRT:
            return 'accompaniment.alert';
        case AccompanimentStateCodes.FNLD:
            return 'accompaniment.finalized';
        case AccompanimentStateCodes.INIT:
            return 'accompaniment.initiated';
        case AccompanimentStateCodes.MGMT:
            return 'accompaniment.in_management';
        case AccompanimentStateCodes.QLFD:
            return 'accompaniment.rated';
        case AccompanimentStateCodes.RACT:
            return 'accompaniment.reaction';
        case AccompanimentStateCodes.REGT:
            return 'accompaniment.registered';
        default:
            return '';
    }
}
