import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const SelectDocumentService = (token, userId, documentListBy) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "";
      if (documentListBy === "client") {
        url = `${URL_AUTH}/api/admin/v1/client/${userId}/document/select/group`;
      } else if (documentListBy === "agent") {
        url = `${URL_AUTH}/api/admin/v1/agent/${userId}/document/select/group`;
      }
      const response = await axios.put(url, [], {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Documento seleccionado", response);
      resolve(response);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};
