import axios from "axios";

const URL_MONGO = import.meta.env.VITE_URL_MONGO;

export const GetUsersSearch = (arg) => {
  return new Promise(async (resolve, reject) => {
    try {
      const rta = await axios.get(`${URL_MONGO}/search/${arg}`);

      resolve(rta.data);
    } catch (error) {
      console.log("GetUsersCity:", error);
      reject(error);
    }
  });
};
