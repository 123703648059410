import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Alert,
  Card,
  CardContent,
  Grid,
  TextField,
  Autocomplete,
  MenuItem,
  Snackbar,
  CircularProgress,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getToken } from "../../utils/GetToken";
import { CreateRoleService } from "../../services/RolesAndPermissions/CreateRoleService";
import { GetAllPermissionsService } from "../../services/RolesAndPermissions/GetAllPermissionsService";
import PermissionsList from "./PermissionsList";
import { useNavigate } from "react-router-dom";
import { GetRoleInfoService } from "../../services/RolesAndPermissions/GetRoleInfoService";
import { UpdateRoleService } from "../../services/RolesAndPermissions/UpdateRoleService";

const langList = [
  { id: 1, lang: "en", name: "English" },
  { id: 2, lang: "es", name: "Español" },
  { id: 3, lang: "pt", name: "Português" },
];

export default function CreateNewRoleForm({ mode, roleId }) {
  const [permissionList, setPermissionList] = useState({});
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [loading, setLoading] = useState(false);

  const [isRoleCreated, setIsRoleCreated] = useState(false);
  const [isRoleUpdated, setIsRoleUpdated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [languageSelected, setLanguageSelected] = useState(null);

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    language: "",
    permissions: [],
  });
  const [roleSelected, setRoleSelected] = useState({});

  const { t } = useTranslation();

  const navigate = useNavigate();

  const getAllPermissions = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetAllPermissionsService(token);
        // console.log("Permissions response", response);
        const { data } = response;
        const sortedData = data.slice().sort((a, b) => {
          return a.id.localeCompare(b.id);
        });
        setPermissionList(sortedData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPermissions(false);
    }
  };

  const getRoleInfo = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetRoleInfoService(token, roleId);
        setRoleSelected(response);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPermissions();
  }, []);

  useEffect(() => {
    if (mode === "edit") {
      getRoleInfo();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setFormValues((prevState) => ({
        ...prevState,
        name: roleSelected?.name,
        description: roleSelected?.description,
        language: roleSelected?.lang
      }));
      const currentLanguage = langList.find(item=> roleSelected?.lang ===item.lang)
      currentLanguage && setLanguageSelected(currentLanguage);
      getPermissionsIds(roleSelected?.permissions);
    }
  }, [roleSelected]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = await getToken();
      if (mode === "edit") {
        const response = await UpdateRoleService(token, formValues, roleId);
        setIsRoleUpdated(true)
      } else {
        const response = await CreateRoleService(token, formValues);
        setIsRoleCreated(true);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
    setIsRoleCreated(false);
    setIsRoleUpdated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleLangChange = (event, value) => {
    setLanguageSelected(value)
    setFormValues((prevState) => ({
      ...prevState,
      language: value.lang,
    }));
  };

  function getPermissionsIds(permissionsString) {
    try {
      const permissions = JSON.parse(permissionsString);
      const ids = permissions.map((permission) => permission.id);
      setFormValues((prevState) => ({
        ...prevState,
        permissions: ids,
      }));
      console.log(ids);
      return ids;
    } catch (error) {
      console.error("Error al analizar los permisos:", error);
      return [];
    }
  }

  useEffect(() => {
    if (isRoleCreated || isRoleUpdated) {
      const redirectTimer = setTimeout(() => {
        navigate(`/usersManagement?tab=3`);
      }, 1000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isRoleCreated]);

  const getSelectedNames = () => {
    if (!Array.isArray(permissionList) || permissionList.length === 0) {
      return [];
    }

    return formValues?.permissions.map((selectedRow) => {
      const selectedRowData = permissionList.find(
        (row) => row.id === selectedRow
      );
      return selectedRowData ? selectedRowData.name : "";
    });
  };

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: "20px",
        // mt: { xs: 0, md: 0 },
        p: { xs: "0.5rem", sm: "2rem" },
        maxWidth: "100%",
      }}
    >
      <CardContent>
        <Snackbar
          open={isRoleCreated || isRoleUpdated}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">
            {isRoleUpdated
              ? t("updatedRole.success.message")
              : t("createNewRole.success.message")}
          </Alert>
        </Snackbar>
        {/* Error Response Alert*/}
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        {/* Form Create New Account */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                label={t("createNewRole.formLabel.name")}
                value={formValues.name}
                onChange={handleInputChange}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="description"
                label={t("createNewRole.formLabel.description")}
                value={formValues.description}
                onChange={handleInputChange}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="lang"
                options={langList}
                loadingText="Loading..."
                onChange={handleLangChange}
                value={languageSelected || null}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={handleLangChange}
                    size="small"
                    name="language"
                    label={t("language")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={2}
                sx={{
                  height: 400,
                  width: "100%",
                }}
              >
                <PermissionsList
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={1}>
            <Typography fontWeight={600}>{t("selectedPermissions")}</Typography>
            {getSelectedNames().filter(Boolean).join(", ")}
          </Box>
          <Box mt={"2rem"} sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color={"secondary"}
              size="large"
              // sx={{ width: "100%" }}
              startIcon={
                loading ? (
                  <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                ) : (
                  <CheckCircleIcon sx={{ color: "white" }} />
                )
              }
            >
              {mode === "edit"
                ? t("update.button")
                : t("createCompany.form.submitButton")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
