import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    Grid,
    Typography,
    Box,
    Divider,
    Stack,
} from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getToken } from "../../utils/GetToken";
import { DeleteEpsService } from "../../services/DeleteEpsServices";
import { ActivateEpsService } from "../../services/ActivateEpsServices";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDelete({ handleClose, isOpen, userId, data, fetchEps }) {
    const { t } = useTranslation();
    const handleDelete = async () => {
        const token = await getToken();
        if(data.active){
            await DeleteEpsService(userId, token);
            fetchEps();
            handleClose();
        } else {
            await ActivateEpsService(userId, token);
            fetchEps();
            handleClose();
        }
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={isOpen}
            TransitionComponent={Transition}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: "2rem" }}>
                <Stack alignItems={"center"} justifyContent="center">
                    <CheckCircleIcon sx={{ color: "yellow" }} />
                    <Typography variant="h6" color="warning">
                        {t("deleteAccount.confirmation.title")}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ p: "2rem" }}>
                <Grid container display='flex'  justifyContent='center' textAlign='center'>
                    <Grid item xs={12} my={1}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {data.active ? t("InactivateAccount.confirmation.content") : t("ActivateAccount.confirmation.content")}
                    </Grid>
                    <Grid mt={2} item xs={12}>
                        <Typography>
                            {data?.name}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ p: "2rem", gap: "0.5rem" }}>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClose}
                >
                    {t("cancel.button")}
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleDelete}
                >
                    {data.active ? t("button.deactivate"): t("button.activate")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
