import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  Chip,
  Skeleton,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InfoIcon from "@mui/icons-material/Info";
import EventIcon from "@mui/icons-material/Event";
import DescriptionIcon from "@mui/icons-material/Description";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import StatusTranslator from "../../utils/StatusTranslator";

export default function SummaryTransaction(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState(props.transaction);
  const [click, setClick] = useState(false);

  const navigate = useNavigate();

  const currentLanguage = localStorage.getItem("i18nextLng");

  const stateCode = transaction.state_code

  const translatedValue = StatusTranslator(
    stateCode,
    currentLanguage
  );

  useEffect(() => {
    setTransaction(props.transaction);
    setLoading(props.loading);
  }, [props]);

  const handleClick = () => {
    setClick(true);
  };

  useEffect(() => {
    if (click) {
      navigate(`/transactionParties/${transaction.transactionId}`);
    }
  }, [click]);

  return (
    <>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={400}
          sx={{
            width: { xs: "320px", sm: "320px", md: "500px", xl: "872px" },
            borderRadius: "20px",
            mx: "1rem",
          }}
        />
      ) : (
        <Card
          elevation={5}
          sx={{ borderRadius: "20px", mx: {xs: "0rem", sm: "1rem"}, height: "auto" }}
        >
          <CardContent>
            <Stack direction="row" alignItems="center" spacing={1}>
              <SummarizeIcon sx={{ color: "primary.light" }} />
              <Typography variant="h6" color="primary.light">
                {t("summaryTransactionCard.title")}
              </Typography>
            </Stack>
            <Divider light sx={{ marginBlock: "1rem" }} />
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <MonetizationOnIcon sx={{ color: "primary.light" }} />
                <Typography variant="subtitle1">
                  {t("summaryTransactionCard.amount")}:
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    color="grays.dark"
                  >
                    ${transaction.saleInfo.amount}
                  </Typography>
                  <Typography variant="subtitle2" color="grays.medium">
                    {transaction.saleInfo.currency}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <InfoIcon sx={{ color: "primary.light" }} />
                <Typography variant="subtitle1">
                  {t("summaryTransactionCard.status")}:
                </Typography>
                <Chip
                  label={translatedValue}
                  variant="outlined"
                  color={
                    stateCode === "ACPT"
                      ? "success"
                      : stateCode === "REJT"
                      ? "error"
                      : "warning"
                  }
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <EventIcon sx={{ color: "primary.light" }} />
                  <Stack direction="row" spacing={1} alignItems="baseline">
                    <Typography variant="subtitle1">
                      {t("summaryTransactionCard.date")}:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      color="grays.medium"
                    >
                      {transaction.paymentDate.date}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <AccessTimeIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("summaryTransactionCard.time")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.paymentDate.time}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <AccountBalanceIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("transactions.table.header-7")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.saleInfo.bank}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <PaymentsIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("transactions.table.header-6")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {transaction.saleInfo.paymentMethod}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ReceiptIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("transactions.table.header-1")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.saleInfo.reference}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Divider sx={{ marginBlock: "1rem" }} />
            <Stack direction="row" alignItems="center" spacing={1}>
              <ReadMoreIcon sx={{ color: "primary.light" }} />
              <Typography variant="h6" color="primary.light">
                {t("summaryTransactionCard.additionalData")}
              </Typography>
            </Stack>
            <Divider sx={{ marginBlock: "1rem" }} />
            <Stack spacing={1} mt={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <DescriptionIcon sx={{ color: "primary.light" }} />
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("summaryTransactionCard.description")}:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {transaction.saleInfo.description}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <PersonIcon sx={{ color: "primary.light" }} />
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="subtitle1">
                  {t("buyerAndPayerCard.buyer.title")}:
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight={400}
                  color="grays.medium"
                >
                  {transaction.buyerInfo.fullName}
                </Typography>
              </Stack>
            </Stack>
            <Stack mt={2} alignItems="center">
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={handleClick}
                sx={{ width: "5rem" }}
              >
                {t("seeMore.tooltip")}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
}
