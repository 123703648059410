import { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  Box,
  Snackbar,
  Alert,
  Divider,
  Button,
  Card,
  CardContent,
  Stack,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getToken } from "../../utils/GetToken";

// import Pointer from "../../assets/pointer.png";
// import PointerUser from "../../assets/pointerUser.png";
//import * as Realm from "realm-web";

import MapContainer from "./MapContainer";

import { GetCountriesService } from "../../services/DictionaryLists/GetCountriesService";
import { DepartmentListByCountryService } from "../../services/DictionaryLists/DepartmentListByCountryService";
import { CitiesListByDepartmentService } from "../../services/DictionaryLists/CitiesListByDepartmentService";

import { GetAllDeptos } from "../../services/Maps/GetAllDeptos";
import { GetAllCities } from "../../services/Maps/GetAllCities";
import { GetUsersCity } from "../../services/Maps/GetUsersCity";
import { GetUsersDepto } from "../../services/Maps/GetUsersDepto";
import { GetTypes } from "../../services/Maps/GetTypes";
import { GetUsersSearch } from "../../services/Maps/GetUsersSearch";
import { GetUserPostalCode } from "../../services/Maps/GetUserPostalCode";
import { WebsocketSuscriptionService } from "../../services/Maps/WebsocketSuscriptionService";

import SearchIcon from "@mui/icons-material/Search";

export default function Mapview() {
  const [checkBoxList, setCheckBoxList] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState({
    agente: true,
    cliente: false,
    dispositivo: true,
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [arrayDispositivo, setArrayDispositivo] = useState([]);
  const [arrayCliente, setArrayCliente] = useState([]);
  const [arrayAgente, setArrayAgente] = useState([]);
  const [keyPath, setKeyPath] = useState(1);

  const [markers, setMarkers] = useState([]);
  const [city, setCity] = useState(null);
  const [rtaSearch, setRtaSearch] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coords, setCoords] = useState([]);
  const [mobileMarkersCoordinates, setMobileMarkersCoordinates] = useState([]);

  const { t } = useTranslation();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [activeMarker, setActiveMarker] = useState([]);
  const [filterMarkers, setFilterMarkers] = useState([]);
  const [centerMap, setCenterMap] = useState(null)
  const [latitud, setLatitud] = useState(4.686703127117463);
  const [longitud, setLongitud] = useState(-74.0695004267405);
  const [zoom, setZoom] = useState(13);

  let zoomMap = zoom;
  let newPosition = {};

  const getCountries = async () => {
    const response = await GetCountriesService();
    console.log(response);
    return response;
  };

  const getDepartments = async () => {
    if (selectedCountry) {
      const token = await getToken();
      const response = await DepartmentListByCountryService(
        token,
        selectedCountry?.id
      );
      console.log("dptos", response);
      return response;
    }
    return [{}];
  };

  const getCities = async () => {
    if (selectedDepartment) {
      const token = await getToken();
      const response = await CitiesListByDepartmentService(
        token,
        selectedDepartment?.id
      );
      return response;
    }
    return [{}];
  };

  const countries = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });

  const departments = useQuery({
    queryKey: ["departments"],
    queryFn: getDepartments,
  });

  const cities = useQuery({
    queryKey: ["cities"],
    queryFn: getCities,
  });

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value);
    setSelectedDepartment(null);
    setSelectedCity(null);
  };

  const handleDepartmentChange = (event, value) => {
    setSelectedDepartment(value);
    if (value) {
      getCities(value?.id);
      setNewCenterLocation(value);
      getUsersDepto("Antioquia");
      setZoom(12);
    } else {
      console.log(value);
    }
  };

  const handleCityChange = (event, value) => {
    setSelectedCity(value);
    console.log(value);
    if (value) {
      setCity(value.name);
      setZoom(13);
      let opcion1 = document.getElementById("agente");
      console.log("opcion1 ", opcion1);
      opcion1.checked = true;
      let opcion2 = document.getElementById("cliente");
      opcion2.checked = true;
      let opcion3 = document.getElementById("dispositivo");
      opcion3.checked = true;
      console.log("inside here");
      getUsers("Bogota");
    } else {
      console.log("error");
    }
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    if (checked) {
      let rta = markers.filter((e) => e.tipo == name);
      setFilterMarkers([...filterMarkers, ...rta]);
    } else {
      let rta = filterMarkers.filter((e) => e.tipo != name);
      setFilterMarkers([...rta]);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    if (searchTerm.length > 1) {
      console.log(`Realizar búsqueda con el término: ${searchTerm}`);
      getSearch(searchTerm);
    }
  };

  useEffect(() => {
    getCurrentLocation();
    getTypes();
  }, []);

  // useEffect(() => {
  //   centerMap = {
  //     lat: latitud,
  //     lng: longitud,
  //   };
  //   zoomMap = zoom;
  // }, [zoom, latitud, longitud]);

  const getCurrentLocation = () => {
    setLatitud(4.686703127117463);
    setLongitud(-74.0695004267405);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitud(position.coords.latitude);
        setLongitud(position.coords.longitude);

        setCenterMap({lat: position.coords.latitude, lng: position.coords.longitude})
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        console.log(" latitude " + latitude);
        console.log(" longitude " + longitude);

        let latlng = latitude + "," + longitude;

        getPostalCodeInfo(latlng);

        //**
      },
      (error) => {
        alert(" error obtener la ubicacion ");
      }
    );
  };

  if (!navigator.geolocation) {
    alert("Función no soportada ");
    return;
  }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const getTypes = async () => {
    const response = await GetTypes();
    console.log("getTipos - response " + JSON.stringify(response.data));
    setCheckBoxList(response.data);
  };

  const getSearch = async (arg) => {
    const response = await GetUsersSearch(arg);

    if (response.length > 0) {
      console.log("Hay datos ");

      setMarkers(addPosition(response));
      setLatitud(Number(response[0].latitude));
      setLongitud(Number(response[0].longitude));
      setZoom(8);
    } else {
      console.log("NO Hay datos ");
    }
  };

  const getUsers = async (arg) => {
    console.log(arg);
    try {
      const response = await GetUsersCity(arg);
      setMarkers(addPosition(response));
      setLatitud(Number(response[0].latitude));
      setLongitud(Number(response[0].longitude));
      console.log(" response " + JSON.stringify(response));
    } catch (error) {
      console.error(error);
    }
  };

  const getPostalCodeInfo = async (arg) => {
    try {
      const response = await GetUserPostalCode(arg);
      console.log(" Info Codigo Postal " + JSON.stringify(response));
    } catch (error) {}
  };

  const setNewCenterLocation = (selectedDepartment) => {
    try {
      setLongitud(selectedDepartment?.location_point?.coordinates[0]);
      setLatitud(selectedDepartment?.location_point?.coordinates[1]);
    } catch (error) {
      console.error(error);
    }
  };

  const getUsersDepto = async (arg) => {
    try {
      const response = await GetUsersDepto(arg);
      setMarkers(addPosition(response));
      // setLatitud(Number(response[0].latitude));
      // setLongitud(Number(response[0].longitude));
    } catch (error) {}
  };

  const addPosition = (a) => {
    let array = [];
    console.log(a);
    a.map((user) => {
      user = {
        position: { lat: Number(user.latitude), lng: Number(user.longitude) },
        icono: user.tipo == "agente" ? "./pointer.png" : "./pointerUser.png",
        ...user,
      };

      array.push(user);
    });
    return array;
  };

  useEffect(() => {
    setFilterMarkers(markers);

    let polygonCoordinates = [];
    markers.map((pts) => {
      polygonCoordinates.push(pts.position);
    });
    console.log(" --coordenadas -- " + JSON.stringify(polygonCoordinates));
    setCoords(polygonCoordinates);

    const x = Math.ceil(Math.random() * 10);
    setKeyPath(x);
  }, [markers]);

  const getData = (objeto) => {
    console.log(" objeto " + JSON.stringify(objeto));
    let array = [];
    array.push(objeto);
    setFilterMarkers(array);

    setLatitud(objeto.position.lat);
    setLongitud(objeto.position.lng);
  };

  useEffect(() => {
    departments.refetch();
    setSelectedDepartment(null);
    setSelectedCity(null);
  }, [selectedCountry]);

  useEffect(() => {
    cities.refetch();
    setSelectedCity(null);
  }, [selectedDepartment]);

  const socketConnection = async () => {
    const token = await getToken();
    const pusher = WebsocketSuscriptionService(token);
    // console.log("pusher", pusher);

    const channel = pusher.subscribe("presence-admin.location");
    channel.bind("client.location", function (data) {
      console.log("Inside pusher function", data);
      // Actualizar el estado con los nuevos datos
      console.log("data", typeof(data));
      setMobileMarkersCoordinates((prevData) => [...prevData, data]);
    });
  }

  useEffect(() => {
    socketConnection();
    
    return () => {
    };
  }, []);

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: "20px",
        mt: { xs: "1rem", sm: 0 },
        p: { xs: "0.4rem", sm: "2rem" },
        maxWidth: "100%",
      }}
    >
      <CardContent>
        <Grid container mb={4}>
          <Grid item xs={6}>
            <Box mb={1.5}>
              <Typography variant="caption">
                {t("maps.filterBy.location")}
              </Typography>
            </Box>
            <Stack spacing={2}>
              {!countries.isFetching && (
                <Autocomplete
                  id="country-select-maps"
                  options={countries.isSuccess ? countries.data : []}
                  loadingText="Loading..."
                  onChange={handleCountryChange}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  value={selectedCountry}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={t("zones.table.header-3")}
                      required
                      value={selectedCountry}
                    />
                  )}
                />
              )}

              {selectedCountry && !departments.isFetching && (
                <Autocomplete
                  id="department-select"
                  options={departments?.data}
                  loadingText="Loading..."
                  onChange={handleDepartmentChange}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  disableClearable
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  value={selectedDepartment}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={t("zones.table.header-4")}
                      required
                      value={selectedDepartment}
                    />
                  )}
                />
              )}
              {selectedDepartment && !cities.isFetching && (
                <Autocomplete
                  id="city-select"
                  options={cities?.data}
                  loadingText="Loading..."
                  onChange={handleCityChange}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  disableClearable
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  value={selectedCity}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={t("zones.table.header-5")}
                      required
                      // value={selectedCity}
                    />
                  )}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={6} pl={2}>
            <Box>
              <Typography variant="caption">
                {t("maps.filterBy.type")}
              </Typography>
            </Box>
            {/* {checkBoxList.map((check, index) => {
              return (
                <Stack
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  key={index}
                >
                  <Checkbox
                    key={index}
                    id={check}
                    type="checkbox"
                    name={check}
                    // checked={true}
                    onChange={handleCheck}
                  />
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {check}
                  </Typography>
                </Stack>
              );
            })} */}
            {Object.entries(checkboxValues).map(([checkboxName, isChecked]) => (
              <FormControlLabel
                key={checkboxName}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheck}
                    name={checkboxName}
                  />
                }
                label={
                  checkboxName === "agente"
                    ? t("maps.checkbox.label.agent")
                    : checkboxName === "cliente"
                    ? t("maps.checkbox.label.client")
                    : t("maps.checkbox.label.device")
                }
              />
            ))}
          </Grid>
          <Grid item xs={6} mt={2}>
            <Box mb={1.5}>
              <Typography variant="caption">
                {t("maps.filterBy.keyword")}
              </Typography>
            </Box>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                type="text"
                placeholder={t("maps.searchbar.label")}
                value={searchTerm}
                onChange={handleInputChange}
                size="small"
                fullWidth
              />
              <Button onClick={handleSearch} variant="contained">
                <SearchIcon />
              </Button>
            </Stack>
          </Grid>
        </Grid>

        {/* <Modal
            isOpen={isModalOpen}
            closeModal={closeModal}
            data={rtaSearch}
            getData={getData}
          /> */}
        <Divider
          sx={{
            marginY: "2rem",
          }}
        />
        <Box
          key={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        {centerMap &&
          <MapContainer
            markers={filterMarkers}
            zoomMap={zoomMap}
            centerMap={centerMap}
            path={coords}
            keyValue={keyPath}
            realTimeMarkers={mobileMarkersCoordinates}
          />}
        </Box>
      </CardContent>
    </Card>
  );
}
