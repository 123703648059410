import { Stack, Typography } from "@mui/material";
import Logo from "../../assets/icons/logo.png";

export default function LogoTitle() {

  return (
    <Stack
      direction="row"
      spacing={-2}
      my='1rem'
      ml='-0.2rem'
      justifyContent="start"
      alignItems="center"
    >
      <img lazyloading="true" src={Logo} alt="Mi Ángel Logo" width={170} />
      <Typography variant="h6" fontWeight={600}>Admin</Typography>
    </Stack>
  );
}
