import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDelete from "../../components/alert-management-components/ModalConfirmDelete";

const HandleAlertActions = ({ userId , data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

 

  const handleCloseModal = () => {
    setIsOpen(false);
    navigate(`/listManagement`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDelete = () => {
    handleClose();
    setIsOpen(true);
  };

  const handleClickEdit = () => {
    handleClose();
    navigate(`/alertManagement`, { state: { mode: "edit", data: data, userId: userId } });
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 160,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("alertOptions.item-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("alertOptions.item-2")}</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmDelete isOpen={isOpen} handleClose={handleCloseModal} userId={userId} data={data}/>
    </>
  );
};

const HeadersAlert = () => {
  const { t } = useTranslation();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 100,
      renderCell: (params) => {
        const userId = params?.row?.id;
        return <HandleAlertActions data={params?.row} userId={userId} />;
      },
    },
    {
      field: "id",
      headerName: `ID`,
      width: 100,
    },
    {
      field: "name",
      headerName: t("roleList.headers-1"),
      width: 200,
    },
    {
      field: "type",
      headerName: t("roleList.headers-6"),
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: t("roleList.headers-2"),
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "imageUrl",
      headerName: t("roleList.headers-7"),
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "enable",
      headerName: t("roleList.headers-5"),
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value === true ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        );
      },
    }
  ];
  return columns;
};

export default HeadersAlert;
