import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Typography,
  Box,
  Divider,
  Stack,
} from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { DocumentTypeByIdService } from "../../services/DocumentTypeByIdService";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ValidationStepOneModal({
  isOpen,
  handleClose,
  handleConfirm,
  formValues,
  isFormValid
}) {
  const { t } = useTranslation();
  const {
    name_1,
    name_2,
    surname_1,
    surname_2,
    document_type_id,
    document_number,
    movil,
    email,
  } = formValues;
  
  const [documentType, setDocumentType] = useState("");

  // console.log(isFormValid);
  const formatName = (name) => {
    return name ? `${name} ` : "";
  };

  const formattedNames = `${formatName(name_1)}${formatName(
    name_2
  )}${formatName(surname_1)}${formatName(surname_2)}`;

  useEffect(() => {
    if (formValues) {
      const getDocumentType = async () => {
        try {
          const response = await DocumentTypeByIdService(document_type_id);
          setDocumentType(response);
        } catch (error) {
          console.log(error);
        }
      };
      getDocumentType();
    }
  }, [document_type_id])

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle sx={{ p: "2rem" }}>
        <Stack direction="row" alignItems={"center"} justifyContent="center">
          <FindInPageIcon sx={{ color: "gray" }} />
          <Typography variant="h6">{t("verifydata.modal")}</Typography>
        </Stack>
        <Box mt={1} sx={{ justifyContent: "center" }}>
          <Typography>{t("verifydata.modal.description")}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: "2rem" }}>
        <Grid container>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-1")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{formattedNames.trim()}</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-2")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{documentType}</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-3")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{document_number}</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-4")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{movil}</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-5")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{email}</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: "2rem", gap: "0.5rem" }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
          }}
        >
          {t("verifydata.modal.correct.button")}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={!isFormValid}
          onClick={() => {
            handleConfirm();
          }}
        >
          {t("verifydata.modal.confirm.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
