import Axios from "axios";

const AUTH_DICTIONARIES = import.meta.env.VITE_AUTH_DICTIONARIES;

export function CreateNewEpsService(data, token) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(data);
      const response = await Axios.post(
        `${AUTH_DICTIONARIES}/api/general/v1/eps/create`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response);
      resolve(response);
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
}