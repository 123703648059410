export const zoneEditMapping = (data) => {

  const invertedCoordinates = data?.geo_area?.coordinates[0].map(coord => [coord[1], coord[0]]);
  const invertedCenter = [data?.geo_area?.coordinates[0][0][1], data?.geo_area?.coordinates[0][0][0]];

  const zoneMapped = {
    id: data?.id,
    name: data?.name,
    location: data?.location,
    country: data?.country,
    department: filteredLocation(data?.department),
    city: filteredLocation(data?.city),
    center: invertedCenter,
    coordinates: invertedCoordinates,
  };
  return zoneMapped;
};

const filteredLocation = (location) => {
  const { id, name, active } = location;
  return { id, name, active };
};
