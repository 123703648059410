import { useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import TimeFilter from "../common/TimeFilter";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PaymentMethodsStats() {
  const { t } = useTranslation();

  const data = {
    labels: ["Tarjeta", "PSE", "Efecty"],
    datasets: [
      {
        label: "Total",
        data: [0, 19, 0],
        backgroundColor: ["#FEBC12", "#36529E", "#96EBCE"],
        borderWidth: 2,
      },
    ],
  };
  
  const options = {
    responsive: true,
    radius: "75%",
    cutout: "50%",
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("paymenthmethods.title")}</Typography>
          <TimeFilter />
        </Stack>
        <Divider sx={{ marginBlock: "1rem" }} />
        <Pie data={data} options={options} />
      </CardContent>
    </Card>
  );
}
