import {
  Card,
  CardContent,
  Typography,
  Divider,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";
import { GetBusinessStatsServices } from "../../services/Stats/GetBusinessStatsServices.js";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getToken } from "../../utils/GetToken";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BusinessStatsByCity() {
  const { t } = useTranslation();
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const getBusinessStatsByCity = async () => {
    const token = await getToken();
    const response = await GetBusinessStatsServices(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["byCity", "byState"],
    queryFn: () => getBusinessStatsByCity(),
  });

  const totalCities = response?.byCity || [];
  const totalStates = response?.byState || [];

  const cityCount = totalCities.reduce((total, city) => total + city.count, 0);
  const stateCount = totalStates.reduce(
    (total, state) => total + state.count,
    0
  );

  const availableCities = [...new Set(totalCities.map((city) => city.city))];
  const availableStates = [
    ...new Set(totalStates.map((states) => states.state)),
  ];

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const getCountForSelectedCity = () => {
    const selectedCityStats = totalCities.find(
      (cities) => cities.city === selectedCity
    );
    return selectedCityStats ? selectedCityStats.count : 0;
  };

  const getCountForSelectedState = () => {
    const selectedStateStats = totalStates.find(
      (states) => states.state === selectedState
    );
    return selectedStateStats ? selectedStateStats.count : 0;
  };

  const data = {
    labels: [t("cities"), t("BusinessState.title")],
    datasets: [
      {
        data: [cityCount, stateCount],
        backgroundColor: ["rgba(29, 65, 122, 0.2)", "rgba(107, 229, 138, 0.2)"],
        borderColor: ["#1D417A", "#6BE58A"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
        y: {
          beginAtZero: true,
        },
      },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          padding: 15,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">{t("businessCard.subtitle-5")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        {/* <TimeFilter />
         */}
        <Typography>{t("businessCard.subtitle-6")}</Typography>

        <Stack display="flex" flexDirection={"row"} justifyContent="center" alignItems="center" mt={1}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel>{t("cities")}</InputLabel>
            <Select
              value={selectedCity}
              onChange={handleCityChange}
              displayEmpty
              label={t("cities")}
            >
              {availableCities.map((city) => (
                <MenuItem key={city} value={city}>
                  {t(city)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            selectedCity && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <LocationCityIcon color="success" />
                <Typography variant="h5" color={"success.main"}>
                  {getCountForSelectedCity()}
                </Typography>
              </Stack>
            )
          )}
        </Stack>

        <Divider sx={{ my: "1rem" }} />
        <Typography mt={"1rem"}>{t("businessCard.subtitle-7")}</Typography>
        <Stack display="flex" flexDirection={"row"} justifyContent="center" alignItems="center" mt={1}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel>{t("BusinessState.title")}</InputLabel>
            <Select
              value={selectedState}
              onChange={handleStateChange}
              displayEmpty
              label={t("BusinessState.title")}
            >
              {availableStates.map((state) => (
                <MenuItem key={state} value={state}>
                  {t(state)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            selectedState && (
              <Stack direction="row" spacing={1} alignItems="center">
                <LocationCityIcon color="success" />
                <Typography variant="h5" color={"success.main"}>
                  {getCountForSelectedState()}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Stack sx={{height: 180, mt: "1rem",alignItems: "center", }}>
            <Bar data={data} options={options} />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
