import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function GetSensitiveChangeByIdService(token, changeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${URL_AUTH}/api/admin/v1/sensitive/${changeId}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}