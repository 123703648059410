import axios from "axios";

const URL_CLIENTS = import.meta.env.VITE_AUTH_CLIENT;

export function GetAlertDetailsService (token, alertId ) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${URL_CLIENTS}/api/admin/v1/alert/${alertId}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response);
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
};