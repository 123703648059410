import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Alert,
  Card,
  CardContent,
  Stack,
  Divider,
  Link,
  TextField,
  Snackbar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import ReplayIcon from "@mui/icons-material/Replay";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { getTokenTempo } from "../../utils/GetTokenTempo";
import { VerifyCodeEmailConfirmService } from "../../services/2FA/VerifyCodeEmailConfirmService";
import { ResendCodeEmailConfirmService } from "../../services/2FA/ResendCodeEmailConfirmService";

function ConfirmEmail() {
  const [loadingEmailConfirmation, setLoadingEmailConfirmation] =
    useState(false);
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const [isEmailCodeValid, setIsEmailCodeValid] = useState(false);
  const [isConfirmedEmailCode, setIsConfirmedEmailCode] = useState(false);
  const [resendCounterEmail, setResendCounterEmail] = useState(60);
  const [activeTimerEmail, setActiveTimerEmail] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showResendedMessage, setShowResendedMessage] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const [formValueEmail, setFormValueEmail] = useState({
    code: "",
  });

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    let interval;

    if (activeTimerEmail && resendCounterEmail > 0) {
      interval = setInterval(() => {
        setResendCounterEmail((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (resendCounterEmail === 0) {
      clearInterval(interval);
      setActiveTimerEmail(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [activeTimerEmail, resendCounterEmail]);

  

  const goHome = () => {
    window.location.reload();
  };

  const handleSubmitEmailCode = async (e) => {
    e.preventDefault();
    try {
      // console.log(formValueEmail);
      const tokenTempo = await getTokenTempo();
      setLoadingEmailConfirmation(true);
      const response = await VerifyCodeEmailConfirmService(formValueEmail, tokenTempo);
      // console.log(response);
      localStorage.removeItem("refreshTokenTimeTempo");
      localStorage.removeItem("refreshTokenTempo");
      localStorage.removeItem("refreshTokenOtp");
      localStorage.setItem("refreshTokenTime", new Date().getTime());
      localStorage.setItem("refreshToken", response.data.access_token);
      goHome();
    } catch (error) {
      console.log(error);
      setErrorMessage(error);
      setError(true);
      setLoadingEmailConfirmation(false);
    } finally {
      setLoadingEmailConfirmation(false);
    }
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleCloseResendedMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResendedMessage(false);
  };

  const handleEmailCodeChange = (event) => {
    const { name, value } = event.target;
    setFormValueEmail((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
    value.length === 6 ? setIsEmailCodeValid(true) : setIsEmailCodeValid(false);
  };

  useEffect(() => {
    handleResendEmailCode();
  }, []);

  const handleResendEmailCode = async () => {
    // e.preventDefault();
    try {
      const token = await getTokenTempo();
      console.log(token);
      const response = await ResendCodeEmailConfirmService(token);
      if (response.status === 200) {
        setResendMessage(response.data.message);
        setShowResendedMessage(true);
      }
    } catch (error) {
      setErrorMessage(error);
      setError(true);
      console.log(error);
    } finally {
      setLoadingResendEmail(false);
    }
    handleResetClickEmail();
  };

  const handleResetClickEmail = () => {
    setActiveTimerEmail(true);
    setResendCounterEmail(60);
  };

  const goToLogin = () => {
    navigate("/");
  };

  return (
    <Box className="login">
      <Stack
        mt={{ xs: 0, md: 2 }}
        sx={{
          justifyContent: "center",
          alignItems: { xs: "center" },
        }}
      >
        <Snackbar
          open={showResendedMessage}
          autoHideDuration={6000}
          onClose={handleCloseResendedMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{resendMessage}</Alert>
        </Snackbar>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Card
          elevation={4}
          sx={{
            borderRadius: "20px",
            p: "1rem",
            mx: { xs: "0.4rem", sm: "10rem", md: "0" },
            mt: { xs: "2rem", md: 0 },
            maxWidth: "500px",
          }}
        >
          <CardContent>
            <Typography variant="h6" fontWeight={600} textAlign={"center"}>
              {t("verification_code.title")}
            </Typography>
            <Divider sx={{ my: "1rem" }} />
            <Typography variant="p" textAlign={"start"}>
              {t("verification_code.text")}
            </Typography>
            <form onSubmit={handleSubmitEmailCode}>
              <Box my={2}>
                <Divider variant="middle">
                  <EmailIcon />
                  <Typography>{t("confirmation.code.email")}</Typography>
                </Divider>
              </Box>
              <Stack mt={2} spacing={2}>
                <TextField
                  label={t("confirmation.code.label.input")}
                  name="code"
                  value={formValueEmail.code || ""}
                  onChange={handleEmailCodeChange}
                  error={!isEmailCodeValid}
                  helperText={
                    !isEmailCodeValid
                      ? `${t("confirmation.code.helper.input")}`
                      : ""
                  }
                />
                <Button
                  variant="contained"
                  onClick={handleResendEmailCode}
                  disabled={resendCounterEmail > 0 || isConfirmedEmailCode}
                  color="primary"
                  startIcon={
                      loadingResendEmail ? (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <ReplayIcon sx={{ color: "white" }} />
                      )
                    }
                >
                  {resendCounterEmail > 0
                    ? `${t(
                        "confirmation.code.button.resendIn"
                      )} ${resendCounterEmail}s`
                    : `${t("confirmation.code.button.resend")}`}
                </Button>
              </Stack>
              <Box mt={4} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={!isEmailCodeValid || isConfirmedEmailCode}
                  startIcon={
                    loadingEmailConfirmation ? (
                      <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                    ) : (
                      <CheckCircleIcon sx={{ color: "white" }} />
                    )
                  }
                >
                  {!isConfirmedEmailCode
                    ? `${t("confirmation.code.button.submit")}`
                    : `${t("confirmation.code.button.confirmed")}`}
                </Button>
              </Box>
              <Stack
                mt={4}
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: { xs: "center" },
                }}
              >
                <Link
                  href="/"
                  sx={{
                    fontSize: "15px",
                    display: "flex",
                    textAlign: "center",
                    color: "secondary.main",
                  }}
                  underline="hover"
                >
                  {t("back.button")}
                </Link>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default React.memo(ConfirmEmail);
