import { styled } from "@mui/material/styles";
import { DRAWER_WIDTH as drawerWidth } from "./DrawerWidth";
import { useMediaQuery } from "@mui/material";

export const Content = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

    return {
      flexGrow: 1,
      minHeight: "calc(100vh - 20.5rem)",
      marginBlock: "2rem",
      paddingLeft: isMobile ? theme.spacing(0) : theme.spacing(2),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
      }),
    };
  },
);