import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { getToken } from "../../utils/GetToken";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CreateCompanyLogoService from "../../services/Companies/CreateCompanyLogoService";
import { useCompany } from "../../context/CompanyContext";

export default function CreateCompanyLogo({ companyId }) {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {handleIsCompanyLogoCreated,iscompanyLogoCreated, resetCompanyState} = useCompany();

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedLogo(file);
  };

  const handleUploadImage = async (e) => {
    //console.log("Nueva imagen seleccionada:", file);
    setLoading(true);
    if (selectedLogo) {
      if (selectedLogo.size > 5 * 1024 * 1024) {
        alert("El tamaño de la imagen no puede ser mayor a 5MB");
        return;
      } else {
        const allowedFormats = [".png", ".jpg", ".jpeg", ".svg"];
        const fileExtension = selectedLogo.name
          .substring(selectedLogo.name.lastIndexOf("."))
          .toLowerCase();

        if (!allowedFormats.includes(fileExtension)) {
          alert("El formato del archivo no es válido");
          return;
        } else {
          try {
            const token = await getToken();
            if (token && selectedLogo) {
              const response = await CreateCompanyLogoService(
                token,
                companyId,
                selectedLogo
              );
              console.log(response, "imagen subida");
            }
          } catch (error) {
            console.error("Error al subir la imagen:", error.response?.data);
          } finally {
            setLoading(false);
          }
        }
      }
    }
    handleCloseDialog();
    handleIsCompanyLogoCreated();
  };

  const handleCloseImage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleIsCompanyLogoCreated();
  };


  useEffect(() => {
    if (selectedLogo) {
      const redirectTimer = setTimeout(() => {
        navigate(`/companies?tab=0`);
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
  }, [selectedLogo]);

  return (
    <>
      <Snackbar
        open={iscompanyLogoCreated}
        autoHideDuration={6000}
        onClose={handleCloseImage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {t("UploadLogoCompany.success.message")}
        </Alert>
      </Snackbar>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle> {t("company.createNew.LogoUpload")}</DialogTitle>
        <DialogContent>
          <TextField
            type="file"
            onChange={handleImageChange}
            variant="outlined"
            sx={{ mt: "1rem" }}
          />
          <Button
            variant="contained"
            sx={{ mt: "1rem", padding: "10px", ml: "1rem" }}
            onClick={handleUploadImage}
          >
            Cargar Imagen
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
