import { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Divider,
  Checkbox,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EditUserLayout from "../layout/EditUserLayout";

export default function EditUserInfo() {
  const label = { inputProps: { "aria-label": "Checkbox" } };
  const { t } = useTranslation();

  return (
    <Stack>
      <Box>
        <Typography
          variant="h6"
          color={"primary"}
          textAlign={"center"}
          sx={{ textTransform: "uppercase" }}
          fontWeight={600}
        >
          {t("settings.subtitle-2")}
        </Typography>
        <Divider sx={{ mb: "1rem" }} />
        <Box mb={3}>
          <EditUserLayout />
        </Box>
        <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
          <Link to="/changePassword">
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ borderRadius: "20px" }}
            >
              {t("changePassword.title")}
            </Button>
          </Link>
        </Stack>
      </Box>
    </Stack>
  );
}
