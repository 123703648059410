import {
  Card,
  Snackbar,
  Alert,
  CardContent,
  Grid,
  TextField,
  Autocomplete,
  Box,
  Button,
  Divider,
  Stack,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Chip,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { GetBusinessTypeListService } from "../../services/BusinessTypeListService";
import { getToken } from "../../utils/GetToken";
import { CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CreateNewCompaniesService } from "../../services/Companies/CreateNewCompaniesService";
import { UpdateCompaniesService } from "../../services/Companies/UpdateCompaniesService";
import { useCompany } from "../../context/CompanyContext";
import { GetCompanyDetailService } from "../../services/Companies/GetCompanyDetailService";
import CreateCompanyLogoService from "../../services/Companies/CreateCompanyLogoService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { Create } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";

const langList = [
  { id: 1, lang: "en", name: "Ingles" },
  { id: 2, lang: "es", name: "Español" },
  { id: 3, lang: "pt", name: "Português" },
];

export default function CreateNewCompaniesForm({
  mode,
  companyId,
  handleNext,
  handleIsCompanyCreated,
}) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingType, setLoadingType] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState({});
  const [typeList, setTypeList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(null);
  const [langValue, setLangValue] = useState(null);
  const [isCompanyCreated, setIsCompanyCreated] = useState(false);
  const [companyUpdated, setCompanyUpdated] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const {
    handleCompanyCreated,
    isCompanyUpdated,
    resetCompanyState,
    handleCompanyUpdated,
  } = useCompany();
  const { t } = useTranslation();
  //const { companyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const fileInputRef = useRef(null);
  const [logoSrc, setLogoSrc] = useState(
    loadingCompany?.logo !== null ? loadingCompany.logo : null
  );
  

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    type: "",
    language: "es",
    // logo: null,
  });

  const CreatePayload = () => {
    return {
      name: formValues.name,
      description: formValues.description,
      type: selectedType?.id,
      language: langValue?.lang || "es",
      // logo: formValues.logo || null,
    };
  };

  const getType = async () => {
    const token = await getToken();
    const type_List = await GetBusinessTypeListService(token);
    console.log("tipo de empresa", type_List);
    setTypeList(type_List?.data);
    setLoadingType(false);
  };

  const getCompany = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetCompanyDetailService(token, companyId);
        console.log("empresa", response);
        if (response) {
          let currentLanguage = null;

          if (response?.type && response.type.language) {
            currentLanguage = langList.find(
              (item) => response.type.language === item.lang
            );
          }

          setLanguageSelected(currentLanguage);
          setLoadingCompany(response);
          currentLanguage && setLangValue(currentLanguage);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleTypeChange = (event, value) => {
    setSelectedType(value);
    if (value?.id !== null) {
      setFormValues({
        ...formValues,
        type: value?.id,
      });
    }
  };

  const handleLangChange = (event, value) => {
    const selectedLanguage = langList.find((lang) => lang.id === value);

    if (selectedLanguage) {
      setFormValues((prevState) => ({
        ...prevState,
        language: selectedLanguage.lang,
      }));
      setLangValue(selectedLanguage);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = await getToken();
      const request = CreatePayload();
      if (mode === "edit") {
        const response = await UpdateCompaniesService(
          token,
          request,
          companyId
        );
        console.log(response);
        setCompanyUpdated(true);
        handleCompanyUpdated();
      } else {
        const response = await CreateNewCompaniesService(token, formValues);
        console.log(response);
        handleIsCompanyCreated(response?.id);
        setIsCompanyCreated(true);
        handleCompanyCreated();
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        alert("El tamaño de la imagen no puede ser mayor a 5MB");
      } else {
        const allowedFormats = [".png", ".jpg", ".jpeg", ".svg"];
        const fileExtension = selectedFile.name
          .substring(selectedFile.name.lastIndexOf("."))
          .toLowerCase();

        if (!allowedFormats.includes(fileExtension)) {
          alert("Error en el formato permitido");
        } else {
          const token = await getToken();
          try {
            const response = await CreateCompanyLogoService(
              token,
              companyId,
              selectedFile
            );

            console.log(response);

            const fileReader = new FileReader();
            fileReader.onload = function () {
              setLogoSrc(fileReader.result);
            };
            fileReader.readAsDataURL(selectedFile);
            setFormValues((prevState) => ({
              ...prevState,
              logo: response.data.logo,
            }));
          } catch (error) {
            console.log(error.response?.data);
            console.log(error);
            alert("Error al actualizar la imagen");
          }
          //handleIsPlanImageUpload();
        }
      }
    }
  };

  const handleEditImageClick = () => {
    fileInputRef.current.click();
  };

  const handleOpenImageDialog = () => {
    setOpenImageDialog(true);
  };

  // const handleCloseImageDialog = () => {
  //   setOpenImageDialog(false);
  // };

  // const handleConfirmDeleteImage = async () => {
  //   handleCloseImageDialog();
  //   await handleDeleteImage();
  // };

  useEffect(() => {
    if (data) {
      setSelectedType(data.type);
      setLanguageSelected(data.language);
      const currentLanguage = langList.find(
        (item) => data?.languaje === item.lang
      );
      currentLanguage && setLanguageSelected(currentLanguage);
    }
  }, [data]);

  useEffect(() => {
    if (loadingCompany?.logo) {
      setLogoSrc(loadingCompany.logo);
    }
  }, [loadingCompany]);

  useEffect(() => {
    getType();
  }, []);

  useEffect(() => {
    if (mode === "edit") {
      getCompany();
    }
  }, [mode, companyId]);

  useEffect(() => {
    if (isCompanyCreated) {
      handleNext();
    }
  }, [isCompanyCreated]);

  useEffect(() => {
    if (mode === "edit" && loadingCompany) {
      setSelectedType(loadingCompany.type);
      setLanguageSelected(loadingCompany.language);

      if (loadingCompany.language) {
        const currentLanguage = langList.find(
          (item) => loadingCompany.language === item.lang
        );

        currentLanguage && setLangValue(currentLanguage);
      }

      setFormValues((prevState) => ({
        ...prevState,
        name: loadingCompany.name,
        description: loadingCompany.description,
        logo: loadingCompany?.logo,
      }));
    }
  }, [mode, loadingCompany]);

  useEffect(() => {
    if (companyUpdated) {
      const redirectTimer = setTimeout(() => {
        navigate(`/companies?tab=0`);
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
    finalizeUpdateCompany();
  }, [companyUpdated]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleCompanyUpdated();
  };

  const finalizeUpdateCompany = () => {
    resetCompanyState();
  };

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: "20px",
        mt: { xs: "1rem", sm: 0 },
        p: { xs: "0.4rem", sm: "2rem" },
        maxWidth: "100%",
      }}
    >
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={isCompanyUpdated}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("companies.edit.sucesse.message")}</Alert>
      </Snackbar>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                label={t("createNewCompanies.formLabel.name")}
                value={formValues.name}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="description"
                label={t("createNewCompanies.formLabel.description")}
                value={formValues.description}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="type"
                name="type"
                options={loadingType ? [{ name: "loading" }] : typeList}
                loading={loadingType}
                value={selectedType || null}
                loadingText="Loading..."
                onChange={handleTypeChange}
                autoHighlight
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    name="type"
                    label={t("createNewCompanies.formLabel.companyType")}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="lang"
                options={langList}
                onChange={handleLangChange}
                inputValue={langValue ? langValue.name : ""}
                value={langValue}
                onInputChange={(event, newInputValue) => {
                  setLangValue((prevState) => ({
                    ...prevState,
                    name: newInputValue,
                  }));
                }}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    name="language"
                    label={t("language")}
                  />
                )}
              />
            </Grid>
            {mode === "edit" && (
              <>
                <Grid item xs={12} my={2}>
                  <Divider variant="middle">
                    <Chip label={t("createNewCompanies.section.label-2")} />
                  </Divider>
                </Grid>
                <Box sx={{ m: "auto", mt: "2rem" }}>
                  <Stack ml={"1rem"}>
                    <Avatar
                      alt="plan image"
                      src={logoSrc}
                      sx={{ width: "160px", height: "155px" }}
                    >
                     <AssignmentIcon sx={{ width: 56, height: 56 }} /> 
                    </Avatar>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    ml={"23%"}
                    mt={"0.5rem"}
                  >
                    <IconButton
                      color="primary"
                      size="large"
                      onClick={handleEditImageClick}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>

                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={handleFileChange}
                    />

                    <IconButton
                      size="large"
                      color="primary"
                      onClick={handleOpenImageDialog}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                </Box>
                {/* <Dialog
                  open={openImageDialog}
                  onClose={() => setOpenImageDialog(false)}
                >
                  <DialogTitle sx={{ p: "1rem" }}>
                    <Stack alignItems={"center"} justifyContent="center">
                       <InfoIcon />  
                      <Typography variant="h6" color="warning">
                        {t("deleteAccount.confirmation.title")}
                      </Typography>
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t("deleteAccount.confirmation.content.image")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenImageDialog(false)}
                      color="primary"
                    >
                      {t("cancel.button")}
                    </Button>

                    <Button onClick={handleConfirmDeleteImage} color="primary">
                      {t("delete.button")}
                    </Button>
                  </DialogActions>
                </Dialog> */}
              </>
            )}
          </Grid>

          <Box mt={"1.5rem"} sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color={"secondary"}
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                ) : (
                  <CheckCircleIcon sx={{ color: "white" }} />
                )
              }
            >
              {mode === "edit"
                ? t("createNewPlan.button.edit")
                : t("createNewPlan.button.create")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
