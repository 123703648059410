import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TimeFilter from "../common/TimeFilter.jsx";
import { useQuery } from "@tanstack/react-query";
import { GetUserStatsServices } from "../../services/Stats/GetUserStatsServices.js";
import { getToken } from "../../utils/GetToken.js";
import { useState } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Doughnut } from "react-chartjs-2";

export default function UserStatsByGender() {
  const { t } = useTranslation();

  const [selectedGender, setSelectedGender] = useState("");

  const getGenderStats = async () => {
    const token = await getToken();
    const response = await GetUserStatsServices(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["genderStats"],
    queryFn: () => getGenderStats(),
  });

  const totalGender = response?.data?.genderStats || [];

  const availableGenders = [
    ...new Set(totalGender.map((gender) => gender.gender_id)),
  ];
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const getCountForSelectedGender = () => {
    const selectedGenderStats = totalGender.find(
      (gender) => gender.gender_id === selectedGender
    );
    return selectedGenderStats ? selectedGenderStats.count : 0;
  };
  const data = {
    labels: totalGender.map((gender) => t(`gender.${gender.gender_id}`)),
    datasets: [
      {
        label: [t("gender.title")],
        data: totalGender.map((gender) => gender.count),
        backgroundColor: ["#1D417A", "#6BE58A", "#FF6660"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          padding: 15,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("accountsCard.title-2")}</Typography>
          {/* <TimeFilter /> */}
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountsCard.subtitle-10")}</Typography>
        <Stack direction="row" spacing={2} alignItems="center" mt="1rem">
          <FormControl sx={{ m: 1, minWidth: 100, mt: "1rem" }} size="small">
            <InputLabel>{t("gender.title")}</InputLabel>
            <Select
              value={selectedGender}
              onChange={handleGenderChange}
              displayEmpty
              label={t("genders")}
            >
              {availableGenders.map((gender_id) => (
                <MenuItem key={gender_id} value={gender_id}>
                  {t(`gender.${gender_id}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
          selectedGender && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <GroupAddIcon color="success" />
              <Typography variant="h5" color={"success.main"}>
                {isLoading || isError ? "-" : getCountForSelectedGender()}
              </Typography>
            </Stack>
          )
          )}
        </Stack>

        <Stack sx={{ height: "200px", alignItems: "center", mt: "1.5rem" }}>
        {isLoading ? (
            <CircularProgress size={24} />
          ) : (
          <Doughnut data={data} options={options} />
        )}
        </Stack>
      </CardContent>
    </Card>
  );
}
