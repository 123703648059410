import Axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export function GetPlansListService(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`${URL_MERCHANTS}/api/v1/plans/list`, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}
