import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Modal,
  Divider,
  IconButton,
  Button,
  Skeleton,
  Stack,
} from "@mui/material";
import { useMarkers } from "../../context/MapContext";
import { ClientInfoByIdService } from "../../services/ClientInfoByIdService";
import { getToken } from "../../utils/GetToken";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

export default function ModalInfoClient({ clientId }) {
  const { clientModalOpen, handleClientModalOpen } = useMarkers();
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const getClient = async () => {
    const token = await getToken();
    if (token) {
      const dataClient = ClientInfoByIdService(token, clientId);
      return dataClient;
    }
    return {};
  };

  const client = useQuery({
    queryKey: ["clientMarker"],
    queryFn: getClient,
  });

  useEffect(() => {
    client.isLoading ? setLoading(true) : setLoading(false);
  }, [client]);

  useEffect(() => {
    if (client.isSuccess) {
      setClientData(client.data);
    }
  }, [client.data]);

  useEffect(() => {
    setLoading(true);
    getClient();
  }, [clientId]);

  const handleClickMoreDetails = () => {
    navigate(`/clients&agents`);
  };

  return (
    <>
      {client.isSuccess && (
        <Modal
          open={clientModalOpen}
          onClose={handleClientModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  color={"primary"}
                >
                  {t("userInfo.modal.title")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                alignItems={"flex-start"}
                display="flex"
                justifyContent={"flex-end"}
              >
                <IconButton onClick={handleClientModalOpen}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider sx={{ marginBlock: "0.5rem", color: "primary" }} />
            <Stack direction="row" spacing={1}>
              <Typography color={"primary"}>ID:</Typography>
              <Typography>{clientData?.id}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color={"primary"}>
                {t("userInfo.modal.fullname.label")}:
              </Typography>
              <Typography>{clientData?.fullName}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color={"primary"}>
                {t("userInfo.modal.documentNumber.label")}:
              </Typography>
              <Typography> {clientData?.document_number}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color={"primary"}>
                {t("userInfo.modal.gender.label")}:
              </Typography>
              <Typography> {clientData?.gender?.name}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color={"primary"}>
                {t("userInfo.modal.city.label")}:
              </Typography>
              <Typography>{clientData?.city?.name}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color={"primary"}>
                {t("userInfo.modal.email.label")}:
              </Typography>
              <Typography>{clientData?.email}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color={"primary"}>
                {t("userInfo.modal.mobile.label")}:
              </Typography>
              <Typography>{clientData?.movil?.number}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography color={"primary"}>
                {t("userInfo.modal.address.label")}:
              </Typography>
              <Typography>{clientData?.address}</Typography>
            </Stack>
            <Divider sx={{ marginBlock: "0.5rem", color: "primary" }} />
            <Box sx={{ textAlign: "center" }}>
              <Button onClick={handleClickMoreDetails}>
                {t("userInfo.modal.buttonDetails.label")}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
