import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Typography,
  Box,
  Divider,
  Stack,
} from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";
import { getToken } from "../../utils/GetToken";
import { DeleteZoneService } from "../../services/Zones/DeleteZoneService";
import { useZone } from "../../context/ZonesContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDeleteZone({
  handleClose,
  isOpen,
  zoneId,
  data,
}) {
  const { t } = useTranslation();

  const { handleZoneDeleted } = useZone();

  const handleDelete = async () => {
    const token = await getToken();
    const _ = await DeleteZoneService(token, zoneId);
    handleZoneDeleted();
    handleClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle sx={{ p: "2rem" }}>
        <Stack alignItems={"center"} justifyContent="center">
          <ErrorIcon color="error" />
          <Typography variant="h6" color="error">
            {t("deleteAccount.confirmation.title")}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: "2rem" }}>
        <Grid
          container
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {t("zoneDesactivate.confirmation.content")}
          </Grid>
          <Grid mt={2} item xs={12}>
            <Typography>{data?.name}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: "2rem", gap: "0.5rem" }}>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          {t("cancel.button")}
        </Button>
        <Button color="secondary" variant="contained" onClick={handleDelete}>
          {t("disable.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
