import { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Checkbox,
  Button,
  Container,
  Tabs,
  Tab,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import PropTypes from "prop-types";
import EditUserInfo from "../settings-components/EditUserInfo";
import EnableOrDisable2FA from "../settings-components/EnableOrDisable2FA";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log(props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box sx={{ p: { xs: "0.2rem", sm: 3 }, mt: 2, mb: 8 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SettingsLayout() {
  const { t } = useTranslation();
  const { isOpen } = useMenu();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Content open={isOpen}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pl: 0 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("settings.tabTitle-1")} {...a11yProps(0)} />
          <Tab label={t("settings.tabTitle-2")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color={"primary"}
              textAlign={"flex-start"}
              sx={{ textTransform: "uppercase" }}
              fontWeight={800}
            >
              {t("settings.tabTitle-1")}
            </Typography>
            <Box my={2} mb={4}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("settings.description")}
              </Typography>
            </Box>
            <EditUserInfo />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={12}>
            <EnableOrDisable2FA />
          </Grid>
        </Grid>
      </TabPanel>
    </Content>
  );
}
