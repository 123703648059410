import { useState, useContext } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Stack,
  useMediaQuery,
  Drawer,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import SideMenuListItems from "./SiteMenuListItems";
import TitleAndDate from "../appbar-components/TitleAndDate";
import MenuAppBar from "../appbar-components/MenuAppBar";
import { AuthContext } from "../../context/AuthContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMenu } from "../../context/MenuOpenContext";
import SwitchLanguages from "./SwitchLanguage";

const drawerWidth = 300;

const drawerWidthOpen = "100%";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 4px)`,
  [theme.breakpoints.down("xl")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    zIndex: theme.zIndex.drawer - 1,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SearchAppBar() {
  const theme = useTheme();
  const { isLoggedIn } = useContext(AuthContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(!isMobile && isLoggedIn ? true : false);
  const { handleOpenMenu } = useMenu();

  const handleDrawer = () => {
    setOpen(!open);
    handleOpenMenu();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="relative"
        sx={{
          height: "6rem",
          justifyContent: "center",
        }}
        open={open}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack direction={"row"}>
            {isLoggedIn && (
              <IconButton
                size="large"
                edge="start"
                aria-label="open drawer"
                onClick={handleDrawer}
                sx={{ mr: 2, color: "primary.light" }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <TitleAndDate />
          </Stack>
          {isLoggedIn ? <MenuAppBar /> : <SwitchLanguages />}
        </Toolbar>
      </AppBar>
      {isLoggedIn &&
        (!isMobile ? (
          <Drawer
            variant={"permanent"}
            open={open}
            onClose={() => setOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              whiteSpace: "nowrap",
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
              boxSizing: "border-box",
              ...(open && {
                ...openedMixin(theme),
                "& .MuiDrawer-paper": openedMixin(theme),
              }),
              ...(!open && {
                ...closedMixin(theme),
                "& .MuiDrawer-paper": closedMixin(theme),
              }),
            }}
          >
            <SideMenuListItems open={open} setOpen={setOpen} />
          </Drawer>
        ) : (
          <Drawer
            sx={{
              width: drawerWidthOpen,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidthOpen,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawer}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <SideMenuListItems open={open} setOpen={setOpen} />
          </Drawer>
        ))}
    </Box>
  );
}
