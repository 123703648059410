import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const GetAllPlansService = (token, page, query, pagination) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("Query from datagrid", query);
      let url;
      if (query === "") {
        url = `${URL_AUTH}/api/general/v1/info/plan?page=${page}&pagination=${pagination}`;
      } else {
        console.log("URL with params inside");
        url = `${URL_AUTH}/api/general/v1/info/plan?${query}&page=${page}&pagination=${pagination}`;
      }
      const planList = await axios.get(
        url,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("service", planList);
      resolve(planList.data);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};