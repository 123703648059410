import Axios from "axios";

const URL_CLIENT = import.meta.env.VITE_AUTH_CLIENT;

export function TypeListService(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `${URL_CLIENT}/api/admin/v1/alert/type/gender`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}