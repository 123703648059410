import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { getToken } from "../../utils/GetToken";
import { GetAllClientsService } from "../../services/GetAllClientsService";
import { t } from "i18next";

const UserCard = ({ call }) => {
  const [user, setUser] = useState({});
  const [state, setState] = useState("idle");

  useEffect(() => {
    const getUser = async () => {
      setState("loading");
      const token = await getToken();
      const userList = await GetAllClientsService(
        token,
        1,
        `movil=${call.numero_telefono}`,
        1
      );
      const user = userList.data[0];
      setUser(user);
      setState("loaded");
    };

    if (call) {
      getUser();
    }
  }, [call]);

  const [showingCard, setShowingCard] = useState(false);
  const showCard = () => {
    setShowingCard(true);
  };
  const hideCard = () => {
    setShowingCard(false);
  };

  if (state === "loading")
    return <CircularProgress style={{ height: 10, width: 10 }} />;

  if (!user) return null;

  return (
    <>
      <div style={{ position: "relative" }}>
        <Box
          style={{
            display: showingCard ? "block" : "none",
            position: "absolute",
            width: 250,
            height: 300,
            backgroundColor: "#fafafa",
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 5,
            borderColor: "#00000017",
            padding: 10,
          }}
        >
          <IconButton
            onClick={hideCard}
            sx={{ position: "absolute", right: 2, top: 2 }}
            aria-label="delete"
          >
            <CloseIcon />
          </IconButton>
          <Avatar
            src={user.avatar_url}
            sx={{ width: 100, height: 100 }}
            alt={user.name}
          />
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body1">
              {t("calls.recentTab.user.name")}:
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              {user.fullName}
            </Typography>
            <Typography variant="body1">
              {t("calls.recentTab.user.email")}:
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              {user.email}
            </Typography>
            <Typography variant="body1">
              {t("calls.recentTab.user.document")}:
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              {user.document_number}
            </Typography>
          </Box>
        </Box>
      </div>
      <span onClick={showCard}>{user.fullName}</span>
    </>
  );
};

export default UserCard;
