import { esES, ptBR } from "@mui/x-data-grid";

const assignDataGridLanguage = (currentLanguage) => {
  if (currentLanguage === "es") {
    return esES.components.MuiDataGrid.defaultProps.localeText;
  } else if (currentLanguage === "pt") {
    return ptBR.components.MuiDataGrid.defaultProps.localeText;
  }
  return {};
};

export default assignDataGridLanguage;