import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Skeleton,
  Divider,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { GetZoneDetailService } from "../../services/Zones/GetZoneDetailService";
import { useParams, useLocation } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MapZoneSelector from "./MapZoneSelector";
import { zoneDetailsMapping } from "../../adapters/zones/zoneDetailsMapping";
import { useQuery } from "@tanstack/react-query";
import { useZone } from "../../context/ZonesContext";

export default function ZoneDetails() {
  const [zoneDetail, setZoneDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { zoneId } = useParams();
  const { handleCenterZone, handleZoneCoordinates } = useZone();

  const getZone = async () => {
    const token = await getToken();
    if (token) {
      const data = await GetZoneDetailService(token, zoneId);
      const mappedZone = zoneDetailsMapping(data);
      return mappedZone;
    }
    return {};
  };

  const zone = useQuery({
    queryKey: ["zoneDetails"],
    queryFn: getZone,
  });

  useEffect(() => {
    zone.isLoading ? setLoading(true) : setLoading(false);
    setZoneDetail(zone.data);
  }, [zone]);

  useEffect(() => {
    if (zone.isSuccess) {
      handleCenterZone(zoneDetail?.center);
      handleZoneCoordinates(zoneDetail?.coordinates);
    }
  }, [zoneDetail]);

  return (
    <>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={400}
          sx={{
            width: { xs: "320px", sm: "320px", md: "500px", xl: "872px" },
            borderRadius: "20px",
            mx: "1rem",
          }}
        />
      ) : (
        <Card
          elevation={5}
          sx={{
            borderRadius: "20px",
            mx: "2rem",
            height: "auto",
            p: { xs: "0.5rem", sm: "2rem" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            {/* map */}
            <Grid item xs={12} sm={6}>
              {zone?.isSuccess ? <MapZoneSelector mode="details" /> : <></>}
            </Grid>
            <Grid item xs={12} sm={6} container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6" color="primary.light">
                          {t("zones.details.title")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider light sx={{ marginBlock: "1rem" }} />
                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AssignmentIcon fontSize="medium" />
                        <Typography variant="subtitle1">
                          {t("zones.table.header-1")}:
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {zoneDetail?.name}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" alignItems="center" spacing={1}>
                        <PlaceIcon fontSize="medium" />
                        <Typography variant="subtitle1">
                          {t("zones.formLabel.location")}:
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {zoneDetail?.location}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <PlaceIcon fontSize="medium" />
                        <Typography variant="subtitle1">
                          {t("zones.table.header-3")}:
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {zoneDetail?.country}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <PlaceIcon fontSize="medium" />
                        <Typography variant="subtitle1">
                          {t("zones.table.header-4")}:
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {zoneDetail?.department}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <PlaceIcon fontSize="medium" />
                        <Typography variant="subtitle1">
                          {t("zones.table.header-5")}:
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {zoneDetail?.city}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}
