
import { Content } from "../../utils/Content";
import { Grid } from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccompanimentDetails from "../accompaniment-components/AccompanimentDetails";

export default function AccompanimentDetailsLayout() {
  const { isOpen } = useMenu();
  const { t } = useTranslation();

  return (
    <Content open={isOpen}>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link to={"/accompaniment"} className="nav-link_back" underline="none">
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <AccompanimentDetails />
    </Content>
  );
}
