import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function VerifyCodeEmailConfirmService(data, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.put(
        `${URL_AUTH}/api/admin/v1/signup/verify/email`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
