import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const HandlePermissionsActions = (props) => {
  const [clickRoleDetails, setClickRoleDetails] = useState(false);
  const [clickEditRole, setClickEditRole] = useState(false);
  const [clickDeleteRole, setClickDeleteRole] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { updateable, roleId } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickRoleDetails = () => {
    handleClose();
    setClickRoleDetails(true);
  };

  const handleClickEdit = () => {
    handleClose();
    setClickEditRole(true);
  };

  const handleClickDelete = () => {
    handleClose();
    setClickDeleteRole(true);
  };

  useEffect(() => {
    clickRoleDetails && navigate(`/roleDetails/${roleId}`);
  }, [clickRoleDetails, clickEditRole, clickDeleteRole]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 175,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClickRoleDetails}>
          <ListItemIcon>
            <PreviewIcon />
          </ListItemIcon>
          <ListItemText>{t("rolesOptions.item-1")}</ListItemText>
        </MenuItem>
        {updateable && (
          <MenuItem onClick={handleClickEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>{t("rolesOptions.item-2")}</ListItemText>
          </MenuItem>
        )}
        {updateable && (
          <MenuItem onClick={handleClickDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>{t("rolesOptions.item-3")}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const HeadersPermissions = () => {
  const { t } = useTranslation();

  const columns = [
    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 100,
    //   renderCell: (params) => {
    //     return <HandlePermissionsActions />;
    //   },
    // },
    {
      field: "id",
      headerName: `ID`,
      width: 75,
    },
    {
      field: "name",
      headerName: t("permissionsList.headers-1"),
      width: 200,
    },
    {
      field: "detail",
      headerName: t("permissionsList.headers-2"),
      width: 750,
    },
  ];
  return columns;
};

export default HeadersPermissions;
