import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CommerceListByAccount from "../commerces-account-list-components/CommercesAccountList";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { GetAccountByIdService } from "../../services/GetAccountByIdService";
import { getToken } from "../../utils/GetToken";

export default function CommercesByAccountLayout() {
  const [accountName, setAccountName] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();
  const params = useParams();
  const { id } = params;
  const accountId = id;

  const handleButtonClick = () => {
    navigate("/createAdminAccount");
  };

  useEffect(() => {
    getAccountData();
  }, [])
  
  const getAccountData = async () => {
    try {
      const token = await getToken();
      if (token) {
        const data = await GetAccountByIdService(token, accountId);
        const fullName = data.data.full_name;
        // console.log("Account Data", data.data.full_name);
        setAccountName(fullName);
      }
    } catch (error) {}
  };

  return (
    <Content open={isOpen}>
      <Grid container>
        <Grid item xs>
          <Box paddingX={"20px"}>
            <Typography
              variant="h4"
              color={"primary"}
              textAlign={"flex-start"}
              sx={{ textTransform: "uppercase" }}
              fontWeight={800}
            >
              {t("accountsAndMerchants.tabTitle-2")}
            </Typography>
          </Box>
          <Box paddingX={"20px"} my={2}>
            <Typography variant="body1" textAlign={"flex-start"}>
              {t("accountsAndMerchants.description-5", {account: `${accountName}`})}
            </Typography>
          </Box>
        </Grid>
        <Box
          mt={2}
          sx={{
            height: 400,
            width: "100%",
            paddingInline: { xs: 0, sm: "1rem" },
          }}
        >
          <CommerceListByAccount />
        </Box>
      </Grid>
    </Content>
  );
}
