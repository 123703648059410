import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  TextField,
  Stack,
  Autocomplete,
  Box,
  Divider,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import { GetCountryListService } from "../../services/GetCountryListService";
import { useTranslation } from "react-i18next";
import { DepartmentListService } from "../../services/DepartmentListService";
import { CitiesListService } from "../../services/CitiesListService";
import ValidationStepThreeModal from "./confirmation-modals/ValidationStepThreeModal";
import SaveIcon from "@mui/icons-material/Save";
import { getToken } from "../../utils/GetToken";
import { useForm } from "../../context/CreateCommerceFormContext";
import { UpdateCommerceService } from "../../services/UpdateCommerceService";
import { useParams } from "react-router-dom";

const DICTIONARIES_URL = import.meta.env.VITE_DICTIONARIES_URL;

export default function LocationAndContactForm({
  isCommerceUpdate,
  setIsCommerceUpdated,
  formResume,
  mode,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isShopUpdated, setIsShopUpdated] = useState(false);

  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingDepartments, setLoadingDepartments] = useState(true);
  const [loadingCities, setLoadingCities] = useState(true);
  const [loadingFormData, setLoadingFormData] = useState(
    mode === "edit" || mode === "continueReg" ? true : false
  );

  const [countryList, setCountryList] = useState({});
  const [departmentList, setDepartmentList] = useState({});
  const [cityList, setCityList] = useState({});

  const [showConfirmation, setShowConfirmation] = useState(false);
  const { formData, saveFormData } = useForm();
  const { selectedValues, saveSelectedValues } = useForm();
  const [formValues, setFormValues] = useState(formData);
  const [inputValue, setInputValue] = useState("");

  const { t } = useTranslation();
  const { shopId } =
    mode === "continueReg" || mode === "edit"
      ? useParams()
      : { shopId: localStorage.getItem("shopId") };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    saveFormData({
      ...formData,
      [name]: value,
    });
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCountryChange = (event, value) => {
    console.log(value);
    if (value?.name) {
      saveFormData({
        ...formData,
        countryId: value.id,
      });
      saveSelectedValues({ ...selectedValues, selectedCountry: value });
      setFormValues({ ...formValues, countryId: value.id });
    }
  };

  const handleDepartmentChange = (event, value) => {
    if (value?.name) {
      saveFormData({
        ...formData,
        departmentId: value.id,
      });
      saveSelectedValues({ ...selectedValues, selectedDepartment: value });
      setFormValues({ ...formValues, departmentId: value.id });
    }
  };

  const handleCityChange = (event, value) => {
    if (value?.name) {
      saveFormData({
        ...formData,
        cityId: value.id,
      });
      saveSelectedValues({ ...selectedValues, selectedCity: value });
      setFormValues({ ...formValues, cityId: value.id });
    }
  };

  const handleCloseConfirmationUserUpdated = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsShopUpdated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filteredByKeys = filterByKeys(formData, [
      "countryId",
      "departmentId",
      "cityId",
      "postalCode",
      "address",
      "step"
    ]);
    const filteredForm = Object.entries(filteredByKeys)
      .filter(
        ([key, value]) =>
          value !== "" && (!Array.isArray(value) || value.length === 0)
      )
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    try {
      setLoading(true);
      const token = await getToken();
      if (mode !== "edit" || mode === "continueReg") {
        console.log("To submit", filteredForm);
        const response = await UpdateCommerceService(
          filteredForm,
          shopId,
          token
        );
        console.log(response);
        setIsCommerceUpdated(true);
        setIsShopUpdated(true);
      } else if (mode === "edit") {
        const filteredForm = filterByKeys(formValues, [
          "countryId",
          "departmentId",
          "cityId",
          "postalCode",
          "address",
        ]);
        const form = Object.entries(filteredForm)
          .filter(([key, value]) => value !== "")
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        const response = await UpdateCommerceService(form, shopId, token);
        console.log(response);
        setIsShopUpdated(true);
      }
      // console.log("Inside of submit", formValues);
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const filterByKeys = (data, keys) => {
    const filteredData = {};

    for (const key of keys) {
      if (key in data) {
        filteredData[key] = data[key];
      }
    }

    return filteredData;
  };

  const setCurrentStep = () => {
    saveFormData({
      ...formData,
      step: 2,
    });
  };

  const isFormValid = () => {
    return (
      Object.values(formValues).every(
        (value) => value !== "" && value !== null
      ) && isEmailValid(formValues.email_address)
    );
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleCloseConfirmation = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowConfirmation(false);
    }
  };

  useEffect(() => {
    console.log("FR", formResume);
    formResume !== null && setFormValues({ ...formValues, ...formResume });
  }, [formResume]);

  useEffect(() => {
    const getCountries = async () => {
      const countryList = await GetCountryListService();
      setCountryList(countryList);
      setLoadingCountries(false);
    };
    getCountries();
    setCurrentStep();

    setIsShopUpdated(false);
  }, []);

  useEffect(() => {
    if (selectedValues.selectedCountry !== null) {
      const getDepartments = async () => {
        const departmentList = await DepartmentListService(
          selectedValues.selectedCountry.id
        );
        setDepartmentList(departmentList);
        setLoadingDepartments(false);
      };
      getDepartments();
    }
  }, [selectedValues.selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      if (
        selectedValues.selectedCountry !== null &&
        selectedValues.selectedDepartment !== null
      ) {
        const citiesList = await CitiesListService(
          selectedValues.selectedDepartment.id
        );
        setCityList(citiesList);
        setLoadingCities(false);
      }
    };
    getCities();
  }, [selectedValues.selectedCountry, selectedValues.selectedDepartment]);

  useEffect(() => {
    const assignObj = async () => {
      const countryAssigned = await assignCountry();
    };
    assignObj();
  }, [formResume, loadingCountries]);

  useEffect(() => {
    const assignObj = async () => {
      const departmentAssigned = await assignDepartment();
    };
    assignObj();
  }, [loadingDepartments]);

  useEffect(() => {
    const assignObj = async () => {
      const cityAssigned = await assignCity();
      setLoadingFormData(false);
    };
    assignObj();
  }, [selectedValues.selectedDepartment, loadingDepartments, loadingCities]);

  const assignCountry = async () => {
    const country = await findCountry();
    if (country !== undefined) {
      saveSelectedValues({
        ...selectedValues,
        selectedCountry: country,
      });
    }
    return (await selectedValues.selectedCountry) !== null;
  };

  const assignDepartment = async () => {
    const department = await findDepartment();
    if (department !== undefined) {
      saveSelectedValues({
        ...selectedValues,
        selectedDepartment: department,
      });
    }
    return (await selectedValues.selectedDepartment) !== null;
  };

  const assignCity = async () => {
    const city = await findCity();
    if (city !== undefined) {
      saveSelectedValues({
        ...selectedValues,
        selectedCity: city,
      });
    }
    return (await selectedValues.selectedCity) !== null;
  };

  const objListIsUndefined = async (listObj) => {
    return await listObj.some((element) => element === undefined);
  };

  const findCountry = async () => {
    if (!isObjEmpty(countryList) && formValues.countryId !== "") {
      const matchingCountry = await findCountryByID(formValues.countryId);
      return matchingCountry;
    }
  };

  const findDepartment = async () => {
    if (!isObjEmpty(departmentList) && formValues.departmentId !== "") {
      const matchingDepartment = await findDepartmentByID(
        formValues.departmentId
      );
      return matchingDepartment;
    }
  };

  const findCity = async () => {
    if (!isObjEmpty(cityList) && formValues.cityId !== "") {
      const matchingCity = await findCityByID(formValues.cityId);
      return matchingCity;
    }
  };

  const findCountryByID = async (countryId) => {
    if (!isObjEmpty(countryList)) {
      return await countryList.find((country) => country.id === countryId);
    }
  };

  const findDepartmentByID = async (departmentId) => {
    if (!isObjEmpty(departmentList)) {
      return await departmentList.find(
        (department) => department.id === departmentId
      );
    }
  };

  const findCityByID = async (cityId) => {
    if (!isObjEmpty(cityList)) {
      return await cityList.find((city) => city.id === cityId);
    }
  };

  const isObjEmpty = (obj) => {
    return Object.values(obj).length === 0;
  };

  return (
    <Stack
      mt={{ xs: 0, md: 2 }}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        elevation={0}
        sx={{
          p: "1rem",
          mr: { xs: "0.4rem", sm: "10rem", md: "10rem" },
          mt: { xs: "2rem", md: "0" },
          maxWidth: "800px",
        }}
      >
        <CardHeader
          title={
            <Typography variant="h6">
              {t("locationandbankinfo.title-1")}
            </Typography>
          }
        />
        <CardContent>
          <Snackbar
            open={isShopUpdated}
            autoHideDuration={6000}
            onClose={handleCloseConfirmationUserUpdated}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("updateCommerce.form.success")}</Alert>
          </Snackbar>
          <Snackbar
            open={error}
            autoHideDuration={6000}
            onClose={handleCloseError}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form onSubmit={handleSubmit}>
            {!loadingFormData ? (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete
                    id="country-select"
                    options={
                      loadingCountries
                        ? [{ name: "loading", url_flag: "" }]
                        : countryList
                    }
                    value={selectedValues.selectedCountry}
                    loading={loadingCountries}
                    loadingText="Loading..."
                    onChange={handleCountryChange}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.country_id === value.country_id
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`${DICTIONARIES_URL}${option.url_flag}`}
                          alt=""
                        />
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        name="country"
                        value={selectedValues.selectedCountry}
                        label={t("locationandbankinfo.form.nationality")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    id="department-select"
                    options={
                      loadingDepartments
                        ? [{ name: "Loading..." }]
                        : departmentList
                    }
                    value={selectedValues.selectedDepartment}
                    loading={loadingDepartments}
                    loadingText="Loading..."
                    onChange={handleDepartmentChange}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.department_id === value.department_id
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleDepartmentChange}
                        size="small"
                        name="departmentId"
                        value={selectedValues.selectedDepartment}
                        label={t("locationandbankinfo.form.department")}
                        required={formData.countryId !== ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="city-select"
                    options={
                      loadingCities ? [{ name: "Loading..." }] : cityList
                    }
                    value={selectedValues.selectedCity}
                    loading={loadingCities}
                    loadingText="Loading..."
                    onChange={handleCityChange}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.city_id === value.city_id
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleCityChange}
                        size="small"
                        name="city"
                        value={selectedValues.selectedCity}
                        label={t("locationandbankinfo.form.city")}
                        required={formData.countryId !== ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="postalCode"
                    label="Zip code"
                    size="small"
                    value={formValues.postalCode ? formValues.postalCode : ""}
                    onChange={handleInputChange}
                    fullWidth
                    required={formData.countryId !== ""}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    name="address"
                    label={t("locationandbankinfo.form.address")}
                    size="small"
                    value={formValues.address ? formValues.address : ""}
                    onChange={handleInputChange}
                    fullWidth
                    required={formValues.countryId !== ""}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={300}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={300}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={300}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={300}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={600}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        </CardContent>
      </Card>
      {/* <Divider orientation="vertical" flexItem /> */}

      <Card
        elevation={0}
        sx={{
          p: "1rem",
          mr: { xs: "0.4rem", sm: "10rem", md: "10rem" },
          mt: { xs: "2rem", md: "0" },
          maxWidth: "800px",
        }}
      >
        <CardHeader
          title={
            <Stack>
              <Typography variant="h6">
                {t("businessData.form.subtitle-2")}
              </Typography>
            </Stack>
          }
        />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="facebook"
                  label={t("businessData.form.facebook")}
                  size="small"
                  value={formValues.facebook}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="linkedin"
                  label={t("businessData.form.linkedin")}
                  size="small"
                  value={formValues.linkedin}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="instagram"
                  label={t("businessData.form.instagram")}
                  size="small"
                  value={formValues.instagram}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="webpage"
                  label={t("businessData.form.webpage")}
                  size="small"
                  value={formValues.webpage}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="business_email"
                  label={t("locationandbankinfo.form.businessEmail")}
                  size="small"
                  value={formValues.business_email}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="business_phone"
                  label={t("locationandbankinfo.form.businessMovil")}
                  size="small"
                  value={formValues.business_phone}
                  onChange={handleInputChange}
                  fullWidth
                  inputProps={{ inputMode: "numeric" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="whatsApp_business"
                  label={t("businessData.form.whatsappBusiness")}
                  size="small"
                  value={formValues.whatsApp_business}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="other"
                  label={t("businessData.form.other")}
                  size="small"
                  value={formValues.other}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box mt={2} sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                  ) : (
                    <SaveIcon sx={{ color: "white" }} />
                  )
                }
              >
                {mode !== "edit" ? t("save.button") : t("update.button")}
              </Button>
            </Box>
            {/* Show Validation Dialog */}
            <ValidationStepThreeModal
              isOpen={showConfirmation}
              handleClose={handleCloseConfirmation}
            />
          </form>
        </CardContent>
      </Card>
    </Stack>
  );
}
