import axios from "axios";

const URL_MONGO = import.meta.env.VITE_URL_MONGO;

export const GetTypes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const rta = await axios.get(`${URL_MONGO}/tipos`);
      resolve(rta);
      // res.setHeader("Content-Type", "text/html");
    } catch (error) {
      console.log("GetTypes:", error);
      reject(error);
    }
  });
};
