import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getToken } from "../../utils/GetToken";
import { ApproveSensitiveChangeService } from "../../services/SensitiveData/ApproveSensitiveChangeService";
import { UpdateAccompanimentStatus } from "../../services/Accompaniment/UpdateAccompanimentStatus";
import { parseStateCodeToTag } from "./helpers/helper.parseData";
import { useStatusChange } from "../../context/AccompanimentChangeStatusContext";

const estados = [
  { "id": "ALRT" },
	{ "id": "FNLD" },
	{ "id": "INIT" },
	{ "id": "MGMT" },
	{ "id": "QLFD" },
	{ "id": "RACT" },
	{ "id": "REGT" }
];

export default function AccompanimentUpdateStatusModal({ elementDetails, handleClose }) {
  const { id } = elementDetails;
  const {t} = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sensitiveChangeState, setSensitiveChangeState] = useState(null);
  const [formValues, setFormValues] = useState({
    status: elementDetails?.state,
  });
  const { handleStatusApproved } = useStatusChange();

  const handleStateChange = (event, value) => {
    if (value?.id) {
      setSensitiveChangeState(value.id);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateStatus();
    
    
  };

  const handleCloseErrorMessage = () => {
    setError(false);
  };

  const updateStatus = async () => {
    try {
      const token = await getToken();
      setLoading(true);
      const response = await UpdateAccompanimentStatus(
        token,
        id,
        sensitiveChangeState
      );
      const { docApproved, message, shopIsApproved } = response.data;
      setSuccessMessage(message);
      handleStatusApproved();
      handleClose();
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseErrorMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Card
        elevation={0}
        sx={{
          borderRadius: "20px",
          mt: { xs: "2rem", md: 0 },
          maxWidth: "500px",
        }}
      >
        <CardContent>
          {elementDetails !== null && (
            <Stack mt={2} spacing={2}>
              <Typography variant="h6" color="primary.light">{t("accompaniment.update-status-title")}</Typography>
              <List>
                  <ListItem key={5}>
                      <ListItemText primary={'Estado actual'} secondary={elementDetails?.state} />
                    </ListItem>
                
              </List>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography variant="caption">
                        {'CHOOSE THE NEW STATUS'}
                      </Typography>
                    <Autocomplete
                      id="stateSelect"
                      options={estados}
                      onChange={handleStateChange}
                      getOptionLabel={(option) =>  t(parseStateCodeToTag(option?.id)) ?? ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("reviewDoc.textfield-2")}
                          size="small"
                          onChange={handleStateChange}
                          value={formValues.state}
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box mt={2} sx={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color={"secondary"}
                    size="large"
                    startIcon={
                      loading && (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      )
                    }
                  >
                    {t("save.button")}
                  </Button>
                </Box>
              </form>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
}
