import { Content } from "../../utils/Content";
import { Grid, Typography } from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import ZoneDetails from "../zones-components/ZoneDetails";
import { Link, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function ZoneDetailsLayout() {
  const { isOpen } = useMenu();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { mode } = state !== null ? state : { mode: "new" };

  return (
    <Content open={isOpen}>
      <Grid container spacing={1} ml={{ xs: 0, sm: "2rem" }} mb={2}>
        <Link
          to={mode === "available" ? "/zones?tab=1" : "/zones?tab=0"}
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <ZoneDetails />
    </Content>
  );
}
