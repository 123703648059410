import axios from "axios";

const URL_DICTIONARIES = import.meta.env.VITE_AUTH_DICTIONARIES;

export const ListMessagesServices = (token, page, query, pagination) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      let url;
      if (query === "") {
        url = `${URL_DICTIONARIES }/api/general/v1/notifications?page=${page}&pagination=${pagination}`;
      } else {
      
        url = `${URL_DICTIONARIES }/api/general/v1/notifications?${query}&page=${page}&pagination=${pagination}`;
      }
      const messages = await axios.get(
        url,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("service", messages);
      resolve(messages.data);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};