import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Box,
  Grid,
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import SaveIcon from "@mui/icons-material/Save";
import { GetDocumentTypeListService } from "../../services/GetDocumentTypeListService";
import { useParams, useLocation, Link } from "react-router-dom";
import { UploadDocumentService } from "../../services/DocumentsManagement/UploadDocumentService";
import { UpdateDocumentService } from "../../services/DocumentsManagement/UpdateDocumentService";
import { GetDocumentListByClientsService } from "../../services/DocumentsManagement/Clients/GetDocumentListByClientsService";
import { GetDocumentListByAgentService } from "../../services/DocumentsManagement/Agents/GetDocumentListByAgentService";
import { GetDocumentTypesFilesService } from "../../services/DocumentsManagement/GetDocumentTypesFilesService";

const allowedFileTypes = ["application/pdf", "image/jpeg", "image/jpg"];
const maxFileSize = "5MB";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DocumentUploadSection = ({
  sectionTitle,
  subtitle,
  mode,
  loadingDocTypes,
  docTypes,
  docTypesSaved,
  setSelectedDocTypeAll,
  selectedDocTypeAll,
  uploadBy,
  userId,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDocUploaded, setIsDocUploaded] = useState(false);

  const [docReference, setDocReference] = useState("");
  const [selectedDocType, setSelectedDocType] = useState(null);

  const [isUploaded, setIsUploaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [file, setFile] = useState(null);
  // const { formData, saveFormData } = useForm();

  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    docType: "",
    comment: "",
    doc: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (fileItems) => {
    if (fileItems.length > 0) {
      // Mostrar solo la última imagen cargada
      setFile(fileItems[fileItems.length - 1].file);
      setFormValues((prevState) => ({
        ...prevState,
        doc: fileItems[fileItems.length - 1].file,
      }));
    } else {
      setFile(null);
    }
  };

  const handleDocTypeChange = (event, value) => {
    if (value === null) {
      setSelectedDocTypeAll(
        selectedDocTypeAll.filter((selected) => selected === formValues.docType)
      );
    } else {
      setSelectedDocTypeAll([value.id]);
    }
    if (value?.id !== null) {
      setFormValues((prevState) => ({
        ...prevState,
        docType: value?.id?.toString(),
      }));
    }
    if (mode === "edit") {
      setFormValues((prevState) => ({
        ...prevState,
        docType: value?.id?.toString(),
      }));
    }
    setSelectedDocType(value === null ? null : value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Inside of submit", formValues);
    try {
      setLoading(true);
      const token = await getToken();
      if (mode !== "edit") {
        const response = await UploadDocumentService(
          token,
          formValues,
          userId,
          uploadBy
        );
        if (response.status === 201) {
          setIsUploaded(true);
          setIsDocUploaded(true);
        }
      } else {
        const response = await UpdateDocumentService(token, formValues, docReference, uploadBy, userId);
        if (response.status === 200) {
          setIsUploaded(true);
          setIsDocUploaded(true);
        }
        // console.log(response);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseConfirmationDocUploaded = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsDocUploaded(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    // console.log(docTypesSaved);
    if (mode === "edit") {
      setFormValues((prevState) => ({
        ...prevState,
        comment: docTypesSaved?.comment,
      }));
      setDocReference(docTypesSaved?.id);
      docTypesSaved && setSelectedDocType(docTypesSaved?.type);
    }
  }, [docTypesSaved]);

  return (
    <Accordion sx={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{sectionTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <Snackbar
            open={isDocUploaded}
            autoHideDuration={6000}
            onClose={handleCloseConfirmationDocUploaded}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("uploadDocument.success")}</Alert>
          </Snackbar>
          <Snackbar
            open={error}
            autoHideDuration={6000}
            onClose={handleCloseError}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <div>
            <Box mb={2}>
              <Typography variant="caption">{subtitle}</Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link
                    to={docTypesSaved?.url}
                    target="_blank"
                    className="Link"
                    style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                  >
                    {docTypesSaved?.url}
                  </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="docType"
                    options={
                      loadingDocTypes
                        ? [{ description: "Loading..." }]
                        : docTypes
                    }
                    loading={loadingDocTypes}
                    loadingText="Loading..."
                    disabled={isUploaded}
                    value={selectedDocType}
                    onChange={handleDocTypeChange}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleDocTypeChange}
                        size="small"
                        name="docType"
                        value={selectedDocType}
                        label={t("locationandbankinfo.form.docType")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="comment"
                    label={t("uploadDocument.form.description")}
                    size="small"
                    disabled={isUploaded}
                    value={formValues.comment}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Stack spacing={2} mt={2}>
                {mode === "edit" && (
                  <Typography>{t("uploadDocument.form.uploadDoc")}:</Typography>
                )}
                <Typography variant="caption">
                  {t("reviewDoc.warning")}
                </Typography>
                <FilePond
                  files={file ? [file] : null}
                  acceptedFileTypes={allowedFileTypes}
                  maxFileSize={maxFileSize}
                  allowMultiple={false}
                  labelIdle={t("uploadDocument.form.messageFilepond")}
                  checkValidity={true}
                  instantUpload={false}
                  server={null}
                  onupdatefiles={handleFileChange}
                  required
                  credits={false}
                />
              </Stack>
              <Box mt={2} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color={"secondary"}
                  size="large"
                  disabled={isUploaded}
                  startIcon={
                    loading ? (
                      <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                    ) : (
                      <SaveIcon sx={{ color: "white" }} />
                    )
                  }
                >
                  {isUploaded
                    ? `${t("saved.button")}`
                    : mode === "edit"
                    ? `${t("update.button")}`
                    : `${t("uploadDocument.form.button")}`}
                </Button>
              </Box>
            </form>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const UploadDocuments = ({ missingDocs, mode, uploadBy, userId }) => {
  const [loadingDocTypes, setLoadingDocTypes] = useState(true);
  const [docTypes, setDocTypes] = useState([]);
  const [docTypesFiles, setDocTypesFiles] = useState([]);
  const [docTypesSaved, setDocTypesSaved] = useState([]);

  const [selectedDocTypeAll, setSelectedDocTypeAll] = useState([]);
  const [docTypeAllFilter, setDocTypeAllFilter] = useState([]);

  const [selectedDocType1, setSelectedDocType1] = useState([]);
  const [selectedDocType2, setSelectedDocType2] = useState([]);

  const { t } = useTranslation();
  const textMissingDocs = missingDocs
    ? missingDocs.map((object) => object.description).join(", ") + "."
    : "";

  useEffect(() => {
    const getDocumentTypes = async () => {
      const token = await getToken();
      const user =
        mode === "continueReg" || mode === "edit"
          ? userId
          : localStorage.getItem("shopId");
      try {
        const documentTypesList = await GetDocumentTypeListService();
        setDocTypes(documentTypesList);

        const documentTypesFilesList = await GetDocumentTypesFilesService();
        setDocTypesFiles(documentTypesFilesList);

        if (mode === "edit") {
          let savedDocList = [];
          if (uploadBy === "client") {
            savedDocList = await GetDocumentListByClientsService(token, userId);
          } else if (uploadBy === "agent") {
            savedDocList = await GetDocumentListByAgentService(token, userId);
          }
          setDocTypesSaved(savedDocList);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDocTypes(false);
      }
    };
    getDocumentTypes();
  }, []);

  useEffect(() => {
    if (mode === "edit") {
      const filterDocs = docTypes.filter((docType) => {
        return !docTypesSaved.some(
          (savedDoc) => savedDoc.description === docType.name
        );
      });
      setDocTypes(filterDocs);
    }
  }, [docTypesSaved]);

  // useEffect(() => {
  //   let docTypeListFilter = [];

  //   let selectedConcat = [
  //     ...selectedDocType1,
  //     ...selectedDocType2,
  //     ...selectedDocType3,
  //     ...selectedDocType4,
  //   ];

  //   if (mode !== "continueReg" && mode !== "edit") {
  //     docTypes.map((docType) => {
  //       if (!selectedConcat.includes(docType.id)) {
  //         docTypeListFilter.push(docType);
  //       }
  //     });
  //   } else if (mode === "continueReg" || mode === "edit") {
  //     docTypes.forEach((docType) => {
  //       if (!selectedConcat.includes(docType.id)) {
  //         docTypeListFilter.push(docType);
  //       }
  //     });
  //   }

  //   setDocTypeAllFilter(docTypeListFilter);
  //   // console.log(
  //   //   docTypeAllFilter
  //   // );
  // }, [
  //   docTypes,
  //   selectedDocType1,
  //   selectedDocType2,
  //   selectedDocType3,
  //   selectedDocType4,
  // ]);
  // console.log(docTypeAllFilter);

  return (
    <Stack
      mt={{ xs: 0, md: 2 }}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {mode === "edit" ? (
        <Box mb={2}>
          <Typography variant="h6" fontWeight={600} textAlign={"center"}>
            {t("updateDocument.form.titleSection")}
          </Typography>
        </Box>
      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h6" fontWeight={600} textAlign={"center"}>
              {t("uploadDocument.form.titleSection")}
            </Typography>
          </Box>

          {mode === "edit" ||
            (mode === "continueReg" && (
              <Box mb={2}>
                <Typography
                  variant="caption"
                  fontWeight={400}
                  textAlign={"center"}
                >
                  {t("reviewDoc.subtitle")}
                </Typography>
                <Typography
                  variant="caption"
                  fontWeight={600}
                  textAlign={"center"}
                >
                  {textMissingDocs}
                </Typography>
              </Box>
            ))}
        </>
      )}
      <DocumentUploadSection
        sectionTitle={t("uploadDocument.form.title-1")}
        subtitle={t("uploadDocs.subtitle-1")}
        mode={mode}
        loadingDocTypes={loadingDocTypes}
        docTypes={docTypes}
        docTypesSaved={docTypesSaved[0]}
        setSelectedDocTypeAll={setSelectedDocType1}
        selectedDocTypeAll={selectedDocType1}
        uploadBy={uploadBy}
        userId={userId}
      />
      <DocumentUploadSection
        sectionTitle={t("uploadDocument.form.title-2")}
        subtitle={t("uploadDocs.subtitle-2")}
        mode={mode}
        loadingDocTypes={loadingDocTypes}
        docTypes={docTypesFiles}
        docTypesSaved={docTypesSaved[1]}
        setSelectedDocTypeAll={setSelectedDocType2}
        selectedDocTypeAll={selectedDocType2}
        uploadBy={uploadBy}
        userId={userId}
      />
    </Stack>
  );
};

export default UploadDocuments;
