import { useState } from "react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import { MakeCall } from "../../services/Calls/MakeCalls";

function ContactAvatar({ contact }) {
  let hash = 0;
  let i;

  for (i = 0; i < contact.fullName.length; i += 1) {
    hash = contact.fullName.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  if (contact.avatar_url)
    return (
      <Avatar src={contact.avatar_url} sx={{ mr: "20px", bgcolor: color }} />
    );

  const words = contact.fullName.split(" ");

  const initials = words.reduce((acc, word) => acc + word.charAt(0), "");

  return <Avatar sx={{ mr: "20px", bgcolor: color }}>{initials}</Avatar>;
}

export default function ContactList({ contacts }) {
  const handleCall = (movil) => {
    MakeCall(movil);
  };
  // ToDo: Condicion no Data
  return (
    <Box maxHeight="65vh" overflow="scroll" sx={{ maxWidth: "50%" }}>
      {contacts?.map((contact) => (
        <Box
          key={contact.id}
          display="flex"
          sx={{ m: "15px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <ContactAvatar contact={contact} />
          <Typography textAlign="center">{contact.fullName}</Typography>
          <IconButton onClick={() => handleCall(contact.movil)}>
            <CallIcon color="primary" fontSize="medium" />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
}
