import { useState, useEffect, forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Snackbar,
  Alert,
  Typography,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getToken } from "../../utils/GetToken";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import { DeleteRoleService } from "../../services/RolesAndPermissions/DeleteRoleService";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteRoleLayout({ roleId, setClickDeleteRole }) {
  const [openDialog, setOpenDialog] = useState(true);
  const { t } = useTranslation();
  const [isRoleDeleted, setIsRoleDeleted] = useState(false);
  const { isOpen } = useMenu();

  console.log("ID Rol", roleId);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      setClickDeleteRole(false);
      setOpenDialog(false);
    }
  };

  const handleClickOpenDelete = () => {
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    console.log("IDs seleccionados:", roleId);
    try {
      const token = await getToken();
      const response = await DeleteRoleService(token, roleId);
      console.log(response);
      setIsRoleDeleted(true);
      return;
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const handleConfirmDelete = () => {
    setOpenDialog(false);
    handleDelete();
  };

  useEffect(() => {
    if (isRoleDeleted) {
      const redirectTimer = setTimeout(() => {
        window.location.reload();
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isRoleDeleted]);

  return (
    <>
      <Snackbar
        open={isRoleDeleted}
        autoHideDuration={6000}
        onClose={handleCloseDialog}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("deleteRole.success.message")}</Alert>
      </Snackbar>
      <Dialog
        disableEscapeKeyDown
        open={openDialog}
        TransitionComponent={Transition}
        onClose={handleClickOpenDelete}
      >
        <DialogTitle>{t("deleteRole.message.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("deleteRole.message.description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseDialog();
            }}
          >
            {t("cancel.button")}
          </Button>
          <Button
            variant="contained"
            startIcon={<DeleteIcon sx={{ color: "white" }} />}
            color="error"
            onClick={handleConfirmDelete}
            sx={{ color: "white" }}
          >
            {t("delete.button")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
