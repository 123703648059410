import GridViewIcon from "@mui/icons-material/GridView";
import SettingsIcon from "@mui/icons-material/Settings";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PaidIcon from "@mui/icons-material/Paid";
import GroupIcon from "@mui/icons-material/Group";
import CallIcon from "@mui/icons-material/Call";
import HelpIcon from "@mui/icons-material/Help";
import FeedIcon from '@mui/icons-material/Feed';
import ShieldIcon from '@mui/icons-material/Shield';
import MapIcon from '@mui/icons-material/Map';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { useMenu } from "../context/MenuOpenContext";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const DOCS_URL = import.meta.env.VITE_DOCS_URL;

const NAVLINKS = [
  {
    icon: GridViewIcon,
    title: "navlink.dashboard",
    link: "/",
    description:
      "El SuperAdministrador Mi Ángel-Admin de Mi Ángel permite obtener información general sobre toda la actividad registrada en la plataforma de Mi Ángel.",
  },
  {
    icon: NotificationsIcon,
    title: "navlink.alerts",
    link: "/alerts",
    description: "Listado de alertas.",
  },
  {
    icon: ShieldIcon,
    title: "navlink.accompaniment",
    link: "/accompaniment",
    description: "Listado de acompañamiento.",
  },
  {
    icon: MapIcon,
    title: "navlink.maps",
    link: "/maps",
    description: "Mapas.",
  },
  {
    icon: PeopleOutlineIcon,
    title: "navlink.accounts",
    link: "/clients&agents",
    description:
      "Resumen de los clientes y agentes registrados dentro de Mi Ángel.",
  },
  {
    icon: BusinessIcon,
    title: "navlink.companies",
    link: "/companies",
    description: "Resumen de las empresas.",
  },
  {
    icon: LocationOnIcon,
    title: "navlink.zones",
    link: "/zones",
    description: "Listado de zonas.",
  },
  {
    icon: PaidIcon,
    title: "navlink.transactions",
    link: "/transactions",
    description: "Resumen de las transacciones realizadas dentro de Mi Ángel.",
  },
  {
    icon: FeedIcon,
    title: "navlink.sensitiveChanges",
    link: "/sensitiveChanges",
    description: "Listado de cambios sensibles que requieren aprobación.",
  },
  {
    icon: AssignmentIcon,
    title: "navlink.plans",
    link: "/plansManagement",
    description: "Manejo de Planes",
  },
  {
    icon: NotificationsActiveIcon,
    title: "navlink.notifications",
    link: "/notifications",
    description: "Manejo de Notificaciones",
  },
  {
    icon: SettingsIcon,
    title: "navlink.settings",
    link: "/settings",
    description: "Panel de Configuraciones",
  },
  {
    icon: GroupIcon,
    title: "navlink.users",
    link: "/usersManagement",
    description: "Administración de Usuarios",
  },
  {
    icon: ViewListIcon,
    title: "navlink.list",
    link: "/listManagement",
    description: "Listado de alertas.",
  },
  {
    icon: HelpIcon,
    title: "navlink.support",
    link: `${DOCS_URL}`,
    description: "Manejo de Tickets de Mi Ángel",
  },
  {
    icon: HelpIcon,
    title: "navlink.pqrs",
    link: `${DOCS_URL}`,
    description: "Manejo de PQRS",
  },
  {
    icon: CallIcon,
    title: "navlink.calls",
    link: "/calls",
    description: "Llamadas",
  },
];

function Navlinks({ setOpen }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setIsOpen } = useMenu();

  const handleClick = () => {
    if (isMobile) {
      setOpen(false);
      setIsOpen(false);
    }
  };

  return (
    <List>
      {NAVLINKS.map((navlink) => (
        <Link to={navlink.link} key={navlink.title} className="nav-link">
          <ListItem disablePadding key={navlink.title}>
            <ListItemButton onClick={handleClick}>
              <Stack direction="row" gap="1rem" my="0.4rem">
                <navlink.icon sx={{ color: "primary.light" }} />
                <Typography>{t(`${navlink.title}`)}</Typography>
              </Stack>
            </ListItemButton>
          </ListItem>
        </Link>
      ))}
    </List>
  );
}

export default Navlinks;
