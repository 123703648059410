import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

const HandleActions = (id, token) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickSeeMore, setClickSeeMore] = useState(false);
  const [clickEditTax, setClickEditTax] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleSeeMore = () => {
    setClickSeeMore(true)
    setAnchorEl(null);
  };

  const handleClickEdit = () => {
    handleClose();
    setClickEditTax(true);
  };

  useEffect(() => {
    if (clickSeeMore) {
      navigate(`/taxDetails/${id?.taxId}`)
    }
    clickEditTax &&
    navigate(`/editTax/${id?.taxId}`, {
      state: { mode: "edit", taxId: id?.taxId },
    });
  }, [clickSeeMore, clickEditTax]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 141,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleSeeMore}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>{t("plan.table.option-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>{t("taxes.edit")}</ListItemText>
          </MenuItem>
      </Menu>
    </>
  );
};

export const HeadersTaxesList = () => {
  const currentLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 60,
      renderCell: (params) => (
        <HandleActions taxId={params.row.id} />
      ),
    },
    {
      field: "id",
      headerName: "Id",
      width: 100,
    },
    {
      field: "title",
      headerName: t("taxes.table.header-1"),
      width: 200,
    },
    {
      field: "value",
      headerName: t("taxes.table.header-2"),
      width: 100,
    },
    {
      field: "taxType",
      headerName: t("taxes.table.header-3"),
      width: 200,
    },
    {
      field: "description",
      headerName: t("taxes.table.header-4"),
      width: 500,
    },
  ];
  return columns;
};
