import { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import TimeFilter from "../common/TimeFilter";
import { useQuery } from "@tanstack/react-query";
import { getToken } from "../../utils/GetToken";
import { GetTransactionsStatsServices } from "../../services/Stats/GetTransactionsStatsService";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Pie } from "react-chartjs-2";
import ReceiptIcon from "@mui/icons-material/Receipt";

export default function TransactionsStatsByType() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const getTransactionsStatsByType = async () => {
    const token = await getToken();
    const response = await GetTransactionsStatsServices(token);
    console.log(response);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["byType", "byStatus"],
    queryFn: () => getTransactionsStatsByType(),
  });

  const totalByType = response?.byType || [];
  const totalByStatus = response?.byStatus || [];

  const countByTransactionType = totalByType?.reduce((types, transaction) => {
    const typeName = transaction.transaction_type;
    const translatedTypeName = t(`transactionTypes.${typeName}`);

    if (!types[translatedTypeName]) {
      types[translatedTypeName] = 0;
    }
    types[translatedTypeName] += transaction.count;
    return types;
  }, {});

  const transactionsTypes = Object.keys(countByTransactionType);

  const handleTransactionTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const availableStatus = [
    ...new Set(totalByStatus.map((status) => status.transaction_status)),
  ];

  const handleTransactionStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const getCountForSelectedType = () => {
    return countByTransactionType[selectedType] || 0;
  };

  const getCountForSelectedStatus = () => {
    const selectedStatusStats = totalByStatus.find(
      (status) => status.transaction_status === selectedStatus
    );
    return selectedStatusStats ? selectedStatusStats.count : 0;
  };

  const data = {
    labels: transactionsTypes,
    datasets: [
      {
        data: transactionsTypes.map(
          (transaction_status) => countByTransactionType[transaction_status]
        ),
        backgroundColor: ["#1D417A", "#FF6660", "#6BE58A"],
      },
    
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          padding: 15,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Card
      elevation={5}
      sx={{ borderRadius: "20px", minWidth: { sm: "50%", md: "100%" } }}
    >
      <CardContent>
        <Stack direction="column" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("transactionsCard.title-2")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography>{t("transactionsCard.subtitle-4")}</Typography>

            <Stack direction="row" spacing={2} alignItems="center" mt="1.5rem">
              <FormControl
                sx={{ m: 1, minWidth: 210, mt: "1rem" }}
                size="small"
              >
                <InputLabel>{t("transactionType.title")}</InputLabel>
                <Select
                  value={selectedType}
                  onChange={handleTransactionTypeChange}
                  displayEmpty
                  label={t("transactionType.title")}
                >
                  {transactionsTypes.map((transaction_type) => (
                    <MenuItem key={transaction_type} value={transaction_type}>
                      {transaction_type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                selectedType && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <PaymentsIcon color="success" />
                    <Typography variant="h5" color={"success.main"}>
                      {getCountForSelectedType()}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>
            <Divider sx={{ my: "1rem" }} />
            <Stack direction="row" spacing={2} alignItems="center" mt="1.5rem">
              <FormControl
                sx={{ m: 1, minWidth: 250, mt: "1rem" }}
                size="small"
              >
                <InputLabel>{t("transactionStatus.title")}</InputLabel>
                <Select
                  value={selectedStatus}
                  onChange={handleTransactionStatusChange}
                  displayEmpty
                  label={t("transactionStatus.title")}
                >
                  {availableStatus.map((transaction_status) => (
                    <MenuItem
                      key={transaction_status}
                      value={transaction_status}
                    >
                      {t(transaction_status)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                selectedStatus && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ReceiptIcon color="success" />
                    <Typography variant="h5" color={"success.main"}>
                      {getCountForSelectedStatus()}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack sx={{ height: "220px", alignItems: "center", mt: "2rem" }}>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Pie data={data} options={options} />
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
