import axios from "axios";

const URL_AGENTS = import.meta.env.VITE_AGENTS;

export default function CreateCompanyLogoService(token, companyId, imageFile) {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("logo", imageFile);

      const response = await axios.post(
        `${URL_AGENTS}/api/admin/v1/business/${companyId}/logo/create`,
        formData,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Imagen cargada correctamente");
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
