import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDeletePlan from "../../components/plans-management-components/DeletePlanComponent";
import DeleteIcon from "@mui/icons-material/Delete";

const HandleActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickSeeMore, setClickSeeMore] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { planId, data } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeeMore = () => {
    setClickSeeMore(true);
    setAnchorEl(null);
  };
  const handleClickDelete = () => {
    handleClose();
    setIsOpen(true);
  };

  const handleClickEdit = () => {
    handleClose();
    navigate(`/editPlan/${planId}`, {
      state: { mode: "edit", data: data },
    });
  };

  useEffect(() => {
    if (clickSeeMore) {
      navigate(`/planDetails/${planId}`);
    }
  }, [clickSeeMore]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 150,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleSeeMore}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>{t("plan.table.option-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("plan.table.option-2")}</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("plan.table.option-3")}</ListItemText>
        </MenuItem>
        <ConfirmDeletePlan
          isOpen={isOpen}
          handleClose={handleCloseModal}
          planId={planId}
          data={data}
        />
      </Menu>
    </>
  );
};

export const HeadersPlanList = () => {
  const currentLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 60,
      renderCell: (params) => <HandleActions planId={params.row.id} />,
    },
    {
      field: "id",
      headerName: "Id",
      width: 100,
    },
    {
      field: "title",
      headerName: t("plan.table.header-1"),
      width: 200,
    },
    // {
    //   field: "description",
    //   headerName: t("plan.table.header-2"),
    //   width: 300,
    //   },

    {
      field: "duration",
      headerName: t("plan.table.header-3"),
      width: 150,
      renderCell: (params) => {
        const monthKey =
          params.value === 1 ? "plan.table.header-3.1" : "plan.table.header-3";
        const translation = t(monthKey);
        return `${params.value} ${translation}`;
      },
    },
    {
      field: "contacts",
      headerName: t("plan.table.header-4"),
      width: 150,
      renderCell: (params) => {
        const contactKey =
          params.value === 1
            ? "plan.table.header-4.1"
            : "plan.table.header-4.2";
        const translation = t(contactKey);
        return `${params.value} ${translation}`;
      },
      renderHeader: (params) => (
        <div
          style={{ whiteSpace: "normal", lineHeight: "1.2", fontWeight: "500" }}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
    {
      field: "value",
      headerName: t("plan.table.header-5"),
      width: 170,
      valueFormatter: (params) =>
        new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(params.value),
    },

    {
      field: "oldValue",
      headerName: t("plan.table.header-7"),
      width: 170,
      valueFormatter: (params) =>
        new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(params.value),
      renderHeader: (params) => (
        <div
          style={{ whiteSpace: "normal", lineHeight: "1.2", fontWeight: "500" }}
        >
          {params.colDef.headerName}
        </div>
      ),
    },

    {
      field: "commissionable",
      headerName: t("plan.table.header-8"),
      width: 170,
      valueFormatter: (params) =>
        new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(params.value),
    },

    // {
    //   width: 100,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return params.value === true ? <DeletePlan /> : null;
    //   },
    // },
  ];
  return columns;
};
