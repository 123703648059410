import axios from 'axios';

const URL_CLIENTS = import.meta.env.VITE_AUTH_CLIENT;

export function GetAlertListService(token, page, query, pagination) {
  return new Promise(async (resolve, reject) => {
    try {
      let url;
      if (query === "") {
        url = `${URL_CLIENTS}/api/admin/v1/alert?page=${page}&pp=${pagination}`;
      } else {
        console.log("URL with params inside", query);
        url = `${URL_CLIENTS}/api/admin/v1/alert?${query}&page=${page}&pp=${pagination}`;
      }
      const response = await axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        }
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
