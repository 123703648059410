import { useState, useEffect } from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BuyerPayerInfoCard from "../transactions-components/BuyerPayerInfoCard";
import { getToken } from "../../utils/GetToken";
import { useNavigate, useParams } from "react-router-dom";
import { TransactionByIdService } from "../../services/TransactionByIdService";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function PartiesTransactionLayout() {
  const { t } = useTranslation();
  // const { state } = useLocation();
  const [transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const { isOpen } = useMenu();

  useEffect(() => {
    const getTransactionSummary = async () => {
      try {
        const token = await getToken();
        if (token) {
          const data = await TransactionByIdService(token, id);
          console.log("Sum Transaction", data);
          setTransaction(data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTransactionSummary();
  }, []);

  const goToBack = () => {
    window.history.back();
  };

  return (
    <Content open={isOpen}>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }}>
        <Link
          onClick={goToBack}
          className="nav-link_back"
          underline="none"
          color={"primary.light"}
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("detailedTransaction.title")}
        </Link>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container justifyContent="center">
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"center"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("buyerAndPayerCard.buyer.title")}
          </Typography>
        </Grid>
        <Grid
          spacing={2}
          container
          alignItems="flex-start"
          justifyContent="center"
          mt={3}
        >
          <Grid item xs={12} sm={6}>
            <BuyerPayerInfoCard transaction={transaction} loading={loading} />
          </Grid>
        </Grid>
      </Grid>
    </Content>
  );
}
