import Axios from "axios";

const DICTIONARIES_URL = import.meta.env.VITE_DICTIONARIES_URL;

export function CiiuListService(countryId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `${DICTIONARIES_URL}/v1/ciiu/getlistCiius/${countryId}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          },
        }
      );
      // console.log("description", response.data.data.data);
      resolve(response.data.data.data);
    } catch (error) {
      reject(error);
    }
  });
}
