import { useState, useEffect, forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Slide,
  Typography,
} from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { GetSensitiveChangeByIdService } from "../../services/SensitiveData/GetSensitiveChangeByIdService";
import { getToken } from "../../utils/GetToken";
import ReviewSensitiveChange from "../sensitive-data-changes-components/ReviewSensitiveChange";
import { GetAccompanimentDetailsService } from "../../services/Accompaniment/GetAccompanimentDetailsService";
import AccompanimentUpdateStatusModal from "../accompaniment-components/AccompanimentUpdateStatusModal";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateAccompanimentStatus({
  changeId,
  setClickReview,
}) {
  const [openDialog, setOpenDialog] = useState(true);

  const [itemDetails, setItemDetails] = useState(null);
  const { t } = useTranslation();


  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
      setClickReview(false);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getElementDetails();
  }, []);


  const getElementDetails = async () => {
    try {
      const token = await getToken();
      if (token) {
        const data = await GetAccompanimentDetailsService(token, changeId);
        setItemDetails(data);
      }
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {itemDetails !== null && (
        <Dialog
          disableEscapeKeyDown
          open={openDialog}
          TransitionComponent={Transition}
          onClose={handleCloseDialog}
        >
          <DialogContent>
            {itemDetails !== null && (
              <AccompanimentUpdateStatusModal
                elementDetails={itemDetails}
                handleClose={handleClose}
              />
            )}
          </DialogContent>
          <DialogActions sx={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                handleCloseDialog();
              }}
            >
              {t("cancel.button")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
