import Axios from "axios";

const AUTH_CLIENT = import.meta.env.VITE_AUTH_CLIENT;

export function DeleteAlertService(id, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(
        `${AUTH_CLIENT}/api/admin/v1/alert/type/${id}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}