import { useState, useEffect } from "react";
import { Grid, Typography, Box, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useTranslation } from "react-i18next";
import UserAlertList from "../alert-components/UserAlertList";

export default function AlertsLayout() {
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();

  const handleShowDialog = (id) => {
    setShowDialog(!showDialog);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
  };

  const handleErrorMessage = (message) => {
    setErrorMessage(message);
  };

  return (
    <Content open={isOpen}>
      <Grid container direction="column">
        <Grid item xs paddingX={"20px"}>
          {/* <Snackbar
            open={showAlertApproved}
            autoHideDuration={6000}
            onClose={handleCloseDocApproved}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("approveDoc.message")}</Alert>
          </Snackbar>
          <Snackbar
            open={showAlertRejected}
            autoHideDuration={6000}
            onClose={handleCloseDocRejected}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="info">{errorMessage}</Alert>
          </Snackbar> */}
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"flex-start"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("alerts.title")}
          </Typography>
          <Box my={2}>
            <Typography variant="body1" textAlign={"flex-start"}>
              {t("alerts.subtitle")}
            </Typography>
          </Box>
        </Grid>
        <Box
          mt={2}
          sx={{
            height: 400,
            width: "100%",
            paddingInline: { xs: 0, sm: "1rem" },
          }}
        >
          <UserAlertList />
        </Box>
      </Grid>
    </Content>
  );
}
