import { useState } from "react";
import { useTranslation } from "react-i18next";

export const StatusTransactionsTranslator = (state_code) => {

  const { t } = useTranslation();

  const getStatusFromCode = (statusCode) => {
    const stateMap = {
      2: "pending",
    };

    const stateKey = stateMap[statusCode];
    if (!stateKey) {
      return "Unknown";
    }
    return t(stateKey);
  };

  return getStatusFromCode(state_code);
}
