import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const DeleteRoleService = (token, roleId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deleteRole = await axios.delete(
        `${URL_AUTH}/api/admin/v1/role/${roleId}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(deleteRole);
      resolve(deleteRole.data);
    } catch (error) {
      reject(error);
    }
  });
};
