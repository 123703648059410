import { useState, useEffect } from "react";
import { Chip } from "@mui/material";
import dayjs from "dayjs";

export function CurrentDate() {
  const [date, setDate] = useState();

  const currentDate = () => {
    return dayjs().locale("es").format(" D MMM YY - HH:mm a");
  };

  function refreshClock() {
    setDate(currentDate());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return <Chip label={date} size="small" sx={{color:"white"}}/>;
}
