import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  Container,
} from "@mui/material";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import UserNotificationList from "../notifications-components/UserNotificationList";
import NotificationMessageList from "../notifications-components/NotificationMessageList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box sx={{ p: { xs: "0.2rem", sm: 3 }, mt: 2 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function NotificationsLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabUrl = searchParams.get("tab");
  const [currentTab, setCurrentTab] = useState(
    tabUrl !== null && tabUrl !== 0 ? parseInt(tabUrl, 10) : 0
  );
  const [value, setValue] = useState(parseInt(currentTab, 10));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tabHasChanged, setTabHasChanged] = useState(false);
  const { isOpen } = useMenu();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setCurrentTab(value);
  }, [value]);

  useEffect(() => {
    const tabParam = extractTabParamFromURL();
    if (tabParam) {
      setValue(tabParam);
    }
  }, []);

  useEffect(() => {
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  }, [currentTab]);

  const extractTabParamFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam !== null) {
      const tabValue = parseInt(tabParam, 10);
      if ([0, 1].includes(tabValue)) {
        console.log("tab param", tabParam);
        return tabValue;
      }
    }
    return 0;
  };

  return (
    <Content open={isOpen}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pl: 0 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("notification.title")} {...a11yProps(0)} />
          <Tab label={t("notificationList.title-1")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("notificationList.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("notificationList.description")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          mt={2}
          sx={{
            height: 600,
            width: "100%",
          }}
        >
          <UserNotificationList currentTab={currentTab} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("notificationList.title-1")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("notification.description-1")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          ></Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 600,
                width: "100%",
              }}
            >
              <NotificationMessageList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Content>
    // <Content open={isOpen}>
    //   <Grid container direction="column">
    //     <Grid item xs paddingX={"20px"}>

    //       <Typography
    //         variant="h4"
    //         color={"primary"}
    //         textAlign={"flex-start"}
    //         sx={{ textTransform: "uppercase" }}
    //         fontWeight={800}
    //       >
    //         {t("navlink.notifications")}
    //       </Typography>
    //       <Box my={2}>
    //         <Typography variant="body1" textAlign={"flex-start"}></Typography>
    //       </Box>
    //     </Grid>
    //     <Box
    //       mt={2}
    //       sx={{
    //         height: 400,
    //         width: "100%",
    //         paddingInline: { xs: 0, sm: "1rem" },
    //       }}
    //     >
    //       <UserNotificationList />
    //     </Box>
    //   </Grid>
    // </Content>
  );
}
