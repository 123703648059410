import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Alert,
  Grid,
  TextField,
  Autocomplete,
  Snackbar,
  Container,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { UpdateAlertService } from "../../services/UpdateAlertService";
import { CreateAlertService } from "../../services/CreateAlertService";
import { TypeListService } from "../../services/TypeListService";

const langList = [
  { id: 1, lang: "en", name: "English" },
  { id: 2, lang: "es", name: "Español" },
  { id: 3, lang: "pt", name: "Português" },
];

export default function AlertInfoForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(true);
  const [showAlertInvalidForm, setShowAlertInvalidForm] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationUserCreated, setConfirmationUserCreated] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [languageSelected, setLanguageSelected] = useState(null);
  const [formData, saveFormData] = useState({
    serviceId: '',
    name: '',
    language: 'es',
    type: "",
    __imageName: '',
    __points: 0,
  });
  const { mode, data } = location.state || {};
  const { t } = useTranslation();
  const { isOpen } = useMenu();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    saveFormData({
      ...formData,
      [name]: value,
    });
  };
 
  const transformDataToFormData = (data) => {
    return {
      serviceId: data.id,
      name: data.name,
      __imageName: data.imageUrl,

    };
  };

  const CreatePayload = () => {
    return {
      serviceId: formData.serviceId,
      name: formData.name,
      language: languageSelected.lang,
      type: selectedType.id,
      __imageName: formData.__imageName,
      __points: formData.__points,
    };
  };


  useEffect(() => {
    if (data) {
      const transformedData = transformDataToFormData(data);
      saveFormData(transformedData);
      setSelectedType({description:data?.description,type: data?.type});
      const currentLanguage = langList.find(item=> data?.languaje ===item.lang)
      currentLanguage && setLanguageSelected(currentLanguage);
    }
  }, [data]);

  const handleTypeChange = (event, value) => {
    setSelectedType(value)
    if (value?.id !== null) {
      saveFormData({
        ...formData,
        type: value?.id
      });
    }
  };

  const handleLangChange = (event, value) => {
    setLanguageSelected(value)
    saveFormData((prevState) => ({
      ...prevState,
      language: value.lang,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getToken();
    const request = CreatePayload();
    if (mode === "edit") {
      try {
        setLoading(true);
        await UpdateAlertService(
          request,
          token,
          data?.id
        );
        setConfirmationUserCreated(true);
        navigate(`/usersManagement?tab=5`);
      } catch (error) {
        setError(true);
        setErrorMessage(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const form = Object.entries(formData)
          .filter(([key, value]) => value !== "")
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        await CreateAlertService(form, token);
        setConfirmationUserCreated(true);
        navigate(`/usersManagement?tab=5`);
      } catch (error) {
        setError(true);
        setErrorMessage(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };


  const handleBack = () => {
    navigate(`/usersManagement?tab=1`);
  };

  const handleCloseInvalidForm = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertInvalidForm(false);
  };

  const handleCloseConfirmationUserCreated = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setConfirmationUserCreated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    getTypes();
  }, []);

  const getTypes = async () => {
    const token = await getToken();
    const types_List = await TypeListService(token);
    setTypes(types_List?.data);
    setLoadingTypes(false);
  };

  return (
    <Content open={isOpen} className="content">
      <Box>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={"/listManagement?tab=5"} 
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
        </Grid>
        <Box mb={2}>
          <Typography variant="h6" fontWeight={600} textAlign={"center"}>
            {t("listManagement.tabTitle-6")}
          </Typography>
        </Box>
        <Snackbar
          open={showAlertInvalidForm}
          autoHideDuration={6000}
          onClose={handleCloseInvalidForm}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity="error"
            onClose={() => setShowAlertInvalidForm(false)}
          >
            {t("createAccount.form.invalidForm")}
          </Alert>
        </Snackbar>
        {/* User Created or Updated Alert*/}
        <Snackbar
          open={confirmationUserCreated}
          autoHideDuration={6000}
          onClose={handleCloseConfirmationUserCreated}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{t("createAccount.form.success")}</Alert>
        </Snackbar>
        {/* Error Response Alert*/}
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        
        {/* Form Create New Account */}
        <Container maxWidth='xl'>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  label={t("createAlert.form.name")}
                  size="small"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="serviceId"
                  label={t("createAlert.form.serviceid")}
                  size="small"
                  inputProps={{maxLength: 4}}
                  value={formData.serviceId || ""}
                  onChange={handleInputChange}
                  required={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="__imageName"
                  label={t("createAlert.form.image")}
                  size="small"
                  value={formData.__imageName || ""}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="type"
                  options={
                    loadingTypes
                      ? [{ description: "Loading..." }]
                      : types
                  }
                  loading={loadingTypes}
                  loadingText="Loading..."
                  value={selectedType || null}
                  onChange={handleTypeChange}
                  autoHighlight
                  getOptionLabel={(option) => option.description}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="documentType"
                      required
                      label={t("roleList.headers-6")}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
              <Autocomplete
                id="lang"
                options={langList}
                loadingText="Loading..."
                onChange={handleLangChange}
                value={languageSelected || null}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={handleLangChange}
                    size="small"
                    name="language"
                    label={t("language")}
                  />
                )}
              />
            </Grid>

            </Grid>

            {mode !== "edit" ? (
              <>
                <Grid container mt={2} sx={{ textAlign: "center" }} gap={2}>
                  <Grid item xs>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        type="submit"
                        variant="contained"
                        color={"secondary"}
                        size="large"
                        startIcon={
                          loading ? (
                            <CircularProgress
                              size="1.5rem"
                              sx={{ color: "white" }}
                            />
                          ) :
                            <CheckCircleOutlineIcon sx={{ color: "white" }} />
                        }
                      >
                        {`${t("save.button")}`}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container mt={2} sx={{ textAlign: "center" }} gap={2}>
                  <Grid item xs>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={
                          loading ? (
                            <CircularProgress
                              size="1.5rem"
                              sx={{ color: "white" }}
                            />
                          ) : (
                            <CheckCircleOutlineIcon sx={{ color: "white" }} />
                          )
                        }
                      >
                        {t("updateAccountButton.text")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        </Container>
      </Box>
    </Content>
  );
}