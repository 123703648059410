import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  Input,
  Card,
  Snackbar,
  Alert,
  Stack,
  Skeleton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/GetToken";
import { GetUserData } from "../../services/GetUserService";
import { GetEnableCodeService } from "../../services/2FA/GetEnableCodeService";
import { GetDisableCodeService } from "../../services/2FA/GetDisableCodeService";
import { Enable2FAService } from "../../services/2FA/Enable2FAService";
import { Disable2FAService } from "../../services/2FA/Disable2FAService";
import SyncIcon from "@mui/icons-material/Sync";

function EnableOrDisable2FA() {
  const [user, setUser] = useState({});
  const { token: rToken } = useAuth();

  const [loading, setLoading] = useState(false);
  const [loadingCodeEnable, setLoadingCodeEnable] = useState(false);
  const [loadingCodeDisable, setLoadingCodeDisable] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(true);

  const [formValueEnable, setFormValueEnable] = useState({
    otp: "",
  });

  const [formValueDisable, setFormValueDisable] = useState({
    otp: "",
  });
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [context, setContext] = useState({
    activate: false,
    disable: false,
    user: user
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showCodeSendedMessage, setShowCodeSendedMessage] = useState(false);
  const [codeSendedMessage, setCodeSendedMessage] = useState("");

  const { t } = useTranslation();
  useEffect(() => {
    async function getUser() {
      try {
        const token = await getToken();
        if (!!rToken) {
          const user = await GetUserData(rToken);
          setUser(user);
          return;
        }
        const user = await GetUserData(token);
        setUser(user);
        setLoadingInfo(false);
      } catch (error) {
        console.log(error);
      }
    }
    getUser();
  }, []);

  const StartActivate = () => {
    console.log("start activate");
    // call getEnableCode
    const response = GetEnableCode();
    setContext(
      {
        ...context,
        activate: true
      }
    )
  };
  const StartDesactivate = () => {
    console.log("start desactivate");
    // call getDisableCode
    const response = GetDisableCode();
    setContext(
      {
        ...context,
        disable: true
      }
    ) 
  };
  async function GetEnableCode() {
    setLoadingCodeEnable(true);
    try {
      const token = await getToken();
      const response = await GetEnableCodeService(token);
      console.log(response.data.message);
      setCodeSendedMessage(response.data.message);
      setShowCodeSendedMessage(true);
      setLoadingCodeDisable(false);
      setLoadingCodeEnable(false);
    } catch (error) {
      setErrorMessage(error);
      setError(true);
      setLoadingCodeEnable(false);
    } finally {
    }
  }

  async function GetDisableCode() {
    setLoadingCodeDisable(true);
    try {
      const token = await getToken();
      const response = await GetDisableCodeService(token);
      console.log(response.data.message);
      setCodeSendedMessage(response.data.message);
      setShowCodeSendedMessage(true);
      setLoadingCodeDisable(false);
    } catch (error) {
      setErrorMessage(error);
      setError(true);
      setLoadingCodeDisable(false);
      console.log(error);
    }
  }

  const handleSubmitEnable = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const token = await getToken();
      console.log("before send", formValueEnable);
      const response = await Enable2FAService(formValueEnable, token);
      console.log(response);
      setCodeSendedMessage(response.data.message);
      setShowCodeSendedMessage(true);
      setLoadingCodeDisable(false);
      window.location.reload();
    } catch (error) {
      setErrorMessage(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitDisable = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const token = await getToken();
      console.log("before send", formValueEnable);
      const response = await Disable2FAService(formValueEnable, token);
      window.location.reload();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleCloseCodeSendedMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowCodeSendedMessage(false);
  };
  // add context to handle ac

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: { xs: "flex-start" },
      }}
    >
      <Snackbar
        open={showCodeSendedMessage}
        autoHideDuration={6000}
        onClose={handleCloseCodeSendedMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{codeSendedMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Typography
        variant="h4"
        color={"primary"}
        textAlign={"center"}
        sx={{ textTransform: "uppercase" }}
        fontWeight={800}
      >
        {t("settings.title-2")}
      </Typography>
      {(!!context?.activate || !!context?.disable )
        ?  <form onSubmit={(e) => !!context?.activate ? handleSubmitEnable(e) : handleSubmitDisable(e)}>
        <Box sx={{ mt: "10px" }}>
          <Typography
            sx={{ color: "grays.lighttext", letterSpacing: "-1px" }}
          >
            {t("setting.edit2FA.text_enable")}
          </Typography>
          <Grid container sx={{ marginTop: "15px" }}>
            <Grid item xs={12} mb={2} alignItems="center">
              <FormControl>
                <Input
                  placeholder={t("setting.edit2FA.input")}
                  onChange={(e) =>
                    setFormValueEnable({
                      ...formValueEnable,
                      otp: e.target.value,
                    })
                  }
                  value={formValueEnable.otp}
                  required
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} alignItems="center">
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  borderRadius: "20px",
                }}
                startIcon={
                  loadingCodeEnable ? (
                    <CircularProgress size="20px" sx={{ color: "white" }} />
                  ) : (
                    ""
                  )
                }
                onClick={GetEnableCode}
              >
                {t("changePassword.getCode")}
              </Button>
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 12 }} mt={2}>
            <Grid item xs={6} container alignItems="center">
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: "20px" }}
                startIcon={
                  loading ? (
                    <CircularProgress size="20px" sx={{ color: "white" }} />
                  ) : (
                    <SyncIcon />
                  )
                }
                type="submit"
              >
                {t("update.button")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
        :< Box my={2} mb={4}>
        <Typography variant="body1" textAlign={"flex-start"}>
          {t("settings.description-2")}
        </Typography>
        <Grid item xs={6} my={4} container alignItems="center">
        {!!user?.tfa 
          ?
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: "20px" }}
            startIcon={
              loading ? (
                <CircularProgress size="20px" sx={{ color: "white" }} />
              ) : (
                ""
              )
            }
            onClick={StartDesactivate}
          >
            {t("button.deactivate") }
          </Button>
          : <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: "20px" }}
            startIcon={
              loading ? (
                <CircularProgress size="20px" sx={{ color: "white" }} />
              ) : (
                ""
              )
            }
            onClick={StartActivate}
          >
            {t("button.activate")}
          </Button>

          }
        </Grid>
      </Box>
      }
      
    </Stack>
  );
}

export default React.memo(EnableOrDisable2FA);
