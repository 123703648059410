import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReviewDocumentLayout from "../../components/layout/ReviewDocumentLayout";
import { SelectDocumentService } from "../../services/DocumentsManagement/SelectDocumentService";
import { getToken } from "../GetToken";
import { useParams } from "react-router-dom";
import StatusTranslator from "../StatusTranslator";
import { useTranslation } from "react-i18next";
import ConfirmDeleteDocument from "../../components/documents-components/ConfirmDeleteDocument";

const HandleDocumentActions = ({ id, documentListBy }) => {
  const [clickReview, setClickReview] = useState(false);
  const [clickUpdate, setClickUpdate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { userId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleClickReview = () => {
    handleClose();
    selectDocuments();
    setClickReview(true);
  };

  const handleClickUpdate = () => {
    handleClose();
    navigate(`/editDocument/${id}`, {
      state: {
        mode: "edit",
        uploadBy: `${documentListBy}`,
        userId: `${userId}`,
      },
    });
  };

  const handleClickDelete = () => {
    handleClose();
    setDeleteModalOpen(true);
  };

  const selectDocuments = async () => {
    try {
      const token = await getToken();
      if (token) {
        if (documentListBy === "client") {
          const response = await SelectDocumentService(token, userId, "client");
          console.log("Select documents response:", response);
        } else if (documentListBy === "agent") {
          const response = await SelectDocumentService(token, userId, "agent");
          console.log("Select documents response:", response);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   setCurrentUser((prevState) => ({
  //     ...prevState,
  //     user: userId,
  //   }));
  //   console.log(currentUser);
  // }, [anchorEl]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 222,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClickReview}>
          <ListItemIcon>
            <PreviewIcon />
          </ListItemIcon>
          <ListItemText>{t("reviewDocument.item-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickUpdate}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("reviewDocument.item-2")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("reviewDocument.item-3")}</ListItemText>
        </MenuItem>
      </Menu>
      {clickReview && (
        <ReviewDocumentLayout
          docId={id}
          clickReview={clickReview}
          setClickReview={setClickReview}
          documentListBy={documentListBy}
        />
      )}
      <ConfirmDeleteDocument
        isOpen={deleteModalOpen}
        docId={id}
        data={documentListBy}
        handleClose={handleCloseModal}
      />
    </>
  );
};

const DocumentColumns = ({ documentListBy }) => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("i18nextLng");

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 80,
      renderCell: (params) => (
        <HandleDocumentActions
          id={params.row.id}
          documentListBy={documentListBy}
        />
      ),
    },
    {
      field: "id",
      headerName: t("documentList.headers-1"),
      width: 350,
    },
    {
      field: "document_type",
      headerName: t("documentList.headers-3"),
      width: 200,
    },
    {
      field: "state",
      headerName: t("documentList.headers-4"),
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const value = params.value;
        return (
          <Chip
            label={value}
            variant="outlined"
            color={
              value === "aprobado"
                ? "success"
                : value === "Rechazado"
                ? "error"
                : value === "NEW"
                ? "info"
                : value === "Pendiente"
                ? "primary"
                : "secondary"
            }
          />
        );
      },
    },
    {
      field: "comment",
      headerName: t("documentList.headers-5"),
      sortable: false,
      width: 360,
    },
    {
      field: "upload_date",
      headerName: t("documentList.headers-6"),
      sortable: false,
      width: 250,
    },
    {
      field: "last_update",
      headerName: t("documentList.headers-7"),
      sortable: false,
      width: 250,
    },
  ];

  return columns;
};

export default DocumentColumns;
