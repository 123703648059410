import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getToken } from "../../utils/GetToken";
import { ApproveSensitiveChangeService } from "../../services/SensitiveData/ApproveSensitiveChangeService";
import { RejectSensitiveChangeService } from "../../services/SensitiveData/RejectSensitiveChangeService";
import { useReviewSensitiveChange } from "../../context/ReviewSensiteChangeContext";

const estados = [
  { id: 1, state: "Aprobado" },
  { id: 2, state: "Rechazado" },
];

export default function ReviewSensitiveChange({ changeDetails, handleClose }) {
  const { id, fields: sensitiveFields, comment } = changeDetails;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sensitiveChangeState, setSensitiveChangeState] = useState(null);
  const [formValues, setFormValues] = useState({
    comment: "",
  });
  const { handleSensitiveChangeApproved, handleSensitiveChangeRejected } =
    useReviewSensitiveChange();

  const { t } = useTranslation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const checkFields = (data) => {
    // check data if data is object and has at least one key
    if (typeof data === "object" && Object.keys(data).length > 0) {
      return true;
    }
  }
  // console.log(fields, comment);

  const handleStateChange = (event, value) => {
    console.log("handle state change", value);
    if (value?.state) {
      setSensitiveChangeState(value.id);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("To submit", formValues);
    console.log("Sensitive Change state", sensitiveChangeState);
    {
      sensitiveChangeState === 1 && approveSensitiveChange();
    }
    {
      sensitiveChangeState === 2 && rejectSensitiveChange();
    }
  };

  const handleCloseErrorMessage = () => {
    setError(false);
  };

  const approveSensitiveChange = async () => {
    try {
      const token = await getToken();
      setLoading(true);
      const response = await ApproveSensitiveChangeService(
        token,
        id,
        formValues
      );
      const { docApproved, message, shopIsApproved } = response.data;
      setSuccessMessage(message);
      handleSensitiveChangeApproved();
      handleClose();
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const rejectSensitiveChange = async () => {
    try {
      const token = await getToken();
      setLoading(true);
      const response = await RejectSensitiveChangeService(
        token,
        id,
        formValues
      );
      const { docApproved, message, shopIsApproved } = response.data;
      setSuccessMessage(message);
      handleSensitiveChangeRejected();
      handleClose();
    } catch (error) {
      setErrorMessage(error);
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseErrorMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Card
        elevation={0}
        sx={{
          borderRadius: "20px",
          mt: { xs: "2rem", md: 0 },
          maxWidth: "500px",
        }}
      >
        <CardContent>
          {changeDetails !== null && (
            <Stack mt={2} spacing={2}>
              <Typography variant="h6">Campos modificados:</Typography>
              <List>
                {checkFields(sensitiveFields) ? (
                  Object.entries?.(sensitiveFields)?.map(([key, value], index) => (
                    <ListItem key={index}>
                      <ListItemText primary={key} secondary={value} />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="p">No hay campos modificados</Typography>
                )}
              </List>
              <Typography variant="h6">Comentarios:</Typography>
              <Typography variant="p">{comment}</Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <Typography variant="caption">
                        {t("sensitiveChange.reviewComments")}
                      </Typography>
                      <TextField
                        name="comment"
                        label={t("reviewDoc.textfield-1")}
                        size="small"
                        value={formValues.comment || ""}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        multiline
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      id="stateSelect"
                      options={estados}
                      onChange={handleStateChange}
                      getOptionLabel={(option) => option.state}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("reviewDoc.textfield-2")}
                          size="small"
                          onChange={handleStateChange}
                          value={formValues.state}
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box mt={2} sx={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color={"secondary"}
                    size="large"
                    startIcon={
                      loading && (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      )
                    }
                  >
                    {t("save.button")}
                  </Button>
                </Box>
              </form>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
}
