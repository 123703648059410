import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Alert, Snackbar } from "@mui/material";
import { GetDocumentListByClientsService } from "../../services/DocumentsManagement/Clients/GetDocumentListByClientsService";
import { GetDocumentListByAgentService } from "../../services/DocumentsManagement/Agents/GetDocumentListByAgentService";
import { getToken } from "../../utils/GetToken";
import DocumentColumns from "../../utils/TableHeaders/DocumentsHeaders";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useReviewDoc } from "../../context/ReviewDocumentContext";
import { useTranslation } from "react-i18next";

export default function DocumentList({ documentListBy }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { docApproved, docRejected, resetDocStates, isDocumentDeleted } = useReviewDoc();
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const { userId } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    getDocumentList();
    if(isDocumentDeleted) {
      setShowDeleteMessage(true);
    }
    resetDocStates();
  }, [docApproved, docRejected, isDocumentDeleted]);

  const getDocumentList = async () => {
    try {
      const token = await getToken();
      if (token) {
        if (documentListBy === "client") {
          const data = await GetDocumentListByClientsService(token, userId);
          console.log(data);
          const mappedData = mapData(data);
          setRows(mappedData);
        } else if (documentListBy === "agent") {
          const data = await GetDocumentListByAgentService(token, userId);
          console.log(data);
          const mappedData = mapData(data);
          setRows(mappedData);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDeletedMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowDeleteMessage(false);
  };

  const mapData = (data) =>
    data.map((item) => {
      const uploadDate = dayjs(item.upload_date).format("DD/MM/YYYY H:m:s A");
      const lastUpdate = dayjs(item.last_update).format("DD/MM/YYYY H:m:s A");
      const mappedItem = {
        id: item.id,
        document_type: item.type?.name,
        url_file: item.url_file,
        state: item.state,
        comment: item.comment,
        upload_date: uploadDate,
        last_update: lastUpdate,
      };
      return mappedItem;
    });

  return (
    <>
      <DataGrid
        rows={rows}
        columns={DocumentColumns({ documentListBy })}
        loading={loading}
        disableRowSelectionOnClick
        hideFooter
        sx={{
          boxShadow: "0 1px 12px rgba(149, 157, 165, 0.2)",
          borderRadius: "25px",
          borderColor: "transparent",
          backgroundColor: "white",
          paddingX: "1.5rem",
          "& .MuiDataGrid-cell": {
            borderColor: "gray.light",
          },
          // "& .MuiTablePagination-selectLabel": {
          //   color: "primary.main",
          // },
          // "& .MuiTablePagination-select": {
          //   fontWeight: 700,
          //   color: "primary.main",
          // },
          // "& .MuiTablePagination-displayedRows": {
          //   color: "primary.main",
          // },
          // "& .MuiTablePagination-actions .MuiButtonBase-root": {
          //   padding: "2px",
          //   marginInline: "5px",
          //   bgcolor: "primary.main",
          //   color: "white",
          // },
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          // "& .MuiDataGrid-columnHeaderTitle": {
          //   color: "primary.main",
          // },
          "& .super-app-theme--header": {
            backgroundColor: "white",
          },
        }}
      />
      <Snackbar
        open={showDeleteMessage}
        autoHideDuration={3000}
        onClose={handleCloseDeletedMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {t("deletedDocument.success.message")}
        </Alert>
      </Snackbar>
    </>
  );
}
