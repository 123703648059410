import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Container,
  Grid,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import CommerceList from "../accounts-and-commerces-components/CommerceList";
import ClientList from "../client-management-components/ClientList";
import { useTranslation } from "react-i18next";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import AccountListClient from "../accounts-and-commerces-components/AccountListClient";
import AccountListAgents from "../accounts-and-commerces-components/AccountListAgents";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box sx={{ p: { xs: "0.2rem", sm: 3 }, mt: 2 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AccountsAndStoresLayout() {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();

  const handleButtonClick = () => {
    navigate("/createAdminAccount");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Aquí va la lógica adicional 
  }, []);

  return (
    <Content open={isOpen}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pl: 0 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("usersManagement.tabTitle-2")} {...a11yProps(0)} />
          <Tab label={t("usersManagement.tabTitle-3")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"flex-start"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("accountsAndMerchants.subtitle-1")}
          </Typography>
        </Box>
        <Box my={2}>
          <Typography
            variant="body1"
            textAlign={"flex-start"}
          >
            {t("accountsAndMerchants.description-1")}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* <Box>
          <Button
            variant="contained"
            color={"secondary"}
            size="large"
            startIcon={<PersonAddIcon sx={{ color: "white" }} />}
            onClick={handleButtonClick}
          >
            {t("createNewAccount.button")}
          </Button>
        </Box> */}
      </Grid>
    </Grid>
    <Box
      mt={2}
      sx={{
        height: 800,
        width: "100%",
        paddingInline: { xs: 0, sm: 0 },
      }}
    >
      <AccountListClient  currentTab={value} />
    </Box>
  </TabPanel>
  <TabPanel value={value} index={1}>
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"flex-start"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("accountsAndMerchants.subtitle-2")}
          </Typography>
        </Box>
        <Box my={2}>
          <Typography
            variant="body1"
            textAlign={"flex-start"}
          >
            {t("accountsAndMerchants.description-2")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
    <Box mt={2} style={{ height: 800, width: "100%" }}>
      <AccountListAgents currentTab={value} />
    </Box>
  </TabPanel>
</Content>
);
}