import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Stack,
  Menu,
  Box,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";

export default function SwitchLanguages() {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showLanguages, setShowLanguages] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowLanguages(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      position="absolute"
      sx={{
        right: "15px",
      }}
    >
      <IconButton sx={{color: "action.active"}} onClick={handleClick}>
        <LanguageIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 18,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }
        }}
      >
          <MenuItem onClick={() => i18n.changeLanguage("es") && handleClose()}>
            Español
          </MenuItem>
          <MenuItem onClick={() => i18n.changeLanguage("en") && handleClose()}>
            English
          </MenuItem>
          <MenuItem onClick={() => i18n.changeLanguage("pt") && handleClose()}>
            Português
          </MenuItem>
      </Menu>
    </Box>
  );
}