import {
  Stack,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReplayIcon from "@mui/icons-material/Replay";
import { getToken } from "../../utils/GetToken";
import { ConfirmEmailService } from "../../services/ConfirmEmailService";
import { ConfirmMovilService } from "../../services/ConfirmMovilService";
import { ResendEmailCodeService } from "../../services/ResendEmailCodeService";
import { ResendSmsCodeService } from "../../services/ResendSmsCodeService";

export default function ConfirmEmailAndPhone({
  isVerificationFinalized,
  setIsVerificationFinalized,
}) {
  const [loadingEmailConfirmation, setLoadingEmailConfirmation] =
    useState(false);
  const [loadingSmsConfirmation, setLoadingSmsConfirmation] = useState(false);
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const [loadingResendSms, setLoadingResendSms] = useState(false);
  const [isEmailCodeValid, setIsEmailCodeValid] = useState(false);
  const [isSmsCodeValid, setIsSmsCodeValid] = useState(false);
  const [isConfirmedEmailCode, setIsConfirmedEmailCode] = useState(false);
  const [isConfirmedSmsCode, setIsConfirmedSmsCode] = useState(false);
  const [resendCounterEmail, setResendCounterEmail] = useState(60);
  const [resendCounterSms, setResendCounterSms] = useState(60);
  const [activeTimerEmail, setActiveTimerEmail] = useState(true);
  const [activeTimerSms, setActiveTimerSms] = useState(true);
  const [formValueEmail, setFormValueEmail] = useState({
    email_code: "",
  });
  const [formValueMovil, setFormValueMovil] = useState({
    movil_code: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    let interval;

    if (activeTimerEmail && resendCounterEmail > 0) {
      interval = setInterval(() => {
        setResendCounterEmail((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (resendCounterEmail === 0) {
      clearInterval(interval);
      setActiveTimerEmail(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [activeTimerEmail, resendCounterEmail]);

  useEffect(() => {
    let interval;

    if (activeTimerSms && resendCounterSms > 0) {
      interval = setInterval(() => {
        setResendCounterSms((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (resendCounterSms === 0) {
      clearInterval(interval);
      setActiveTimerSms(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [activeTimerSms, resendCounterSms]);

  const handleResetClickEmail = () => {
    setActiveTimerEmail(true);
    setResendCounterEmail(60);
  };

  const handleResetClickSms = () => {
    setActiveTimerSms(true);
    setResendCounterSms(60);
  };

  const handleSubmitEmailCode = async (e) => {
    e.preventDefault();
    console.log(formValueEmail);
    try {
      const token = await getToken();
      setLoadingEmailConfirmation(true);
      const response = await ConfirmEmailService(formValueEmail, token);
      console.log(response.status);
      if (response.status === 200) {
        setIsConfirmedEmailCode(true);
        if (response.data.status === 1) {
          console.log("Verified Email!");
        }
      }
    } catch (error) {
      setLoadingEmailConfirmation(false);
    } finally {
      setLoadingEmailConfirmation(false);
    }
  };

  const handleSubmitSmsCode = async (e) => {
    e.preventDefault();
    try {
      const token = await getToken();
      setLoadingSmsConfirmation(true);
      const response = await ConfirmMovilService(formValueMovil, token);
      console.log(response.status);
      if (response.status === 200) {
        setIsConfirmedSmsCode(true);
        if (response.data.status === 1) {
          console.log("Verified SMS!");
        }
      }
    } catch (error) {
      setLoadingSmsConfirmation(false);
    } finally {
      setLoadingSmsConfirmation(false);
    }
  };

  const handleEmailCodeChange = (event) => {
    const { name, value } = event.target;
    setFormValueEmail((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
    value.length === 6 ? setIsEmailCodeValid(true) : setIsEmailCodeValid(false);
  };

  const handleSmsCodeChange = (event) => {
    const { name, value } = event.target;
    setFormValueMovil((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
    value.length === 6 ? setIsSmsCodeValid(true) : setIsSmsCodeValid(false);
  };

  const handleResendEmailCode = async (e) => {
    e.preventDefault();
    try {
      const token = await getToken();
      setLoadingResendEmail(true);
      const response = await ResendEmailCodeService(token);
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingResendEmail(false);
    }
    handleResetClickEmail();
  };

  const handleResendSmsCode = async (e) => {
    e.preventDefault();
    try {
      const token = await getToken();
      setLoadingResendSms(true);
      const response = await ResendSmsCodeService(token);
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingResendSms(false);
    }
    handleResetClickSms();
  };

  useEffect(() => {
    if (isConfirmedEmailCode && isConfirmedSmsCode) {
      setIsVerificationFinalized(true);
    }
  }, [isConfirmedEmailCode, isConfirmedSmsCode]);

  return (
    <Stack mt={{ xs: 0, sm: 4 }}>
      <Grid
        container
        mt={{ xs: 0, md: 2 }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" fontWeight={600}>
              {t("step.two.label")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Card
            elevation={0}
            sx={{
              p: "1rem",
              mr: { xs: "0.4rem", sm: "10rem", md: "10rem" },
              mt: { xs: "2rem", md: "0" },
              maxWidth: "800px",
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmitEmailCode}>
                <Box my={2}>
                  <Divider variant="middle">
                    <EmailIcon />
                    <Typography>{t("confirmation.code.email")}</Typography>
                  </Divider>
                </Box>
                <Stack mt={2} spacing={2}>
                  <TextField
                    label={t("confirmation.code.label.input")}
                    name="email_code"
                    value={formValueEmail.email_code || ""}
                    onChange={handleEmailCodeChange}
                    error={!isEmailCodeValid}
                    helperText={
                      !isEmailCodeValid
                        ? `${t("confirmation.code.helper.input")}`
                        : ""
                    }
                  />
                  <Button
                    variant="contained"
                    onClick={handleResendEmailCode}
                    disabled={resendCounterEmail > 0 || isConfirmedEmailCode}
                    color="secondary"
                    startIcon={
                      loadingResendEmail ? (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <ReplayIcon sx={{ color: "white" }} />
                      )
                    }
                  >
                    {resendCounterEmail > 0
                      ? `${t(
                          "confirmation.code.button.resendIn"
                        )} ${resendCounterEmail}s`
                      : `${t("confirmation.code.button.resend")}`}
                  </Button>
                </Stack>
                <Box mt={4} sx={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="success"
                    disabled={!isEmailCodeValid || isConfirmedEmailCode}
                    startIcon={
                      loadingEmailConfirmation ? (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <CheckCircleIcon sx={{ color: "white" }} />
                      )
                    }
                  >
                    {!isConfirmedEmailCode
                      ? `${t("confirmation.code.button.submit")}`
                      : `${t("confirmation.code.button.confirmed")}`}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            elevation={0}
            sx={{
              p: "1rem",
              mr: { xs: "0.4rem", sm: "10rem", md: "10rem" },
              mt: { xs: "2rem", md: "0" },
              maxWidth: "800px",
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmitSmsCode}>
                <Box my={2}>
                  <Divider variant="middle">
                    <PhoneAndroidIcon />
                    <Typography>{t("confirmation.code.sms")}</Typography>
                  </Divider>
                </Box>
                <Stack spacing={2}>
                  <TextField
                    label={t("confirmation.code.label.input")}
                    name="movil_code"
                    value={formValueMovil.movil_code || ""}
                    onChange={handleSmsCodeChange}
                    error={!isSmsCodeValid}
                    helperText={
                      !isSmsCodeValid
                        ? `${t("confirmation.code.helper.input")}`
                        : ""
                    }
                  />
                  <Button
                    variant="contained"
                    onClick={handleResendSmsCode}
                    disabled={resendCounterSms > 0 || isConfirmedSmsCode}
                    color="secondary"
                    startIcon={
                      loadingResendSms ? (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <ReplayIcon sx={{ color: "white" }} />
                      )
                    }
                  >
                    {resendCounterSms > 0
                      ? `${t(
                          "confirmation.code.button.resendIn"
                        )} ${resendCounterSms}s`
                      : `${t("confirmation.code.button.resend")}`}
                  </Button>
                </Stack>
                <Box mt={4} sx={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="success"
                    disabled={!isSmsCodeValid || isConfirmedSmsCode}
                    startIcon={
                      loadingSmsConfirmation ? (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <CheckCircleIcon sx={{ color: "white" }} />
                      )
                    }
                  >
                    {!isConfirmedSmsCode
                      ? `${t("confirmation.code.button.submit")}`
                      : `${t("confirmation.code.button.confirmed")}`}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
}
