import { useEffect, useState, useCallback, forwardRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getToken } from "../../utils/GetToken";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Snackbar,
  Alert,
} from "@mui/material";
import { DeleteAccountService } from "../../services/DeleteAccountService";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import assignDataGridLanguage from "../../utils/AssignDatagridLanguage";
import ClientsHeaders from "../../utils/TableHeaders/ClientsHeaders";
import { GetAccountListClientService } from "../../services/GetAccountListClientService";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AccountList({ currentTab }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [languageDatagrid, setLanguageDatagrid] = useState({});
  const [queryOptions, setQueryOptions] = useState({});
  const [query, setQuery] = useState(searchParams ? searchParams : {});
  const pageUrl = searchParams.get("page");
  const paginationUrl = searchParams.get("pp");
  const fromParamUrl = searchParams.get("from");
  const [pageFromUrl, setPageFromUrl] = useState(
    pageUrl !== null && pageUrl !== 0
      ? parseInt(searchParams.get("page"), 10)
      : 0
  );
  const [paginationFromUrl, setPaginationFromUrl] = useState(
    paginationUrl !== null && paginationUrl !== 0
      ? parseInt(searchParams.get("pp"), 10)
      : 100
  );
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });

  const [selectedId, setSelectedId] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isUserDeleted, setIsUserDeleted] = useState(false);
  const [editRow, setEditRow] = useState([]);
  const [filterModel, setFilterModel] = useState({});

  const currentLanguage = localStorage.getItem("i18nextLng");
  const currentURL = window.location.href;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePageChange = (params) => {
    setPageState((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
    setPaginationModel((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
  };

  const handleDelete = async () => {
    console.log("IDs seleccionados:", selectedId);
    try {
      const token = await getToken();
      const response = await DeleteAccountService(token, selectedId);
      console.log(response);
      setIsUserDeleted(true);
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpenSuspend = () => {
    setOpenDialogSuspend(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
    }
  };

  const handleConfirmDelete = () => {
    setOpenDialog(false);
    handleDelete();
  };

  const handleCellEditCommit = (params) => {
    const updatedRows = [...editRow];
    const rowIndex = updatedRows.findIndex((row) => row.id === params.id);
    updatedRows[rowIndex][params.field] = params.value;
    setEditRow(updatedRows);
  };

  useEffect(() => {
    searchParams.set("page", `${pageState.page}`);
    searchParams.set("pp", `${pageState.pageSize}`);
    setSearchParams(searchParams);
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setLanguageDatagrid(assignDataGridLanguage(currentLanguage));
    extractParamsAndAssignState();
  }, [currentLanguage]);

  const onFilterChange = useCallback(
    (filterModel) => {
      setQueryOptions({ filterModel: { ...filterModel } });
    },
    [query]
  );

  useEffect(() => {
    if (isUserDeleted) {
      const redirectTimer = setTimeout(() => {
        window.location.reload();
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isUserDeleted]);

  useEffect(() => {
    setPaginationModel((old) => ({
      ...old,
      page: pageFromUrl,
      pageSize: paginationFromUrl,
    }));
  }, [pageFromUrl, paginationFromUrl, query]);

  useEffect(() => {
    setPageState((old) => ({
      ...old,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    }));
    getAccounts();
  }, [pageState.page, pageState.pageSize, query, paginationModel]);

  useEffect(() => {
    const queryOptionsMapping = {
      forename: "name",
      surname: "name",
      "plan.id": "pnid",
      "plan.title": "pn",
      state_code: "st",
      state: "stid",
    };
    const _query = queryOptions?.filterModel?.items[0];
    // console.log("Query with filterModel", query);

    if (_query !== undefined) {

      const { field, value } = _query;

      if (field !== undefined && value !== undefined) {
        const mappedField = queryOptionsMapping[field] || field;
        setQuery(`${mappedField}=${value}`);
        searchParams.set(mappedField, value);
        resetPagination();
        setSearchParams(searchParams);
      } else {
        setQuery({});
        searchParams.delete(field);
        setSearchParams(searchParams);
      }
    }
  }, [queryOptions]);

  const getAccounts = async () => {
    try {
      const token = await getToken();
      if (token) {
        setPageState((old) => ({ ...old, isLoading: true }));
        const currentPage = pageState.page + 1;
        const pagination = pageState.pageSize;
        console.log("Filter Model:", filterModel);
        console.log("Query:", query);
        const response = await GetAccountListClientService(
          token,
          currentPage,
          query,
          pagination
        );
        console.log("Data Accounts", response);
        const { data, links, meta } = response;
        if (Array.isArray(data) && data.length !== 0) {
          const mappedData = mapData(data);
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: mappedData,
            total: meta.total,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: [],
            total: 0,
          }));
        }
        return;
      }
    } catch (error) {
      console.log(error);
    }finally {
    }
  };

  const mapData = (data) =>
    data.map((item) => {
      const mappedItem = {
        id: item.id,
        forename: item.forename,
        surname: item.surname,
        email: item.email,
        document_type: item.document.typeInfo
          ? item.document.typeInfo.name
          : null,
        document_number: item.document.number,
        movil: item.movil.number,
        state_code: item.state_code,
        state: item.state,
        plan: {
          id: item.plan?.id || null,
          title: item.plan?.title || null,
        },
      };
      return mappedItem;
    });

  const resetPagination = () => {
    searchParams.set("page", 0);
    searchParams.set("pagination", 100);
    setPageFromUrl(parseInt(searchParams.get("page"), 10));
    setPaginationFromUrl(parseInt(searchParams.get("pagination"), 10));
  };

  const extractParamsAndAssignState = () => {
    const urlSearchParams = new URLSearchParams(currentURL.split("?")[1]);
    const paramsArray = [];

    urlSearchParams.forEach((value, key) => {
      paramsArray.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    });

    const state = paramsArray.join("&");
    setQuery(state);

    return state;
  };

  return (
    <>
      <DataGrid
        columns={ClientsHeaders()}
        rows={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page}
        filterMode="server"
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(params) => handlePageChange(params)}
        onFilterModelChange={onFilterChange}
        editMode="row"
        onCellEditCommit={handleCellEditCommit}
        // onRowSelectionModelChange={(ids) => {
        //   handleRowSelection(ids);
        // }}
        localeText={languageDatagrid}
        slots={{
          toolbar: GridToolbar,
        }}
        disableRowSelectionOnClick
        // hideFooterSelectedRowCount={false}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        sx={{
          boxShadow: "0 1px 12px rgba(149, 157, 165, 0.2)",
          borderRadius: "25px",
          borderColor: "transparent",
          backgroundColor: "white",
          paddingX: "1.5rem",
          "& .MuiDataGrid-cell": {
            borderColor: "gray.light",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          "& .super-app-theme--header": {
            backgroundColor: "white",
          },
        }}
      />

      {/* Delete Dialog */}

      <Dialog
        disableEscapeKeyDown
        open={openDialog}
        TransitionComponent={Transition}
        onClose={handleClickOpenSuspend}
      >
        <DialogTitle>
          {t("accountsAndMerchants.account.deleted.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("accountsAndMerchants.account.deleted.description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseDialog();
            }}
          >
            {t("cancel.button")}
          </Button>
          <Button
            variant="contained"
            startIcon={<DeleteIcon sx={{ color: "white" }} />}
            color="error"
            onClick={handleConfirmDelete}
            sx={{ color: "white" }}
          >
            {t("delete.button")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isUserDeleted}
        autoHideDuration={6000}
        onClose={handleCloseDialog}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {t("accountsAndMerchants.account.deleted")}
        </Alert>
      </Snackbar>

      {/* Edit Dialog */}
      {/* 
      <Dialog
        disableEscapeKeyDown
        open={openDialogEdit}
        TransitionComponent={Transition}
        onClose={handleCloseDialogEdit}
      >
        <DialogTitle>{t("updateAccount.title")}</DialogTitle>
        <DialogContent>
          <EditAccount id={selectedId} />
        </DialogContent>
        <DialogActions sx={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              handleCloseDialogEdit();
            }}
          >
            {t("cancel.button")}
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Suspend Dialog */}

      {/* Add Company Dialog */}

      {/* <Dialog
        disableEscapeKeyDown
        open={openDialogAddCompany}
        TransitionComponent={Transition}
        onClose={handleCloseDialogAddCompany}
      >
        <DialogTitle>{t("createCompany.card.title")}</DialogTitle>
        <DialogContent>
          <CreateCompany id={selectedId} />
        </DialogContent>
        <DialogActions sx={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              handleCloseDialogAddCompany();
            }}
          >
            {t("cancel.button")}
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
