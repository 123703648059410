import { createContext, useState, useContext } from "react";

export const AccompanimentChangeStatusContext = createContext();

export const useStatusChange = () =>
  useContext(AccompanimentChangeStatusContext);

export const AccompanimentChangeStatusProvider= ({ children }) => {
  const [statusChanged, setStatusChanged] = useState(false);
  const [statusRejected, setStatusRejected] = useState(false);

  const handleStatusApproved = () => {
    setStatusChanged(true);
  };

  const handleStatusRejected = () => {
    setStatusRejected(true);
  };

  const resetUpdateStates = () => {
    setStatusChanged(false);
    setStatusRejected(false);
  };

  return (
    <AccompanimentChangeStatusContext.Provider
      value={{
        statusChanged,
        statusRejected,
        handleStatusApproved,
        handleStatusRejected,
        resetUpdateStates,
      }}
    >
      {children}
    </AccompanimentChangeStatusContext.Provider>
  );
};
