import { useState, useEffect } from "react";
import { Grid, Typography, Box, Snackbar, Alert,Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CompaniesList from "../companies-management-components/CompaniesList";

export default function CompaniesLayout() {
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();

  const handleShowDialog = (id) => {
    setShowDialog(!showDialog);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
  };

  const handleErrorMessage = (message) => {
    setErrorMessage(message);
  };

  const handleButtonNewCompanieClick = () => {
    navigate("/companies/new");
  };


  return (
    <Content open={isOpen}>
      <Grid  container sx={{ paddingInline: { xs: "1rem", sm: "2rem" } }} >
        <Grid item xs={12} sm={6}>
          {/* <Snackbar
            open={showAlertApproved}
            autoHideDuration={6000}
            onClose={handleCloseDocApproved}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("approveDoc.message")}</Alert>
          </Snackbar>
          <Snackbar
            open={showAlertRejected}
            autoHideDuration={6000}
            onClose={handleCloseDocRejected}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="info">{errorMessage}</Alert>
          </Snackbar> */}
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"flex-start"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("navlink.companies")}
          </Typography>
          <Box my={2}>
            <Typography variant="body1" textAlign={"flex-start"}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("companies.subtitle")}
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box>
            <Button
              variant="contained"
              color={"secondary"}
              size="large"
              startIcon={<AddIcon sx={{ color: "white" }} />}
              onClick={handleButtonNewCompanieClick}
            >
              {t("companies.button.createNew")}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box
        mt={2}
        sx={{
          height: 400,
          width: "100%",
          paddingInline: { xs: 0, sm: "1rem" },
        }}
      >
        <CompaniesList/>
      </Box>
    </Content>
  );
}
