import { useState, useEffect } from "react";
import CreateNewPlanForm from "../plans-management-components/CreateNewPlanForm";
import { Content } from "../../utils/Content";
import {
  Typography,
  Grid,
  Box,
  StepLabel,
  Button,
  Step,
  Stepper,
  Snackbar,
  Alert,
  Dialog,
  Slide,
  DialogTitle,
  Stack,
  DialogActions,
} from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../context/CompanyContext";
import { forwardRef } from "react";
import CreateCompanyLogo from "../companies-management-components/CreateCompanyLogo";
import CreateNewCompaniesForm from "../companies-management-components/CreateNewCompaniesForm";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateNewCompaniesLayout() {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const navigate = useNavigate();

  const [companyIdFromResponse, setCompanyIdFromResponse] = useState("");
  const [mode, setMode] = useState("");
  const { isCompanyCreated, resetCompanyState, handleCompanyCreated } = useCompany();
  const { isOpen } = useMenu();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const { companyId } = useParams();

  const [showLogoUploadModal, setShowLogoUploadModal] = useState(false);
  const steps = [t("company.createNew.name"), t("company.createNew.imageUpload")];

  useEffect(() => {
    pathname.includes("editCompany") ? setMode("edit") : setMode("new");
  }, [pathname]);

  useEffect(() => {
    console.log(companyIdFromResponse);
  }, [companyIdFromResponse]);

  const handleIsCompanyCreated = (idCompany) => {
    setCompanyIdFromResponse(idCompany);
  };

  const finalizeCreateCompany = () => {
    resetCompanyState();
    navigate(`/companies?tab=0`);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleCompanyCreated();
  };

  return (
    <Content open={isOpen}>
      <Snackbar
        open={isCompanyCreated}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("companies.createNew.success.message")}</Alert>
      </Snackbar>

      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={"/companies?tab=0"}
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box paddingX={"20px"}>
            <Typography
              variant="h4"
              color={"primary"}
              textAlign={"flex-start"}
              sx={{ textTransform: "uppercase" }}
              fontWeight={800}
            >
              {mode === "edit"
                ? t("companies.edit.title")
                : t("companies.createNew.title")}
            </Typography>
          </Box>
          <Box my={2} paddingX={"20px"}>
            <Typography variant="body1" textAlign={"flex-start"}>
              {mode === "edit"
                ? t("companies.edit.description")
                : t("companies.createNew.description")}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        ></Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box
            paddingX={{ xs: "0", sm: "20px" }}
            mt={"1rem"}
            sx={{
              width: "100%",
            }}
          >
            {mode === "new" && (
              <Stepper
                sx={{ mb: "2rem", width: "80%", ml: "10%" }}
                activeStep={activeStep}
                orientation="horizontal"
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <Box>
              {activeStep === steps.length ? (
                finalizeCreateCompany()
              ) : (
                <div>
                  {activeStep === 0 ? (
                    <CreateNewCompaniesForm
                      mode={mode}
                      companyId={companyId}
                      handleNext={handleNext}
                      handleIsCompanyCreated ={handleIsCompanyCreated }
                    />
                  ) : (
                    <Box>
                      {mode === "new" && (
                        <Dialog
                          disableEscapeKeyDown
                          open={isOpen}
                          TransitionComponent={Transition}
                        >
                          <DialogTitle sx={{ p: "1rem" }}>
                            <Stack
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Typography variant="h6">
                                ¿Deseas subir el logo de la empresa?
                              </Typography>
                            </Stack>
                          </DialogTitle>
                          <DialogActions sx={{ p: "1.5rem", gap: "0.5rem" }}>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => setShowLogoUploadModal(true)}
                            >
                              Sí
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => finalizeCreateCompany()}
                            >
                              No
                            </Button>
                          </DialogActions>

                          {showLogoUploadModal && (
                            <CreateCompanyLogo
                              companyId={
                                mode === "edit" ? companyId : companyIdFromResponse
                              }
                            />
                          )}
                        </Dialog>
                      )}
                    </Box>
                  )}
                </div>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
         {/* <Grid container>
        <Grid item xs={12}>
          <Box sx={{ padding: "20px" }}>
            <CreateNewCompaniesForm mode={mode} companyId={companyId} />
          </Box>
        </Grid>
      </Grid> */}
    </Content>
  );
}
