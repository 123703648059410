import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function ResetPasswordService(data) {
  return new Promise(async (resolve, reject) => {
    console.log("reset password service", data);
    try {
      const response = await Axios.put(
        `${URL_AUTH}/api/admin/v1/password/link/reset`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            "Content-Type": "application/json",
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
