import axios from "axios";

const URL_DICTIONARIES = import.meta.env.VITE_AUTH_DICTIONARIES;

export const GetZoneDetailService = (token, zoneId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${URL_DICTIONARIES}/api/locations/v1/zones/${zoneId}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      // console.log(response);
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
};