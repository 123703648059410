import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Typography,
  Box,
  Divider,
  Stack,
} from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationAccountCreated({
  isOpen,
  handleClose,
  handleRegistrationFinalized,
  message,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleConfirmation = () => {
    goAccountsAndCommerces();
    handleClose;
  };

  const goAccountsAndCommerces = () => {
    handleRegistrationFinalized();
    navigate("/accounts&stores");
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle sx={{ p: "2rem" }}>
        <Stack alignItems={"center"} justifyContent="center">
          <CheckCircleIcon sx={{ color: "green" }} />
          <Typography variant="h6" color="success">
            {t("createAccount.confirmation.title")}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: "2rem" }}>
        <Grid container>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {t("createAccount.confirmation.text")}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: "2rem", gap: "0.5rem" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleConfirmation}
        >
          {t("next.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
