import { useState, useEffect } from "react";
import { TablePagination, Box } from "@mui/material";
import { getToken } from "../../utils/GetToken";
import { GetAccountListClientService } from "../../services/GetAccountListClientService";
import ContactList from "./ContactList";

const mapData = (data) =>
  data.map((item) => ({
    id: item.id,
    fullName: item.fullName,
    movil: `+${item.movil.dial}${item.movil.number}`,
  }));

export default function ContactListDisplay() {
  const [page, setPage] = useState(0);
  const [pageState, setPageState] = useState({
    contacts: [],
    isLoading: false,
    total: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getAccounts();
  }, [page]);

  const getAccounts = async () => {
    try {
      const token = await getToken();
      if (token) {
        setPageState((old) => ({ ...old, isLoading: true }));
        const currentPage = page + 1;
        const response = await GetAccountListClientService(
          token,
          currentPage,
          null,
          rowsPerPage
        );
        console.log("Data Accounts", response);
        const { data, links, meta } = response;
        if (Array.isArray(data) && data.length !== 0) {
          const mappedData = mapData(data);
          setPageState((old) => ({
            ...old,
            isLoading: false,
            contacts: mappedData,
            total: meta.total,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            contacts: [],
            total: 0,
          }));
        }
      }
    } catch (error) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
        contacts: [],
        total: 0,
      }));
      console.log(error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-around">
      <ContactList contacts={pageState.contacts} />

      <TablePagination
        sx={{ mt: "10px" }}
        component="div"
        count={pageState.total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
