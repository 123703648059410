import { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import { getToken } from "../../utils/GetToken";
import { GetAllRolesService } from "../../services/RolesAndPermissions/GetAllRolesService";
import { GetAllPermissionsService } from "../../services/RolesAndPermissions/GetAllPermissionsService";
import { AssignRoleToUserService } from "../../services/RolesAndPermissions/AssignRoleToUserService";
import { RemoveRoleToUserService } from "../../services/RolesAndPermissions/RemoveRoleToUserService";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetRolesByUserService } from "../../services/RolesAndPermissions/GetRolesByUserService";

export default function RolesByUser() {
  const [loading, setLoading] = useState(true);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingPermissions, setLoadingPermissions] = useState(true);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [roleList, setRoleList] = useState({});
  const [permissionList, setPermissionList] = useState({});
  const [rolesByUser, setRolesByUser] = useState([]);

  const [isRoleAssignmentModified, setIsRoleAssignmentModified] =
    useState(false);
  const [checked, setChecked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedRole, setSelectedRole] = useState({
    role: "",
  });
  const { t } = useTranslation();

  const { userId } = useParams();

  useEffect(() => {
    try {
      getAllRoles();
      getAllPermissions();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const rolesByUser = async () => {
      try {
        const token = await getToken();
        if (token) {
          const { data } = await GetRolesByUserService(token, userId);
          console.log(data);
          const rolesIdByUser = data.map((role) => role.id);
          setRolesByUser(rolesIdByUser);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    rolesByUser();
  }, [isRoleAssignmentModified]);

  const handleChange = (event) => {
    console.log(event.target.checked);
    setChecked(event.target.checked);
    setSelectedRole({
      ...selectedRole,
      role: event.target.id,
    });
  };

  const handleCloseConfirmationRoleAdded = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsRoleAssignmentModified(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    checked
      ? selectedRole.role !== "" && assignRole()
      : selectedRole.role !== "" && removeRole();
  }, [selectedRole, checked]);

  const getAllRoles = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetAllRolesService(token);
        console.log("Role response", response);
        const { data } = response;
        const roles = data.map((role) => ({
          ...role,
          permissions: JSON.parse(role.permissions).map(
            (permission) => permission.id
          ),
        }));
        setRoleList(roles);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRoles(false);
    }
  };

  const getAllPermissions = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetAllPermissionsService(token);
        console.log("Permissions response", response);
        const { data } = response;
        const sortedData = data.slice().sort((a, b) => {
          return a.id.localeCompare(b.id);
        });
        setPermissionList(sortedData);
        return sortedData;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPermissions(false);
    }
  };

  const assignRole = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await AssignRoleToUserService(
          token,
          selectedRole,
          userId
        );
        console.log("Permissions response", response);
        const { data } = response;
        // console.log(data);
        setSuccessMessage(data.message);
        setIsRoleAssignmentModified(true);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
    }
  };

  const removeRole = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await RemoveRoleToUserService(
          token,
          selectedRole,
          userId
        );
        console.log("Permissions response", response);
        const { data } = response;
        // console.log(data);
        setSuccessMessage(data.message);
        setIsRoleAssignmentModified(true);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
    }
  };

  const isObjEmpty = (obj) => {
    return Object.values(obj).length === 0;
  };

  return (
    <>
      <Snackbar
        open={isRoleAssignmentModified}
        autoHideDuration={6000}
        onClose={handleCloseConfirmationRoleAdded}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <TableContainer component={Paper}>
        {!loading && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Permissions/Roles</TableCell>
                {!loadingRoles &&
                  roleList?.map((role) => (
                    <TableCell key={role.id} align="center">
                      <Checkbox
                        id={role.id}
                        checked={rolesByUser.includes(role.id)}
                        onChange={handleChange}
                      />
                      {role.name}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingPermissions &&
                !isObjEmpty(permissionList) &&
                permissionList?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row">{row.name}</TableCell>
                    {roleList?.map((role) => (
                      <TableCell align="center" scope="row" key={`${row.id}-${role.id}`}>
                        {role.permissions.includes(row.id) ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}
