import { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TimeFilter() {
  const [filter, setFilter] = useState("yearly");
  const { t } = useTranslation();

  const handleFilter = (event, newFilter) => {
    setFilter(newFilter);
  };

  return (
    <ToggleButtonGroup
      value={filter}
      exclusive
      onChange={handleFilter}
      size="small"
      sx={{ height: "1.5rem" }}
    >
      <ToggleButton value="daily">1D</ToggleButton>
      <ToggleButton value="weekly">{t("filter.week")}</ToggleButton>
      <ToggleButton value="monthly">1M</ToggleButton>
      <ToggleButton value="yearly">{t("filter.year")}</ToggleButton>
    </ToggleButtonGroup>
  );
}
