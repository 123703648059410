import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function getArlService() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `${URL_AUTH}/api/general/v1/info/arl/list`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}