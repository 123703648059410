import { useEffect, useState, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GetTransactionsListService } from "../../services/GetTransactionsListService";
import { TransactionsByAccountService } from "../../services/TransactionsByAccountService";
import { TransactionsByShopService } from "../../services/TransactionsByShopService";
import HeadersTransactions from "../../utils/TableHeaders/TransactionHeaders";
import { getToken } from "../../utils/GetToken";
import assignDataGridLanguage from "../../utils/AssignDatagridLanguage";
import { useSearchParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";

export default function TransactionList(props) {
  const { accountId, shopId, currentTab, tabHasChanged, setTabHasChanged } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [languageDatagrid, setLanguageDatagrid] = useState({});
  const [queryOptions, setQueryOptions] = useState({});
  const [query, setQuery] = useState(searchParams ? searchParams : {});
  const pageUrl = searchParams.get("page");
  const paginationUrl = searchParams.get("pagination");
  const [pageFromUrl, setPageFromUrl] = useState(
    pageUrl !== null && pageUrl !== 0 && !tabHasChanged
      ? parseInt(searchParams.get("page"), 10)
      : 0
  );
  const [paginationFromUrl, setPaginationFromUrl] = useState(
    paginationUrl !== null && paginationUrl !== 0 && !tabHasChanged
      ? parseInt(searchParams.get("pagination"), 10)
      : 100
  );
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });
  const currentURL = window.location.href;
  
  const currentLanguage = localStorage.getItem("i18nextLng");
  
  const handlePageChange = (params) => {
    setPageState((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
    setPaginationModel((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
  };

  useEffect(() => {
    setLanguageDatagrid(assignDataGridLanguage(currentLanguage));
    extractParamsAndAssignState();
  }, [currentLanguage]);

  const onFilterChange = useCallback(
    (filterModel) => {
      setQueryOptions({ filterModel: { ...filterModel } });
    },
    [query]
  );

  useEffect(() => {
    if (tabHasChanged) {
      searchParams.set("page", 0);
      searchParams.set("pagination", 100);
      setSearchParams(searchParams);
      setPaginationModel((old) => ({ ...old, page: 0, pageSize: 100 }));
      setPageState((old) => ({
        ...old,
        page: 0,
        pageSize: 100,
      }));
    }
  }, [tabHasChanged]);

  useEffect(() => {
    searchParams.set("page", `${pageState.page}`);
    searchParams.set("pagination", `${pageState.pageSize}`);
    setSearchParams(searchParams);
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setPageState((old) => ({
      ...old,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    }));
    getTransactions();
  }, [currentURL, paginationModel.page, paginationModel.pageSize]);

  // useEffect(() => {
  //   query !== undefined && getTransactions();
  // }, [type, pageState.page, pageState.pageSize, queryOptions, query]);

  const getTransactions = async () => {
    try {
      const token = await getToken();
      if (token) {
        setPageState((old) => ({ ...old, isLoading: true }));
        const currentPage = pageState.page + 1;
        const pagination = pageState.pageSize;
        const response = await getTransactionsByType(
          token,
          accountId,
          shopId,
          currentPage,
          query,
          pagination
        );
        const { transactions } = response;
        const { data, total } = transactions;
        if (Array.isArray(data) && data.length !== 0) {
          const mappedData = mapData(data);
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: mappedData,
            total: total,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: [],
            total: 0,
          }));
        }
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTabHasChanged(false);
    }
  };

  useEffect(() => {
    const _query = queryOptions?.filterModel?.items[0];
    // console.log("Query with filterModel", query);
    if (_query !== undefined) {
      const { field, value } = _query;
      if (field !== undefined && value !== undefined) {
        if (field === "id") {
          setQuery(`transactionId=${value}`);
          searchParams.set("page", 0);
          searchParams.set("pagination", 100);
          searchParams.set(`transactionId`, `${value}`);
          setSearchParams(searchParams);
        } else {
          setQuery(`${field}=${value}`);
          searchParams.set("page", 0);
          searchParams.set("pagination", 100);
          searchParams.set(`${field}`, `${value}`);
          setSearchParams(searchParams);
        }
      } else {
        setQuery({});
        searchParams.delete(`${field}`);
        searchParams.delete(`transactionId`);
        setSearchParams(searchParams);
      }
    }
  }, [queryOptions]);

  const getTransactionsByType = async (
    token,
    accountId,
    shopId,
    currentPage,
    query,
    pagination
  ) => {
    let response = null;
    if (shopId === undefined && accountId === undefined) {
      response = await GetTransactionsListService(
        token,
        currentPage,
        query,
        pagination
      );
    } else if (accountId !== undefined) {
      response = await TransactionsByAccountService(
        token,
        accountId,
        currentPage,
        query,
        pagination
      );
    } else if (shopId !== undefined) {
      response = await TransactionsByShopService(
        token,
        shopId,
        currentPage,
        query,
        pagination
      );
    }
    return response;
  };

  const mapData = (data) =>
    data.map((item) => {
      const formattedDate = dayjs(item?.epayco_response?.data?.fecha).format(
        "DD/MM/YYYY H:m:s A"
      );
      const mappedItem = {
        id: item.id,
        state: item.transaction_status,
        paymentDatetime: formattedDate,
        amount: item.epayco_response?.data?.valor,
        bank: item.epayco_response?.data?.banco,
        buyerFullname: item.buyerInfo?.fullName,
        buyerEmail: item.buyerInfo?.email,
        payerFullname: item.payerInfo?.fullName,
        payerEmail: item.payerInfo?.email,
      };
      return mappedItem;
    });

  const extractParamsAndAssignState = () => {
    const currentURL = window.location.href;
    const urlSearchParams = new URLSearchParams(currentURL.split("?")[1]);
    const paramsArray = [];

    urlSearchParams.forEach((value, key) => {
      paramsArray.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    });

    const state = paramsArray.join("&");
    setQuery(state);

    return state;
  };

  return (
    <>
      <DataGrid
        rows={pageState.data}
        columns={HeadersTransactions()}
        localeText={languageDatagrid}
        rowCount={pageState?.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page}
        filterMode="server"
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(params) => handlePageChange(params)}
        onFilterModelChange={onFilterChange}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        slots={{
          toolbar: GridToolbar,
        }}
        sx={{
          boxShadow: "0 1px 12px rgba(149, 157, 165, 0.2)",
          borderRadius: "25px",
          borderColor: "transparent",
          backgroundColor: "white",
          paddingX: "1.5rem",
          "& .MuiDataGrid-cell": {
            borderColor: "gray.light",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          "& .super-app-theme--header": {
            backgroundColor: "white",
          },
        }}
      />
    </>
  );
}
