import { Card, CardContent, Divider, Typography, Stack, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getToken } from "../../utils/GetToken";
import { GetTransactionsService } from "../../services/GetTransactionsService";
import { Pie } from "react-chartjs-2";
import PaidIcon from "@mui/icons-material/Paid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function ResumeCard() {
  const { t } = useTranslation();


  const getTransactions = async () => {
    const token = await getToken();
    const response = await GetTransactionsService(token);
    console.log(response.data);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["transactions"],
    queryFn: () => getTransactions(),
  });

  const transactions = response?.data || [];

  const calculateTotals = () => {
    if (!transactions) {
      return {
        madePercentage: 0,
        approvedPercentage: 0,
        rejectedPercentage: 0,
      };
    }

    const keys = Object.keys(transactions);
    const totalMade = keys.reduce(
      (total, key) => total + transactions[key].realizadas,
      0
    );
    const totalApproved = keys.reduce(
      (total, key) => total + transactions[key].aprobadas,
      0
    );
    const totalRejected = keys.reduce(
      (total, key) => total + transactions[key].rechazadas,
      0
    );

    const totalTransactions = totalApproved + totalRejected;
    const madePercentage = (totalMade / totalTransactions) * 100;
    const approvedPercentage = (totalApproved / totalTransactions) * 100;
    const rejectedPercentage = (totalRejected / totalTransactions) * 100;

    return {
      totalMade,
      totalApproved,
      totalRejected,
      madePercentage,
      approvedPercentage,
      rejectedPercentage,
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px", p: 2 }}>
      <CardContent>
        <Typography variant="h6">{t("resumecard.title")}</Typography>
        <Divider sx={{ my: "1rem" }} />
        <Typography color="text.primary">
          {t("resumecard.subtitle-3")}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center" my={1}>
          <PaidIcon
             sx={{ fontSize: 30, color: "primary.light", marginRight: "1rem" }}
          />
          <Typography
            variant="subtitle1"
            fontWeight={500}
            color="primary.light"
          >
            {isLoading ? "" : calculateTotals().totalMade} {t("made")}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          gap={1}
          mt="1rem"
        >
          <CheckCircleIcon color="success" sx={{ fontSize: "1.2rem" }} />
          <Typography variant="subtitle1" fontWeight={500} color="success.main">
            {isLoading ? "Loading..." : calculateTotals().totalApproved}{" "}
            {t("approved")}
          </Typography>
          <CancelIcon color="error" sx={{ fontSize: "1.2rem" }} />
          <Typography variant="subtitle1" fontWeight={500} color="error.main">
            {isLoading ? "Loading..." : calculateTotals().totalRejected}{" "}
            {t("rejected")}
          </Typography>
        </Stack>
        {isLoading ? (
            <CircularProgress size={24} />
          ) : (
        <Stack sx={{ height: "12rem", alignItems: "center", mt: "1rem" }}>
          <Pie
            data={{
              datasets: [
                {
                  data: [
                    calculateTotals().approvedPercentage,
                    calculateTotals().rejectedPercentage,
                  ],
                  backgroundColor: ["#FF6660", "#6BE58A"],
                  borderWidth: 2,
                },
              ],
            }}
            options={options}
          />
        </Stack>
        )}
      </CardContent>
    </Card>
  );
}
