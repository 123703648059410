import { createContext, useState, useContext } from 'react';

export const CreateCommerceFormContext = createContext();

export const useForm = () => useContext(CreateCommerceFormContext);

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    countryId: "",
    departmentId: "",
    address: "",
    cityId: "",
    postalCode: "",
    ciiuId_1: "",
    ciiuId_2: "",
    bankId: "",
    typeAccount: "",
    accountNumber: "",
    documentTypeId: "",
    documentNumber: "",
    name_shop: "",
    nit_shop: "",
    description: "",
    email_shop: "",
    web: "",
    confirmationUrl: "",
    responseUrl: "",
    payment_methods: [],
    shop_document_type: "",
    shop_document_number: "",
    shop_person_type: "",
    code: "",
    plan: "",
    step: ""
  });
  const [selectedValues, setSelectedValues] = useState({
    selectedCountry: null,
    selectedDepartment: null,
    selectedCity: null,
    selectedMainActivity: null,
    selectedSecondaryActivity: null,
    selectedPersonType: null,
    selectedBank: null,
    selectedPaymentMethod: null,
    selectedDocumentType: null,
    selectedDocumentTypeShop: null,
    selectedAccountType: null,
    selectedPaymentMethods: [],
    selectedPlan: null,
  })

  const saveFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  const saveSelectedValues = (data) => {
    setSelectedValues({ ...selectedValues, ...data });
  };

  return (
    <CreateCommerceFormContext.Provider value={{ formData, saveFormData, selectedValues, saveSelectedValues }}>
      {children}
    </CreateCommerceFormContext.Provider>
  );
};