import { createContext, useState, useContext } from "react";

export const PlanContext = createContext();

export const usePlan = () => useContext(PlanContext);

export const PlanProvider = ({ children }) => {
  const [isPlanDeleted, setIsPlanDeleted] = useState(false);
  const [createdPlanId, setCreatedPlanId] = useState(null);
  const [isPlanCreated, setIsPlanCreated] = useState(false);
  const [isPlanImageUpload, setIsPlanImageUpload] = useState(false);
  const [isDeletePlanMessage, setIsDeletePlanMessageOpen] = useState(false);
  const[isUploadImage, setIsUploadImage]=useState(false);
  const [isPlanUpdate, setIsPlanUpdate]=useState(false);

  const handlePlanDeleted = () => {
    setIsPlanDeleted(true);
  };

  const resetPlanStates = () => {
    setIsPlanDeleted(false);
    setIsPlanCreated(false);
    setIsDeletePlanMessageOpen(false);
    setIsPlanUpdate(false);

  };

  const handlePlanCreated = (idPlan) => {
    setCreatedPlanId(idPlan);
  };

  const handleIsPlanCreated = () => {
    setIsPlanCreated(!isPlanCreated);
  };
  const handleIsPlanImageUpload = () => {
    setIsPlanImageUpload(!isPlanImageUpload);
  };

  const handleUploadImage=(selectedImage)=>{
    setIsUploadImage(selectedImage);
  }

  const handleIsPlanUpdate=()=>{
    setIsPlanUpdate(!isPlanUpdate);
  }

  return (
    <PlanContext.Provider
      value={{
        isPlanDeleted,
        handlePlanDeleted,
        resetPlanStates,
        createdPlanId,
        handlePlanCreated,
        isPlanCreated,
        handleIsPlanCreated,
        isDeletePlanMessage,
        handleIsPlanImageUpload,
        isPlanImageUpload,
        handleUploadImage,
        isUploadImage,
        handleIsPlanUpdate,
        isPlanUpdate
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};
