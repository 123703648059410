import axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export const CreateTaxService = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${URL_MERCHANTS}/api/brain/v1/tax/create`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
