import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getToken } from "../../utils/GetToken";
import PaidIcon from "@mui/icons-material/Paid";
import { GetTransactionsStatsServices } from "../../services/Stats/GetTransactionsStatsService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function TransactionsStats() {
  const { t } = useTranslation();
  const [selectedDateTransaction, setSelectedDateTransaction] = useState("");

  const getTransactionsStats = async () => {
    const token = await getToken();
    const response = await GetTransactionsStatsServices(token);
    console.log(response);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["byType", "byDate"],
    queryFn: () => getTransactionsStats(),
  });

  const totalTransactions = response?.byType || [];
  const totolCoutTransactions = totalTransactions.reduce(
    (transaction, item) => transaction + item.count,
    0
  );

  const totalTransactionByDate = response?.byDate || [];

  const transactionCountByDate = () => {
    const totalPerMonth = {};

    totalTransactionByDate.forEach((transaction) => {
      const date = new Date(transaction.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const key = `${year}-${month.toString().padStart(2, "0")}`;

      if (!totalPerMonth[key]) {
        totalPerMonth[key] = 0;
      }
      totalPerMonth[key] += transaction.count;
    });
    return totalPerMonth;
  };

  const transactionCount = transactionCountByDate();

  const getCountForSelecteDate = () => {
    if (!selectedDateTransaction) {
      return 0;
    }
    return transactionCount[selectedDateTransaction] || 0;
  };

  const handleDateChange = (event) => {
    setSelectedDateTransaction(event.target.value);
  };

  const theme = useTheme();
  const labels = ["Lun", "Mar"];

  const getChartData = () => {
    const labels = Object.keys(transactionCount);
    const data = Object.values(transactionCount);

    return {
      labels: labels,
      datasets: [
        {
          label: t("transactionsCard.subtitle-3"),
          data: data,
          fill: false,
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.main,
          hoverBackgroundColor:theme.palette.primary.light,
          borderWidth: 3,
          
        },
      ],
    };
  };

  const options = {
    fill: true,
    responsive: true,
    scales: {
      y: {
        min: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <Card
      elevation={5}
      sx={{ borderRadius: "20px", minWidth: { sm: "50%", md: "100%" } }}
    >
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("transactionsCard.title")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography>{t("transactionsCard.subtitle-1")}</Typography>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Stack direction="row" spacing={1} alignItems="baseline" my={1}>
                <Typography variant="h5" fontWeight={600}>
                  {totolCoutTransactions}
                </Typography>
                <Typography variant="p">{t("registered-1")}</Typography>
              </Stack>
            )}
            <Divider sx={{ my: "1rem" }} />
            <Typography mt={"1rem"}>
              {t("transactionsCard.subtitle-2")}
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center" mt="1.5rem">
              <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                <InputLabel>{t("months")}</InputLabel>
                <Select
                  value={selectedDateTransaction}
                  onChange={handleDateChange}
                  displayEmpty
                  label={t("months")}
                >
                  {Object.entries(transactionCount).map(([date]) => (
                    <MenuItem key={date} value={date}>
                      {date}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                selectedDateTransaction && (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <PaidIcon color="success" />
                    <Typography variant="h5" color={"success.main"}>
                      {getCountForSelecteDate()}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack sx={{ height: "220px", alignItems: "center", mt: "1.5rem" }}>
            {isLoading ? (
            <CircularProgress size={24} />
          ) : (
              <Bar data={getChartData()} options={options} />
            )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
