import { LoginUserService } from "../services/LoginUserService";

export function LoginUser(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await LoginUserService(data);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
