import { useState, useEffect } from "react";
import { Content } from "../../utils/Content";
import { Typography, Grid, Box, Skeleton } from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { getToken } from "../../utils/GetToken";
import { useParams, Link, useLocation } from "react-router-dom";
import RolesByUser from "../roles-and-permissions-components/RolesByUser";
import { useTranslation } from "react-i18next";
import { GetUserDataById } from "../../services/GetUserDataByIdService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function RolesByUserLayout() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState({});
  const { isOpen } = useMenu();
  const { userId } = useParams();
  const { t } = useTranslation();

  const { data} = location.state || {};

  useEffect(() => {
    const getUser = async () => {
      const token = await getToken();
      try {
        const response = await GetUserDataById(token, userId);
        setUser(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingUser(false);
      }
    };
    getUser();
  }, []);

  return (
    <Content open={isOpen}>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={"/usersManagement?tab=0"}
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box paddingX={"20px"}>
            <Typography
              variant="h4"
              color={"primary"}
              textAlign={"flex-start"}
              sx={{ textTransform: "uppercase" }}
              fontWeight={800}
            >
              {t("rolesByUser.title")}
            </Typography>
          </Box>
          <Box my={2} paddingX={"20px"}>
            {!loadingUser ? (
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("rolesByUser.description", { user: `${data?.fullName}` })}
              </Typography>
            ) : (
              <Skeleton></Skeleton>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {/* <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<SupervisedUserCircleIcon sx={{ color: "white" }} />}
                onClick={handleButtonNewRoleClick}
              >
                {t("roles.createNewRole.button")}
              </Button>
            </Box> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Box
            mt={2}
            sx={{
              width: "100%",
            }}
            paddingX={"20px"}
          >
            <RolesByUser />
          </Box>
        </Grid>
      </Grid>
    </Content>
  );
}
