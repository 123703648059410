import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function StatusTranslator(state_code) {

  const { t } = useTranslation();

  const getStatusFromCode = (statusCode) => {
    const stateMap = {
      ACPT: "accepted",
      PEND: "pending",
      REJT: "rejected",
      NEW: "accountState-new",
      SUSP: "accountState-suspended",
      APPR: "accountState-approved",
      REGN: "in-register",
    };

    const stateKey = stateMap[statusCode];
    if (!stateKey) {
      return "Unknown";
    }
    return t(stateKey);
  };

  return getStatusFromCode(state_code);
}
