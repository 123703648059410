import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Card,
  CardContent,
  Grid,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CreateNewArlService } from "../../services/CreateNewArlService";
import { UpdateArlService } from "../../services/UpdateArlService";

export default function ArlManagementLayout() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [formValues, setFormValues] = useState({
    name: "",
  });
  const [isArlCreated, setIsArlCreated] = useState(false);

  const { isOpen } = useMenu();
  const { mode, data, userId } = location.state || {};
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(data)
    if(data){
      setFormValues(data);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getToken();
    console.log("This is data to submit:", formValues);
    if(mode !== "edit"){
      try {
        setLoading(true);
        const response = await CreateNewArlService(formValues, token);
        console.log(response.status);
        setIsArlCreated(true);
      } catch (error) {
        setError(true);
        setErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else{
      try {
        setLoading(true);
        const response = await UpdateArlService(formValues, token, userId);
        console.log(response.status);
        setIsArlCreated(true);
      } catch (error) {
        setError(true);
        setErrorMessage(error);
      } finally {
        setLoading(false);
      
    }
  }
    console.log("Submit");
  };



  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsArlCreated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (isArlCreated) {
      const redirectTimer = setTimeout(() => {
        navigate(`/listManagement?tab=0`)
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isArlCreated]);

  return (
    <Content open={isOpen} className="content">
      <Snackbar
        open={isArlCreated}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("createNewUser.success.message")}</Alert>
      </Snackbar>
      {/* Error Response Alert*/}
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={"/listManagement?tab=0"} 
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <Stack>
        <Box my={2}>
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"center"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {mode !== "edit" ? t("createNewArl.button"): 'ARL'}
          </Typography>
        </Box>
        <Typography variant="subtitle-1" sx={{ textAlign: "center" }}>
          {t("management.description")}
        </Typography>
        <Stack
          mt={{ xs: 0, md: 2 }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            elevation={4}
            sx={{
              p: "1rem",
              mt: { xs: "2rem", md: "0" },
              width: "800px",
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid >
                    <TextField
                      name="name"
                      label={t("arlAccount.form.name")}
                      size="small"
                      value={formValues.name || ""}
                      onChange={handleInputChange}
                      required
                      fullWidth
                    />
                </Grid>
                      
                <Grid container mt={4} sx={{ textAlign: "center" }} gap={2}>
                  <Grid item xs>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={
                          loading && (
                            <CircularProgress
                              size="1.5rem"
                              sx={{ color: "white" }}
                            />
                          )
                        }
                      >
                        {mode !== "edit"
                          ? `${t("save.button")}`
                          : `${t("update.button")}`}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </Content>
  );
}
