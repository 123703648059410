import axios from "axios";

const AUTH_DICTIONARIES = import.meta.env.VITE_AUTH_DICTIONARIES;

export function GetAllDepartmentService(token, page, query, pagination) {
  return new Promise(async (resolve, reject) => {
    try { 
      console.log("Query from datagrid", query);
      let url;
      if (query === "") {
        console.log("URL without params inside");
        url = `${AUTH_DICTIONARIES}/api/locations/v1/departments?page=${page}&pagination=${pagination}`;
      } else {
        console.log("URL with params inside", query);
        url = `${AUTH_DICTIONARIES}/api/locations/v1/departments?${query}&page=${page}&pagination=${pagination}`;
      }
      const response = await axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        }
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
