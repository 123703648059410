import React, { useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  Select,
  MenuItem,
  CircularProgress
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { GetAccompanimentStats } from "../../services/Stats/GetAccompanimentStats";
import { getToken } from "../../utils/GetToken";
import AddModeratorIcon from "@mui/icons-material/AddModerator";

export default function AccompanimentStats() {
  const [selectedMonth, setSelectedMonth] = useState("");
  const { t } = useTranslation();


  const getAccompanimentStats = async () => {
    const token =  await getToken();
    const response = await GetAccompanimentStats(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["accompanimentStats"],
    queryFn: () => getAccompanimentStats(),
  });

  const accompanimentStats = response?.data || [];
  const totalAccompaniment = accompanimentStats.reduce(
    (total, accompaniment) => total + accompaniment.total,
    0
  );

  const renderMonthOptions = () => {
    if (!response) return null;

    const months = response.data.reduce((acc, curr) => {
      const monthYear = `${curr.month}/${curr.year}`;
      if (!acc.includes(monthYear)) {
        acc.push(monthYear);
      }
      return acc;
    }, []);

    return months.map((month, index) => (
      <MenuItem key={index} value={month}>
        {month}
      </MenuItem>
    ));
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredAccompanimentStats = accompanimentStats.filter(
    (accompaniment) => {
      const monthYear = `${accompaniment.month}/${accompaniment.year}`;
      return monthYear === selectedMonth;
    }
  );

  const totalAccompanimentsForMonth = filteredAccompanimentStats.reduce(
    (total, accompaniment) => total + accompaniment.total,
    0
  );

  const data = {
    labels: [t("accountsCard.subtitle-8")],
    datasets: [
      {
        data: [totalAccompanimentsForMonth],
        backgroundColor: "#6BE58A",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,

      },
    },
    indexAxis: "x",
    barThickness: 55,
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px", p: 2 }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("resumecard.title-2")}</Typography>
          {/* <TimeFilter /> */}
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountsCard.subtitle-8")}</Typography>
        <Stack direction="row" spacing={1} alignItems="center" my={1}>
          <AddModeratorIcon
            sx={{ fontSize: 30, color: "success.main", marginRight: "1rem" }}
          />
          <Typography variant="h5" fontWeight={600} sx={{ mt: "2rem" }}>
            {isLoading || isError ? "" : totalAccompaniment}
          </Typography>
          <Typography variant="body1">{t("registered")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountCard.subtitle-9")}</Typography>

        <Stack sx={{ height: "3rem", alignItems: "center", mt: "1rem" }}>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            displayEmpty
          >
            <MenuItem value="">{t("cardMonth.options")}</MenuItem>
            {renderMonthOptions()}
          </Select>
        </Stack>

        <Stack sx={{ height: "12rem", alignItems: "center", mt: "2rem" }}>
        {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Bar
              data={data}
              options={{
                ...options,
                animation: {
                  tension: {
                    duration: 1000,
                    easing: "linear",
                    from: 1,
                    to: 0,
                    loop: true,
                  },
                },
              }}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
