import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  Skeleton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Box,
  ButtonBase,
  Avatar,
  Modal,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { useParams } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import dayjs from "dayjs";
import { GetTransactionsDetailsService } from "../../services/GetTransactionsDetailsService";
import { parseTransactionStatus } from "../../utils/formatUtils";

export default function TransactionsDetails() {
  const [loading, setLoading] = useState(true);
  const [elementDetail, setElementDetail] = useState({});
  const { t } = useTranslation();
  const { elementId } = useParams();
  
  useEffect(() => {
    const elementDetails = async () => {
      try {
        const token = await getToken();
        if (token) {
          const data = await GetTransactionsDetailsService(token, elementId);
          const { transaction, user_email } = data;
          const epaycoData = transaction?.epayco_response?.original?.data ?? {};
          console.log(epaycoData);
          const transactionDate = !!epaycoData?.fecha ? dayjs(data.createdAt).format("DD/MM/YYYY H:m:s A") : "";
          const element = {
            id: transaction.id,
            factura: epaycoData?.factura,
            email: user_email,
            type: transaction?.transaction_type,
            state: transaction?.transaction_status,
            title: data?.type?.name,
            duration: data?.duration,
            contacts: data?.contacts,
            description: epaycoData?.descripcion,
            createdAt: transactionDate,
            city: epaycoData?.ciudad,
            valor: epaycoData?.valor,
            moneda: epaycoData?.moneda,
          };
          setElementDetail(element);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    elementDetails();
  }, []);

  
  return (
    <>
      {loading ? (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
         <CircularProgress />
        </div>
      ) : (
        <Card
          loading={loading}
          elevation={5}
          sx={{
            borderRadius: "20px",
            mx: "1rem",
            height: "auto",
            p: { xs: "0.5rem", sm: "2rem" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        
                        <Typography variant="h6" color="primary.light">
                          {t("transactions.details.title")}
                        </Typography>
                      </Grid>
                      
                    </Grid>

                    <Divider light sx={{ marginBlock: "1rem" }} />

                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AssignmentIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {'ID' + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.id}
                          </Typography>
                        </Stack>
                      </Stack>
                      
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AssignmentIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {'Número de factura' + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.factura}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" alignItems="center" spacing={1}>
                        <EventAvailableRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {'Fecha'+ ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.createdAt}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AppsRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("accompaniment.status") + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest" className="capitalize">
                            {parseTransactionStatus(elementDetail?.state)}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <EventRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {'Tipo de transacción' + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.type}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <EventRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {'Moneda' + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {elementDetail?.moneda}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <EventRoundedIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {'Valor' + ':'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {'$'+elementDetail?.valor}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                        >
                          <DescriptionIcon
                            fontSize="medium"
                            sx={{ color: "primary.light" }}
                          />
                          <Typography variant="subtitle1">
                            {t("accompaniment.description") + ':'}
                          </Typography>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1" color="grays.darkest">
                              {elementDetail?.description}
                            </Typography>
                          </Stack>
                        </Stack>

                    </Stack>
                    
                  </CardContent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Card>
      )}
    </>
  );
}
