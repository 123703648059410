import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Typography,
  Box,
  Divider,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import InfoIcon from "@mui/icons-material/Info";
import { Colors } from "../../config/themeConfig.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteCompaniesService } from "../../services/Companies/DeleteCompaniesService.js";
import { useCompany } from "../../context/CompanyContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDeleteCompanies({
  handleClose,
  isOpen,
  companyId,
  data,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCompanyDelete, setIsCompanyDelete] = useState(false);
  const { handleCompanyDeleted } = useCompany();

  useEffect(() => {
    if (isCompanyDelete) {
      const redirectTimer = setTimeout(() => {
        navigate(`/companies?tab=0`), { replace: true };
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isCompanyDelete, navigate]);

  const handleDeleteCompany = async () => {
    try {
      const token = await getToken();
      await DeleteCompaniesService(token, companyId);
      setIsCompanyDelete(true);
      handleCompanyDeleted();
    } catch (error) {
      console.error("Error al eliminar empresa:", error);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogTitle sx={{ p: "1rem" }}>
          <Stack alignItems={"center"} justifyContent="center">
            <InfoIcon sx={{ color: Colors.primary }} />
            <Typography variant="h6" color="warning">
              {t("deleteAccount.confirmation.title")}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: "2rem" }}>
          <Grid
            container
            display="flex"
            justifyContent="center"
            textAlign="center"
          >
            <Grid item xs={12} my={1}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {t("deleteAccount.confirmation.content")}
            </Grid>
            <Grid mt={2} item xs={12}>
              <Typography>{data?.fullName}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: "1.5rem", gap: "0.5rem" }}>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            {t("cancel.button")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleDeleteCompany}
          >
            {t("delete.button")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
