import { createContext, useState, useContext } from "react";

export const CreateCompaniesContext = createContext();

export const useCompany = () => useContext(CreateCompaniesContext);

export const CompanyProvider = ({ children }) => {
  const [isCompanyDeleted, setIsCompanyDeleted] = useState(false);
  const [isCompanyCreated, setIsCompanyCreated] = useState(false);
  const [isCompanyUpdated, setIsCompanyUpdated] = useState(false);
  const [createdCompanyId, setCreatedCompanyId] = useState(null);
  const [iscompanyLogoCreated, setIsCompanyLogoCreated] = useState(false);


  const handleCompanyDeleted = () => {
    setIsCompanyDeleted(!isCompanyDeleted);
  };

  const handleCompaniesCreated = (idCompany) => {
    setCreatedCompanyId(idCompany);
  };

  const handleCompanyCreated = () => {
    setIsCompanyCreated(!isCompanyCreated);
  };

  const handleCompanyUpdated = () => {
    setIsCompanyUpdated(!isCompanyUpdated);
  }

  const handleIsCompanyLogoCreated = () => {
    setIsCompanyLogoCreated(!iscompanyLogoCreated);
  }

  const resetCompanyState = () => {
    setIsCompanyCreated(false);
    setIsCompanyUpdated(false);
    setIsCompanyDeleted(false);
    setIsCompanyLogoCreated(false);
  
  };

  return (
    <CreateCompaniesContext.Provider
      value={{
        handleCompanyCreated,
        isCompanyCreated,
        resetCompanyState,
        handleCompanyUpdated,
        isCompanyUpdated,
        handleCompaniesCreated,
        createdCompanyId,
        handleCompanyDeleted,
        isCompanyDeleted,
        handleIsCompanyLogoCreated,
        iscompanyLogoCreated,
      }}
    >
      {children}
    </CreateCompaniesContext.Provider>
  );
};
