import { useState, useEffect } from "react";
import { Chip, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { StatusTransactionsTranslator } from "../TableHelper";

const RedirectToTransactionDetails = (id) => {
  const [click, setClick] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    setClick(true);
  };

  useEffect(() => {
    if (click) {
      navigate(`/transactions/detail/${id.transactionId}`, {
        state: { transactionId: id },
      });
    }
  }, [click]);

  return (
    <Tooltip title={t("seeMore.tooltip")}>
      <IconButton onClick={handleClick} size="small">
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

const HeadersTransactions = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("i18nextLng");

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 60,
      renderCell: (params) => (
        <RedirectToTransactionDetails transactionId={params.row.id} />
      ),
    },
    {
      field: "id",
      headerName: t("transactionList.headers-1"),
      width: 350,
    },
    {
      field: "state",
      headerName: t("transactionList.headers-2"),
      width: 140,
      renderCell: (params) => {
        const value = params.value;
        const translatedValue = StatusTransactionsTranslator(value, currentLanguage);
        return (
          <Chip
            label={translatedValue}
            variant="outlined"
            color={
              value === "ACPT"
                ? "success"
                : value === "REJT"
                ? "error"
                : "primary"
            }
          />
        );
      },
    },
    {
      field: "paymentDatetime",
      headerName: t("transactionList.headers-3"),
      width: 180,
    },
    {
      field: "amount",
      headerName: t("transactionList.headers-4"),
      width: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `$ ${params.value.toLocaleString()}`;
      },
    },
    {
      field: "bank",
      headerName: t("transactionList.headers-5"),
      sortable: false,
      width: 180,
    },

    // {
    //   field: "buyerFullname",
    //   headerName: t("transactionList.headers-6"),
    //   width: 150,
    // },
    // {
    //   field: "buyerEmail",
    //   headerName: t("transactionList.headers-7"),
    //   width: 250,
    // },
    // {
    //   field: "payerFullname",
    //   headerName: t("transactionList.headers-8"),
    //   width: 150,
    // },
    // {
    //   field: "payerEmail",
    //   headerName: t("transactionList.headers-9"),
    //   width: 250,
    // },
  ];
  return columns;
};

export default HeadersTransactions;
