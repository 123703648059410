import {
  Card,
  CardContent,
  Typography,
  Stack,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getToken } from "../../utils/GetToken";
import { GetPlansStatsServices } from "../../services/Stats/GetPlansStatsService";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Bar } from "react-chartjs-2";
import { useTheme } from "@mui/material/styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PlansStats() {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState("");
  const theme = useTheme();

  const getPlanStats = async () => {
    const token = await getToken();
    const response = await GetPlansStatsServices(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["byType", "byDate"],
    queryFn: () => getPlanStats(),
  });

  const totalPlans = response?.byType || [];
  const totalPlanCount = totalPlans.reduce(
    (total, plan) => total + plan.count,
    0
  );

  const totalPlansByDate = response?.byDate || [];

  const plansCountByMonth = () => {
    const totalPerMonth = {};

    totalPlansByDate.forEach((plans) => {
      const date = new Date(plans.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const key = `${year}-${month.toString().padStart(2, "0")}`;

      if (!totalPerMonth[key]) {
        totalPerMonth[key] = 0;
      }
      totalPerMonth[key] += plans.count;
    });
    return totalPerMonth;
  };

  const planCount = plansCountByMonth();

  const getCountForSelecteDate = () => {
    if (!selectedDate) {
      return 0;
    }
    return planCount[selectedDate] || 0;
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const data = {
    labels: Object.keys(planCount),
    datasets: [
      {
        label: t("plansChart.title"),
        data: Object.values(planCount),
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("plansCard.title-1")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        {/* <TimeFilter />
         */}
        <Typography>{t("plansCard.subtitle-1")}</Typography>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Stack direction="row" spacing={1} alignItems="baseline" my={1}>
            <Typography variant="h5" fontWeight={600}>
              {totalPlanCount}
            </Typography>
            <Typography variant="p">{t("registered")}</Typography>
          </Stack>
        )}
        <Divider sx={{ my: "1rem" }} />
        <Typography mt={"1rem"}>{t("plansCard.subtitle-2")}</Typography>
        <Stack direction="row" spacing={2} alignItems="center" mt="1.5rem">
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel>{t("months")}</InputLabel>
            <Select
              value={selectedDate}
              onChange={handleDateChange}
              displayEmpty
              label={t("months")}
            >
              {Object.entries(planCount).map(([date]) => (
                <MenuItem key={date} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            selectedDate && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AssignmentIcon color="success" />
                <Typography variant="h5" color={"success.main"}>
                  {getCountForSelecteDate()}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
        <Stack sx={{ mt: 2 }}>
          <Typography variant="h6">{t("plansChart.title")}</Typography>
          <Bar data={data} options={options} />
        </Stack>
      </CardContent>
    </Card>
  );
}
