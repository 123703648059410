import { createContext, useState, useContext } from 'react';

export const CreateAccountFormContext = createContext();

export const useForm = () => useContext(CreateAccountFormContext);

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    name_1: "",
    name_2: null,
    surname_1: "",
    surname_2: null,
    email: "",
    movil: "",
    country_id: "",
    document_type_id: "",
    document_number: "",
    birthday: "",
    password: "",
    password_confirmation: "",
  });

  const saveFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  return (
    <CreateAccountFormContext.Provider value={{ formData, saveFormData }}>
      {children}
    </CreateAccountFormContext.Provider>
  );
};