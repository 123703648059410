import Axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export function GetDocumentationTypesService(token, shopId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`${URL_MERCHANTS}/api/brain/v1/documenttype/list/${shopId}`, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("DocTypes List:", response.data);
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}