import Axios from "axios";

const URL_TRANSACTIONS = import.meta.env.VITE_TRANSACTIONS_URL;

export function TransactionByIdService(token, transactionId) {
  return new Promise(async (resolve, reject) => {
    try {
      const transactionList = await Axios.get(
        `${URL_TRANSACTIONS}/api/sandbox/v1/brain/transaction/info/${transactionId}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Transaction Summary:", transactionList);
      resolve(transactionList.data.data);
    } catch (error) {
      console.error("Error:",error);
      reject(error);
    }
  });
}