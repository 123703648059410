import { useState } from "react";
import { useMediaQuery, Tabs, Tab, Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import GeneralStatistics from "./GeneralStatisticsLayout";
import AgentsAccountsLayout from "./AgentsAccountsLayout";
import TransactionsSummaryLayout from "./TransactionsSummaryLayout";
import { useTranslation } from "react-i18next";
import DocumentsReportLayout from "./DocumentsReportLayout";
import UserAccountsLayout from "./UserAccountsLayout";
import BusinessStatsLayout from "./BusinessStatsLayout";
import PlanStatsLayout from "./PlanStatsLayout";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const isHidden = value !== index;

  return (
    <div
      role="tabpanel"
      hidden={isHidden} 
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box sx={{ p: 3 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function TabsHomeLayout() {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      {isMobile ? (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pl: "1rem" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={t("general")} {...a11yProps(0)} />
              <Tab label={t("merchants")} {...a11yProps(1)} />
              <Tab label={t("accounts")} {...a11yProps(2)} />
              <Tab label={t("bussiness")} {...a11yProps(3)} />
              <Tab label={t("plans")} {...a11yProps(4)} />
              <Tab label={t("transactions")} {...a11yProps(5)} />
              {/* <Tab label={"docs"} {...a11yProps(3)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GeneralStatistics />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserAccountsLayout  />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AgentsAccountsLayout />
          </TabPanel>
          <TabPanel value={value} index={3}>
          <BusinessStatsLayout />
          </TabPanel>
          <TabPanel value={value} index={4}>
          <PlanStatsLayout />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <TransactionsSummaryLayout />
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <DocumentsReportLayout />
          </TabPanel> */}
        </Box>
      ) : (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider", pl: "1rem" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={t("general")} wrapped {...a11yProps(0)} />
              <Tab label={t("merchants")} wrapped {...a11yProps(2)} />
              <Tab label={t("accounts")} wrapped {...a11yProps(1)} />
              <Tab label={t("bussiness")} wrapped {...a11yProps(3)} />
              <Tab label={t("plans")} wrapped {...a11yProps(4)} />
              <Tab label={t("transactions")} wrapped {...a11yProps(5)} />
              {/* <Tab label={"docs"} wrapped {...a11yProps(3)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GeneralStatistics />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserAccountsLayout />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AgentsAccountsLayout />
          </TabPanel>
          <TabPanel value={value} index={3}>
          <BusinessStatsLayout />
          </TabPanel>
          <TabPanel value={value} index={4}>
          <PlanStatsLayout />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <TransactionsSummaryLayout />
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <DocumentsReportLayout />
          </TabPanel> */}
        </Box>
      )}
    </>
  );
}
