import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import MenuIcon from "@mui/icons-material/Menu";
import ReviewSensitiveChangesLayout from "../../components/layout/ReviewSensitiveChangesLayout";
import { SelectSensitiveChangeService } from "../../services/SensitiveData/SelectSensitiveChangeService";
import { getToken } from "../GetToken";
import { useParams } from "react-router-dom";
import StatusTranslator from "../StatusTranslator";
import { useTranslation } from "react-i18next";

const HandleSensitiveChangesActions = ({ changeId }) => {
  const [clickReview, setClickReview] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickReview = () => {
    handleClose();
    selectSensitiveChange();
    setClickReview(true);
  };

  const selectSensitiveChange = async () => {
    const data = {
      item: changeId,
    };
    try {
      const token = await getToken();
      if (token) {
        const response = await SelectSensitiveChangeService(token, data, changeId);
        console.log("Select documents response:", response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 170,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClickReview}>
          <ListItemIcon>
            <PreviewIcon />
          </ListItemIcon>
          <ListItemText>{t("sensitiveChanges.menu.action-1")}</ListItemText>
        </MenuItem>
      </Menu>
      {clickReview && (
        <ReviewSensitiveChangesLayout
          changeId={changeId}
          clickReview={clickReview}
          setClickReview={setClickReview}
        />
      )}
    </>
  );
};

const SensitiveChangesColumns = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("i18nextLng");

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 80,
      renderCell: (params) => (
        <HandleSensitiveChangesActions changeId={params.row.id} />
      ),
    },
    {
      field: "id",
      headerName: t("sensitiveChangesList.header-1"),
      width: 350,
    },
    {
      field: "state",
      headerName: t("sensitiveChangesList.header-2"),
      sortable: false,
      width: 180,
      renderCell: (params) => {
        const value = params.value;
        return (
          <Chip
            label={value}
            variant="outlined"
            color={
              value === "Aprobado"
                ? "success"
                : value === "Rechazado"
                ? "error"
                : value === "NEW"
                ? "info"
                : value === "Pendiente"
                ? "primary"
                : "secondary"
            }
          />
        );
      },
    },
    {
      field: "approverName",
      headerName: t("sensitiveChangesList.header-3"),
      width: 200,
    },
    {
      field: "approverComment",
      headerName: t("sensitiveChangesList.header-4"),
      width: 250,
      renderCell: (params) => {
        const { field, value } = params;
        if (field === "description") {
          return <div style={{ whiteSpace: "pre-line" }}>{value}</div>;
        }
        return <div>{value}</div>;
      },
    },
    {
      field: "approvedAt",
      headerName: t("sensitiveChangesList.header-5"),
      sortable: false,
      width: 250,
    },
    {
      field: "rejectedAt",
      headerName: t("sensitiveChangesList.header-6"),
      sortable: false,
      width: 250,
    },
    {
      field: "comment",
      headerName: t("sensitiveChangesList.header-7"),
      width: 250,
      renderCell: (params) => {
        const { field, value } = params;

        return <div style={{ whiteSpace: "pre-line" }}>{value}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: t("sensitiveChangesList.header-8"),
      sortable: false,
      width: 250,
    },
  ];

  return columns;
};

export default SensitiveChangesColumns;
