import axios from "axios";

const DICTIONARIES_URL = import.meta.env.VITE_DICTIONARIES_URL;

export const BankListService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const bankList = await axios.get(
        `${DICTIONARIES_URL}/v1/bank/list`
      );
      console.log(bankList);
      resolve(bankList.data.data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};