import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Card,
  CardContent,
  Grid,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import dayjs from "dayjs";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { CreateNewUserService } from "../../services/CreateNewUserService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UpdateAdminService } from "../../services/UpdateAdminService";

export default function CreateNewUserLayout() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [formValues, setFormValues] = useState({
    forename: "",
    surname: "",
    secondSurname: "",
    secondForename: "",
    email: "",
    password_confirmation: "",
    password: "",
  });
  const [isUserCreated, setIsUserCreated] = useState(false);

  const { isOpen } = useMenu();
  const { mode, data, userId } = location.state || {};
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(data)
    if(!!data){
      setFormValues(data);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getToken();
    console.log("This is data to submit:", formValues);
    if(mode !== "edit"){
      try {
        setLoading(true);
        const response = await CreateNewUserService(formValues, token);
        console.log(response.status);
        setIsUserCreated(true);
      } catch (error) {
        setError(true);
        setErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else{
      try {
        setLoading(true);
        const response = await UpdateAdminService(formValues, token, userId);
        console.log(response.status);
        setIsUserCreated(true);
      } catch (error) {
        setError(true);
        setErrorMessage(error);
      } finally {
        setLoading(false);
      
    }
  }
    console.log("Submit");
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    console.log("Formatted Day", formattedDate);
    setFormValues({ ...formValues, date_of_birth: formattedDate });
  };

  const handleRoleChange = (event, value) => {
    const selectedRole = value?.id;
    setFormValues({ ...formValues, role: selectedRole });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsUserCreated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (isUserCreated) {
      const redirectTimer = setTimeout(() => {
        navigate(`/usersManagement?tab=0`)
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isUserCreated]);

  return (
    <Content open={isOpen} className="content">
      <Snackbar
        open={isUserCreated}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("createNewUser.success.message")}</Alert>
      </Snackbar>
      {/* Error Response Alert*/}
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={"/usersManagement?tab=0"} 
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <Stack>
        <Box my={2}>
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"center"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("createNewSuperadmin.button")}
          </Typography>
        </Box>
        <Typography variant="subtitle-1" sx={{ textAlign: "center" }}>
          {t("createNewSuperadmin.description")}
        </Typography>
        <Stack
          mt={{ xs: 0, md: 2 }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            elevation={4}
            sx={{
              p: "1rem",
              mt: { xs: "2rem", md: "0" },
              maxWidth: "800px",
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      name="forename"
                      label={t("createAccount.form.forename")}
                      size="small"
                      value={formValues.forename || ""}
                      onChange={handleInputChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="secondForename"
                      label={t("createAccount.form.secondForename")}
                      size="small"
                      value={formValues.secondForename || ""}
                      onChange={handleInputChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="surname"
                      label={t("createAccount.form.surname")}
                      size="small"
                      value={formValues.surname || ""}
                      onChange={handleInputChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="secondSurname"
                      label={t("createAccount.form.secondSurname")}
                      size="small"
                      value={formValues.secondSurname || ""}
                      onChange={handleInputChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="email"
                      label={"Email"}
                      size="small"
                      type="email"
                      value={formValues.email || ""}
                      onChange={handleInputChange}
                      error={
                        !isEmailValid(
                          formValues.email !== ""
                            ? formValues.email
                            : "example@email.com"
                        )
                      }
                      helperText={
                        !isEmailValid(
                          formValues.email !== ""
                            ? formValues.email
                            : "example@email.com"
                        ) && t("createAccount.form.email.helper")
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="password"
                      label="Password"
                      size="small"
                      type="password"
                      value={formValues.password || ""}
                      onChange={handleInputChange}
                      required={mode !== "edit"}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="password_confirmation"
                      label="Confirm Password"
                      size="small"
                      type="password"
                      value={formValues.password_confirmation || ""}
                      onChange={handleInputChange}
                      required={mode !== "edit"}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                      
                <Grid container mt={2} sx={{ textAlign: "center" }} gap={2}>
                  <Grid item xs>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={
                          loading && (
                            <CircularProgress
                              size="1.5rem"
                              sx={{ color: "white" }}
                            />
                          )
                        }
                      >
                        {mode !== "edit"
                          ? `${t("save.button")}`
                          : `${t("update.button")}`}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </Content>
  );
}
