import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TimeFilter from "../common/TimeFilter.jsx";
import { useQuery } from "@tanstack/react-query";
import { GetUserStatsServices } from "../../services/Stats/GetUserStatsServices.js";
import { getToken } from "../../utils/GetToken.js";
import { useState } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function UserStatsByCity() {
  const { t } = useTranslation();

  const [selectedCity, setSelectedCity] = useState("");

  const getCityStats = async () => {
    const token = await getToken();
    const response = await GetUserStatsServices(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["cityStats"],
    queryFn: () => getCityStats(),
  });

  const cityStats = response?.data?.cityStats || {};
  const totalCity = Object.values(cityStats);

  const countActiveCities = totalCity.reduce((sum, city) => {
    if (city.state.en === "active" || city.state.es === "activo") {
      return sum + city.count;
    }
    return sum;
  }, 0);

  const countRegisteredCities = totalCity.reduce((sum, city) => {
    if (city.state.en === "registration" || city.state.es === "registro") {
      return sum + city.count;
    }
    return sum;
  }, 0);

  const handleCityTypeChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const getCountForSelectedCityType = () => {
    if (selectedCity === "active") {
      return countActiveCities;
    } else if (selectedCity === "registered") {
      return countRegisteredCities;
    }
    return 0;
  };

  const data = {
    labels: [t("city.active"), t("city.registered")],
    datasets: [
      {
        label: t("state.title"),
        data: [countActiveCities, countRegisteredCities],
        backgroundColor: ["#FF6660", "#6BE58A", "#1D417A"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 15,
        },
      },
    },
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("accountCard.title-3")}</Typography>
          {/* <TimeFilter /> */}
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountsCard.subtitle-11")}</Typography>
        <Stack direction="row" spacing={2} alignItems="center" mt="1rem">
          <FormControl sx={{ m: 1, minWidth: 120, mt: "1rem" }} size="small">
            <InputLabel>{t("state.title")}</InputLabel>
            <Select
              value={selectedCity}
              onChange={handleCityTypeChange}
              displayEmpty
              label={t("city.title")}
            >
              <MenuItem value="active">{t("city.active")}</MenuItem>
              <MenuItem value="registered">{t("city.registered")}</MenuItem>
            </Select>
          </FormControl>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            selectedCity && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <LocationCityIcon color="success" />
                <Typography variant="h5" color={"success.main"}>
                  {getCountForSelectedCityType()}
                </Typography>
              </Stack>
            )
          )}
        </Stack>

        <Stack sx={{ height: "12rem", alignItems: "center", mt: "2rem" }}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Bar data={data} options={options} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
