import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/themeConfig";
import { AuthProvider } from "./context/AuthContext";
import { MenuProvider } from "./context/MenuOpenContext.jsx";
import "./i18n";
import { AxiosInterceptor } from "./interceptors/AxiosInterceptor.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

AxiosInterceptor();

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MenuProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </MenuProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>
);
