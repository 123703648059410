import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { Box, Tab, Tabs, Container } from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import RecentCalls from "../calls-components/RecentCalls";
import ContactListDisplay from "../calls-components/ContactListDisplay";
import { usePhone } from "../../hooks/calls/usePhone";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ flex: 1 }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CallsLayout() {
  const [value, setValue] = useState(0);
  const { isOpen } = useMenu();

  usePhone();

  const handleChange = (_, newValue) => {
    setValue(parseInt(newValue));
  };

  return (
    <Content open={isOpen}>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("calls.tabTitle-1")} {...a11yProps(0)}></Tab>
          <Tab label={t("calls.tabTitle-2")} {...a11yProps(1)}></Tab>
        </Tabs>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Box flex="2" sx={{ display: "flex", flex: 1 }}>
          <TabPanel value={value} index={0} display="flex">
            <ContactListDisplay />
          </TabPanel>
          <TabPanel value={value} index={1} display="flex">
            <RecentCalls />
          </TabPanel>
        </Box>
      </Box>
    </Content>
  );
}
