import { Content } from "../../utils/Content";
import { useMenu } from "../../context/MenuOpenContext";
import RoleDetails from "../roles-and-permissions-components/RoleDetails";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

export default function RoleDetailsLayout() {
  const { isOpen } = useMenu();
  const { t } = useTranslation();

  return (
    <Content open={isOpen}>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={"/usersManagement?tab=3"} 
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <RoleDetails />
    </Content>
  );
}
