import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetBusinessStatsServices } from "../../services/Stats/GetBusinessStatsServices.js";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getToken } from "../../utils/GetToken";
import { useTheme } from "@mui/material/styles";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import MovingIcon from "@mui/icons-material/Moving";
import { Line } from "react-chartjs-2";

export default function BusinessStatsSummary() {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState("");
  const theme = useTheme();

  const getBusinessStats = async () => {
    const token = await getToken();
    const response = await GetBusinessStatsServices(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["byCategory", "byDate"],
    queryFn: () => getBusinessStats(),
  });

  const totalBusiness = response?.byCategory || [];
  const totalCount = totalBusiness.reduce(
    (total, business) => total + business.count,
    0
  );

  const totalBusinessByDate = response?.byDate || [];

  const businessCountByMonth = () => {
    const totalPerMonth = {};

    totalBusinessByDate.forEach((business) => {
      const date = new Date(business.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const key = `${year}-${month.toString().padStart(2, "0")}`;

      if (!totalPerMonth[key]) {
        totalPerMonth[key] = 0;
      }
      totalPerMonth[key] += business.count;
    });
    return totalPerMonth;
  };

  const businessCount = businessCountByMonth();

  const getCountForSelecteDate = () => {
    if (!selectedDate) {
      return 0;
    }
    return businessCount[selectedDate] || 0;
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const data = {
    labels: Object.keys(businessCount),
    datasets: [
      {
        data: Object.values(businessCount),
        tension: 0.4,
        borderColor: theme.palette.primary.light,
        pointRadius: 4,
        pointBackgroundColor: theme.palette.primary.main,
        backgroundColor: "transparent",
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: t("months"),
        },
      },
      y: {
        title: {
          display: true,
          text: t("businessCard.subtitle-2"),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("businessCard.subtitle-1")}</Typography>
        </Stack>
        <Divider sx={{ my: "0.5rem" }} />
        {/* <TimeFilter />
         */}
        <Typography>{t("businessCard.subtitle-2")}</Typography>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Stack direction="row" spacing={1} alignItems="baseline" my={1}>
            <Typography variant="h5" fontWeight={600}>
              {isLoading || isError ? "-" : totalCount}
            </Typography>
            <Typography variant="p">{t("registered-1")}</Typography>
          </Stack>
        )}
        <Divider sx={{ my: "1rem" }} />
        <Typography mt={"1rem"}>{t("businessCard.subtitle-3")}</Typography>
        <Stack  display="flex" flexDirection={"row"} justifyContent="center" alignItems="center" mt={1.5}>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel>{t("months")}</InputLabel>
            <Select
              value={selectedDate}
              onChange={handleDateChange}
              displayEmpty
              label={t("months")}
            >
              {Object.entries(businessCount).map(([month]) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            selectedDate && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AddBusinessIcon color="success" />
                <Typography variant="h5" color={"success.main"}>
                  {isLoading || isError ? "-" : getCountForSelecteDate()}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Stack sx={{ height: "170px", alignItems: "center", mt: "1.5rem" }}>
            <Line data={data} options={options} />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
