import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Typography,
  Box,
  Divider,
  Stack,
} from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import FindInPageIcon from "@mui/icons-material/FindInPage";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ValidationStepThreeModal({
  isOpen,
  handleClose,
  handleConfirm,
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle sx={{ p: "2rem" }}>
        <Stack direction="row" alignItems={"center"} justifyContent="center">
          <FindInPageIcon sx={{ color: "gray" }} />
          <Typography variant="h6">{t("verifydata.modal")}</Typography>
        </Stack>
        <Box mt={1} sx={{ justifyContent: "center" }}>
          <Typography>{t("verifydata.modal.description")}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: "2rem" }}>
        <Grid container>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-1")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Santiago Vivas</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-2")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>CC</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-3")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>10566234</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-4")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>3145002233</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500}>
              {t("verifydata.modal.row-5")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>sago.vivas@gmail.com</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: "2rem", gap: "0.5rem" }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
          }}
        >
          {t("verifydata.modal.correct.button")}
        </Button>
        <Button color="secondary" variant="contained" onClick={handleConfirm}>
          {t("verifydata.modal.confirm.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
