import axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export const GetTaxByIdService = (token, taxId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${URL_MERCHANTS}/api/brain/v1/tax/${taxId}`, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        }
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};