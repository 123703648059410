import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import LocationAndContactForm from "../create-commerce-components/LocationAndContactForm";
import BusinessDataForm from "../create-commerce-components/BusinessDataForm";
import ChoosePlanForm from "../create-commerce-components/ChoosePlanForm";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { GetPlansListService } from "../../services/GetPlansListService";
import { ResumeCommerceRegisterService } from "../../services/ResumeCommerceRegisterService";
import UploadDocs from "../create-commerce-components/UploadDocs";
import { FormProvider } from "../../context/CreateCommerceFormContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { ClientInfoByIdService } from "../../services/ClientInfoByIdService";
import { useForm } from "../../context/CreateCommerceFormContext";

export default function CreateCommerceLayout() {
  const [activeStep, setActiveStep] = useState(0);
  const [stepFromRegister, setStepFromRegister] = useState(0);
  const [missingDocs, setMissingDocs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [savedStepOne, setSavedStepOne] = useState(false);
  const [isCommerceCreated, setIsCommerceCreated] = useState(false);
  const [isCommerceUpdated, setIsCommerceUpdated] = useState(false);
  const [savedPlan, setSavedPlan] = useState(false);
  const [isRegistrationFinalized, setIsRegistrationFinalized] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();
  const { state } = useLocation();
  const { mode } = state !== null ? state : { mode: "new" };
  const params = useParams();
  const { shopId } = params;

  const steps = [
    {
      label: `${t("step.four.label")}`,
      description: ``,
    },
    {
      label: `${t("step.three.label")}`,
      description: ``,
    },
    {
      label: `${t("step.five.label")}`,
      description: ``,
    },
    {
      label: `${t("step.six.label")}`,
      description: ``,
    },
  ];

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (!isRegistrationFinalized) {
        e.preventDefault();
        e.returnValue =
          "¿Seguro que deseas abandonar esta página? Los cambios no guardados se perderán.";
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isRegistrationFinalized]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const finalizeRegister = () => {
    localStorage.removeItem("shopId");
    goAccountsAndCommerces();
  };

  const handleSavedStep = () => {
    setSavedStepOne(true);
  };

  const goAccountsAndCommerces = () => {
    navigate("/accounts&stores?tab=1");
  };

  useEffect(() => {
    const getPlans = async () => {
      try {
        const token = await getToken();
        if (token) {
          const response = await GetPlansListService(token);
          const data = response;
          setPlans(data);
          console.log("Data plans", data);
          // console.log("From state", plans);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getPlans();
    console.log("state", state, shopId);
  }, []);

  useEffect(() => {
    {
      isCommerceCreated && setActiveStep(1);
    }
    {
      isCommerceUpdated && setActiveStep(2);
    }
    {
      savedPlan && setActiveStep(3);
    }
  }, [isCommerceCreated, isCommerceUpdated, savedPlan]);

  useEffect(() => {
    {
      if (mode === "continueReg" || mode === "edit") {
        getCommerce();
      }
    }
  }, [mode, activeStep]);

  const getCommerce = async () => {
    try {
      const token = await getToken();
      if (token) {
        setLoading(true);
        if (mode === "continueReg") {
          const response = await ResumeCommerceRegisterService(shopId, token);
          const mappedItem = mapData(response);
          setFormValues(mappedItem);
          setMissingDocs(response.document_types.missing);
        } else if (mode === "edit") {
          const response = await ClientInfoByIdService(token, shopId);
          console.log(response);
          const mappedItem = mapData(response);
          setFormValues(mappedItem);
          // setMissingDocs(response.document_types.missing);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const mapData = (response) => {
    if (mode === "continueReg") {
      const { data } = response;
      const bankDocType = parseInt(data.bank_data.documentTypeId, 10);
      const mappedItem = {
        id: data.id,
        name_shop: data.name_shop,
        description: data.description,
        ciiuId_1: data.ciiuId_1,
        ciiuId_2: data.ciiuId_2,
        shop_person_type: data.type_of_person,
        web: data.web,
        shop_document_type: data.type_of_document,
        shop_document_number: data.document_number,
        bankId: data.bank_data.bankId,
        payment_methods: data.payment_method_list,
        typeAccount: data.bank_data.typeAccount,
        accountNumber: data.bank_data.accountNumber,
        documentNumber: data.bank_data.documentNumber,
        documentTypeId: bankDocType,
        countryId: data.countryId,
        departmentId: data.departmentId,
        cityId: data.cityId,
        postalCode: data.postalCode,
        address: data.address,
        plan: data.plan !== null ? data.plan?.id : "",
      };
      return mappedItem;
    } else if (mode === "edit") {
      const bankDocType = parseInt(response?.bank_data?.documentTypeId, 10);
      const mappedItem = {
        id: response.id,
        name_shop: response.name_shop,
        description: response.description,
        ciiuId_1: response.ciiuId_1,
        ciiuId_2: response.ciiuId_2,
        shop_person_type: response.type_of_person,
        web: response.web,
        shop_document_type: response.type_of_document,
        shop_document_number: response.document_number,
        bankId: response?.bank_data?.bankId,
        payment_methods: response.payment_method_list,
        typeAccount: response?.bank_data?.typeAccount,
        accountNumber: response?.bank_data?.accountNumber,
        documentNumber: response?.bank_data?.documentNumber,
        documentTypeId: bankDocType,
        countryId: response.countryId,
        departmentId: response.departmentId,
        cityId: response.cityId,
        postalCode: response.postalCode,
        address: response.address,
        plan: response?.plan !== null ? response?.plan?.id : "",
      };
      return mappedItem;
    }
  };

  const filterByKeys = (data, keys) => {
    const filteredData = {};

    for (const key of keys) {
      if (key in data) {
        filteredData[key] = data[key];
      }
    }

    return filteredData;
  };

  return (
    <FormProvider>
      <Content open={isOpen}>
        <Grid container>
          <Grid item xs={3}>
            <Box sx={{ maxWidth: 400, ml: "1rem" }}>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "primary.main", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "greys.dark", // Just text label (COMPLETED)
                    },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "primary.main", // circle color (ACTIVE)
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                      color: "greys.lightest", // Just text label (ACTIVE)
                    },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "white", // circle's number (ACTIVE)
                  },
                }}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <Typography variant="caption">
                        {step.description}
                      </Typography>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {index !== steps.length - 1 ? (
                            <Button
                              variant="contained"
                              color="success"
                              onClick={handleNext}
                              size="small"
                              sx={{ mt: 1, mr: 1, color: "white" }}
                            >
                              {`${t("next.button")}`}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="success"
                              onClick={finalizeRegister}
                              size="small"
                              sx={{ mt: 1, mr: 1, color: "white" }}
                            >
                              {`${t("finish.button")}`}
                            </Button>
                          )}
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            size="small"
                            sx={{ mt: 1, mr: 1, color: "primary.light" }}
                          >
                            {t("back.button")}
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    Has finalizado el registro correctamente
                  </Typography>
                </Paper>
              )}
            </Box>
          </Grid>
          <Grid item xs={9}>
            {activeStep === 0 && (
              <BusinessDataForm
                isCommerceCreated={isCommerceCreated}
                setIsCommerceCreated={setIsCommerceCreated}
                formResume={formValues}
                mode={mode}
              />
            )}
            {activeStep === 1 && (
              <LocationAndContactForm
                isCommerceUpdate={isCommerceUpdated}
                setIsCommerceUpdated={setIsCommerceUpdated}
                formResume={formValues}
                mode={mode}
              />
            )}
            {activeStep === 2 && (
              <ChoosePlanForm
                plans={plans}
                savedPlan={savedPlan}
                setSavedPlan={setSavedPlan}
                plan={
                  mode === "edit" || mode === "continueReg"
                    ? filterByKeys(formValues, ["plan"])
                    : { plan: "1" }
                }
                mode={mode}
              />
            )}
            {activeStep === 3 && (
              <UploadDocs missingDocs={missingDocs} mode={mode} />
            )}
          </Grid>
        </Grid>
      </Content>
    </FormProvider>
  );
}
