import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const ClientInfoByIdService = (token, clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${URL_AUTH}/api/admin/v1/client/${clientId}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      // console.log(response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};