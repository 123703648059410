import React, { useState } from "react";
import {
  Grid,
  Box,
  Button,
  Input,
  InputAdornment,
  Stack,
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CircularProgress,
  Link
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import LoginIcon from "@mui/icons-material/Login";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateResetPasswordCodeService } from "../../services/CreateResetPasswordCodeService";
import { Send } from "@mui/icons-material";
import { CreateResetPasswordLinkService } from "../../services/CreateResetPasswordLinkService";

function SearchUserWithEmail() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const { mode } = location.state || {};
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if(mode === 'code') {
      try {
        const response = await CreateResetPasswordCodeService(formValue);
        navigate("/verification_code_email", {state: formValue});
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await CreateResetPasswordLinkService(formValue);
        navigate("/password_recovery_link_send");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    
  };

  const onTaskChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const goBack = () => {
    navigate("/select_two_factor");
  };

  return (
    <Stack
      mt={{ xs: "2rem" }}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        // width: { xs: "70%", md: "65%" },
      }}
    >
      <Box
        sx={{
          mt: { xs: "1rem", md: 0 },
        }}
      >
        <Card
          elevation={4}
          sx={{
            borderRadius: "20px",
            p: "1rem",
            mx: { xs: "0.4rem", sm: "10rem", md: "0" },
            mt: { xs: "2rem", md: 0 },
            maxWidth: "500px",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h5" fontWeight={600}>
                {t("recoveryPassword.title")}
              </Typography>
            }
            subheader={
              <Typography variant="subtitle-1" fontWeight={200}>
                {t("recoveryPassword.subtitle")}
              </Typography>
            }
          />
          <CardContent>
            <form onSubmit={(e) => handleSubmit(e)}>
              <FormControl
                sx={{
                  marginBottom: "1.5rem",
                  // width: { xs: "100%", md: "100%", lg: "64%" },
                }}
              >
                <InputLabel
                  // htmlFor="bootstrap-input"
                  sx={{
                    color: "primary.main",
                    fontSize: { xs: "20px", md: "25px" },
                    margin: { xs: "0px", md: "-10px" },
                    marginInline: { xs: "-10px" },
                    letterSpacing: "-1px",
                  }}
                >
                  {t("username")}
                </InputLabel>
                <Input
                  name="email"
                  fullWidth
                  label={t("username")}
                  placeholder="example@gmail.com"
                  onChange={(e) => onTaskChange(e)}
                  value={formValue.email}
                  sx={{
                    bgcolor: "white",
                    borderRadius: "25px",
                    padding: "8px",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonIcon sx={{ color: "action.active" }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Grid container columns={12} mt={2}>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent={{ xs: "center", md: "flex-start" }}
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ borderRadius: "15px" }}
                    startIcon={
                      loading ? (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <SendIcon />
                      )
                    }
                    type="submit"
                  >
                    {t("sendVerificationCode")}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Box mt={2} textAlign={"center"}>
              <Stack direction={"row"} alignItems={"center"} spacing={1} justifyContent={"center"}>
                <LoginIcon sx={{ transform: "rotate(180deg)" }} />
                <Link
                  onClick={goBack}
                  sx={{
                    fontSize: "15px",
                    display: "flex",
                    textAlign: "center",
                    color: "secondary.main",
                    cursor: "pointer",
                  }}
                  underline="hover"
                >
                  {t("back.button")}
                </Link>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Stack>
  );
}

export default React.memo(SearchUserWithEmail);
