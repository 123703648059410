import { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Divider,
  TextField,
  Button,
  Stack,
  Autocomplete,
  Box,
  Snackbar,
  Alert,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../../utils/GetToken";
import { SendNotificationService } from "../../services/Notifications/SendNotificationService";
import { useNotification } from "../../context/NotificationContext";
import { t } from "i18next";

const notificationOptions = [
  { id: 1, lang: "en", name: "Push" },
  { id: 2, lang: "en", name: "Email" },
  { id: 3, lang: "en", name: "WhatsApp" },
];

const genderOptions = [
  { id: 1, name: "Femenino", code: "F" },
  { id: 2, name: "Masculino", code: "M" },
];

export default function SendNotification() {
  const {
    handleSelectNotificationType,
    selectedNotificationType,
    resetNotificationType,
  } = useNotification();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const userData = state?.userData || {};
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [useGenderFilter, setUseGenderFilter] = useState(false);
  const [notificationType, setNotificationType] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    type: "",
    to: "",
    subject: "",
    content: "",
    name: "hello_world",
    code: "EN_US",
    text1: "",
  });

  const handleNotificationChange = (event, value) => {
    setSelectedNotification(value);
    userInfo(userData, value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      type: value ? value.name.toLowerCase() : "",
    }));
  };

  const handleGenderChange = (event, value) => {
    setSelectedGender(value);
  };

  const handleSwitchChange = (event) => {
    setUseGenderFilter(event.target.checked);
    if (event.target.checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        to: "",
      }));
    } else {
      userInfo(userData, selectedNotification);
    }
  };

  const userInfo= (user, notification) => {
    if (user && notification) {
      let toValue = "";
      switch (notification.name) {
        case "Push":
          toValue = user.fcm_token || "";
          break;
        case "Email":
          toValue = user.email || "";
          break;
        case "WhatsApp":
          const countryCode = "+57";
          toValue = `${countryCode}${user.movil_number || ""}`;
          break;
        default:
          toValue = "";
      }
      setFormData((prevState) => ({
        ...prevState,
        to: toValue,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      type: selectedNotification?.name.toLowerCase(),
      ...formData,
      gender: useGenderFilter ? selectedGender?.code : null,
    };
    try {
      const token = await getToken();
      if (token) {
        const response = await SendNotificationService(token, data);
        console.log("Notification sent", response);
        setNotificationType(true);
        handleSelectNotificationType();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (notificationType) {
      const redirectTimer = setTimeout(() => {
        navigate(`/notifications?tab=0`);
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
    finalizeNotification();
  }, [notificationType]);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleSelectNotificationType();
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const finalizeNotification = () => {
    resetNotificationType();
  };

  return (
    <>
      <Snackbar
        open={selectedNotificationType}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("notification.message")}</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info">{errorMessage}</Alert>
      </Snackbar>
      <Card
        elevation={5}
        sx={{
          borderRadius: "20px",
          maxWidth: 450,
          width: "90%",
          margin: "auto",
          p: { xs: "1rem", sm: "2rem" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" fontWeight={600} textAlign={"center"}>
          {t("notification.title")}
        </Typography>
        <Divider sx={{ my: "2rem" }} />
        <Typography
          variant="p"
          fontWeight={400}
          textAlign={"center"}
          sx={{ mb: "2rem" }}
        >
          {t("notification.text")}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Autocomplete
            id="notification"
            options={notificationOptions}
            onChange={handleNotificationChange}
            value={selectedNotification}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                name="notification"
                label={t("notification.type")}
              />
            )}
          />

          {selectedNotification && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={useGenderFilter}
                    onChange={handleSwitchChange}
                    color="primary"

                  />
                }
                label={t("notification.useGenderFilter")}
                sx={{ mt: "1rem", display: "flex", alingItems: "center",  justifyContent: "center"}}
              />

              {useGenderFilter && (
                <Autocomplete
                  id="gender"
                  options={genderOptions}
                  onChange={handleGenderChange}
                  value={selectedGender}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="gender"
                      label={t("notification.gender")}
                      sx={{ mt: "1rem", mb: "1rem"}}
                    />
                  )}
                />
              )}

              {!useGenderFilter && (
                <TextField
                  fullWidth
                  label={t("notification.form-1")}
                  name="to"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  value={formData.to}
                  onChange={handleInputChange}
                  sx={{ mt: 1, fontSize: { xs: "0.8rem", sm: "1rem" } }}
                />
              )}

              <TextField
                fullWidth
                label={t("notification.form-2")}
                name="subject"
                variant="outlined"
                margin="normal"
                size="small"
                value={formData.subject}
                onChange={handleInputChange}
                sx={{ mt: 1, fontSize: { xs: "0.8rem", sm: "1rem" } }}
              />
              <TextField
                fullWidth
                label={t("notification.form-3")}
                name="content"
                variant="outlined"
                margin="normal"
                size="small"
                value={formData.content}
                onChange={handleInputChange}
                sx={{ mt: 1, fontSize: { xs: "0.8rem", sm: "1rem" } }}
              />

              {selectedNotification.name === "WhatsApp" && (
                <>
                  <TextField
                    fullWidth
                    label={t("notification.form-4")}
                    name="name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    value={formData.name}
                    onChange={handleInputChange}
                    sx={{ mt: 1, fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  />
                  <TextField
                    fullWidth
                    label={t("notification.form-5")}
                    name="code"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    value={formData.code}
                    onChange={handleInputChange}
                    sx={{ mt: 1, fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  />
                  <TextField
                    fullWidth
                    label={t("notification.form-6")}
                    name="text1"
                    variant="outlined"
                    margin="normal"
                    value={formData.text1}
                    onChange={handleInputChange}
                    sx={{ mt: 1, fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  />
                </>
              )}
            </>
          )}

          <Stack
            mt={4}
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: { xs: "center" },
            }}
          >
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ borderRadius: "20px" }}
              type="submit"
            >
              {t("notification.Button")}
            </Button>
          </Stack>
        </form>
      </Card>
    </>
  );
}
