import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  Tabs,
  Tab,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SuperAdminList from "../user-management-components/UsersList";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import PropTypes from "prop-types";
import RoleList from "../roles-and-permissions-components/RoleList";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ClientList from "../client-management-components/ClientList";
import AgentList from "../agent-management-components/AgentList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box sx={{ p: { xs: "0.2rem", sm: 3 }, mt: 2, mb: 8 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UsersManagementLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabUrl = searchParams.get("tab");
  const [currentTab, setCurrentTab] = useState(
    tabUrl !== null && tabUrl !== 0 ? parseInt(tabUrl, 10) : 0
  );
  const [value, setValue] = useState(parseInt(currentTab, 10));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();

  const handleButtonClick = () => {
    navigate("/AdminAccount");
  };

  const handleButtonClickClient = () => {
    navigate("/clientAccount");
  };

  const handleButtonClickAgent = () => {
    navigate("/AgentAccount");
  };
  
  const handleButtonNewRoleClick = () => {
    navigate("/createNewRole");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const tabParam = extractTabParamFromURL();
    if (tabParam) {
      setValue(tabParam);
    }
  }, []);

  useEffect(() => {
    setCurrentTab(value);
  }, [value]);

  useEffect(() => {
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  }, [currentTab]);

  const extractTabParamFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam !== null) {
      const tabValue = parseInt(tabParam, 10);
      if ([0, 1].includes(tabValue)) {
        console.log("tab param", tabParam);
        return tabValue;
      }
    }
    return 0;
  };

  return (
    <Content open={isOpen}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pl: 0 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("usersManagement.tabTitle-1")} {...a11yProps(0)} />
          <Tab label={t("usersManagement.tabTitle-2")} {...a11yProps(1)} />
          <Tab label={t("usersManagement.tabTitle-3")} {...a11yProps(2)} />
          <Tab label={t("usersManagement.tabTitle-4")} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("users.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("users.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClick}
              >
                {t("createNewSuperadmin.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <SuperAdminList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>


      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("client.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("users.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClickClient}
              >
                {t("createNewClient.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <ClientList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>


      <TabPanel value={value} index={2}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("agent.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("users.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClickAgent}
              >
                {t("createNewAgent.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <AgentList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>


      <TabPanel value={value} index={3}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("role.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("roles.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<SupervisedUserCircleIcon sx={{ color: "white" }} />}
                onClick={handleButtonNewRoleClick}
              >
                {t("roles.createNewRole.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <RoleList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Content>
  );
}
