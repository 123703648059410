import Axios from "axios";

const URL_CLIENT = import.meta.env.VITE_AUTH_CLIENT;

export function UpdateAlertService(data, token, accountId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.put(
        `${URL_CLIENT}/api/admin/v1/alert/type/${accountId}`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
