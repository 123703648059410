import axios from "axios";

const URL_REACTION = import.meta.env.VITE_AUTH_REACTION;

export function GetBusinessStatsServices(token){
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${URL_REACTION}/api/admin/v1/count-Businesses`, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(response);
      console.log(response);
    
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
}