import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  Tabs,
  Tab,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import PropTypes from "prop-types";
import ArlList from "../arl-management-components/ArlList";
import EpsList from "../eps-management-components/EpsList";
import CountryList from "../country-management-components/CountryList";
import DepartmentList from "../department-management-components/DepartmentList";
import CityList from "../city-management-components/CityList";
import AlertList from "../alert-management-components/AlertList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box sx={{ p: { xs: "0.2rem", sm: 3 }, mt: 2, mb: 8 }}>
            {children}
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ListManagementLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabUrl = searchParams.get("tab");
  const [currentTab, setCurrentTab] = useState(
    tabUrl !== null && tabUrl !== 0 ? parseInt(tabUrl, 10) : 0
  );
  const [value, setValue] = useState(parseInt(currentTab, 10));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();

  const handleButtonClick = () => {
    navigate("/arlManagement");
  };

  const handleButtonClickEps = () => {
    navigate("/epsManagement");
  };

  const handleButtonClickCountry = () => {
    navigate("/countryManagement");
  };

  const handleButtonClickDepartment = () => {
    navigate("/departmentManagement");
  };
  const handleButtonClickCity = () => {
    navigate("/cityManagement");
  };
  const handleButtonClickAlert = () => {
    navigate("/alertManagement");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const tabParam = extractTabParamFromURL();
    if (tabParam) {
      setValue(tabParam);
    }
  }, []);

  useEffect(() => {
    setCurrentTab(value);
  }, [value]);

  useEffect(() => {
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  }, [currentTab]);

  const extractTabParamFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam !== null) {
      const tabValue = parseInt(tabParam, 10);
      if ([0, 1].includes(tabValue)) {
        // console.log("tab param", tabParam);
        return tabValue;
      }
    }
    return 0;
  };

  return (
    <Content open={isOpen}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pl: 0 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t("listManagement.tabTitle-1")} {...a11yProps(0)} />
          <Tab label={t("listManagement.tabTitle-2")} {...a11yProps(1)} />
          <Tab label={t("listManagement.tabTitle-3")} {...a11yProps(2)} />
          <Tab label={t("listManagement.tabTitle-4")} {...a11yProps(3)} />
          <Tab label={t("listManagement.tabTitle-5")} {...a11yProps(4)} />
          <Tab label={t("listManagement.tabTitle-6")} {...a11yProps(5)} />
          <Tab label={t("listManagement.tabTitle-7")} {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("arl.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("arl.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClick}
              >
                {t("createNewArl.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <ArlList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("eps.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("eps.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClickEps}
              >
                {t("createNewEps.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <EpsList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("country.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("country.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClickCountry}
              >
                {t("createNewCountry.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <CountryList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("department.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("department.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClickDepartment}
              >
                {t("createNewDep.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <DepartmentList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("city.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("city.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClickCity}
              >
                {t("createNewCity.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <CityList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={5}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("alert.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("alert.description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<PersonAddIcon sx={{ color: "white" }} />}
                onClick={handleButtonClickAlert}
              >
                {t("createNewAlert.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <AlertList />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Content>
  );
}
