import axios from "axios";

const URL_DICTIONARIES = import.meta.env.VITE_AUTH_DICTIONARIES;

export function GetZonesListService(token, page, query, pagination) {
  return new Promise(async (resolve, reject) => {
    try {
      let url;
      if (query === "") {
        url = `${URL_DICTIONARIES}/api/locations/v1/zones?page=${page}&pp=${pagination}&safe=1`;
      } else {
        url = `${URL_DICTIONARIES}/api/locations/v1/zones?${query}&page=${page}&pp=${pagination}&safe=1`;
      }
      const response = await axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
