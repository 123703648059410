import axios from "axios";

const VITE_API_KEY_MAP = import.meta.env.VITE_API_KEY_MAP;

let objeto = [];

export const GetUserPostalCode = (arg) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url =
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        arg +
        "&key=" +
        VITE_API_KEY_MAP;
      await axios
        .get(url)
        .then((res) => {
          for (let i = 0; i < res.data.results.length; i++) {
            //console.log ( " objeto completo " +  JSON.stringify(res.data))
            if (res.data.results[i].types[0] === "postal_code") {
              let address_components = res.data.results[i].address_components;
              for (let j = 0; j < address_components.length; j++) {
                if (address_components[j].types[0] === "postal_code") {
                  let googleGeocodePostalCode = address_components[j].long_name;
                  //console.log("  codigo postal   : ", googleGeocodePostalCode);
                  // resolve(googleGeocodePostalCode);
                  let item = { codigoPostal: googleGeocodePostalCode };
                  objeto.push(item);
                }

                if (address_components[j].types[0] === "country") {
                  let googleGeocodecountry = address_components[j].long_name;
                  //console.log("  codigo postal   : ", googleGeocodePostalCode);
                  // resolve(googleGeocodecountry);
                  let item = { country: googleGeocodecountry };
                  objeto.push(item);
                }

                if (
                  address_components[j].types[0] ===
                  "administrative_area_level_1"
                ) {
                  let googleGeocodearea1 = address_components[j].long_name;
                  //console.log("  codigo postal   : ", googleGeocodePostalCode);
                  // resolve(googleGeocodecountry);
                  let item = { area1: googleGeocodearea1 };
                  objeto.push(item);
                }

                if (
                  address_components[j].types[0] ===
                  "administrative_area_level_2"
                ) {
                  let googleGeocodearea2 = address_components[j].long_name;
                  //console.log("  codigo postal   : ", googleGeocodePostalCode);
                  // resolve(googleGeocodecountry);
                  let item = { area2: googleGeocodearea2 };
                  objeto.push(item);
                }

                if (address_components[j].types[0] === "locality") {
                  let locality = address_components[j].long_name;
                  //console.log("  codigo postal   : ", googleGeocodePostalCode);
                  // resolve(googleGeocodecountry);
                  let item = { locality: locality };
                  objeto.push(item);
                }

                if (address_components[j].types[1] === "sublocality") {
                  let sublocality = address_components[j].long_name;
                  //console.log("  codigo postal   : ", googleGeocodePostalCode);
                  // resolve(googleGeocodecountry);
                  let item = { sublocality: sublocality };
                  objeto.push(item);
                }

                if (address_components[j].types[1] === "sublocality") {
                  let sublocality = address_components[j].long_name;
                  //console.log("  codigo postal   : ", googleGeocodePostalCode);
                  // resolve(googleGeocodecountry);
                  let item = { sublocality: sublocality };
                  objeto.push(item);
                }

                if (address_components[j].types[0] === "neighborhood") {
                  let neighborhood = address_components[j].long_name;
                  //console.log("  codigo postal   : ", googleGeocodePostalCode);
                  // resolve(googleGeocodecountry);
                  let item = { neighborhood: neighborhood };
                  objeto.push(item);
                }
              }
              resolve(objeto);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log("GetUsersPostalCode:", error);
      reject(error);
    }
  });
};
