import { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Skeleton,
  Grid,
  ButtonBase,
  Modal,
  Box,
  Stack,
  CardContent,
  Divider,
  Avatar,
  ImageList,
  ImageListItem,
  Chip,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getToken } from "../../utils/GetToken";
import { GetAlertDetailsService } from "../../services/Alerts/GetAlertDetailsService";
import { styled } from "@mui/material/styles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import dayjs from "dayjs";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import NotificationsIcon from "@mui/icons-material/Notifications";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import DescriptionIcon from "@mui/icons-material/Description";
import CategoryIcon from "@mui/icons-material/Category";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AlertOptions from "./AlertOptions";

export default function AlertDetails() {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertDetail, setAlertDetail] = useState({});
  const { t } = useTranslation();
  const { alertId } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    //bgcolor: "background.paper",
    border: "2px ",
    //boxShadow: 20,
    p: 4,
  };

  const styleIcon = {
    fontSize: 22,
    color: "primary.light",
  };

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    width: 450,
    Height: 200,
  });

  useEffect(() => {
    const alertDetails = async () => {
      try {
        const token = await getToken();
        if (token) {
          const data = await GetAlertDetailsService(token, alertId);
          console.log(data);
          const createdAt = dayjs(data.createdAt).format("DD/MM/YYYY H:m:s A");
          const alert = {
            id: data.alertType.id,
            name: data.alertType.name,
            type: data.alertType.type,
            description: data.description,
            imageUrl: data.alertType.imageUrl,
            imageUrl_other: data.alertType.imageUrl_other,
            state: data.state,
            state_code: data.state_code,
            createdAt: createdAt,
            latitude: data.latitude,
            longitude: data.longitude,
            address: data.addressInfo.address,
            images: data.images,
            video: data.video,
          };
          setAlertDetail(alert);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    alertDetails();
  }, []);

  useEffect(() => {
    console.log(alertDetail);
  }, [alertDetail]);

  return (
    <>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={400}
          sx={{
            width: { xs: "320px", sm: "320px", md: "500px", xl: "872px" },
            borderRadius: "20px",
            mx: "1rem",
          }}
        />
      ) : (
        <Card
          elevation={5}
          sx={{
            borderRadius: "20px",
            mx: "1rem",
            height: "auto",
            p: { xs: "0.5rem", sm: "2rem" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase
                onClick={handleOpen}
                sx={{
                  width: 160,
                  height: 160,
                  top: "30%",
                  ml: "2rem",
                  mb: "2rem",
                  mr: "1rem",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  alt="plan image"
                  src={alertDetail.imageUrl_other}
                  sx={{ width: "100%", height: "90%" }}
                >
                  <AssignmentIcon sx={{ width: "80%", height: "80%" }} />
                </Avatar>
              </ButtonBase>

              <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                  <Img src={alertDetail.imageUrl_other} />
                </Box>
              </Modal>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Typography
                        variant="h6"
                        color="primary.light"
                        sx={{ ml: "1rem", pt: "1rem" }}
                      >
                        {t("alertDetails.title")}
                      </Typography>
                    </Grid>
                
                    <Grid container spacing={2}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        ml: "98%",
                        mt: "-1rem",
                      }}
                    >
                      <AlertOptions />
                    </Stack>
                    </Grid>
                    <Divider light sx={{ marginBlock: "1rem" }} />

                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <CircleNotificationsIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("alertDetails.id")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.id}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <NotificationsIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("alertDetails.name")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <CategoryIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("alertDetails.type")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.type}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <NotificationsActiveIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("alertDetails.state")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.state}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <GpsFixedIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("alertDetails.latitude")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.latitude}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <GpsFixedIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("alertDetails.longitude")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.longitude}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <PendingActionsIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("alertDetails.createdAt")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.createdAt}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <PersonPinCircleIcon sx={styleIcon} />
                        <Typography variant="subtitle1">
                          {t("alertDetails.address")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.address}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Box alignItems="baseline">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <DescriptionIcon sx={styleIcon} />
                          <Typography variant="subtitle1">
                            {t("alertDetails.description")}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          sx={{ ml: "2rem" }}
                        >
                          <Typography variant="subtitle1" color="grays.darkest">
                            {alertDetail.description}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </CardContent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} my={2}>
            <Divider variant="middle">
              <Chip label={t("alertDetails.Images")} />
            </Divider>
          </Grid>

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12}>
              <ImageList
                sx={{
                  width: "100%",
                  height: "95%",
                  margin: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                  pb: "1rem",
                  pt: "2rem",
                  flexWrap: "wrap",
                  display: "flex",
                }}
                cols={3}
                rowHeight={162}
                gap={5}
              >
                {alertDetail.images && alertDetail.images.length > 0 ? (
                  alertDetail.images.map((image, index) => (
                    <ImageListItem key={index}>
                      <img
                        srcSet={`${image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`${image}?w=164&h=164&fit=crop&auto=format`}
                        // alt={item.title}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "5px",
                        }}
                      />
                    </ImageListItem>
                  ))
                ) : (
                  <Box textAlign="center" width="100%">
                    <Typography variant="subtitle1" color="grays.darkest">
                      No hay imágenes
                    </Typography>
                  </Box>
                )}
              </ImageList>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}
