import React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Stack,
  Divider,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function LinkNotificaction() {

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box className="login">
      <Stack
        mt={{ xs: 0, md: 2 }}
        sx={{
          justifyContent: "center",
          alignItems: { xs: "center" },
        }}
      >
        <Card
          elevation={4}
          sx={{
            borderRadius: "20px",
            p: "1rem",
            mx: { xs: "0.4rem", sm: "10rem", md: "0" },
            mt: { xs: "2rem", md: 0 },
            maxWidth: "500px",
          }}
        >
          <CardContent>
            <Typography variant="h6" fontWeight={600} textAlign={"center"}>
              {t("select_two_factor.title")}
            </Typography>
            <Divider sx={{ my: "1rem" }} />
            <Stack mt={2} justifyContent='center' textAlign='center'>
            <Typography variant="p" textAlign={"start"}>
              {t("link_notification.text")}
            </Typography>
            </Stack>
            <form>
              <Stack
                mt={4}
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: { xs: "center" },
                }}
              >
                <Link
                  href="/"
                  sx={{
                    fontSize: "15px",
                    display: "flex",
                    textAlign: "center",
                    color: "secondary.main",
                  }}
                  underline="hover"
                >
                  {t("backToHome.button")}
                </Link>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default React.memo(LinkNotificaction);