import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  Snackbar,
  Alert,
  Popover,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import SmsIcon from "@mui/icons-material/Sms";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useAlert } from "../../context/AlertOptionsContext";
import { TakeAlertService } from "../../services/Alerts/TakeAlertService";
import { ValidateAlertService } from "../../services/Alerts/ValidateAlertService";
import { FinalizeAlertService } from "../../services/Alerts/FinalizeAlertService";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const HandleActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickSeeMore, setClickSeeMore] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const {
    alertOptions,
    validateAlert,
    finalizeAlert,
    handleAlertOptions,
    handleIsValidateAlert,
    handleIsFinalizeAlert,
  } = useAlert();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const { t } = useTranslation();

  const styleButton = {
    pl: "1rem",
    color: "secondary.main",
  };
  const { alertId, data } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeeMore = () => {
    setClickSeeMore(true);
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleConfirmAction = async () => {
    try {
      const token = await getToken();
      await TakeAlertService(token, alertId);
      setSelectedOption(true);
      handleAlertOptions();
      console.log("Alerta enviada");
    } catch (error) {
      console.error("Error al enviar la alerta:", error);
    } finally {
      handleClose();
      setPopoverAnchorEl(null);
    }
  };

  const handleValidateAlert = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      await ValidateAlertService(token, alertId);
      setSelectedOption(null);
      handleClose();
    } catch (error) {
      console.error("Error al validar la alerta:", error);
    } finally {
      setLoading(false);
    }
    handleIsValidateAlert();
    setPopoverAnchorEl(null);
  };

  const handleFinalizeAlert = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      await FinalizeAlertService(token, alertId);
      console.log("Alerta finalizada");
      setSelectedOption(null);
      handleClose();
    } catch (error) {
      console.error("Error al finalizar la alerta:", error);
    } finally {
      setLoading(false);
    }
    handleIsFinalizeAlert();
    setPopoverAnchorEl(null);
  };

  useEffect(() => {
    if (clickSeeMore) {
      navigate(`/alertDetails/${alertId}`);
    }
  }, [clickSeeMore]);

  useEffect(() => {
    if (selectedOption) {
      const redirectTimer = setTimeout(() => {
        navigate(`/alerts?tab=0`);
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
  }, [selectedOption]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>

      <Menu
         anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 208,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleSeeMore}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>{t("plan.table.option-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("SMS")}>
          <ListItemIcon>
            <SmsIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-1")}</ListItemText>
          {selectedOption === "SMS" && (
            <Button sx={styleButton} onClick={handleConfirmAction}>
              {t("alert.activation.button-1")}
            </Button>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("Email")}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-2")}</ListItemText>
          {selectedOption === "Email" && (
            <Button sx={styleButton} onClick={handleConfirmAction}>
              {t("alert.activation.button-1")}
            </Button>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("WhatsApp")}>
          <ListItemIcon>
            <WhatsAppIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-3")}</ListItemText>
          {selectedOption === "WhatsApp" && (
            <Button sx={styleButton} onClick={handleConfirmAction}>
              {t("alert.activation.button-1")}
            </Button>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("Call")}>
          <ListItemIcon>
            <PhoneCallbackIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-4")}</ListItemText>
          {selectedOption === "Call" && (
            <Button sx={styleButton} onClick={handleConfirmAction}>
              {t("alert.activation.button-1")}
            </Button>
          )}
        </MenuItem>

        <MenuItem onClick={() => handleOptionClick("Validate")}>
          <ListItemIcon>
            <MobileFriendlyIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-5")}</ListItemText>
          {selectedOption === "Validate" && (
            <Button sx={styleButton} onClick={handleValidateAlert}>
              {t("alert.activation.button-2")}
            </Button>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("Finalize")}>
          <ListItemIcon>
            <NotificationsOffIcon />
          </ListItemIcon>
          <ListItemText>{t("alerts.table.option-6")}</ListItemText>
          {selectedOption === "Finalize" && (
            <Button sx={styleButton} onClick={handleFinalizeAlert}>
              {t("alert.activation.button-3")}
            </Button>
          )}
        </MenuItem>
      </Menu>
      
       
    
    </>
  );
};

export const HeadersUserAlertList = () => {
  const currentLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 60,
      renderCell: (params) => <HandleActions alertId={params.row.id} />,
    },
    {
      field: "id",
      headerName: "Id",
      width: 350,
    },
    {
      field: "alertTypeName",
      headerName: t("alerts.table.header-2"),
      width: 200,
      renderCell: (params) => {
        const value = params.value.toString();
        const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
        return <div>{formattedValue}</div>;
      },
    },
    {
      field: "state",
      headerName: t("alerts.table.header-3"),
      width: 150,
      renderCell: (params) => {
        const value = params.value.toString();
        const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
        return <div>{formattedValue}</div>;
      },
    },
    {
      field: "description",
      headerName: t("alerts.table.header-5"),
      width: 250,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", overflowWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: t("alerts.table.header-6"),
      width: 200,
    },
  ];
  return columns;
};
