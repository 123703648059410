import { createContext, useState, useContext } from "react";

export const AlertOptionsContext = createContext();

export const useAlert = () => useContext(AlertOptionsContext);

export const AlertOptionsProvider = ({ children }) => {
  const [alertOptions, setAlertOptions] = useState(null);
  const [validateAlert, setValidateAlert] = useState(null);
  const [finalizeAlert, setFinalizeAlert] = useState(null);

  const handleAlertOptions = () => {
    setAlertOptions(!alertOptions);
  };

  const handleIsValidateAlert = () => {
    setValidateAlert(!validateAlert);
  };

  const handleIsFinalizeAlert = () => {
    setFinalizeAlert(!finalizeAlert);
  };

  const resetAlertOptions = () => {
    setAlertOptions(false);
    setValidateAlert(false);
    setFinalizeAlert(false);
  };

  return (
    <AlertOptionsContext.Provider
      value={{
        handleAlertOptions,
        alertOptions,
        resetAlertOptions,
        handleIsValidateAlert,
        validateAlert,
        handleIsFinalizeAlert,
        finalizeAlert,
      }}
    >
      {children}
    </AlertOptionsContext.Provider>
  );
};
