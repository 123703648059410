import axios from "axios";

const URL_AGENTS = import.meta.env.VITE_AGENTS;

export const GetCompanyDetailService = (token , companyId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${URL_AGENTS}/api/admin/v1/business/${companyId}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
};

