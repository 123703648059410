import Axios from "axios";

const URL_TRANSACTIONS = import.meta.env.VITE_TRANSACTIONS_URL;

export function TransactionsByShopService(
  token,
  shopId,
  page,
  query,
  pagination
) {
  return new Promise(async (resolve, reject) => {
    let url;
    if (query === "") {
      url = `${URL_TRANSACTIONS}/api/sandbox/v1/brain/transaction/list/shop/${shopId}?page=${page}&pagination=${pagination}`;
    } else {
      console.log("URL with params inside", query);
      url = `${URL_TRANSACTIONS}/api/sandbox/v1/brain/transaction/list/shop/${shopId}?${query}&page=${page}&pagination=${pagination}`;
    }
    try {
      const transactionList = await Axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("Transaction List by Shop:", transactionList);
      resolve(transactionList.data);
    } catch (error) {
      console.error("Error:", error);
      reject(error);
    }
  });
}
