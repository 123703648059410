import axios from "axios";

const URL_AUTH  = import.meta.env.VITE_AUTH_URL;

export function CreateNewPlanService(token, data) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(data);
      const response = await axios.post(
        `${URL_AUTH }/api/admin/v1/plan/create`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
}