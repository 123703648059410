import Axios from "axios";

const AUTH_DICTIONARIES = import.meta.env.VITE_AUTH_DICTIONARIES;

export function DeleteEpsService(id, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(
        `${AUTH_DICTIONARIES}/api/general/v1/eps/${id}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}