import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const GetAccountListClientService = (token, page, query, pagination) => {
  return new Promise(async (resolve, reject) => {
    console.log("Query from datagrid", query);
    let url;
 
    if (query === "") {
      url = `${URL_AUTH}/api/admin/v1/client?page=${page}&pp=${pagination}`;
    } else {
      console.log("URL with params inside");
      url = `${URL_AUTH}/api/admin/v1/client?${query}&page=${page}&pp=${pagination}`;
    }
    try {
      const accountsList = await axios.get(
        url,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('Account List:',accountsList);
      resolve(accountsList.data);
    } catch (error) {
      console.log("Error:", error);
      reject(error);
    }
  });
};
