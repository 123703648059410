import { useState, useEffect, useCallback } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Grid, Typography } from "@mui/material";
import HeadersPermissions from "../../utils/TableHeaders/PermissionsHeaders";
import { getToken } from "../../utils/GetToken";
import assignDataGridLanguage from "../../utils/AssignDatagridLanguage";
import { useSearchParams } from "react-router-dom";
import { GetAllPermissionsService } from "../../services/RolesAndPermissions/GetAllPermissionsService";
import { useTranslation } from "react-i18next";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

export default function PermissionsList({ formValues, setFormValues }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [languageDatagrid, setLanguageDatagrid] = useState({});
  const [queryOptions, setQueryOptions] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [query, setQuery] = useState(searchParams ? searchParams : {});
  const pageUrl = searchParams.get("page");
  const paginationUrl = searchParams.get("pagination");
  const [pageFromUrl, setPageFromUrl] = useState(
    pageUrl !== null && pageUrl !== 0
      ? parseInt(searchParams.get("page"), 10)
      : 0
  );
  const [paginationFromUrl, setPaginationFromUrl] = useState(
    paginationUrl !== null && paginationUrl !== 0
      ? parseInt(searchParams.get("pagination"), 10)
      : 100
  );
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: paginationFromUrl,
    page: pageFromUrl,
  });
  const currentLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  
  console.log("Values", formValues);

  const handlePageChange = (params) => {
    setPageState((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
    setPaginationModel((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
  };

  useEffect(() => {
    setSelectedRows(formValues?.permissions);
  }, [formValues.permissions]);

  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows])
  

  useEffect(() => {
    setLanguageDatagrid(assignDataGridLanguage(currentLanguage));
    extractParamsAndAssignState();
  }, [currentLanguage]);

  const onFilterChange = useCallback(
    (filterModel) => {
      setQueryOptions({ filterModel: { ...filterModel } });
    },
    [query]
  );

  useEffect(() => {
    query !== undefined && getPermissions();
  }, [pageState.page, pageState.pageSize, queryOptions, query]);

  useEffect(() => {
    searchParams.set("page", `${pageState.page}`);
    searchParams.set("pagination", `${pageState.pageSize}`);
    setSearchParams(searchParams);
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    const _query = queryOptions?.filterModel?.items[0];
    // console.log("Query with filterModel", _query);
    if (_query === undefined) {
      extractParamsAndAssignState();
    } else {
      const { field, value } = _query;
      if (field !== undefined && value !== undefined) {
        setQuery(`${field}=${value}`);
        searchParams.set("page", 0);
        searchParams.set("pagination", 100);
        searchParams.set(`${field}`, `${value}`);
        setSearchParams(searchParams);
      } else {
        setQuery({});
        searchParams.delete(`${field}`);
        setSearchParams(searchParams);
      }
    }
  }, [queryOptions]);

  const getPermissions = async () => {
    try {
      const token = await getToken();
      if (token) {
        setPageState((old) => ({ ...old, isLoading: true }));
        const currentPage = pageState.page + 1;
        const pagination = pageState.pageSize;
        const response = await GetAllPermissionsService(
          token,
          currentPage,
          query,
          pagination
        );
        console.log("Permissions response", response);
        const { data, links, meta } = response;
        console.log(data);
        if (Array.isArray(data) && data.length !== 0) {
          const mappedData = mapData(data);
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: mappedData,
            total: meta.total,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: [],
            total: 0,
          }));
        }
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mapData = (data) =>
    data.map((item) => {
      const mappedItem = {
        id: item.id,
        name: item.name,
        detail: item.detail,
      };
      return mappedItem;
    });

  const extractParamsAndAssignState = () => {
    const currentURL = window.location.href;
    const urlSearchParams = new URLSearchParams(currentURL.split("?")[1]);
    const paramsArray = [];

    urlSearchParams.forEach((value, key) => {
      paramsArray.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    });

    const state = paramsArray.join("&");
    setQuery(state);

    return state;
  };

  const handleRowSelection = (ids) => {
    setFormValues((prevState) => ({
      ...prevState,
      permissions: ids,
    }));
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection.selectionModel);
  };

  return (
    <>
      <DataGrid
        columns={HeadersPermissions(formValues.permissions)}
        rows={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        checkboxSelection
        page={pageState.page}
        localeText={languageDatagrid}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        onRowSelectionModelChange={(ids) => {
          handleRowSelection(ids);
        }}
        rowSelectionModel={selectedRows}
        slots={{ toolbar: CustomToolbar }}
        sx={{
          p: "2rem",
          borderColor: "grays.lightest",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.light",
          },
          "& .super-app-theme--header": {
            backgroundColor: "grays.light",
          },
        }}
      />
    </>
  );
}
