import Axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export function UpdateCommerceService(data, shopId, token) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("Data from Service", data);
      const response = await Axios.put(
        `${URL_MERCHANTS}/api/brain/v1/company/${shopId}`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log("Update Commerce Service Response: ", response);
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
