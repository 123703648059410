import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_CLIENT;

export const UpdateAccompanimentStatus = (token, elementId, status) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(
        `${URL_AUTH}/api/admin/v1/accompanyme/${elementId}/changestate`,
        {
          state: status,
          // rate: 4.5,
          // comment: "string",
        },
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response);
      resolve(response);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};