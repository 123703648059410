import Axios from "axios";

const URL_CLIENTS = import.meta.env.VITE_AUTH_CLIENT;

export function FinalizeAlertService(token, alertId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.put(
        `${URL_CLIENTS}/api/admin/v1/alert/${alertId}/finalize`,{},
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
