import axios from "axios";

const URL_CALLS = import.meta.env.VITE_CALL_API_URL;
const TOKEN = import.meta.env.VITE_CALLS_API_KEY;
const PROJECT_ID = import.meta.env.VITE_CALLS_API_PROJECT_ID;

export const GetRecentCalls = async (startDate, endDate) => {
  const request = [
    {
      Token_v: TOKEN,
      IdProyecto_i: PROJECT_ID,
      StartDate_d: startDate,
      EndDate_d: endDate,
    },
  ];
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${URL_CALLS}/public/llamadas/historial`;
      const recentCalls = await axios.post(url, request);
      const data = recentCalls.data;
      if (data.status === "error") {
        reject(data.response);
      }
      resolve(data.response);
    } catch (error) {
      reject(error);
    }
  });
};
