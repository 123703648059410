import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const HandleNotificationActions = (props) => {
  const [clickUserDetails, setClickUserDetails] = useState(false);
  const [clickEditRoles, setClickEditRoles] = useState(false);
  const [clickDeleteRole, setClickDeleteRole] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
     
      {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 212,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
         
      </Menu> */}
    </>
  );
};

export const HeadersNotification = () => {
  const { t } = useTranslation();

  const columns = [
    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 100,
    //   renderCell: (params) => {
    //     const notificationId = params?.row?.id;
    //     return (
    //       <HandleNotificationActions data={params?.row} notificationId={notificationId} />
    //     );
    //   },
    // },
    {
      field: "id",
      headerName: `ID`,
      width: 100,
    },
    {
      field: "message_type",
      headerName: t("notification.header-1"),
      width: 200,
    },
    {
      field: "message_subject",
      headerName: t("notification.header-2"),
      width: 200,
    },
    {
      field: "message_content",
      headerName: t("notification.header-3"),
      width: 270,
    },
    {
      field: "department_id",
      headerName: t("notification.header-4"),
      width: 180,
      renderHeader: (params) => (
        <div
          style={{ whiteSpace: "normal", lineHeight: "1.2", fontWeight: "500" }}
        >
          {params.colDef.headerName}
        </div>
      ),
    },
  
      {
        field: "gender_id",
        headerName: t("notification.header-5"),
        width: 150,
        renderHeader: (params) => (
            <div
              style={{ whiteSpace: "normal", lineHeight: "1.2", fontWeight: "500" }}
            >
              {params.colDef.headerName}
            </div>
          ),
      },
      {
        field: "city_id",
        headerName: t("notification.header-6"),
        width: 150,
        renderHeader: (params) => (
            <div
              style={{ whiteSpace: "normal", lineHeight: "1.2", fontWeight: "500" }}
            >
              {params.colDef.headerName}
            </div>
          ),
      },
      {
        field: "created_at",
        headerName: t("notification.header-7"),
        width: 200,
      },
      {
        field: "updated_at",
        headerName: t("notification.header-8"),
        width: 200,
      },


  ];
  return columns;
};
