import { useState, useEffect, Fragment } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import { activeMarkerMapping } from "../../adapters/maps/ActiveMarkerMapping";
import { ClientInfoByIdService } from "../../services/ClientInfoByIdService";
import { getToken } from "../../utils/GetToken";
import ModalInfoClient from "./ModalInfoClient";
import { useMarkers } from "../../context/MapContext";

const VITE_API_KEY_MAP = import.meta.env.VITE_API_KEY_MAP;

const containerStyle = {
  width: "80vw",
  height: "80vh",
};

export default function MapContainer({
  zoomMap,
  centerMap,
  path,
  keyValue,
  realTimeMarkers,
}) {
  const optionsMaps = {
    scrollwheel: true,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: VITE_API_KEY_MAP,
  });

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={optionsMaps}
          center={centerMap}
          zoom={zoomMap}
        >
          <Polygon
            options={{
              fillColor: "#FF0000",
              fillOpacity: 0.2,
              strokeColor: "#FF0000",
              strokeOpacity: 0.2,
              strokeWeight: 0.5,
            }}
            key={keyValue}
            paths={path}
          />
          <LocationMarker realTimeMarkers={realTimeMarkers} />
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
}

const LocationMarker = ({ realTimeMarkers }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markers, setMarkers] = useState({});
  const [clientIdSelected, setClientIdSelected] = useState(null);
  const { handleClientModalOpen, handleClientId } = useMarkers();

  useEffect(() => {
    const mappedMarkers = realTimeMarkers.map(activeMarkerMapping);

    setMarkers((existingMarkers) => {
      const updatedMarkers = { ...existingMarkers };

      mappedMarkers.forEach((newMarker) => {
        if (updatedMarkers[newMarker.clientId]) {
          const existingMarker = updatedMarkers[newMarker.clientId];
          if (
            existingMarker.lat !== newMarker.lat ||
            existingMarker.lng !== newMarker.lng
          ) {
            updatedMarkers[newMarker.clientId] = newMarker;
          }
        } else {
          updatedMarkers[newMarker.clientId] = newMarker;
        }
      });

      return updatedMarkers;
    });
  }, [realTimeMarkers]);

  const handleMarkerClick = (markerData) => {
    setSelectedMarker(markerData);
    setClientIdSelected(markerData?.clientId);
    handleClientModalOpen();
  };

  return (
    <>
      {Object.values(markers).map((markerData, index) => (
        <Marker
          key={index}
          position={{ lat: markerData.lat, lng: markerData.lng }}
          icon={{
            url: "/pointerUser.png",
          }}
          onClick={() => handleMarkerClick(markerData)}
        />
      ))}
      {Object.values(markers).map((markerData) => (
        <Fragment key={markerData.clientId}>
          {clientIdSelected === markerData.clientId && (
            <ModalInfoClient
              clientId={markerData.clientId}
              onCloseClick={() => setClientIdSelected(null)}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
