import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Skeleton,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getToken } from "../../utils/GetToken";
import { GetRoleInfoService } from "../../services/RolesAndPermissions/GetRoleInfoService";
import { useParams } from "react-router-dom";

export default function RoleDetails() {
  const [loading, setLoading] = useState(false);
  const [roleDetail, setRoleDetail] = useState({});
  const [permissions, setPermissions] = useState([]);
  const { t } = useTranslation();
  const { roleId } = useParams();

  useEffect(() => {
    const roleDetails = async () => {
      try {
        const token = await getToken();
        if (token) {
          const data = await GetRoleInfoService(token, roleId);
          console.log(data);
          const permissionsArray = JSON.parse(data.permissions);
          const permissionNames = permissionsArray.map(
            (permission) => permission.name
          );
          const role = {
            id: data.id,
            name: data.name,
            description: data.description,
            permissions: permissionNames,
          };
          setPermissions(role.permissions);
          setRoleDetail(role);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    roleDetails();
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={400}
          sx={{
            width: { xs: "320px", sm: "320px", md: "500px", xl: "872px" },
            borderRadius: "20px",
            mx: "1rem",
          }}
        />
      ) : (
        <Card
          elevation={5}
          sx={{ borderRadius: "20px", mx: "1rem", height: "auto", p: {xs: "0.5rem", sm: "2rem"} }}
        >
          <CardContent>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h6" color="primary.light">
                {t("roleDetails.title")}
              </Typography>
            </Stack>
            <Divider light sx={{ marginBlock: "1rem" }} />
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle1">
                  {t("roleDetails.name")}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="subtitle1" color="grays.medium">
                    {roleDetail.name}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography variant="subtitle1">
                    {t("roleDetails.description")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={400}
                    color="grays.medium"
                  >
                    {roleDetail.description}
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ marginBlock: "1rem" }} />
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h6" color="primary.light">
                  {t("roleDetails.permissions")}
                </Typography>
              </Stack>
              <Divider sx={{ marginBlock: "1rem" }} />
              <Stack spacing={1} mt={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="baseline"
                  ></Stack>
                  <List>
                    {permissions.map((permission, index) => (
                      <ListItem dense key={index}>
                        <ListItemIcon>
                          <CheckBoxIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="subtitle1">
                            {permission}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              </Stack>
            </Stack>
            {/* <Stack mt={2} alignItems="center">
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  onClick={() => console.log("Click")}
                  sx={{ width: "5rem" }}
                >
                  ver mas
                </Button>
              </Stack> */}
          </CardContent>
        </Card>
      )}
    </>
  );
}
