import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const DeleteAccountService = (token, account_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deletelist = await axios.delete(
        `${URL_AUTH}/api/brain/v1/admin/${account_id}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(deletelist.data);
    } catch (error) {
      reject(error);
    }
  });
};
