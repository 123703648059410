export const parseTransactionStatus = (status) => {
    const statusNumber = parseInt(status);
    switch (statusNumber) {
      case 1:
        return "Aprobado";
      case 2:
        return "Pendiente";
      case 3:
        return "Rechazado";
      default:
        return "";
    }
  }