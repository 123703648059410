import Axios from "axios";

const URL_TRANSACTIONS = import.meta.env.VITE_TRANSACTIONS_URL;

export function GetAllReportsService(
  token,
  page,
  query,
  pagination
) {
  return new Promise(async (resolve, reject) => {
    let url;
    if (query === "") {
      url = `${URL_TRANSACTIONS}/api/brain/v1/report/list?page=${page}&pagination=${pagination}`;
    } else {
      url = `${URL_TRANSACTIONS}/api/brain/v1/report/list?${query}&page=${page}&pagination=${pagination}`;
      console.log("URL with params inside", url);
    }
    try {
      const reportList = await Axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("Transaction List by Shop:", transactionList);
      resolve(reportList.data);
    } catch (error) {
      console.error("Error:", error);
      reject(error);
    }
  });
}