import Axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export function ResumeCommerceRegisterService(shopId, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `${URL_MERCHANTS}/api/brain/v1/company/${shopId}/registration/resume`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log("Response resume service: ", response.data);
      resolve(response.data);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}