import axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export const GetCommercesByIdService = (
  token,
  account_id,
  page,
  query,
  pagination
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log("Query from datagrid", query);
      let url;
      if (query === "") {
        url = `${URL_MERCHANTS}/api/brain/v1/account/${account_id}/company/list?page=${page}&pagination=${pagination}`;
      } else {
        // console.log("URL with params inside");
        url = `${URL_MERCHANTS}/api/brain/v1/account/${account_id}/company/list?${query}&page=${page}&pagination=${pagination}`;
      }

      const commerces = await axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(commerces.data);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};
