import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Alert,
  Card,
  CardContent,
  Grid,
  TextField,
  Autocomplete,
  MenuItem,
  Snackbar,
  CircularProgress,
  Stack,
  Divider,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getToken } from "../../utils/GetToken";
import { CreateTaxService } from "../../services/Taxes/CreateTaxService";
import { GetTaxTypesService } from "../../services/Taxes/GetTaxTypesService";
import { GetPeriodListService } from "../../services/Taxes/GetPeriodListService";
import { GetTaxByIdService } from "../../services/Taxes/GetTaxByIdService";
import { UpdateTaxService } from "../../services/Taxes/UpdateTaxService";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useNavigate, useLocation } from "react-router-dom";

export default function CreateNewTaxForm() {
  const [loading, setLoading] = useState(false);
  const [loadingTaxTypes, setLoadingTaxTypes] = useState({});
  const [loadingPeriods, setLoadingPeriods] = useState({});

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccesMessage] = useState("");

  const [taxTypeList, setTaxTypeList] = useState({});
  const [periodList, setPeriodList] = useState({});

  const [isTaxCreated, setIsTaxCreated] = useState(false);
  const [isTaxUpdated, setIsTaxUpdated] = useState(false);
  const [isTaxTypeSelected, setIsTaxTypeSelected] = useState(false);
  const [isTaxTypeTouched, setIsTaxTypeTouched] = useState(false);
  const [rangeAndConditionsSelected, setRangeAndConditionsSelected] =
    useState(false);

  const [formValues, setFormValues] = useState({
    tax_type: "",
    title: "",
    description: "",
    period_key: "",
    tax_value: "",
    min_sales: "",
    tax_under_sales: "",
    tax_over_sales: "",
  });

  const [taxSelected, setTaxSelected] = useState({});
  const [selectedTaxType, setSelectedTaxType] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { state } = useLocation();
  const { mode, taxId } = state !== null ? state : { mode: "new", taxId: "" };

  useEffect(() => {
    getTaxTypes();
    getPeriods();
  }, []);

  const getTaxTypes = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetTaxTypesService(token);
        console.log("Taxes response", response);
        const { data } = response;
        setTaxTypeList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTaxTypes(false);
    }
  };

  const getPeriods = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetPeriodListService(token);
        // console.log("Period response", response);
        const { data } = response;
        setPeriodList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPeriods(false);
    }
  };

  const getTaxInfo = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetTaxByIdService(token, taxId);
        setTaxSelected(response.data);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = await getToken();
      const formFiltered = Object.entries(formValues)
        .filter(([key, value]) => value !== "")
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
      if (mode === "edit") {
        const response = await UpdateTaxService(token, formFiltered, taxId);
        console.log(response);
        setIsTaxUpdated(true);
        setSuccesMessage(response?.message);
      } else {
        const response = await CreateTaxService(token, formFiltered);
        console.log(response);
        setIsTaxCreated(true);
        setSuccesMessage(response?.message);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleValueChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    setFormValues((prevState) => ({
      ...prevState,
      tax_value: numericValue,
    }));
    // console.log(numericValue)
  };

  const handleTaxTypeChange = (event, value) => {
    // setSelectedTaxType(value);
    setFormValues((prevState) => ({
      ...prevState,
      tax_type: value?.id,
    }));
    setIsTaxTypeSelected(true);
  };

  const handlePeriodChange = (event, value) => {
    // setSelectedTaxType(value);
    setFormValues((prevState) => ({
      ...prevState,
      period_key: value?.id,
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsTaxCreated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleTaxTypeBlur = () => {
    setIsTaxTypeTouched(true);
  };

  useEffect(() => {
    if (isTaxCreated || isTaxUpdated) {
      const redirectTimer = setTimeout(() => {
        navigate(`/plansManagement?tab=1`);
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isTaxCreated, isTaxUpdated]);

  useEffect(() => {
    if (formValues.tax_type === "RCO") {
      setRangeAndConditionsSelected(true);
    } else {
      setRangeAndConditionsSelected(false);
    }
  }, [formValues.tax_type]);

  useEffect(() => {
    if (mode === "edit") {
      getTaxInfo();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      setFormValues((prevState) => ({
        ...prevState,
        id: taxSelected?.id,
        tax_type: taxSelected?.tax_type?.id,
        title: taxSelected?.title,
        description: taxSelected?.description,
        tax_value: taxSelected?.value,
        period_key: taxSelected?.details?.period_key,
        min_sales: taxSelected?.details?.min_sales,
        tax_under_sales: taxSelected?.details?.tax_under_sales,
        tax_over_sales: taxSelected?.details?.tax_over_sales,
      }));
      setIsTaxTypeSelected(true);
    }
  }, [taxSelected]);

  useEffect(() => {
    findTaxType();
    findPeriod();
  }, [formValues.tax_type, formValues.period_key]);

  const findTaxType = () => {
    if (!isObjEmpty(periodList) && formValues.tax_type !== "") {
      const matchingTaxType = findTaxById(formValues.tax_type);
      if (matchingTaxType) {
        setSelectedTaxType(matchingTaxType);
      }
    }
  };

  const findPeriod = () => {
    if (!isObjEmpty(periodList) && formValues.period_key !== "") {
      const matchingPeriod = findPeriodById(formValues.period_key);
      if (matchingPeriod) {
        // console.log(matchingTaxType);
        setSelectedPeriod(matchingPeriod);
      }
    }
  };

  const findTaxById = (id) => {
    if (!isObjEmpty(periodList)) {
      return taxTypeList.filter((taxType) => taxType.id === id)[0];
    }
  };

  const findPeriodById = (id) => {
    if (!isObjEmpty(periodList)) {
      return periodList.filter((period) => period.id === id)[0];
    }
  };

  const isObjEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: "20px",
        mt: { xs: "1rem", sm: 0 },
        p: { xs: "0.4rem", sm: "2rem" },
        maxWidth: "100%",
      }}
    >
      <CardContent sx={{ mt: { xs: "1rem", sm: "0rem" } }}>
        <Snackbar
          open={isTaxCreated || isTaxUpdated}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{successMessage}</Alert>
        </Snackbar>
        {/* Error Response Alert*/}
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        {/* Form Create New Account */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="tax-types"
                options={
                  loadingTaxTypes
                    ? [{ name: "loading", description: "" }]
                    : taxTypeList
                }
                loadingText="Loading..."
                value={selectedTaxType !== null ? selectedTaxType : null}
                onChange={handleTaxTypeChange}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {option?.name}
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={selectedTaxType}
                    onBlur={handleTaxTypeBlur}
                    onChange={handleTaxTypeChange}
                    size="small"
                    name="tax_type"
                    label={t("createNewTax.form.label-1")}
                    error={isTaxTypeTouched && !isTaxTypeSelected}
                    required
                    helperText={
                      isTaxTypeTouched && !isTaxTypeSelected
                        ? t("requireField")
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="title"
                label={t("createNewTax.form.label-2")}
                value={formValues.title}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="description"
                label={t("createNewTax.form.label-3")}
                value={formValues.description}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            {!rangeAndConditionsSelected && (
              <Grid item xs={12} md={6}>
                <TextField
                  name="tax_value"
                  label={t("createNewTax.form.label-4")}
                  value={formValues.tax_value}
                  onChange={handleValueChange}
                  size="small"
                  fullWidth
                  required
                  inputProps={{
                    maxLength: 15,
                    inputMode: "numeric",
                  }}
                />
              </Grid>
            )}
            {rangeAndConditionsSelected && (
              <>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="period"
                    options={
                      loadingPeriods
                        ? [{ name: "loading", description: "" }]
                        : periodList
                    }
                    loadingText="Loading..."
                    value={selectedPeriod !== null ? selectedPeriod : null}
                    onChange={handlePeriodChange}
                    getOptionLabel={(option) => option?.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {option?.label}
                        </Typography>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handlePeriodChange}
                        value={selectedPeriod}
                        size="small"
                        name="period_key"
                        label={t("createNewTax.form.label-5")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="min_sales"
                    label={t("createNewTax.form.label-6")}
                    value={formValues.min_sales}
                    onChange={handleInputChange}
                    size="small"
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 15,
                      inputMode: "numeric",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="tax_under_sales"
                    label={t("createNewTax.form.label-7")}
                    value={formValues?.tax_under_sales}
                    onChange={handleInputChange}
                    size="small"
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 15,
                      inputMode: "numeric",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="tax_over_sales"
                    label={t("createNewTax.form.label-8")}
                    value={formValues?.tax_over_sales}
                    onChange={handleInputChange}
                    size="small"
                    fullWidth
                    required
                    inputProps={{
                      maxLength: 15,
                      inputMode: "numeric",
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Box mt={"2rem"} sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color={"secondary"}
              size="large"
              // sx={{ width: "100%" }}
              startIcon={
                loading ? (
                  <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                ) : (
                  <CheckCircleIcon sx={{ color: "white" }} />
                )
              }
            >
              {mode === "edit"
                ? t("update.button")
                : t("createCompany.form.submitButton")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
