import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoutes = ({
  isAllowed,
  redirectTo = "/",
  children,
}) => {
  const location = useLocation();

  const allowedRoutes = ["/password_recovery_link_send","/forgetPassword", "/changeForgottenPassword/:id","/reset/:token","/select_two_factor", "/password_recovery_link_send","/Confirm_email","/verification_code_email"];
  const isRouteAllowed = allowedRoutes.includes(location.pathname);

  if (!isAllowed && !isRouteAllowed) {
    return <Navigate to={redirectTo} replace/>;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoutes;