import { Box, Grid } from "@mui/material";
import Login from "../login-components/Login";
import WelcomeMessage from "../login-components/WelcomeMessage";
import { Suspense } from "react";
import { Content } from "../../utils/Content";

export default function LoginLayout() {
  return (
    <Box mt={6}>
      <Grid container columns={{ xs: 6, lg: 12 }} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent={{ xs: "center", lg: "flex-end" }}
          sx={{ pr: { xs: "0", sm: "2rem" } }}
        >
          <WelcomeMessage />
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <Suspense delayMs={3500}>
            <Login />
          </Suspense>
        </Grid>
      </Grid>
    </Box>
  );
}
