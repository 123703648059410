import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SummaryTransaction from "../transactions-components/SummaryTransaction";
import BuyerInfoCard from "../transactions-components/BuyerPayerInfoCard";
import { getToken } from "../../utils/GetToken";
import { TransactionByIdService } from "../../services/TransactionByIdService";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function DetailedTransactionLayout() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(true);
  const transactionId = state.transactionId.transactionId;
  const { isOpen } = useMenu();
  // console.log("transaction id:", transactionId);
  // console.log("state ID:", state.transactionId.transactionId);

  useEffect(() => {
    const getTransactionSummary = async () => {
      try {
        const token = await getToken();
        if (token) {
          const data = await TransactionByIdService(token, transactionId);
          // console.log(data);
          setTransaction(data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTransactionSummary();
  }, []);

  return (
    <Content open={isOpen}>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }}>
        <Link to={"/transactions"} className="nav-link_back" underline="none">
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("resumecard.title")}
        </Link>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"center"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("detailedTransaction.title")}
          </Typography>
        </Grid>
        <Grid
          spacing={2}
          container
          alignItems="flex-start"
          justifyContent="center"
          mt={3}
        >
          <Grid item xs={12} sm={6}>
            <SummaryTransaction transaction={transaction} loading={loading} />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <BuyerInfoCard
              transaction={transaction}
              loading={loading}
            />
          </Grid> */}
        </Grid>
      </Grid>
    </Content>
  );
}
