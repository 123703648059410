import { useState, useEffect } from "react";
import { Snackbar, Alert, Grid, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Content } from "../../utils/Content";
import { useMenu } from "../../context/MenuOpenContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import CreateNewZoneForm from "../zones-components/CreateNewZoneForm";
import { useZone } from "../../context/ZonesContext";
import { useNavigate, useLocation } from "react-router-dom";

export default function CreateNewZoneLayout() {
  const [mode, setMode] = useState("");
  const { isOpen } = useMenu();
  const { t } = useTranslation();
  const { isZoneCreated, resetZonesStates } = useZone();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  useEffect(() => {
    pathname.includes("edit")
      ? state.mode === "editAvailable"
        ? setMode("editAvailable")
        : setMode("edit")
      : pathname.includes("availableZones")
      ? setMode("available")
      : setMode("new");
    // console.log(mode);
  }, [pathname]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    finalizeCreateZone();
  };

  const finalizeCreateZone = () => {
    resetZonesStates();
    navigate(
      mode === "available" || mode === "editAvailable"
        ? "/zones?tab=1"
        : `/zones?tab=0`
    );
  };

  return (
    <Content open={isOpen} className="content">
      <Snackbar
        open={isZoneCreated}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {mode === "edit"
            ? t("zones.edit.success.message")
            : t("zones.createNew.success.message")}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={
            mode === "available" || mode === "editAvailable"
              ? "/zones?tab=1"
              : "/zones?tab=0"
          }
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box paddingX={"20px"}>
            <Typography
              variant="h4"
              color={"primary"}
              textAlign={"flex-start"}
              sx={{ textTransform: "uppercase" }}
              fontWeight={800}
            >
              {["edit", "editAvailable"].includes(mode)
                ? t("zones.edit.title")
                : t("zones.createNew.title")}
            </Typography>
          </Box>
          <Box my={2} paddingX={"20px"}>
            <Typography variant="body1" textAlign={"flex-start"}>
              {["edit", "editAvailable"].includes(mode)
                ? t("zones.edit.description")
                : mode === "available"
                ? t("zones.available.createNew.description")
                : t("zones.createNew.description")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ padding: "20px" }}>
            <CreateNewZoneForm mode={mode} />
          </Box>
        </Grid>
      </Grid>
    </Content>
  );
}
