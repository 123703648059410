import React, { useState } from "react";
import {
  Typography,
  Checkbox,
  Input,
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Link,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Alert,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
    remember_me: false,
  });
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const { Login } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const Auth = await Login(formValue);
    if (!Auth?.error) {
      Auth.status === 1 && goDashboard();
      Auth.status === 2 && confirmEmail();
      Auth.status === 3 && goTwoFactor();
    }
    setWrongCredentials(true);
    setLoading(false);
  };

  const onTaskChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "remember_me") {
      setFormValue({ ...formValue, remember_me: checked });
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const goDashboard = () => {
    navigate("/");
  };

  const goForgetPassword = () => {
    navigate("/select_two_factor")
  }

  const confirmEmail = () => {
    navigate("/confirm_email");
  };

  const goTwoFactor = () => {
    navigate("/verification_code_otp");
  };

  return (
    <Stack
      mt={{ xs: 0, xl: 2 }}
      sx={{
        justifyContent: "center",
        alignItems: { xs: "center", lg: "flex-start" },
      }}
    >
      <Card
        elevation={4}
        sx={{
          borderRadius: "20px",
          p: "1rem",
          mx: { xs: "0.4rem", sm: "10rem", md: "0" },
          mt: { xs: "0.5rem", md: 0 },
          maxWidth: "500px",
        }}
      >
        <CardContent>
          <Typography variant="h6" fontWeight={600} textAlign={"center"}>
            {t("login")}
          </Typography>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Box my={2}>
              {wrongCredentials && (
                <Alert severity="error">{t("login.error")}</Alert>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                name="email"
                label={t("username")}
                variant="standard"
                onChange={(e) => onTaskChange(e)}
                value={formValue.email}
                fullWidth
              />
            </Box>
            <FormControl
              sx={{ m: 1, width: "98%" }}
              variant="standard"
              fullWidth
            >
              <InputLabel htmlFor="standard-adornment-password">
                {t("password")}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => onTaskChange(e)}
                value={formValue.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "flex-end" }} mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...label}
                    name="remember_me"
                    onChange={(e) => onTaskChange(e)}
                    value={formValue.remember_me}
                    sx={{
                      "&.Mui-checked": {
                        color: "primary.light",
                      },
                    }}
                  />
                }
                label={t("rememberMe")}
              />
            </Box>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} mt={2}>
              <Grid item xs={6} container alignItems="center">
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  sx={{ borderRadius: "20px" }}
                  startIcon={
                    loading ? (
                      <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                    ) : (
                      <CheckCircleIcon />
                    )
                  }
                  type="submit"
                >
                  {t("login")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Link
                  onClick={goForgetPassword}
                  sx={{
                    fontSize: "15px",
                    display: "flex",
                    textAlign: "center",
                    color: "secondary.main",
                    cursor: "pointer",
                  }}
                  underline="hover"
                >
                  {t("forgetPassword")}
                </Link>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default React.memo(Login);
