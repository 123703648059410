import axios from "axios";

const URL_AGENTS = import.meta.env.VITE_AGENTS;

export function GetCompaniesListService(token, page, query, pagination) {
  return new Promise(async (resolve, reject) => {
    try {
      let url;
      if (query === "") {
        url = `${URL_AGENTS}/api/admin/v1/business?page=${page}&pp=${pagination}`;
      } else {
        url = `${URL_AGENTS}/api/admin/v1/business?${query}&page=${page}&pp=${pagination}`;
      }
      const response = await axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
