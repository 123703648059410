
import { ChangeForgottenPasswordService } from "../services/ChangeForgottenPasswordService";

export function ChangePassword(data) {
  return new Promise(async (resolve, reject) => {
    try {
			const response = await ChangeForgottenPasswordService(data);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
