import { CreateCompanyService } from "../services/CreateCompanyService";

export function create_company(data, token, accountId) {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await CreateCompanyService(data, token, accountId)
            resolve(response)
        } catch (error) {
            reject(error)
        }
    })
}