import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function DeleteImagePlanService(token, planId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(
        `${URL_AUTH}/api/admin/v1/plan/${planId}/image`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Imagen eliminada correctamente");
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
