import { useEffect, useState, useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getToken } from "../../utils/GetToken";
import { useTranslation } from "react-i18next";
import assignDataGridLanguage from "../../utils/AssignDatagridLanguage";
import { useSearchParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { GetAlertListService } from "../../services/Alerts/GetAlertListService";
import { HeadersUserAlertList } from "../../utils/TableHeaders/UserAlertsHeaders";
import { useAlert } from "../../context/AlertOptionsContext";

export default function UserAlertList() {
  const { t } = useTranslation();
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [languageDatagrid, setLanguageDatagrid] = useState({});
  const [queryOptions, setQueryOptions] = useState({});
  const [query, setQuery] = useState(searchParams ? searchParams : {});
  const pageUrl = searchParams.get("page");
  const paginationUrl = searchParams.get("pp");
  const fromParamUrl = searchParams.get("from");
  const toParamUrl = searchParams.get("to");
  const {
    alertOptions,
    handleAlertOptions,
    resetAlertOptions,
    handleIsValidateAlert,
    validateAlert,
    handleIsFinalizeAlert,
    finalizeAlert,
  } = useAlert();

  const [pageFromUrl, setPageFromUrl] = useState(
    pageUrl !== null && pageUrl !== 0
      ? parseInt(searchParams.get("page"), 10)
      : 0
  );
  const [paginationFromUrl, setPaginationFromUrl] = useState(
    paginationUrl !== null && paginationUrl !== 0
      ? parseInt(searchParams.get("pp"), 10)
      : 100
  );
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });
  const currentLanguage = localStorage.getItem("i18nextLng");
  const [dateFrom, setDateFrom] = useState(
    fromParamUrl !== null ? dayjs(searchParams.get("from")) : null
  );
  const [dateTo, setDateTo] = useState(
    toParamUrl !== null ? dayjs(searchParams.get("to")) : null
  );
  const currentURL = window.location.href;

  const handlePageChange = (params) => {
    setPageState((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
    setPaginationModel((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
  };

  useEffect(() => {
    setLanguageDatagrid(assignDataGridLanguage(currentLanguage));
    extractParamsAndAssignState();
  }, [currentLanguage]);

  const onFilterChange = useCallback(
    (filterModel) => {
      setQueryOptions({ filterModel: { ...filterModel } });
    },
    [query]
  );

  useEffect(() => {
    setPaginationModel((old) => ({
      ...old,
      page: pageFromUrl,
      pageSize: paginationFromUrl,
    }));
  }, [pageFromUrl, paginationFromUrl, query]);

  useEffect(() => {
    searchParams.set("page", `${pageState.page}`);
    searchParams.set("pp", `${pageState.pageSize}`);
    setSearchParams(searchParams);
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setPageState((old) => ({
      ...old,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    }));
    getAlerts();
  }, [pageState.page, pageState.pageSize, query, paginationModel]);

  const getAlerts = async () => {
    try {
      const token = await getToken();
      if (token) {
        setPageState((old) => ({ ...old, isLoading: true }));
        const currentPage = pageState.page + 1;
        const pagination = pageState.pageSize;
        const response = await GetAlertListService(
          token,
          currentPage,
          query,
          pagination
        );
        const { data, links, meta } = response;
        console.log(data);
        if (Array.isArray(data) && data.length !== 0) {
          const mappedData = mapData(data);
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: mappedData,
            total: meta.total,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: [],
            total: 0,
          }));
        }
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    const queryOptionsMapping = {
      state: "st",
      stateCode: "stid",
      alertTypeName: "at",
      alertTypeId: "atid",
    };
    const query = queryOptions?.filterModel?.items[0];

    if (query !== undefined) {
      const { field, value } = query;

      if (field !== undefined && value !== undefined) {
        const mappedField = queryOptionsMapping[field] || field;
        setQuery(`${mappedField}=${value}`);
        searchParams.set(mappedField, `${value}`);
        resetPagination();
        setSearchParams(searchParams);
      } else {
        setQuery({});
        searchParams.delete(field);
        setSearchParams(searchParams);
      }
    }
  }, [queryOptions]);

  const mapData = (data) =>
    data.map((item) => {
      const createdAt = dayjs(item.createdAt).format("DD/MM/YYYY H:m:s A");
      const mappedItem = {
        id: item.id,
        //alertTypeId: item.alertType.id,
        alertTypeName: item.alertType.name,
        state: item.state,
        //stateCode: item.state_code,
        description: item.description,
        createdAt: createdAt,
      };
      return mappedItem;
    });

  const extractParamsAndAssignState = () => {
    const currentURL = window.location.href;
    const urlSearchParams = new URLSearchParams(currentURL.split("?")[1]);
    const paramsArray = [];

    urlSearchParams.forEach((value, key) => {
      paramsArray.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    });

    const state = paramsArray.join("&");
    setQuery(state);

    return state;
  };

  useEffect(() => {
    const formattedDateFrom = dayjs(dateFrom).format("YYYY-MM-DD");
    if (formattedDateFrom !== null && dayjs(formattedDateFrom).isValid()) {
      searchParams.set(`from`, `${formattedDateFrom}`);
      setSearchParams(searchParams);
      extractParamsAndAssignState();
    } else {
      searchParams.delete(`from`);
      setSearchParams(searchParams);
      extractParamsAndAssignState();
    }
  }, [dateFrom]);

  useEffect(() => {
    const formattedDateTo = dayjs(dateTo).format("YYYY-MM-DD");

    if (formattedDateTo !== null && dayjs(formattedDateTo).isValid()) {
      searchParams.set(`to`, `${formattedDateTo}`);
      setSearchParams(searchParams);
      extractParamsAndAssignState();
    } else {
      searchParams.delete(`to`);
      setSearchParams(searchParams);
      extractParamsAndAssignState();
    }
  }, [dateTo]);

  const resetPagination = () => {
    searchParams.set("page", 0);
    searchParams.set("pagination", 100);
    setPageFromUrl(parseInt(searchParams.get("page"), 10));
    setPaginationFromUrl(parseInt(searchParams.get("pagination"), 10));
  };

  const handleTakeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleAlertOptions();
    resetAlertOptions();
  };

  const handleValidatedAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleIsValidateAlert();
    resetAlertOptions();
  };

  const handleFinalizedAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleIsFinalizeAlert();
    resetAlertOptions();
  };

  return (
    <>
      <DataGrid
        rows={pageState.data}
        columns={HeadersUserAlertList()}
        localeText={languageDatagrid}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page}
        filterMode="server"
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(params) => handlePageChange(params)}
        onFilterModelChange={onFilterChange}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        slots={{
          toolbar: GridToolbar,
        }}
        sx={{
          boxShadow: "0 1px 12px rgba(149, 157, 165, 0.2)",
          borderRadius: "25px",
          borderColor: "transparent",
          backgroundColor: "white",
          paddingX: "1.5rem",
          "& .MuiDataGrid-cell": {
            borderColor: "gray.light",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          "& .super-app-theme--header": {
            backgroundColor: "white",
          },
        }}
      />
      <Snackbar
        open={alertOptions}
        autoHideDuration={5000}
        onClose={handleTakeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {t("alert.activation.success.message")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={validateAlert}
        autoHideDuration={5000}
        onClose={handleValidatedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          {t("alert.validation.success.message")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={finalizeAlert}
        autoHideDuration={5000}
        onClose={handleFinalizedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("alert.finalize.success.message")}</Alert>
      </Snackbar>
    </>
  );
}
