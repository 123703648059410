import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function CreateResetPasswordCodeService(data) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("service Forgot Pass", data);
      const response = await Axios.post(
        `${URL_AUTH}/api/admin/v1/password/code/create`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
