import { Stack, Box, Typography } from "@mui/material";
import Logo from "../../assets/icons/logo.png";
import { useTranslation } from "react-i18next";

export default function WelcomeMessage() {
  const { t } = useTranslation();
  
  return (
    <Stack sx={{justifyContent:"center", alignItems:{xs:"center", lg:"flex-start"}}}>
      <Box sx={{ textAlign: { xs: "center", lg: "start" } }}>
        <img width="180px" src={Logo} />
      </Box>
      <Typography
        variant="h4"
        fontWeight={600}
        textAlign={{ xs: "center", lg: "start" }}
      >
        {t("welcome")}
      </Typography>
      <Typography textAlign={{ xs: "center", lg: "start" }}>
        {t("welcome.subtitle")}
      </Typography>
    </Stack>
  );
}
