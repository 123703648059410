import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Alert,
  Grid,
  TextField,
  Autocomplete,
  Snackbar,
  Divider,
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Container,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { DocumentTypeListService } from "../../services/DocumentTypeListService";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getGenderService } from "../../services/GenderListService";
import { getBloodTypesService } from "../../services/BloodTypesListService";
import { getEpsService } from "../../services/EpsListService";
import { getArlService } from "../../services/ArlListService";
import { useLocation, useNavigate } from 'react-router-dom';
import { UpdateAgentService } from "../../services/UpdateAgentService";
import { CreateAgentService } from "../../services/CreateAgentService";
import { PersonTypeListService } from "../../services/PersonTypeListService";
import { Content } from "../../utils/Content";
import { useMenu } from "../../context/MenuOpenContext";

export default function AgentInfoForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingDocTypes, setLoadingDocTypes] = useState(true);
  const [loadingPersonType, setLoadingPersonType] = useState(true);
  const [loadingGenders, setLoadingGenders] = useState(true);
  const [loadingBloodTypes, setLoadingBloodTypes] = useState(true);
  const [loadingArl, setLoadingArl] = useState(true);
  const [loadingEps, setLoadingEps] = useState(true);
  const [showAlertInvalidForm, setShowAlertInvalidForm] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationUserCreated, setConfirmationUserCreated] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [bloodTypesList, setBloodTypesList] = useState([]);
  const [epsList, setEpsList] = useState([]);
  const [arlList, setArlList] = useState([]);
  const [personTypeList, setPersonTypeList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedBloodTypes, setSelectedBloodTypes] = useState(null);
  const [selectedEps, setSelectedEps] = useState(null);
  const [selectedArl, setSelectedArl] = useState(null);
  const [selectedPersonType, setSelectedPersonType] = useState(null);
  const [formData, saveFormData] = useState({
    forename: '',
    secondForename: '',
    surname: '',
    secondSurname: '',
    email: '',
    password: '',
    password_confirmation: '',
    movilNumber: '',
    movilDial: '57',
    documentNumber: '',
    address: '',
    eps: '',
    arl: '',
    gender: '',
    documentType: '',
    bloodType: '',
  });
  const { mode, data } = location.state || {};
  const { t } = useTranslation();
  const { isOpen } = useMenu();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    saveFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const transformDataToFormData = (data) => {
    return {
      forename: data.forename,
      secondForename: data.secondForename,
      surname: data.surname,
      secondSurname: data.secondSurname,
      email: data.email,
      password: '',
      password_confirmation: '',
      movilNumber: data.movil.number,
      movilDial: data.movil.dial,
      documentNumber: data.document_number,
      address: data.address
    };
  };

  const CreatePayload = () => {
    return {
      forename: formData.forename,
      secondForename: formData.secondForename,
      surname: formData.surname,
      secondSurname: formData.secondSurname,
      email: formData.email,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
      movilNumber: formData.movilNumber,
      movilDial: formData.movilDial,
      documentNumber: formData.documentNumber,
      address: formData.address,
      eps: selectedEps.id,
      arl: selectedArl.id,
      documentType: selectedDocType.id,
      gender: selectedGender.id,
      bloodType: selectedBloodTypes.id,
      personType: selectedPersonType.id
    };
  };


  useEffect(() => {
    if (data) {
      const transformedData = transformDataToFormData(data);
      saveFormData(transformedData);
      setSelectedDocType(data.document?.typeInfo);
      setSelectedGender(data.gender);
      setSelectedBloodTypes(data.bloodType);
      setSelectedEps(data.eps);
      setSelectedArl(data.arl);
      setSelectedPersonType(data.personType)
    }
  }, [data]);

  const handlePersonTypeChange = (event, value) => {
    setSelectedPersonType(value)
    if (value?.id !== null) {
      saveFormData({
        ...formData,
        personType: value?.id,
      });
    }
  };
  const handleArlChange = (event, value) => {
    setSelectedArl(value)
    if (value?.id !== null) {
      saveFormData({
        ...formData,
        arl: value?.id,
      });
    }
  };
  const handleEpsChange = (event, value) => {
    setSelectedEps(value)
    if (value?.id !== null) {
      saveFormData({
        ...formData,
        eps: value?.id,
      });
    }
  };

  const handleBloodTypeChange = (event, value) => {
    setSelectedBloodTypes(value)
    if (value?.id !== null) {
      saveFormData({
        ...formData,
        bloodType: value?.id,
      });
    }
  };

  const handleGenderChange = (event, value) => {
    setSelectedGender(value)
    if (value?.id !== null) {
      saveFormData({
        ...formData,
        gender: value?.id,
      });
    }
  };

  const handleDocTypeChange = (event, value) => {
    setSelectedDocType(value)
    if (value?.id !== null) {
      saveFormData({
        ...formData,
        documentType: value?.id
      });
    }
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    saveFormData({
      ...formData,
      password: value,
    });
    validatePassword(value);
  };

  const handlePasswordConfirmChange = (event) => {
    const { value } = event.target;
    saveFormData({
      ...formData,
      password_confirmation: value,
    });
    validatePasswordEquals(value);
  };

  const validatePassword = (value) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    if (!passwordRegex.test(value)) {
      setPasswordError(`${t("createAccount.form.passwordValidation-1")}`);
    } else {
      setPasswordError("");
    }
  };

  const validatePasswordEquals = (value) => {
    if (formData.password !== value) {
      setPasswordConfirmError(
        `${t("createAccount.form.passwordValidation-2")}`
      );
    } else {
      setPasswordConfirmError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getToken();
    console.log(formData);
    const request = CreatePayload();
    // console.log("This is data to submit:", form);
    if (mode === "edit") {
      try {
        setLoading(true);
        console.log("This is data to update:", request);
        await UpdateAgentService(
          request,
          token,
          data?.id
        );
        setConfirmationUserCreated(true);
        navigate(`/usersManagement?tab=2`);
      } catch (error) {
        setError(true);
        setErrorMessage(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await CreateAgentService(request, token);
        setConfirmationUserCreated(true);
        navigate(`/usersManagement?tab=2`);
      } catch (error) {
        setError(true);
        setErrorMessage(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleBack = () => {
    navigate(`/usersManagement?tab=2`);
  };

  const handleCloseInvalidForm = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertInvalidForm(false);
  };

  const handleCloseConfirmationUserCreated = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setConfirmationUserCreated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    getDocumentTypes();
    getGender();
    getBloodTypes();
    getEps();
    getArl();
    getPersonType();
  }, []);

  const getPersonType = async () => {
    const personType_List = await PersonTypeListService();
    setPersonTypeList(personType_List?.data);
    setLoadingPersonType(false);
  };
  const getDocumentTypes = async () => {
    const documentTypes_List = await DocumentTypeListService();
    setDocumentTypes(documentTypes_List?.data);
    setLoadingDocTypes(false);
  };

  const getGender = async () => {
    const gender_List = await getGenderService();
    setGenderList(gender_List?.data);
    setLoadingGenders(false);
  };

  const getBloodTypes = async () => {
    const bloodType_List = await getBloodTypesService();
    setBloodTypesList(bloodType_List?.data);
    setLoadingBloodTypes(false);
  };

  const getEps = async () => {
    const eps_List = await getEpsService();
    setEpsList(eps_List?.data);
    setLoadingEps(false);
  };

  const getArl = async () => {
    const arl_List = await getArlService();
    setArlList(arl_List?.data);
    setLoadingArl(false);
  };

  return (
    <Content open={isOpen} className="content">
      <Box>
        <Box mb={2}>
          <Typography variant="h6" fontWeight={600} textAlign={"center"}>
            {t("step.one.label")}
          </Typography>
        </Box>
        <Box my={4}>
          <Divider variant="middle">
            <Chip label={t("createAccount.form.subtitle.one")} />
          </Divider>
        </Box>
        <Snackbar
          open={showAlertInvalidForm}
          autoHideDuration={6000}
          onClose={handleCloseInvalidForm}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity="error"
            onClose={() => setShowAlertInvalidForm(false)}
          >
            {t("createAccount.form.invalidForm")}
          </Alert>
        </Snackbar>
        {/* User Created or Updated Alert*/}
        <Snackbar
          open={confirmationUserCreated}
          autoHideDuration={6000}
          onClose={handleCloseConfirmationUserCreated}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{t("createAccount.form.success")}</Alert>
        </Snackbar>
        {/* Error Response Alert*/}
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        {/* Form Create New Account */}
        <Container maxWidth='xl'>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="forename"
                  label={t("createAccount.form.forename")}
                  size="small"
                  value={formData.forename}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="secondForename"
                  label={t("createAccount.form.middleName")}
                  size="small"
                  value={formData.secondForename || ""}
                  onChange={handleInputChange}
                  required={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="surname"
                  label={t("createAccount.form.surname")}
                  size="small"
                  value={formData.surname || ""}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="secondSurname"
                  label={t("createAccount.form.secondSurname")}
                  size="small"
                  value={formData.secondSurname || ""}
                  onChange={handleInputChange}
                  required={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="personType"
                  options={
                    loadingPersonType
                      ? [{ description: "Loading..." }]
                      : personTypeList
                  }
                  loading={loadingPersonType}
                  loadingText="Loading..."
                  value={selectedPersonType || null}
                  onChange={handlePersonTypeChange}
                  autoHighlight
                  getOptionLabel={(option) => option.type}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="personType"
                      required
                      label={t("clientList.headers-6")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="docType-select"
                  options={
                    loadingDocTypes
                      ? [{ description: "Loading..." }]
                      : documentTypes
                  }
                  loading={loadingDocTypes}
                  loadingText="Loading..."
                  value={selectedDocType || null}
                  onChange={handleDocTypeChange}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="documentType"
                      required
                      label={t("createAccount.form.document.type")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="documentNumber"
                  label={t("createAccount.form.document.number")}
                  size="small"
                  value={formData.documentNumber || ''}
                  onChange={handleInputChange}
                  type="number"
                  required
                  fullWidth
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Grid>


              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="gender"
                  options={loadingGenders ? [{ name: "loading" }] : genderList}
                  loading={loadingGenders}
                  value={selectedGender || null}
                  loadingText="Loading..."
                  onChange={handleGenderChange}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="gender"
                      label="Gender"
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="address"
                  label={t("createAccount.form.address")}
                  size="small"
                  value={formData.address || ""}
                  onChange={handleInputChange}
                  required={false}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="bloodType"
                  options={loadingBloodTypes ? [{ name: "loading" }] : bloodTypesList}
                  loading={loadingBloodTypes}
                  value={selectedBloodTypes || null}
                  loadingText="Loading..."
                  onChange={handleBloodTypeChange}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="bloodType"
                      label="Blood Type"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="eps"
                  options={loadingEps ? [{ name: "loading" }] : epsList}
                  loading={loadingEps}
                  value={selectedEps || null}
                  loadingText="Loading..."
                  onChange={handleEpsChange}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="eps"
                      label="EPS"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="arl"
                  options={loadingArl ? [{ name: "loading" }] : arlList}
                  loading={loadingArl}
                  value={selectedArl || null}
                  loadingText="Loading..."
                  onChange={handleArlChange}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="arl"
                      label="ARL"
                      required
                    />
                  )}
                />
              </Grid>

            </Grid>
            <Box my={4}>
              <Divider variant="middle">
                <Chip label={t("createAccount.form.subtitle.two")} />
              </Divider>
            </Box>
            <Grid container spacing={2}>

              <Grid item xs={12} md={6}>
                <TextField
                  name="movilNumber"
                  label={t("createAccount.form.mobile")}
                  size="small"
                  type="tel"
                  value={formData.movilNumber || ""}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  inputProps={{ inputMode: "numeric" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="email"
                  label="Email"
                  size="small"
                  type="email"
                  value={formData.email || ""}
                  onChange={handleInputChange}
                  error={
                    !isEmailValid(
                      formData.email !== ""
                        ? formData.email
                        : "example@email.com"
                    )
                  }
                  helperText={
                    !isEmailValid(
                      formData.email !== ""
                        ? formData.email
                        : "example@email.com"
                    ) && t("createAccount.form.email.helper")
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("password")}
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={handlePasswordChange}
                    value={formData.password || ""}
                    error={passwordError !== ""}
                    required={mode !== "edit"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t("password")}
                  />
                  {passwordError && (
                    <FormHelperText error id="passwordError-error">
                      {passwordError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("createAccount.form.confirmPassword")}
                  </InputLabel>
                  <OutlinedInput
                    id="password_confirmation"
                    name="password_confirmation"
                    type={showPassword ? "text" : "password"}
                    onChange={handlePasswordConfirmChange}
                    value={formData.password_confirmation || ""}
                    error={passwordConfirmError !== ""}
                    required={mode !== "edit"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t("createAccount.form.confirmPassword")}
                  />
                  {passwordConfirmError && (
                    <FormHelperText error id="passwordConfirm-error">
                      {passwordConfirmError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {mode !== "edit" ? (
              <>
                <Grid container mt={2} sx={{ textAlign: "center" }} gap={2}>
                  <Grid item xs>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Button
                        variant="contained"
                        color={"secondary"}
                        size="large"
                        onClick={handleBack}
                      >
                        {t("createAccount.cancelar.button")}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box display={"flex"} justifyContent={"flex-start"}>
                      <Button
                        type="submit"
                        variant="contained"
                        color={"secondary"}
                        size="large"
                        startIcon={
                          loading ? (
                            <CircularProgress
                              size="1.5rem"
                              sx={{ color: "white" }}
                            />
                          ) :
                            <CheckCircleOutlineIcon sx={{ color: "white" }} />
                        }
                      >
                        {`${t("save.button")}`}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container mt={2} sx={{ textAlign: "center" }} gap={2}>
                <Grid item xs>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Button
                        variant="contained"
                        color={"secondary"}
                        size="large"
                        onClick={handleBack}
                      >
                        {t("createAccount.cancelar.button")}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box display={"flex"} justifyContent={"flex-start"}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={
                          loading ? (
                            <CircularProgress
                              size="1.5rem"
                              sx={{ color: "white" }}
                            />
                          ) : (
                            <CheckCircleOutlineIcon sx={{ color: "white" }} />
                          )
                        }
                      >
                        {t("updateAccountButton.text")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        </Container>
      </Box>
    </Content>
  );
}
