import { useState, useEffect } from "react";
import { Grid, Typography, Box, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useTranslation } from "react-i18next";
import SensitiveDataChangesList from "../sensitive-data-changes-components/SensitiveDataChangesList";
import { getToken } from "../../utils/GetToken";
import { ClientInfoByIdService } from "../../services/ClientInfoByIdService";
import { useParams } from "react-router-dom";
import ReviewSensitiveChangesLayout from "./ReviewSensitiveChangesLayout";
import { useReviewSensitiveChange } from "../../context/ReviewSensiteChangeContext";

export default function SensitiveDataChangesLayout() {
  const [showDialog, setShowDialog] = useState(false);
  const [docId, setDocId] = useState("");
  const [shopName, setShopName] = useState("");
  const [showAlertApproved, setShowAlertApproved] = useState(false);
  const [showAlertRejected, setShowAlertRejected] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();
  const { sensitiveChangeApproved, sensitiveChangeRejected } =
    useReviewSensitiveChange();

  const { shopId } = useParams();

  const handleShowDialog = (id) => {
    setShowDialog(!showDialog);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
  };

  const handleErrorMessage = (message) => {
    setErrorMessage(message);
  };

  const handleCloseDocApproved = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertApproved(false);
  };

  const handleCloseDocRejected = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertRejected(false);
  };

  useEffect(() => {
    sensitiveChangeApproved && setShowAlertApproved(true);
    sensitiveChangeRejected && setShowAlertRejected(true);
  }, [sensitiveChangeApproved, sensitiveChangeRejected]);

  return (
    <Content open={isOpen}>
      <Grid container direction="column">
        <Grid item xs paddingX={"20px"}>
          <Snackbar
            open={showAlertApproved}
            autoHideDuration={6000}
            onClose={handleCloseDocApproved}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("approveDoc.message")}</Alert>
          </Snackbar>
          <Snackbar
            open={showAlertRejected}
            autoHideDuration={6000}
            onClose={handleCloseDocRejected}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="info">{t("rejectDoc.message")}</Alert>
          </Snackbar>
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"flex-start"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("sensitiveChanges.title")}
          </Typography>
          <Box my={2}>
            <Typography variant="body1" textAlign={"flex-start"}>
              {t("sensitiveChanges.subtitle")}
            </Typography>
          </Box>
        </Grid>
        <Box
          mt={2}
          sx={{
            height: 600,
            width: "100%",
            paddingInline: { xs: 0, sm: "1rem" },
          }}
        >
          <SensitiveDataChangesList />
        </Box>
      </Grid>
      {showDialog && <ReviewSensitiveChangesLayout docId={docId} />}
    </Content>
  );
}
