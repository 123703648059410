import { createTheme } from "@mui/material/styles";

export const Colors = {
  primary: "#A054D2",
  primary_b: "#1D417A",
  secondary: "#FE4E85",
  secondary_b: "#541D5C",
  success: "#6BE58A",
  warning: "#F9ED07",
  danger: "#FF6660",
  dark: "#0e1b20",



  // Grays
  gris_1: "#EEEEEE",
  gris_2: "#CCCCCC",
  gris_3: "#AAAAAA",
  gris_4: "#888888",
  gris_5: "#666666",
};

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Roboto"].join(","),
    // h2: {
    //   fontSize: "2rem",
    //   "@media (min-width:600px)": {
    //     fontSize: "3rem",
    //   },
    // },
  },
  container: {
    bgColor: "black",
  },
  palette: {
    primary: {
      main: Colors.primary,
      light: Colors.primary_b,
      // contrastText: Colors.gris_1,
    },
    secondary: {
      main: Colors.secondary,
      light: Colors.secondary_b,
    },
    success: {
      main: Colors.success,
    },
    error: {
      main: Colors.danger,
      // contrastText: "white",
    },
    warning: {
      main: Colors.warning,
    },
    grays: {
      darkest: Colors.gris_5,
      dark: Colors.gris_4,
      medium: Colors.gris_3,
      light: Colors.gris_2,
      lightest: Colors.gris_1,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          boxShadow: "3px"
        },
      },
      variants: [
        {
          props: { variant: "dark" },
          style: {
            color: "white",
            backgroundColor: Colors.primary,
            "&:hover": {
              backgroundColor: Colors.primary_b,
            },
          },
        },
        {
          props: { variant: "yellow" },
          style: {
            color: "white",
            backgroundColor: Colors.primary_b,
            "&:hover": {
              backgroundColor: Colors.primary,
            },
          },
        },
        {
          props: { variant: "purple" },
          style: {
            color: "white",
            backgroundColor: Colors.secondary_b,
            WebkitBoxShadow: 2,
            "&:hover": {
              backgroundColor: Colors.primary_b,
            },
          },
        },
        {
          props: { variant: "important" },
          style: {
            color: "white",
            backgroundColor: Colors.primary_b,
            "&:hover": {
              backgroundColor: Colors.primary,
            },
            minWidth: 200,
            transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
            "&:hover": {
              transform: "scale(1.1)",
              label: {
                color: Colors.primary,
                textTransform: "none",
                fontSize: 15,
                fontWeight: 700,
              },
              contained: {
                minHeight: 30,
                boxShadow: 2,
                "&:active": {
                  boxShadow: Colors.primary,
                },
              },
            },
          },
        },
      ],
    },
  },
});

export default theme;
