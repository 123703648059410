import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const GetPlansStatsServices = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${URL_AUTH}/api/admin/v1/count-plans`, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
};
