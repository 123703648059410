import { Divider, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LogoTitle from "./LogoTitle";
import UserAvatarInfo from "./UserAvatarInfo";
import Navlinks from "../../utils/NavLinks";
import { useMediaQuery } from "@mui/material";

export default function SideMenuListItems({ open, setOpen }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        pt: isMobile ? "0" : "2rem",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "2px",
        },
      }}
    >
      {open ? <LogoTitle /> : <Divider sx={{ my: "2.5rem" }} />}
      <UserAvatarInfo />
      <Divider />
      <Navlinks setOpen={setOpen} />
      <Divider />
    </Box>
  );
}
