import { createContext, useState, useContext } from "react";

export const CreateReviewContext = createContext();

export const useReviewDoc = () => useContext(CreateReviewContext);

export const ReviewProvider = ({ children }) => {
  const [docApproved, setDocApproved] = useState(false);
  const [docRejected, setDocRejected] = useState(false);
  const [isDocumentDeleted, setIsDocumentDeleted] = useState(false);

  const handleDocApproved = () => {
    setDocApproved(true);
  };

  const handleDocRejected = () => {
    setDocRejected(true);
  };

  const handleDocumentDeleted = () => {
    setIsDocumentDeleted(true);
  };

  const resetDocStates = () => {
    setDocApproved(false);
    setDocRejected(false);
  };

  return (
    <CreateReviewContext.Provider
      value={{
        docApproved,
        docRejected,
        handleDocApproved,
        handleDocRejected,
        resetDocStates,
        handleDocumentDeleted,
        isDocumentDeleted,
      }}
    >
      {children}
    </CreateReviewContext.Provider>
  );
};
