import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const HandleUserNotificationActions = (props) => {
  const [clickUserDetails, setClickUserDetails] = useState(false);
  const [clickEditRoles, setClickEditRoles] = useState(false);
  const [clickDeleteRole, setClickDeleteRole] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { data } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotification = () => {
    handleClose();
    const token = data?.fcm_token;
    navigate(`/notification/${token}`, {
      state: { userData: data },
    });
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 212,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
         <MenuItem onClick={handleClickNotification } >
          <ListItemIcon>
        <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText>{t("notificationOptions.item-1")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export const HeadersUserNotification = () => {
  const { t } = useTranslation();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 100,
      renderCell: (params) => {
        const userId = params?.row?.id;
        return (
          <HandleUserNotificationActions data={params?.row} userId={userId} />
        );
      },
    },
    {
      field: "id",
      headerName: `ID`,
      width: 100,
    },
    {
      field: "fullName",
      headerName: t("clientList.headers-2"),
      width: 200,
    },
    {
      field: "fcm_token",
      headerName: t("clientList.headers-12"),
      width: 200,
    },
    {
      field: "movil_number",
      headerName: t("clientList.headers-7"),
      width: 200,
    },
    {
      field: "email",
      headerName: t("clientList.headers-4"),
      width: 250,
    },
  ];
  return columns;
};
