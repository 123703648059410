import { Box } from '@mui/material';
import React from 'react';
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";

export default function SupportLayout() {
  const {isOpen} = useMenu();

  return (
    <Content open={isOpen}>SupportLayout</Content>
  )
}
