import Axios from "axios";

const URL_TRANSACTIONS = import.meta.env.VITE_AUTH_URL;

export function GetTransactionsListService(token, page, query, pagination) {
  return new Promise(async (resolve, reject) => {
    try {
      let url;
      if (query === "") {
        // url = `${URL_TRANSACTIONS}/api/sandbox/v1/brain/transaction/list?page=${page}&pagination=${pagination}`;
        url = `${URL_TRANSACTIONS}/api/epayco/v1/list-transactions`;
      } else {
        console.log("URL with params inside", query);
        // url = `${URL_TRANSACTIONS}/api/sandbox/v1/brain/transaction/list?${query}&page=${page}&pagination=${pagination}`;
        url = `${URL_TRANSACTIONS}/api/epayco/v1/list-transactions?${query}`;
      }
      const transactionList = await Axios.get(
        url,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(transactionList.data);
    } catch (error) {
      // console.error("Error:",error);
      reject(error);
    }
  });
}
