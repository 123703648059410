import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useTranslation } from "react-i18next";

export default function PageNotFound() {
  const { t } = useTranslation();

  return (
    <Box className="content">
      <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
        <Stack
          direction="row"
          sx={{ alignItems: "center", justifyContent: "center" }}
          gap={1}
          mt={5}
        >
          <SentimentVeryDissatisfiedIcon fontSize="large" />
          <Typography variant="h2" fontWeight={700}>
            404
          </Typography>
        </Stack>
        <Typography variant="h3" textAlign="center">Page Not Found</Typography>
        <Box mt={3}>
          <Link to="/">
            <Button variant="yellow">{t("backToHome.button")}</Button>
          </Link>
        </Box>
      </Stack>
    </Box>
  );
}
