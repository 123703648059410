import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Stack,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useForm } from "../../context/CreateCommerceFormContext";
import { AssignPlanService } from "../../services/AssignPlanService";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { useParams } from "react-router-dom";

export default function ChoosePlanForm({
  plans,
  savedPlan,
  setSavedPlan,
  plan,
  mode,
}) {
  const [loading, setLoading] = useState(false);
  const { formData, saveFormData } = useForm();
  const { selectedValues, saveSelectedValues } = useForm();
  const [error, setError] = useState(false);
  const { shopId } =
    mode === "continueReg" || mode === "edit"
      ? useParams()
      : { shopId: localStorage.getItem("shopId") };
  const commerceId = shopId;

  const { t } = useTranslation();

  useEffect(() => {
    setSavedPlan(false);
    // console.log(plan);
    // plan !== null &&
    //   saveSelectedValues({ ...selectedValues, selectedPlan: plan });
    mode === "continueReg" && setCurrentStep();
  }, []);

  const setCurrentStep = () => {
    if (mode === "continueReg") {
      saveFormData({
        ...formData,
        step: 3,
      });
    }
  };

  const handleChange = (event) => {
    const _plan = event.target.value;
    saveFormData({
      ...formData,
      plan: _plan,
    });
    saveSelectedValues({
      ...selectedValues,
      selectedPlan: _plan,
    });
    // setFormValues({
    //   ...formValues,
    //   plan: _plan,
    // });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = filterByKeys(formData, ["plan", "step"]);
    try {
      const token = await getToken();
      const shopId =
        mode === "continueReg" ? commerceId : localStorage.getItem("shopId");
      const response = await AssignPlanService(token, shopId, form);
      console.log(response);
      saveSelectedValues({
        ...selectedValues,
        selectedPlan: form?.plan,
      });
      setSavedPlan(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const filterByKeys = (data, keys) => {
    const filteredData = {};

    for (const key of keys) {
      if (key in data) {
        filteredData[key] = data[key];
      }
    }

    return filteredData;
  };

  const transformDescription = (descriptionObj) => {
    return Object.values(descriptionObj);
  };

  const controlProps = (item) => ({
    checked: selectedValues.selectedPlan === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  useEffect(() => {
    if (plan !== null && (mode === "continueReg" || mode === "edit")) {
      saveSelectedValues({
        ...selectedValues,
        selectedPlan: formData.plan === "" ? `${plan.plan}` : `${formData.plan}`,
      });
    } else {
      saveFormData({
        ...formData,
        plan: 1,
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container mt={{ xs: 0, md: 4 }}>
        {plans.map((plan, index) => (
          <Grid key={plan.name} item xs>
            <Card
              elevation={4}
              key={index}
              sx={{
                p: "1rem",
                mr: { xs: "0.4rem", sm: "10rem", md: "6rem" },
                mt: { xs: "2rem", md: "0" },
                ml: "1rem",
                maxWidth: "800px",
                borderRadius: 8,
                height: "100%",
              }}
            >
              <CardHeader
                title={
                  <Stack>
                    <Grid container>
                      <Grid item xs={6}>
                        {plan.recommended && (
                          <Chip label="Recomendado" color="success" />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <FormControlLabel
                            id={plan.id}
                            value={selectedValues.selectedPlan}
                            control={
                              <Radio
                                {...controlProps(`${plan.id}`)}
                                sx={{
                                  color: "#888888",
                                  "&.Mui-checked": {
                                    color: "#ffb61d",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 28,
                                  },
                                }}
                              />
                            }
                            sx={{ color: "#888888", fontSize: "5px" }}
                            // label="Large"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <Typography variant="h6">Plan Personalizado</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Según negociación
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                }
              />
              <CardContent>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle2">Medios de Pago</Typography>
                  <Stack>
                    <Typography color={"gray"} variant="body2">
                      PSE
                    </Typography>
                  </Stack>
                </Stack>
                <Stack key={plan.name} spacing={0.5} mt={3}>
                  <Typography variant="subtitle2">Soluciones</Typography>
                  {transformDescription(plan.characteristics).map(
                    (value, idx) => (
                      <Stack
                        direction={"row"}
                        spacing={1.5}
                        alignItems={"center"}
                        key={idx}
                      >
                        <FiberManualRecordIcon
                          sx={{ fontSize: "5px", color: "gray" }}
                        />
                        <Typography color={"gray"} variant="body2">
                          {value}
                        </Typography>
                      </Stack>
                    )
                  )}
                </Stack>
                <Stack key={index} spacing={0.5} mt={3}>
                  <Typography variant="subtitle2">Plus</Typography>
                  {transformDescription(plan.plus).map((value, idx) => (
                    <Stack
                      key={idx}
                      direction={"row"}
                      spacing={1.5}
                      alignItems={"center"}
                    >
                      <FiberManualRecordIcon
                        sx={{ fontSize: "5px", color: "gray" }}
                      />
                      <Typography color={"gray"} variant="body2">
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid container>
          <Grid item xs={12}>
            <Box mt={5} sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color={"secondary"}
                size="large"
                // disabled={selectedPlan}
                startIcon={
                  loading ? (
                    <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                  ) : (
                    <SaveIcon sx={{ color: "white" }} />
                  )
                }
              >
                {t("save.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
