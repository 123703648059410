import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function DeleteAdminService(id, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(
        `${URL_AUTH}/api/admin/v1/admins/${id}`,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}