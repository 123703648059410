import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function Enable2FAService(data, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `${URL_AUTH}/api/admin/v1/twofactor/enable`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
