import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Alert,
  Card,
  CardContent,
  Stack,
  Divider,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ChangePassword } from "../../helpers/ChangePassword";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ChangeForgottenPasswordLayout() {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    new_password: "",
    new_password_confirmation: "",
  });
  const [error, setError] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { data } = location.state || {};
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      verifyNewPassword(formValue.new_password, formValue.new_password_confirmation);
        if (!error) {
          const body = {
            ...data?.data,
            ...formValue
          }
          const response = await ChangePassword(body);
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("refreshTokenTime");
          setPasswordChanged(true);
        }
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onTaskChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const verifyNewPassword = (newPassword, passwordConfirmation) => {
    if (newPassword !== passwordConfirmation) {
      setError(true);
      return;
    }
  };

  const goHome = () => {
    navigate("/");
  };

  useEffect(() => {
    if (error) {
      const redirectTimer = setTimeout(() => {
        setError(false);
      }, 8000);

      return () => clearTimeout(redirectTimer);
    }
  }, [error]);

  useEffect(() => {
    if (passwordChanged) {
      const redirectTimer = setTimeout(() => {
        goHome();
        window.location.reload();
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }
  }, [passwordChanged]);

  return (
    <Box className="login">
      <Stack
        mt={{ xs: 0, md: 2 }}
        sx={{
          justifyContent: "center",
          alignItems: { xs: "center" },
        }}
      >
        <Card
          elevation={4}
          sx={{
            borderRadius: "20px",
            p: "1rem",
            mx: { xs: "0.4rem", sm: "10rem", md: "0" },
            mt: { xs: "2rem", md: 0 },
            maxWidth: "500px",
          }}
        >
          <CardContent>
            <Typography variant="h6" fontWeight={600} textAlign={"center"}>
              {t("changePassword.title")}
            </Typography>
            <Divider sx={{ my: "1rem" }} />
            <Typography variant="p" textAlign={"start"}>
              {t("changePassword.text")}
            </Typography>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box my={2}>
                {passwordChanged && (
                  <Alert severity="success">
                    {t("changePassword.alert.success")}
                  </Alert>
                )}
                {error && (
                  <Alert severity="error">
                    {t("changePassword.alert.error")}
                  </Alert>
                )}
              </Box>
              <FormControl
                sx={{ m: 1, width: "98%" }}
                variant="standard"
                fullWidth
              >
                <InputLabel htmlFor="standard-adornment-password">
                  {t("changePassword.new")}
                </InputLabel>
                <Input
                  name="new_password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => onTaskChange(e)}
                  value={formValue.new_password}
                />
              </FormControl>
              <FormControl
                sx={{ m: 1, width: "98%" }}
                variant="standard"
                fullWidth
              >
                <InputLabel htmlFor="standard-adornment-password">
                  {t("changePassword.confirm")}
                </InputLabel>
                <Input
                  name="new_password_confirmation"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => onTaskChange(e)}
                  value={formValue.new_password_confirmation}
                />
              </FormControl>
              <Stack
                mt={4}
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: { xs: "center" },
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  sx={{ borderRadius: "20px" }}
                  startIcon={
                    loading ? (
                      <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                    ) : (
                      <CheckCircleIcon />
                    )
                  }
                  type="submit"
                >
                  {t("changePassword.button")}
                </Button>
                <Link
                  to=".." 
                  relative="path"
                  className="nav-link_back"
                  underline="none"
                >
                  <ArrowBackIcon sx={{ marginRight: "5px" }} />
                  {t("back.button")}
                </Link>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default React.memo(ChangeForgottenPasswordLayout);
