import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteAgent from "../../components/agent-management-components/ModalConfirmDelete";
import TaskIcon from "@mui/icons-material/Task";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const HandleClientActions = (props) => {
  const [clickEditRoles, setClickEditRoles] = useState(false);
  const [clickApproveDocuments, setClickApproveDocuments] = useState(false);
  const [clickUploadDocuments, setClickUploadDocuments] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { userId, data } = props;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEditRoles = () => {
    handleClose();
    setClickEditRoles(true);
  };

  const handleClickDelete = () => {
    handleClose();
    setIsOpen(true);
  };

  const handleClickReviewDocuments = () => {
    setClickApproveDocuments(true);
  };

  const handleClickEdit = () => {
    handleClose();
    navigate(`/AgentAccount`, {
      state: { mode: "edit", data: data },
    });
  };

  const handleClickUploadDocuments = () => {
    setClickUploadDocuments(true);
  };

  useEffect(() => {
    clickApproveDocuments &&
      navigate(`/documentList/${userId}`, {
        state: { documentListBy: "agent" },
      });
    clickUploadDocuments &&
      navigate(`/uploadDocuments/${userId}`, {
        state: { uploadBy: "agent", documentType: data?.document?.type, userId: userId },
      });
  }, [clickApproveDocuments, clickUploadDocuments]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 175,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClickEditRoles}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText>{t("usersOptions.item-2")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("agentOptions.item-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("agentOptions.item-2")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickReviewDocuments}>
          <ListItemIcon>
            <TaskIcon />
          </ListItemIcon>
          <ListItemText>{t("clientOptions.item-4")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickUploadDocuments}>
          <ListItemIcon>
            <UploadFileIcon />
          </ListItemIcon>
          <ListItemText>{t("clientOptions.item-5")}</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmDeleteAgent
        isOpen={isOpen}
        handleClose={handleCloseModal}
        userId={userId}
        data={data}
      />
    </>
  );
};

const HeadersAgent = () => {
  const { t } = useTranslation();

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 100,
      renderCell: (params) => {
        const userId = params?.row?.id;
        return <HandleClientActions data={params?.row} userId={userId} />;
      },
    },
    {
      field: "id",
      headerName: `ID`,
      width: 100,
    },
    {
      field: "forename",
      headerName: t("superadminList.headers-1"),
      width: 200,
    },
    {
      field: "surname",
      headerName: t("superadminList.headers-2"),
      width: 200,
    },
    {
      field: "email",
      headerName: t("superadminList.headers-3"),
      width: 250,
    },
  ];
  return columns;
};

export default HeadersAgent;
