import { createContext, useState, useContext } from "react";

export const CreateZonesContext = createContext();

export const useZone = () => useContext(CreateZonesContext);

export const ZoneProvider = ({ children }) => {
  const [isZoneDeleted, setIsZoneDeleted] = useState(false);
  const [isZoneCreated, setIsZoneCreated] = useState(false);
  const [isZoneUpdated, setIsZoneUpdated] = useState(false);
  const [isZoneRestored, setIsZoneRestored] = useState(false);
  const [centerZone, setCenterZone] = useState([0, 0]);
  const [zoneCoordinates, setZoneCoordinates] = useState(null);

  const handleZoneDeleted = () => {
    setIsZoneDeleted(true);
  };

  const handleZoneCreated = () => {
    setIsZoneCreated(true);
  };

  const handleZoneUpdated = () => {
    setIsZoneUpdated(true);
  }

  const handleZoneRestored = () => {
    setIsZoneRestored(true);
  }

  const resetZonesStates = () => {
    setIsZoneDeleted(false);
    setIsZoneCreated(false);
    setIsZoneUpdated(false);
    setIsZoneRestored(false);
  };

  const handleCenterZone = (center) => {
    setCenterZone(center);
  };

  const handleZoneCoordinates = (coordinates) => {
    setZoneCoordinates(coordinates);
  };

  const resetCoordinates = () => {
    setZoneCoordinates([[], []]);
    setCenterZone([0, 0]);
  }

  return (
    <CreateZonesContext.Provider
      value={{
        resetZonesStates,
        handleZoneDeleted,
        handleZoneCreated,
        isZoneDeleted,
        isZoneCreated,
        handleCenterZone,
        centerZone,
        handleZoneCoordinates,
        zoneCoordinates,
        resetCoordinates,
        handleZoneUpdated,
        isZoneUpdated,
        handleZoneRestored,
        isZoneRestored,
      }}
    >
      {children}
    </CreateZonesContext.Provider>
  );
};
