import { useState } from "react";
import CreateNewTaxForm from "../taxes-management-components/CreateNewTaxForm";
import { Content } from "../../utils/Content";
import { Typography, Grid, Box, Skeleton } from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function CreateNewTaxLayout() {
  const { isOpen } = useMenu();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { mode, taxId } = state !== null ? state : { mode: "new", taxId: "" };

  return (
    <Content open={isOpen}>
      <Box sx={{ mb: "12rem" }}>
        <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
          <Link
            to={"/plansManagement?tab=1"}
            className="nav-link_back"
            underline="none"
          >
            <ArrowBackIcon sx={{ marginRight: "5px" }} />
            {t("back.button")}
          </Link>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box paddingX={"20px"}>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {mode !== "edit" ? t("taxes.createNew.title") : t("taxes.edit")}
              </Typography>
            </Box>
            <Box my={2} paddingX={"20px"}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {mode !== "edit" ? t("taxes.createNew.description") : t("taxes.edit.subtitle")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          ></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box
              paddingX={{ xs: "0", sm: "20px" }}
              mt={2}
              sx={{
                width: "100%",
              }}
            >
              <CreateNewTaxForm />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Content>
  );
}
