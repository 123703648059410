import axios from "axios";

const DICTIONARIES_URL = import.meta.env.VITE_DICTIONARIES_URL;

export const DepartmentListService = (countryId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deparmentList = await axios.get(
        `${DICTIONARIES_URL}/v1/department/getDepartmentList/${countryId}`
      );
      resolve(deparmentList.data[0]);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
