import { useState, useEffect } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";



const HandleClientActions = ({ id, onCellClick }) => {
  // const [clickedTransactions, setClickedTransactions] = useState(false);
  // const [clickedAddCommerce, setClickedAddCommerce] = useState(false);
  // const navigate = useNavigate();
  // const accountId = id;

  // const handleClickTransactions = () => {
  //   setClickedTransactions(true);
  // };

  // const handleClickAddCommerce = () => {
  //   setClickedAddCommerce(true);
  //   onCellClick({ accountId });
  // };

  
  // useEffect(() => {
  //   if (clickedTransactions) {
  //     navigate(`/transactions`, {
  //       state: { type: "byAccount", accountId: { accountId } },
  //     });
  //   }
  // }, [clickedTransactions]);

  return (
    <>
      {/* <IconButton onClick={handleClickTransactions} size="small">
        <PaidIcon />
      </IconButton>
      <IconButton onClick={handleClickAddCommerce} size="small">
        <AddBusinessIcon />
      </IconButton> */}
    </>
  );
};

const ClientsColumns = () => {
  const { t } = useTranslation();

  const columns = [
    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 100,
    //   renderCell: (params) => (
    //     <HandleClientActions id={params.row.id} onCellClick={onCellClick} />
    //   ),
    // },
    {
      field: "id",
      headerName: t("clientList.headers-1"),
      width: 80,
    },

  
    {
      field: "forename",
      headerName: t("clientList.headers-2"),
      width: 150,
      sortable: false,
      
    },
    {
      field: "surname",
      headerName: t("clientList.headers-3"),
      width: 150,
    },

    {
      field: "email",
      headerName: t("clientList.headers-4"),
      width: 270,
    },
    {
      field: "document_type",
      headerName: t("clientList.headers-5"),
      width: 200,
     
    },
    {
      field: "document_number",
      headerName: t("clientList.headers-6"),
      width: 180,
      sortable: false,
    },
    {
      field: "movil",
      headerName: t("clientList.headers-7"),
      sortable: false,
      width: 120,
    },
    {
      field: "state",
      headerName: t("clientList.headers-8"),
      sortable: false,
      width: 100,
    },

    {
      field: "state_code",
      headerName: t("clientList.headers-9"),
      sortable: false,
      width: 100,
    },

    {
      field: "plan.id",
      headerName: t("clientList.headers-10"),
      sortable: false,
      width: 80,
      valueGetter: (params) => params.row.plan?.id || '' 
    },
  
    {
      field: "plan.title",
      headerName: t("clientList.headers-11"),
      sortable: false,
      width: 160,
      valueGetter: (params) => params.row.plan?.title || '' 
    },
   
  ];

  return columns;
};

export default ClientsColumns;
