import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function AssignRoleToUserService(token, data, userId) {
  return new Promise(async (resolve, reject) => {
    console.log("Updated Data:", data);
    try {
      const response = await Axios.put(
        `${URL_AUTH}/api/admin/v1/admins/${userId}/role/assign`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}