import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import ConfirmDeleteZone from "../../components/zones-components/ConfirmDeleteZone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { RestoreZoneService } from "../../services/Zones/RestoreZoneService";
import { useZone } from "../../context/ZonesContext";

const HandleActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickSeeMore, setClickSeeMore] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { handleZoneRestored } = useZone();

  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { zoneId, data, mode, active } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeeMore = () => {
    setClickSeeMore(true);
    setAnchorEl(null);
  };
  const handleClickDelete = () => {
    handleClose();
    setIsOpen(true);
  };

  const handleClickEdit = () => {
    handleClose();
    navigate(`/zones/edit/${zoneId}`, {
      state: {
        mode: mode === "available" ? "editAvailable" : "edit",
        data: data,
      },
    });
  };

  const handleClickRestore = async () => {
    handleClose();
    const token = await getToken();
    try {
      const _ = await RestoreZoneService(token, zoneId);
      handleZoneRestored();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (clickSeeMore) {
      navigate(`/zones/details/${zoneId}`, {
        state: { mode: `${mode}` },
      });
    }
  }, [clickSeeMore]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 175,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleSeeMore}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>{t("zone.table.option-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t("zone.table.option-2")}</ListItemText>
        </MenuItem>
        {active === false ? (
          <MenuItem onClick={handleClickRestore}>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText>{t("zone.table.option-3b")}</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClickDelete}>
            <ListItemIcon>
              <LocationOffIcon />
            </ListItemIcon>
            <ListItemText>{t("zone.table.option-3")}</ListItemText>
          </MenuItem>
        )}
        <ConfirmDeleteZone
          isOpen={isOpen}
          handleClose={handleCloseModal}
          zoneId={zoneId}
          data={data}
        />
      </Menu>
    </>
  );
};

export const HeadersZones = ({ mode }) => {
  const currentLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  let columns = [
    {
      field: "actions",
      type: "actions",
      width: 60,
      renderCell: (params) => (
        <HandleActions
          zoneId={params.row.id}
          mode={mode}
          active={params.row.active}
        />
      ),
    },
    {
      field: "id",
      headerName: "Id",
      width: 100,
    },
    {
      field: "name",
      headerName: t("zones.table.header-1"),
      width: 200,
    },
    {
      field: "location",
      headerName: t("zones.table.header-2"),
      width: 200,
    },
    {
      field: "country",
      headerName: t("zones.table.header-3"),
      width: 200,
    },
    {
      field: "department",
      headerName: t("zones.table.header-4"),
      width: 200,
    },
    {
      field: "city",
      headerName: t("zones.table.header-5"),
      width: 200,
    },
    {
      field: "active",
      headerName: t("zones.table.header-6"),
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value === true ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        );
      },
    },
  ];
  return columns;
};
