import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function VerifyOtpCodeService(data, token, tokenOtp) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `${URL_AUTH}/api/admin/v1/otp/web/verify`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            "X-Auth-Otp": tokenOtp,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
