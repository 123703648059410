import axios from "axios";

const DICTIONARIES_URL = import.meta.env.VITE_DICTIONARIES_URL;

export const GetPaymentMethodsService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const paymentMethodsList = await axios.get(
        `${DICTIONARIES_URL}/v1/dictionary/getDictionaryList/MPA`
      );
      console.log(paymentMethodsList.data);
      resolve(paymentMethodsList.data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
