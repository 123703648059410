import { createContext, useState, useContext } from "react";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [selectedNotificationType, setSelectedNotificationType] =
    useState(false);

  const handleSelectNotificationType = () => {
    setSelectedNotificationType(!selectedNotificationType);
  };

  const resetNotificationType = () => {
    setSelectedNotificationType(false);
  };

  return (
    <NotificationContext.Provider
      value={{
        selectedNotificationType,
        handleSelectNotificationType,
        resetNotificationType,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
