import { useState, useEffect } from "react";
import { Box, CircularProgress, TablePagination, Alert } from "@mui/material";
import { t } from "i18next";
import { GetRecentCalls } from "../../services/GetRecentCalls";
import { dateCompareDesc, substractMonts } from "../../utils/dateUtils";
import RecentCallsTable from "./RecentCallsTable";

export default function RecentCalls() {
  const [page, setPage] = useState(0);
  const [pageState, setPageState] = useState({
    calls: [],
    isLoading: false,
    total: 0,
    error: null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getRecentCalls();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //get today's date in format yyyy-mm-dd
  const today = new Date().toISOString().slice(0, 10);

  //get the date 1 month ago in format yyyy-mm-dd
  const oneMonthAgo = substractMonts(new Date(), 1).toISOString().slice(0, 10);

  const getRecentCalls = async () => {
    try {
      setPageState((old) => ({ ...old, isLoading: true, error: null }));
      const recentCalls = await GetRecentCalls(oneMonthAgo, today);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        error: null,
        calls: recentCalls,
        total: recentCalls.length,
      }));
    } catch (error) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
        contacts: [],
        total: 0,
        error: error.message,
      }));
      console.log(error);
    }
  };

  const sortedData = pageState.calls.sort(dateCompareDesc("fecha_hora"));

  const pageStart = page * rowsPerPage;
  const pageData =
    sortedData.length === 0
      ? []
      : sortedData.slice(pageStart, pageStart + rowsPerPage);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
        margin: 0,
      }}
    >
      <h1>{t("calls.recentTab.table")}</h1>
      <RecentCallsTable data={pageData} />
      {pageState.isLoading ? (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      {pageState.error ? (
        <Alert severity="error">{t("calls.recentTab.error")}</Alert>
      ) : null}
      <TablePagination
        sx={{ mt: "10px" }}
        component="div"
        count={sortedData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
