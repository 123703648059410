import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Box,
  Grid,
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import SaveIcon from "@mui/icons-material/Save";
import { GetDocumentationTypesService } from "../../services/GetDocumentationTypesService";
import { useParams } from "react-router-dom";
import { UploadDocumentService } from "../../services/DocumentsManagement/UploadDocumentService";
import { UpdateDocumentService } from "../../services/DocumentsManagement/UpdateDocumentService";
import { useForm } from "../../context/CreateCommerceFormContext";
import { GetDocumentListByClientsService } from "../../services/DocumentsManagement/Clients/GetDocumentListByClientsService";

const allowedFileTypes = ["application/pdf", "image/jpeg", "image/jpg"];
const maxFileSize = "5MB";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DocumentUploadSection = ({
  sectionTitle,
  mode,
  loadingDocTypes,
  docTypes,
  docTypesSaved,
  setSelectedDocTypeAll,
  selectedDocTypeAll,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDocUploaded, setIsDocUploaded] = useState(false);

  const [docReference, setDocReference] = useState("");
  const [selectedDocType, setSelectedDocType] = useState("");

  const [isUploaded, setIsUploaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const [selectedDocTypeAll, setSelectedDocTypeAll] = useState([]);
  // const [docTypeAllFilter, setDocTypeAllFilter] = useState([]);

  const [file, setFile] = useState(null);
  const { formData, saveFormData } = useForm();

  const { t } = useTranslation();
  const { shopId } =
    mode === "continueReg" || mode === "edit"
      ? useParams()
      : { shopId: localStorage.getItem("shopId") };
  const [formValues, setFormValues] = useState({
    shop_id:
      mode === "continueReg" || mode === "edit"
        ? shopId
        : localStorage.getItem("shopId"),
    document_type_id: "",
    description: "",
    document: null,
    step: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (fileItems) => {
    if (fileItems.length > 0) {
      // Mostrar solo la última imagen cargada
      setFile(fileItems[fileItems.length - 1].file);
      setFormValues((prevState) => ({
        ...prevState,
        document: fileItems[fileItems.length - 1].file,
      }));
    } else {
      setFile(null);
    }
  };

  const handleDocTypeChange = (event, value) => {
    console.log(value);
    console.log("form", formValues);
    setSelectedDocType(value);
    if (value === null) {
      setSelectedDocTypeAll(
        selectedDocTypeAll.filter(
          (selected) => selected === formValues.document_type_id
        )
      );
    } else {
      setSelectedDocTypeAll([value.id]);
    }
    console.log("todos", selectedDocTypeAll);
    if (value?.id !== null) {
      setFormValues((prevState) => ({
        ...prevState,
        document_type_id: value?.id.toString(),
      }));
    }
    if (mode === "edit") {
      const findedDoc = docTypesSaved.filter(
        (doc) => doc.document_type === value.name
      );
      setDocReference(findedDoc[0].reference);
      console.log(findedDoc[0].reference);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Inside of submit", formValues);
    try {
      setLoading(true);
      const token = await getToken();
      if (mode !== "edit") {
        const response = await UploadDocumentService(token, formValues);
        if (response.status === 200) {
          setIsUploaded(true);
          setIsDocUploaded(true);
        }
        console.log(response);
      } else {
        const response = await UpdateDocumentService(
          token,
          formValues,
          shopId,
          docReference
        );
        if (response.status === 200) {
          setIsUploaded(true);
          setIsDocUploaded(true);
        }
        console.log(response);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setCurrentStep = () => {
    saveFormData({
      ...formData,
      step: 4,
    });
    setFormValues({
      ...formValues,
      step: 4,
    });
  };

  useEffect(() => {
    setCurrentStep();
  }, []);

  const handleCloseConfirmationDocUploaded = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsDocUploaded(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  // useEffect(() => {
  //   let docTypeListFilter = [];

  //   docTypes.forEach((docType) => {
  //     if (!selectedDocTypeAll.includes(docType.id)) {
  //       docTypeListFilter.push(docType);
  //     }
  //   });

  //   setDocTypeAllFilter(docTypeListFilter);
  //   console.log(selectedDocTypeAll);
  // }, [docTypes, selectedDocTypeAll]);
  // console.log(docTypeAllFilter);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{sectionTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <Snackbar
            open={isDocUploaded}
            autoHideDuration={6000}
            onClose={handleCloseConfirmationDocUploaded}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("uploadDocument.success")}</Alert>
          </Snackbar>
          <Snackbar
            open={error}
            autoHideDuration={6000}
            onClose={handleCloseError}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="document_type_id"
                    options={
                      loadingDocTypes
                        ? [{ description: "Loading..." }]
                        : docTypes
                    }
                    loading={loadingDocTypes}
                    loadingText="Loading..."
                    disabled={isUploaded}
                    onChange={handleDocTypeChange}
                    autoHighlight
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleDocTypeChange}
                        size="small"
                        name="document_type_id"
                        value={selectedDocType}
                        label={t("locationandbankinfo.form.docType")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="description"
                    label={t("uploadDocument.form.description")}
                    size="small"
                    disabled={isUploaded}
                    value={formValues.description}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Stack spacing={2} mt={2}>
                <Typography>{t("uploadDocument.form.uploadDoc")}:</Typography>
                <Typography variant="caption">
                  {t("reviewDoc.warning")}
                </Typography>
                <FilePond
                  files={file ? [file] : null}
                  acceptedFileTypes={allowedFileTypes}
                  maxFileSize={maxFileSize}
                  allowMultiple={false}
                  labelIdle={t("uploadDocument.form.messageFilepond")}
                  checkValidity={true}
                  instantUpload={false}
                  server={null}
                  onupdatefiles={handleFileChange}
                  required
                  credits={false}
                />
              </Stack>
              <Box mt={2} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color={"secondary"}
                  size="large"
                  disabled={isUploaded}
                  startIcon={
                    loading ? (
                      <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                    ) : (
                      <SaveIcon sx={{ color: "white" }} />
                    )
                  }
                >
                  {isUploaded
                    ? `${t("saved.button")}`
                    : mode === "edit"
                    ? `${t("update.button")}`
                    : `${t("uploadDocument.form.button")}`}
                </Button>
              </Box>
            </form>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const UploadDocs = ({ missingDocs, mode }) => {
  const [loadingDocTypes, setLoadingDocTypes] = useState(true);
  const [docTypes, setDocTypes] = useState([]);
  const [docTypesSaved, setDocTypesSaved] = useState([]);

  const [selectedDocTypeAll, setSelectedDocTypeAll] = useState([]);
  const [docTypeAllFilter, setDocTypeAllFilter] = useState([]);

  const [selectedDocType1, setSelectedDocType1] = useState([]);
  const [selectedDocType2, setSelectedDocType2] = useState([]);
  const [selectedDocType3, setSelectedDocType3] = useState([]);
  const [selectedDocType4, setSelectedDocType4] = useState([]);

  const { t } = useTranslation();
  const textMissingDocs = missingDocs
    ? missingDocs.map((object) => object.description).join(", ") + "."
    : "";
  const { shopId } =
    mode === "continueReg" || mode === "edit"
      ? useParams()
      : { shopId: localStorage.getItem("shopId") };

  useEffect(() => {
    const getDocumentTypes = async () => {
      const token = await getToken();
      const shop =
        mode === "continueReg" || mode === "edit"
          ? shopId
          : localStorage.getItem("shopId");
      try {
        const documentTypesList = await GetDocumentationTypesService(
          token,
          shop
        );
        console.log("type list", documentTypesList);
        setDocTypes(documentTypesList);

        if (mode === "continueReg" || mode === "edit") {
          const savedDocList = await GetDocumentListByShopService(token, shop);
          setDocTypesSaved(savedDocList);
          // setDocTypes(missDocs !== undefined && missDocs);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDocTypes(false);
      }
    };
    getDocumentTypes();
  }, []);

  useEffect(() => {
    if (mode === "continueReg") {
      const filterDocs = docTypes.filter((docType) => {
        return !docTypesSaved.some(
          (savedDoc) => savedDoc.description === docType.name
        );
      });

      console.log(filterDocs);
      setDocTypes(filterDocs);
    }
  }, [docTypesSaved]);

  useEffect(() => {
    let docTypeListFilter = [];

    let selectedConcat = [
      ...selectedDocType1,
      ...selectedDocType2,
      ...selectedDocType3,
      ...selectedDocType4,
    ];

    if (mode !== "continueReg" && mode !== "edit") {
      docTypes.forEach((docType) => {
        if (!selectedConcat.includes(docType.id)) {
          docTypeListFilter.push(docType);
        }
      });
    } else if (mode === "continueReg" || mode === "edit") {
      docTypes.forEach((docType) => {
        if (!selectedConcat.includes(docType.id)) {
          docTypeListFilter.push(docType);
        }
      });
    }

    setDocTypeAllFilter(docTypeListFilter);
    console.log(
      selectedDocType1,
      selectedDocType2,
      selectedDocType3,
      selectedDocType4
    );
  }, [
    docTypes,
    selectedDocType1,
    selectedDocType2,
    selectedDocType3,
    selectedDocType4,
  ]);
  // console.log(docTypeAllFilter);

  return (
    <Stack
      mt={{ xs: 0, md: 2 }}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {mode === "edit" ? (
        <Box mb={2}>
          <Typography variant="h6" fontWeight={600} textAlign={"center"}>
            {t("updateDocument.form.titleSection")}
          </Typography>
        </Box>
      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h6" fontWeight={600} textAlign={"center"}>
              {t("uploadDocument.form.titleSection")}
            </Typography>
          </Box>

          {mode === "edit" ||
            (mode === "continueReg" && (
              <Box mb={2}>
                <Typography
                  variant="caption"
                  fontWeight={400}
                  textAlign={"center"}
                >
                  {t("reviewDoc.subtitle")}
                </Typography>
                <Typography
                  variant="caption"
                  fontWeight={600}
                  textAlign={"center"}
                >
                  {textMissingDocs}
                </Typography>
              </Box>
            ))}
        </>
      )}
      <DocumentUploadSection
        sectionTitle={t("uploadDocument.form.title-1")}
        mode={mode}
        loadingDocTypes={loadingDocTypes}
        docTypes={docTypeAllFilter}
        docTypesSaved={docTypesSaved}
        setSelectedDocTypeAll={setSelectedDocType1}
        selectedDocTypeAll={selectedDocType1}
      />
      <DocumentUploadSection
        sectionTitle={t("uploadDocument.form.title-2")}
        mode={mode}
        loadingDocTypes={loadingDocTypes}
        docTypes={docTypeAllFilter}
        docTypesSaved={docTypesSaved}
        setSelectedDocTypeAll={setSelectedDocType2}
        selectedDocTypeAll={selectedDocType2}
      />
      <DocumentUploadSection
        sectionTitle={t("uploadDocument.form.title-3")}
        mode={mode}
        loadingDocTypes={loadingDocTypes}
        docTypes={docTypeAllFilter}
        docTypesSaved={docTypesSaved}
        setSelectedDocTypeAll={setSelectedDocType3}
        selectedDocTypeAll={selectedDocType3}
      />
      <DocumentUploadSection
        sectionTitle={t("uploadDocument.form.title-4")}
        mode={mode}
        loadingDocTypes={loadingDocTypes}
        docTypes={docTypeAllFilter}
        docTypesSaved={docTypesSaved}
        setSelectedDocTypeAll={setSelectedDocType4}
        selectedDocTypeAll={selectedDocType4}
      />
    </Stack>
  );
};

export default UploadDocs;
