import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const GetDocumentTypeListService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const documentTypeList = await axios.get(
        `${URL_AUTH}/api/general/v1/info/document-type/identity`
      );
      resolve(documentTypeList.data.data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
