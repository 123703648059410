import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  Stack,
  Divider,
  Chip,
  IconButton,
  Typography,
  FormControl,
  Avatar,
  InputLabel,
  Alert,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getToken } from "../../utils/GetToken";
import { CreateNewPlanService } from "../../services/Plans/CreateNewPlanService";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { GetPlanDetailsService } from "../../services/Plans/GetPlanDetailsService";
import { UpdatePlanService } from "../../services/UpdatePlanService";
import UploadImagePlanService from "../../services/Plans/UploadImagePlanService";
import EditIcon from "@mui/icons-material/Edit";
import { usePlan } from "../../context/PlanContext";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { DeleteImagePlanService } from "../../services/Plans/DeleteImagePlanService";
import { Colors } from "../../config/themeConfig";
import InfoIcon from "@mui/icons-material/Info";
import numeral from "numeral";

const langOptions = [
  { id: 1, lang: "en", name: "English" },
  { id: 2, lang: "es", name: "Español" },
];

export default function CreateNewPlanForm({
  mode,
  planId,
  handleNext,
  handlePlanCreated,
}) {
  const isMounted = useRef(true);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isPlanCreated, setIsPlanCreated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [descriptions, setDescriptions] = useState([""]);
  const [planSelected, setPlanSelected] = useState({});
  const [isPlanUpdated, setIsPlanUpdated] = useState(false);
  const fileInputRef = useRef(null);
  const {
    handleIsPlanCreated,
    handleUploadImage,
    handleIsPlanImageUpload,
    resetPlanStates,
    handleIsPlanUpdate,
    isPlanUpdate,
  } = usePlan();
  const [imageSrc, setImageSrc] = useState(
    planSelected?.imageUrl !== null ? planSelected.url_image : null
  );

  const [isDiscountActive, setIsDiscountActive] = useState(false);
  const [discountValue, setDiscountValue] = useState("");
  const [originalValue, setOriginalValue] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [displayedValue, setDisplayedValue] = useState("");
  const [editableValue, setEditableValue] = useState("");
  const [langValue, setLangValue] = useState(null);
  const [editableCommissionable, setEditableCommissionable] = useState("");

  const [formValues, setFormValues] = useState({
    title: "",
    descriptions: [],
    language: "es",
    duration: "",
    contacts: "",
    value: "",
    imageUrl: "",
    // old_value: "",
    // discount: "",
    commissionable: "",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDiscountChange = (event) => {
    const isChecked = event.target.checked;

    if (!isChecked) {
      setFormValues((prevFormValues) => {
        // Crear una copia del estado actual
        const { discount, ...updatedFormValues } = prevFormValues;
        return updatedFormValues;
      });
    }

    setIsDiscountActive(isChecked);
  };

  const handleDiscountValueChange = (event) => {
    let { name, value } = event.target;

    const numericString = value.replace(/[^\d.]/g, "");

    const numericValue = numeral(numericString).value();

    if (numericValue >= 0 && numericValue <= 100) {
      const numericValuePercentage = numericValue / 100;
      const formattedValue = numeral(numericValuePercentage).format("0.00%");
      const valueToSend = numeral(numericValue).format("0.00");
      setDiscountValue(formattedValue);

      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: valueToSend,
      }));
    }
  };

  const handleLangChange = (event, value) => {
    const selectedLanguage = langOptions.find((lang) => lang.id === value);

    if (selectedLanguage) {
      setFormValues((prevState) => ({
        ...prevState,
        language: selectedLanguage.lang,
      }));
      setLangValue(selectedLanguage);
    }
  };

  const handleEditImageClick = () => {
    fileInputRef.current.click();
  };

  const getPlanInfo = async () => {
    try {
      const token = await getToken();
      if (token) {
        const response = await GetPlanDetailsService(token, planId);
        console.log(response);
        setPlanSelected(response);

        const currentLanguage = langOptions.find(
          (item) => response?.language === item.lang
        );

        setLangValue(currentLanguage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleValueChange = (event) => {
    const { value: rawValue } = event.target;

    const numericString = rawValue.replace(/[^\d.]/g, "");
    const numericValue = numeral(numericString).value();

    if (isNaN(numericValue)) {
      console.log("Valor no válido");
      setEditableValue("");
      setFormValues((prevValues) => ({
        ...prevValues,
        value: "",
      }));
      return;
    }

    const formattedValue = numeral(numericValue).format("$0,0.00");
    const valueToSend = numeral(numericValue).format("0.00");

    setEditableValue(formattedValue);
    setFormValues((prevValues) => ({
      ...prevValues,
      value: valueToSend,
    }));
  };

  const handleCommissionableChange = (event) => {
    let { value } = event.target;
    const numericString = value.replace(/[^\d.]/g, "");
    const numericValue = numeral(numericString).value();
    console.log(numericValue);
    const formattedValue = numeral(numericValue).format("$0,0.00");
    const valueToSend = numeral(numericValue).format("0.00");

    setEditableCommissionable(formattedValue);
    setFormValues((prevValues) => ({
      ...prevValues,
      commissionable: valueToSend,
    }));
  };

  const handleOpenImageDialog = () => {
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  useEffect(() => {
    if (mode === "edit") {
      setOriginalValue(planSelected?.value);
      setEditableValue(numeral(planSelected?.value).format("0,0.00"));
      setFormValues((prevValues) => ({
        ...prevValues,
        value: planSelected?.value,
      }));
    } else {
      setOriginalValue(null);
      setEditableValue("");
      setFormValues((prevValues) => ({
        ...prevValues,
        value: "",
      }));
    }
  }, [mode, planSelected]);

  useEffect(() => {
    if (isDiscountActive) {
      if (discountValue === "") {
        setDisplayedValue(editableValue);
        return;
      } else {
        console.log(discountValue);
        const discountedValueFormatted = parseFloat(
          String(discountValue || "").replace(/[^\d.]/g, "")
        );
        const numericEditableValue =
          parseFloat(String(editableValue || "").replace(/[^0-9.]/g, "")) || 0;
        const discountedValue =
          numericEditableValue -
          (numericEditableValue * discountedValueFormatted) / 100;
        const formattedDiscountedValue =
          discountedValue.toLocaleString("ES-CO");
        setDisplayedValue(formattedDiscountedValue);
        return;
      }
    }
  }, [isDiscountActive, editableValue, discountValue]);

  useEffect(() => {
    if (mode === "edit") {
      getPlanInfo();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      console.log(planSelected);
      setDescriptions(planSelected?.description);
      setEditableCommissionable(numeral(planSelected?.commissionable).format("0,0.00"));
      setFormValues((prevState) => ({
        ...prevState,
        title: planSelected?.title,
        descriptions: planSelected?.description,
        language: planSelected?.language,
        duration: planSelected?.duration,
        contacts: planSelected?.contacts,
        value: planSelected?.value,
        imageUrl: planSelected?.imageUrl,

        commissionable: planSelected?.commissionable,
        // discount: planSelected?.discount,
        //old_value: planSelected?.oldValue,
      }));
    }
  }, [planSelected]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (planSelected?.imageUrl) {
      setImageSrc(planSelected.imageUrl);
    }
  }, [planSelected]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = await getToken();
      const filteredFormValues = filterForm(formValues);
      if (mode === "edit") {
        console.log(formValues);
        const response = await UpdatePlanService(
          token,
          planId,
          filteredFormValues
        );
        console.log(response);
        setIsPlanUpdated(true);
        handleIsPlanUpdate();
      } else {
        const response = await CreateNewPlanService(token, formValues);
        console.log(response);

        handlePlanCreated(response?.id);
        setIsPlanCreated(true);
        handleIsPlanCreated();
      }
    } catch (error) {
      setError(true);
      console.log(error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const filterForm = (form) => {
    const filteredFormValues = Object.fromEntries(
      Object.entries(form).filter(([key, value]) => {
        return mode === "edit"
          ? key !== "discount" || (key === "discount" && value !== "")
          : key !== "old_value" || (key === "old_value" && value !== "");
      })
    );

    if (form.value) {
      console.log("filteredFormValues", filteredFormValues, isDiscountActive);
      const numericString = editableValue.replace(/[^\d.]/g, "");
      return mode === "edit" && !isDiscountActive
        ? {
            ...filteredFormValues,
            value: numericString,
            old_value: planSelected?.value,
          }
        : {
            ...filteredFormValues,
            value: numericString,
          };
    } else {
      return filteredFormValues;
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        alert("El tamaño de la imagen no puede ser mayor a 5MB");
      } else {
        const allowedFormats = [".png", ".jpg", ".jpeg", ".svg"];
        const fileExtension = selectedFile.name
          .substring(selectedFile.name.lastIndexOf("."))
          .toLowerCase();

        if (!allowedFormats.includes(fileExtension)) {
          alert("Error en el formato permitido");
        } else {
          const token = await getToken();
          try {
            const response = await UploadImagePlanService(
              token,
              planId,
              selectedFile
            );
            console.log(response);
            const fileReader = new FileReader();
            fileReader.onload = function () {
              setImageSrc(fileReader.result);
            };
            fileReader.readAsDataURL(selectedFile);
          } catch (error) {
            console.log(error);
            alert("Error al actualizar la imagen");
          }
          handleIsPlanImageUpload();
        }
      }
    }
  };

  const handleDeleteImage = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      if (token) {
        const response = await DeleteImagePlanService(token, planId);
        console.log("Imagen eliminada correctamente: ", response);
      }
      setImageSrc("");
    } catch (error) {
      console.error("Error al eliminar la imagen:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDeleteImage = async () => {
    handleCloseImageDialog();
    await handleDeleteImage();
  };

  const addDescription = () => {
    setDescriptions([...descriptions, ""]);
  };

  const removeDescription = (index) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions.splice(index, 1);
    setDescriptions(updatedDescriptions);
  };

  const updateDescription = (index, value) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = value;
    setDescriptions(updatedDescriptions);
  };

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      description: descriptions,
    }));
  }, [descriptions]);

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      descriptions: descriptions,
    }));
  }, [descriptions]);

  useEffect(() => {
    if (isPlanCreated) {
      handleNext();
    }
  }, [isPlanCreated]);

  useEffect(() => {
    if (isPlanUpdated) {
      const redirectTimer = setTimeout(() => {
        navigate(`/plansManagement?tab=0`);
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
    finalizeUpdatePlan();
  }, [isPlanUpdated]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleIsPlanUpdate();
  };

  const finalizeUpdatePlan = () => {
    resetPlanStates();
  };

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: "20px",
        mt: { xs: "1rem", sm: 0 },
        p: { xs: "0.4rem", sm: "2rem" },
        maxWidth: "100%",
      }}
    >
      <Snackbar
        open={isPlanUpdate}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("createEditPlan.success.message")}</Alert>
      </Snackbar>
      <CardContent sx={{ mt: { xs: "1rem", sm: "0rem" } }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="title"
                label={t("createNewPlan.formLabel.name")}
                value={formValues.title}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="duration"
                label={t("createNewPlan.formLabel.duration")}
                value={formValues.duration}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="contacts"
                label={t("createNewPlan.formLabel.contacts")}
                value={formValues.contacts}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="value"
                label={t("createNewPlan.formLabel.value")}
                value={!isDiscountActive ? editableValue : displayedValue}
                onChange={handleValueChange}
                size="small"
                fullWidth
                required
                // InputProps={{
                //   inputProps: {
                //     inputMode: "decimal",
                //     pattern: "[0-9]*",
                //   },
                // }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="commissionable"
                label={t("createNewPlan.formLabel.commissionable")}
                value={editableCommissionable}
                onChange={handleCommissionableChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="lang"
                options={langOptions}
                onChange={handleLangChange}
                inputValue={langValue ? langValue.name : ""}
                onInputChange={(event, newInputValue) => {
                  setLangValue((prevState) => ({
                    ...prevState,
                    name: newInputValue,
                  }));
                }}
                value={langValue}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  value && option.name === value.name
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    name="language"
                    label={t("language")}
                  />
                )}
              />
            </Grid>
            <Grid container spacing={2} pt={"1rem"}>
              <Grid item xs={12} my={2}>
                <Divider variant="middle">
                  <Chip label={t("createNewPlan.section.label-5")} />
                </Divider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ justifyContent: "center", display: "flex" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDiscountActive}
                      onChange={handleDiscountChange}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {t("createNewPlan.section.example-5")}
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {isDiscountActive && (
                  <FormControl fullWidth>
                    <TextField
                      name="discount"
                      label="Valor del Descuento"
                      // type="number"
                      size="small"
                      value={formValues.discount}
                      onChange={handleDiscountValueChange}
                      inputProps={{ max: 100, min: 0 }}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
            {/* Add Descriptions */}
            <Grid item xs={12} my={2}>
              <Divider variant="middle">
                <Chip label={t("createNewPlan.section.label-1")} />
              </Divider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption">
                {t("createNewPlan.section.example-1")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack gap={1.5}>
                {descriptions.map((description, index) => (
                  <Stack key={index}>
                    <Grid spacing={2} container>
                      <Grid item xs={11}>
                        <TextField
                          label={t("createNewPlan.section.label-1")}
                          value={description}
                          size="small"
                          fullWidth
                          onChange={(e) =>
                            updateDescription(index, e.target.value)
                          }
                          rows="5"
                          multiline
                          required
                        />
                      </Grid>
                      {descriptions.length > 1 && (
                        <Grid item xs={1}>
                          <IconButton
                            variant="contained"
                            color="error"
                            onClick={() => removeDescription(index)}
                            sx={{ color: "error" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Stack>
                ))}
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={addDescription}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Box>
              </Stack>
            </Grid>
            {mode === "edit" && (
              <>
                <Grid item xs={12} my={2}>
                  <Divider variant="middle">
                    <Chip label={t("createNewPlan.section.label-6")} />
                  </Divider>
                </Grid>
                <Box sx={{ m: "auto", mt: "2rem" }}>
                  <Stack ml={"1rem"}>
                    <Avatar
                      alt="plan image"
                      src={imageSrc}
                      sx={{ width: "160px", height: "155px" }}
                    >
                      <AssignmentIcon sx={{ width: 56, height: 56 }} />
                    </Avatar>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    ml={"23%"}
                    mt={"0.5rem"}
                  >
                    <IconButton
                      color="primary"
                      size="large"
                      onClick={handleEditImageClick}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>

                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      accept=".png, .jpg, .jpeg, .svg"
                      onChange={handleFileChange}
                    />

                    <IconButton
                      size="large"
                      color="primary"
                      onClick={handleOpenImageDialog}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                </Box>
                <Dialog
                  open={openImageDialog}
                  onClose={() => setOpenImageDialog(false)}
                >
                  <DialogTitle sx={{ p: "1rem" }}>
                    <Stack alignItems={"center"} justifyContent="center">
                      <InfoIcon sx={{ color: Colors.primary }} />
                      <Typography variant="h6" color="warning">
                        {t("deleteAccount.confirmation.title")}
                      </Typography>
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t("deleteAccount.confirmation.content.image")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenImageDialog(false)}
                      color="primary"
                    >
                      {t("cancel.button")}
                    </Button>
                    <Button onClick={handleConfirmDeleteImage} color="primary">
                      {t("delete.button")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
          <Box mt={"1.5rem"} sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color={"secondary"}
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                ) : (
                  <CheckCircleIcon sx={{ color: "white" }} />
                )
              }
            >
              {mode === "edit"
                ? t("createNewPlan.button.edit")
                : t("createNewPlan.button.create")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
