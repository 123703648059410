import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { t } from "i18next";
import UserCard from "./UserCard";

export default function RecentCallsTable({ data }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("calls.recentTab.table.client")}</TableCell>
          <TableCell>{t("calls.recentTab.table.type")}</TableCell>
          <TableCell>{t("calls.recentTab.table.state")}</TableCell>
          <TableCell>{t("calls.recentTab.table.number")}</TableCell>
          <TableCell>{t("calls.recentTab.table.date")}</TableCell>
          <TableCell>{t("calls.recentTab.table.duration")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((call) => (
          <TableRow key={call.id}>
            <TableCell>
              <UserCard call={call} />
            </TableCell>
            <TableCell>{call.sentido}</TableCell>
            <TableCell>{call.estado}</TableCell>
            <TableCell>{call.numero_telefono}</TableCell>
            <TableCell>{call.fecha_hora}</TableCell>
            <TableCell>{call.tiempo_total}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
