import axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export const SuspendCommerceService = (token, shopId, observation) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(
        `${URL_MERCHANTS}/api/brain/v1/state/shop/${shopId}/suspend`,
        observation,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response);
      resolve(response);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};