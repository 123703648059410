import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
  Alert,
  Card,
  CardContent,
  FormControl,
  Stack,
  Divider,
  OutlinedInput,
  FormHelperText,
  Snackbar
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { ResetPasswordService } from "../../services/ResetPasswordService";
import { FindResetPasswordService } from "../../services/FindResetPasswordService";

export default function ForgetPasswordLayout() {
  const [loading, setLoading] = useState(false);
  const [isChangedPassword, setIsChangedPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    id: "",
    email: "",
    resetToken: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const { token } = useParams();

  const [wrongCredentials, setWrongCredentials] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { Login } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      new_password: value,
    }));
    validatePassword(value);
  };

  const handlePasswordConfirmChange = (event) => {
    const { value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      new_password_confirmation: value,
    }));
    validatePasswordEquals(value);
  };

  const validatePassword = (value) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    if (!passwordRegex.test(value)) {
      setPasswordError(`${t("createAccount.form.passwordValidation-1")}`);
    } else {
      setPasswordError("");
    }
  };

  const validatePasswordEquals = (value) => {
    if (formValues.new_password !== value) {
      setPasswordConfirmError(
        `${t("createAccount.form.passwordValidation-2")}`
      );
    } else {
      setPasswordConfirmError("");
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await ResetPasswordService(formValues);
      setIsChangedPassword(true);
      setSuccessMessage(response.data.message);
      console.log(response);
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getDataUser = async (token) => {
      try {
        const response = await FindResetPasswordService(token);
        const { data } = response.data;
        setFormValues((prev) => {
          const copy = { ...prev };
          return {
            ...copy,
            id: data.id,
            email: data.email,
            resetToken: data.resetToken,
          };
        });
      } catch (error) {
        console.log("No se reconoce esta acción");
      }
    };
    getDataUser(token);
  }, [token]);

  const handleCloseConfirmationChangedPassword = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsChangedPassword(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (isChangedPassword) {
      const redirectTimer = setTimeout(() => {
        goLogin();
        window.location.reload();
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }
  }, [isChangedPassword]);

  const goLogin = () => {
    navigate("/");
  };

  return (
    <Box mt={3}>
      <Stack
        mt={{ xs: 0, md: 2 }}
        sx={{
          justifyContent: "center",
          alignItems: { xs: "center" },
        }}
      >
        <Snackbar
          open={isChangedPassword}
          autoHideDuration={6000}
          onClose={handleCloseConfirmationChangedPassword}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{successMessage}</Alert>
        </Snackbar>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Card
          elevation={4}
          sx={{
            borderRadius: "20px",
            p: "1rem",
            mx: { xs: "0.4rem", sm: "10rem", md: "0" },
            mt: { xs: "2rem", md: 0 },
            maxWidth: "500px",
          }}
        >
          <CardContent>
            <Typography variant="h6" fontWeight={600} textAlign={"center"}>
              {t("updatePassword.title")}
            </Typography>
            <Divider sx={{ my: "1rem" }} />
            <Typography variant="p" textAlign={"start"}>
              {t("updatePassword.subtitle")}
            </Typography>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box my={2}>
                {wrongCredentials && (
                  <Alert severity="error">{t("login.error")}</Alert>
                )}
              </Box>
              <Stack spacing={3}>
                <FormControl size="small" variant="outlined">
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("password")}
                  </InputLabel>
                  <Input
                    id="new_password"
                    name="new_password"
                    type={showPassword ? "text" : "password"}
                    onChange={handlePasswordChange}
                    value={formValues.new_password || ""}
                    error={passwordError !== ""}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t("password")}
                  />
                  {passwordError && (
                    <FormHelperText error id="passwordError-error">
                      {passwordError}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl size="small" variant="outlined">
                  <InputLabel htmlFor="standard-adornment-password">
                    {t("createAccount.form.confirmPassword")}
                  </InputLabel>
                  <Input
                    id="new_password_confirmation"
                    name="new_password_confirmation"
                    type={showPassword ? "text" : "password"}
                    onChange={handlePasswordConfirmChange}
                    value={formValues.new_password_confirmation || ""}
                    error={passwordConfirmError !== ""}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t("createAccount.form.confirmPassword")}
                  />
                  {passwordConfirmError && (
                    <FormHelperText error id="passwordConfirm-error">
                      {passwordConfirmError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
              <Grid container columns={{ xs: 12, sm: 12, md: 12 }} mt={4}>
                <Grid item xs={6} container alignItems="center">
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ borderRadius: "20px" }}
                    startIcon={
                      loading ? (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <CheckCircleIcon />
                      )
                    }
                    type="submit"
                  >
                    {t("update.button")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="text "
                    size="large"
                    sx={{ borderRadius: "20px", color: "gray" }}
                    onClick={goLogin}
                  >
                    {t("cancel.button")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}
