import { useState, useEffect, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getToken } from "../../utils/GetToken";
import assignDataGridLanguage from "../../utils/AssignDatagridLanguage";
import { useSearchParams } from "react-router-dom";
import { ListMessagesServices } from "../../services/Notifications/ListMessagesServices";
import { HeadersNotification } from "../../utils/TableHeaders/NotificationHeaders";

export default function NotificationMessageList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [languageDatagrid, setLanguageDatagrid] = useState({});
  const [queryOptions, setQueryOptions] = useState({});
  const [query, setQuery] = useState(searchParams ? searchParams : {});
  const pageUrl = searchParams.get("page");
  const paginationUrl = searchParams.get("pp");
  const [pageFromUrl, setPageFromUrl] = useState(
    pageUrl !== null && pageUrl !== 0
      ? parseInt(searchParams.get("page"), 10)
      : 0
  );
  const [paginationFromUrl, setPaginationFromUrl] = useState(
    paginationUrl !== null && paginationUrl !== 0
      ? parseInt(searchParams.get("pp"), 10)
      : 100
  );
  const [pageState, setPageState] = useState({
    isLoading: false,
    messages: [],
    total: 0,
    page: pageFromUrl,
    pageSize: paginationFromUrl,
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: paginationFromUrl,
    page: pageFromUrl,
  });

  const currentLanguage = localStorage.getItem("i18nextLng");
  console.log("page State", pageState);

  const handlePageChange = (params) => {
    setPageState((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
    setPaginationModel((old) => ({
      ...old,
      page: params.page,
      pageSize: params.pageSize,
    }));
  };

  useEffect(() => {
    setLanguageDatagrid(assignDataGridLanguage(currentLanguage));
    // extractParamsAndAssignState();
  }, [currentLanguage]);

  const onFilterChange = useCallback(
    (filterModel) => {
      setQueryOptions({ filterModel: { ...filterModel } });
    },
    [query]
  );

  useEffect(() => {
    searchParams.set("page", `${pageState.page}`);
    searchParams.set("pp", `${pageState.pageSize}`);
    setSearchParams(searchParams);
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setPaginationModel((old) => ({
      ...old,
      page: pageFromUrl,
      pageSize: paginationFromUrl,
    }));
  }, [pageFromUrl, paginationFromUrl, query]);

  useEffect(() => {
    setPageState((old) => ({
      ...old,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    }));
    getNotificationList();
  }, [pageState.page, pageState.pageSize, query, paginationModel]);

  const getNotificationList = async () => {
    try {
      const token = await getToken();
      if (token) {
        setPageState((old) => ({ ...old, isLoading: true }));
        const currentPage = pageState.page + 1;
        const pagination = pageState.pageSize;
        const response = await ListMessagesServices(token);
        console.log("list response", response);
        const { messages, meta } = response;
        console.log("messages", messages);
        if (Array.isArray(messages) && messages.length !== 0) {
          const mappedData = mapData(messages);
          setPageState((old) => ({
            ...old,
            isLoading: false,
            messages: mappedData,
            total: meta?.total,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            messages: [],
            total: 0,
          }));
        }
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mapData = (messages) =>
    messages.map((item) => {
      const mappedItem = {
        id: item.id,
        message_type: item.message_type,
        message_subject: item.message_subject,
        message_content: item.message_content,
        department_id: item.department_id,
        country_id: item.country_id,
        gender_id: item.gender_id,
        city_id: item.city_id,
        created_at: item.created_at,
        updated_at: item.updated_at,
      };
      return mappedItem;
    });

  return (
    <>
      <DataGrid
        columns={HeadersNotification()}
        rows={pageState.messages}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page}
        filterMode="server"
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(params) => handlePageChange(params)}
        onFilterModelChange={onFilterChange}
        localeText={languageDatagrid}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        slots={{
          toolbar: GridToolbar,
        }}
        sx={{
          boxShadow: "0 1px 12px rgba(149, 157, 165, 0.2)",
          borderRadius: "25px",
          borderColor: "transparent",
          backgroundColor: "white",
          paddingX: "1.5rem",
          "& .MuiDataGrid-cell": {
            borderColor: "gray.light",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          "& .super-app-theme--header": {
            backgroundColor: "white",
          },
        }}
      />
    </>
  );
}
