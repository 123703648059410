import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  Skeleton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Box,
  ButtonBase,
  Avatar,
  Modal,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import { GetPlanDetailsService } from "../../services/Plans/GetPlanDetailsService";
import { useParams } from "react-router-dom";
import UpdateIcon from "@mui/icons-material/Update";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DescriptionIcon from "@mui/icons-material/Description";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { styled } from "@mui/material/styles";
import GroupsIcon from "@mui/icons-material/Groups";
import PaidIcon from "@mui/icons-material/Paid";

export default function PlanDetails() {
  const [loading, setLoading] = useState(false);
  const [planDetail, setPlanDetail] = useState({});
  const [permissions, setPermissions] = useState([]);
  const { t } = useTranslation();
  const { planId } = useParams();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState("");

  const handleInputFormat = (event) => {
    const { name, value } = event.target;
    const numericOnly = value.replace(/[^\d]/g, "");
    const formattedValue = value
      .replace(/\./g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    setValue(formattedValue);
    setFormValues({ ...formValues, [name]: numericOnly });
  };

  useEffect(() => {
    const planDetails = async () => {
      try {
        const token = await getToken();
        if (token) {
          const data = await GetPlanDetailsService(token, planId);
          //console.log(data);

          console.log("Image URL:", data.imageUrl);
          const plan = {
            id: data.id,
            title: data.title,
            duration: data.duration,
            contacts: data.contacts,
            description: data.description,
            imageUrl: data.imageUrl,
            value: `$${data.value.toLocaleString("es-CL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
            oldValue: `$${data.oldValue.toLocaleString("es-CL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} `,
            commissionable: `$${data.commissionable.toLocaleString("es-CL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
          };
          setPlanDetail(plan);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    planDetails();
  }, []);

  useEffect(() => {
    console.log(planDetail);
  }, [planDetail]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    //bgcolor: "background.paper",
    border: "2px ",
    //boxShadow: 20,
    p: 4,
  };
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    width: 450,
    Height: 200,
  });

  return (
    <>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={400}
          sx={{
            width: { xs: "320px", sm: "320px", md: "500px", xl: "872px" },
            borderRadius: "20px",
            mx: "1rem",
          }}
        />
      ) : (
        <Card
          elevation={5}
          sx={{
            borderRadius: "20px",
            mx: "1rem",
            height: "auto",
            p: { xs: "0.5rem", sm: "2rem" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase
                onClick={handleOpen}
                sx={{
                  width: 160,
                  height: 160,
                  top: "30%",
                  ml: "2rem",
                  mb: "2rem",
                  mr: "1rem",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  alt="plan image"
                  src={planDetail.imageUrl}
                  sx={{ width: "100%", height: "auto" }}
                >
                  <AssignmentIcon sx={{ width: "80%", height: "80%" }} />
                </Avatar>
              </ButtonBase>

              <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                  <Img src={planDetail.imageUrl} />
                </Box>
              </Modal>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6" color="primary.light">
                          {t("planDetails.title")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                        >
                          <Typography variant="subtitle1" color="primary">
                            {t("planDetails.value")}
                          </Typography>
                          <Stack direction="row" spacing={1} alignItems="end">
                            <Typography variant="body1" color="primary">
                              {planDetail.value}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                        >
                          <Typography variant="subtitle1" color="primary.light">
                            {t("planDetails.oldValue")}
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography variant="body1" color="primary.light">
                              {planDetail.oldValue}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>

                    <Divider light sx={{ marginBlock: "1rem" }} />

                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AssignmentIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("planDetails.name")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {planDetail.title}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" alignItems="center" spacing={1}>
                        <UpdateIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("planDetails.duration")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {planDetail.duration}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <GroupsIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("planDetails.contacts")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {planDetail.contacts}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <PaidIcon
                          fontSize="medium"
                          sx={{ color: "primary.light" }}
                        />
                        <Typography variant="subtitle1">
                          {t("planDetails.commissionable")}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1" color="grays.darkest">
                            {planDetail.commissionable}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Box alignItems="baseline">
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mb="-1rem"
                        >
                          <DescriptionIcon
                            fontSize="medium"
                            sx={{ color: "primary.light" }}
                          />
                          <Typography variant="subtitle1">
                            {t("planDetails.description")}
                          </Typography>
                        </Stack>
                        <List>
                          {planDetail?.description ? (
                            planDetail.description.map((description, index) => (
                              <ListItem dense key={index}>
                                <ListItemText>
                                  <Typography
                                    variant="subtitle1"
                                    color="grays.darkest"
                                    sx={{ textAlign: "justify", ml: "1rem" }}
                                  >
                                    {description}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            ))
                          ) : (
                            <p>No hay descripciones disponibles</p>
                          )}
                        </List>
                      </Box>
                    </Stack>
                  </CardContent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}
