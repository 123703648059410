import { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Stack,
  Card,
  CardContent,
  Divider,
  Chip,
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import ValidationStepFourModal from "./confirmation-modals/ValidationStepFourModal";
import { CiiuListService } from "../../services/CiiuListService";
import { BankListService } from "../../services/BankListService";
import { DocumentTypeListService } from "../../services/DocumentTypeListService";
import { create_company } from "../../helpers/create_company";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/GetToken";
import { useParams } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { GetPaymentMethodsService } from "../../services/GetPaymentMethodsService";
import { GetPersonTypeService } from "../../services/GetPersonTypeService";
import { useForm } from "../../context/CreateCommerceFormContext";
import { UpdateCommerceService } from "../../services/UpdateCommerceService";

const accountTypeList = [
  { id: 0, value: "Ahorros" },
  { id: 1, value: "Corriente" },
];

export default function BusinessDataForm({
  isCommerceCreated,
  setIsCommerceCreated,
  formResume,
  mode,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isShopUpdated, setIsShopUpdated] = useState(false);

  const [loadingCiius, setLoadingCiius] = useState(true);
  const [loadingBanks, setLoadingBanks] = useState(true);
  const [loadingDocTypes, setLoadingDocTypes] = useState(true);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true);
  const [loadingPersonType, setLoadingPersonType] = useState(true);
  const [loadingFormData, setLoadingFormData] = useState(
    mode === "edit" || mode === "continueReg" ? true : false
  );

  const [ciiuList, setCiiuList] = useState({});
  const [bankList, setBankList] = useState({});
  const [documentTypes, setDocumentTypes] = useState({});
  const [paymentMethodsList, setPaymentMethodsList] = useState({});
  const [personTypeList, setPersonTypeList] = useState({});

  const { formData, saveFormData } = useForm();
  const [formValues, setFormValues] = useState(formData);
  const { selectedValues, saveSelectedValues } = useForm();

  const { accountId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { shopId } =
    mode === "continueReg" || mode === "edit"
      ? useParams()
      : { shopId: localStorage.getItem("shopId") };

  useEffect(() => {
    const getListCiius = async () => {
      const ciiuList = await CiiuListService(1);
      setCiiuList(ciiuList);
      setLoadingCiius(false);
    };
    const getBankList = async () => {
      const bankList = await BankListService();
      setBankList(bankList);
      setLoadingBanks(false);
    };
    const getDocumentTypes = async () => {
      // console.log("Id", selectedPersonType.order + 1)
      const documentTypesList = await DocumentTypeListService(1);
      setDocumentTypes(documentTypesList);
      setLoadingDocTypes(false);
    };
    const getPaymentMethods = async () => {
      const paymentMethodsList = await GetPaymentMethodsService();
      setPaymentMethodsList(paymentMethodsList);
      setLoadingPaymentMethods(false);
    };

    const getPersonType = async () => {
      const personTypeList = await GetPersonTypeService();
      // console.log(personTypeList);
      setPersonTypeList(personTypeList);
      setLoadingPersonType(false);
    };

    getListCiius();
    getBankList();
    getDocumentTypes();
    getPaymentMethods();
    getPersonType();

    if (mode === "continueReg") {
      setCurrentStep();
    }

    setIsShopUpdated(false);
  }, []);

  const memoizedCiiuList = useMemo(() => ciiuList, [ciiuList]);

  const goToAccountsStores = () => {
    navigate("/accounts&stores");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = Object.entries(formData)
      .filter(([key, value]) => value !== "")
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    console.log(formValues);
    console.log("FD", formData);
    try {
      const token = await getToken();
      setLoading(true);
      if (mode !== "continueReg" && mode !== "edit" && !isCommerceCreated) {
        const response = await create_company(form, accountId, token);
        localStorage.setItem("shopId", response.data.data.id);
        console.log(response);
        setIsCommerceCreated(true);
      } else if (
        mode === "continueReg" ||
        mode === "edit" ||
        isCommerceCreated
      ) {
        const form = Object.entries(formData)
          .filter(([key, value]) => {
            return value !== "" && !isObjEmpty(value);
          })
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        const response = await UpdateCommerceService(form, shopId, token);
        console.log(response);
        // setIsCommerceCreated(true);
        setIsShopUpdated(true);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setCurrentStep = () => {
    if (mode === "continueReg") {
      saveFormData({
        ...formData,
        step: 1,
      });
      setFormValues({
        ...formValues,
        step: 1,
      });
    }
  };

  const filterByKeys = (data, keys) => {
    const filteredData = {};

    for (const key of keys) {
      if (key in data) {
        filteredData[key] = data[key];
      }
    }

    return filteredData;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    saveFormData({
      ...formData,
      [name]: value,
    });
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseConfirmationUserUpdated = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsShopUpdated(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleShowConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowConfirmation(false);
    }
  };

  const handleCiiuChange1 = (event, value) => {
    if (value?.description) {
      saveFormData({
        ...formData,
        ciiuId_1: `${value.id}`,
      });
      saveSelectedValues({ ...selectedValues, selectedMainActivity: value });
      setFormValues({ ...formValues, ciiuId_1: value.id });
    }
  };

  const handleCiiuChange2 = (event, value) => {
    if (value?.description) {
      saveFormData({
        ...formData,
        ciiuId_2: `${value.id}`,
      });
      saveSelectedValues({
        ...selectedValues,
        selectedSecondaryActivity: value,
      });
      setFormValues({ ...formValues, ciiuId_2: value.id });
    }
  };

  const handlePersonTypeChange = (event, value) => {
    console.log(value);
    if (value?.name) {
      saveFormData({
        ...formData,
        shop_person_type: value.code,
      });
      saveSelectedValues({
        ...selectedValues,
        selectedPersonType: value,
        selectedDocumentTypeShop: null,
      });
      setFormValues({
        ...formValues,
        shop_person_type: value.code,
        shop_document_number: "",
        shop_document_type: "",
      });
    }
  };

  const handleBankChange = (event, value) => {
    if (value?.bank_name) {
      if (mode !== "edit" && mode !== "continueReg") {
        saveFormData({
          ...formData,
          bankId: `${value.code}`,
        });
        saveSelectedValues({ ...selectedValues, selectedBank: value });
        setFormValues({ ...formValues, bankId: value.code });
      } else if (mode === "edit" || mode === "continueReg") {
        saveFormData({
          ...formData,
          bankId: `${value.code}`,
          typeAccount: "",
        });
        saveSelectedValues({
          ...selectedValues,
          selectedBank: value,
          selectedAccountType: null,
          selectedDocumentType: null,
        });
        setFormValues({
          ...formValues,
          bankId: value.code,
          typeAccount: "",
          accountNumber: "",
          documentTypeId: "",
          documentNumber: "",
        });
      }
    }
  };

  const handleDocTypeChange = (event, value) => {
    if (value?.description) {
      if (mode !== "edit" && mode !== "continueReg") {
        saveFormData({
          ...formData,
          documentTypeId: `${value.id}`,
        });
        saveSelectedValues({ ...selectedValues, selectedDocumentType: value });
        setFormValues({ ...formValues, documentTypeId: value.id });
      } else if (mode === "edit" || mode === "continueReg") {
        saveFormData({
          ...formData,
          documentTypeId: `${value.id}`,
          bankId: `${formValues.bankId}`,
          typeAccount: formValues.typeAccount,
        });
        saveSelectedValues({ ...selectedValues, selectedDocumentType: value });
        setFormValues({
          ...formValues,
          documentTypeId: value.id,
          documentNumber: "",
          accountNumber: "",
        });
      }
    }
  };

  const handleDocTypeShopChange = (event, value) => {
    if (value?.id) {
      if (mode !== "edit" && mode !== "continueReg") {
        saveFormData({
          ...formData,
          shop_document_type: `${value.id}`,
        });
        saveSelectedValues({
          ...selectedValues,
          selectedDocumentTypeShop: value,
        });
        setFormValues({ ...formValues, shop_document_type: `${value.id}` });
      } else if (mode === "edit" || mode === "continueReg") {
        saveFormData({
          ...formData,
          shop_document_type: `${value.id}`,
          shop_person_type: formValues.shop_person_type,
        });
        saveSelectedValues({
          ...selectedValues,
          selectedDocumentTypeShop: value,
        });
        setFormValues({
          ...formValues,
          shop_document_type: `${value.id}`,
          shop_document_number: "",
        });
      }
    }
  };

  const handleShopDocumentNumberChange = (event) => {
    const { name, value } = event.target;
    if (mode !== "edit" && mode !== "continueReg") {
      saveFormData({
        ...formData,
        [name]: value,
      });
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (mode === "edit" || mode === "continueReg") {
      saveFormData({
        ...formData,
        [name]: value,
        shop_person_type: formValues.shop_person_type,
        shop_document_type: `${formValues.shop_document_type}`,
      });
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAccountTypeChange = (event, value) => {
    if (value?.value) {
      if (mode !== "edit" && mode !== "continueReg") {
        saveFormData({
          ...formData,
          typeAccount: value.value,
        });
        saveSelectedValues({ ...selectedValues, selectedAccountType: value });
        setFormValues({ ...formValues, typeAccount: value.value });
      } else if (mode === "edit" || mode === "continueReg") {
        saveFormData({
          ...formData,
          typeAccount: value.value,
          bankId: `${formValues.bankId}`,
          documentTypeId: `${formValues.shop_document_type}`,
          documentNumber: `${formValues.shop_document_number}`,
        });
        saveSelectedValues({ ...selectedValues, selectedAccountType: value });
        setFormValues({
          ...formValues,
          typeAccount: value.value,
          accountNumber: "",
        });
      }
    }
  };

  const handleAccountNumberChange = (event) => {
    const { name, value } = event.target;
    if (mode !== "edit" && mode !== "continueReg") {
      saveFormData({
        ...formData,
        [name]: value,
      });
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (mode === "edit" || mode === "continueReg") {
      saveFormData({
        ...formData,
        [name]: value,
        typeAccount: formValues.typeAccount,
        bankId: `${formValues.bankId}`,
        documentTypeId: `${formValues.documentTypeId}`,
        documentNumber: `${formValues.documentNumber}`,
      });
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleDocumentNumberChange = (event) => {
    const { name, value } = event.target;
    if (mode !== "edit" && mode !== "continueReg") {
      saveFormData({
        ...formData,
        [name]: value,
      });
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (mode === "edit" || mode === "continueReg") {
      saveFormData({
        ...formData,
        [name]: value,
        bankId: `${formValues.bankId}`,
        typeAccount: formValues.typeAccount,
        documentTypeId: `${formValues.documentTypeId}`,
      });
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
        accountNumber: "",
      }));
    }
  };

  const handlePaymentMethodChange = (event, selectedValues) => {
    const selectedOptions = selectedValues.map((option) => option.code);
    saveFormData({
      ...formData,
      payment_methods: selectedOptions,
    });
    saveSelectedValues({
      ...selectedValues,
      selectedPaymentMethods: selectedValues,
    });
    setFormValues({
      ...formValues,
      payment_methods: selectedOptions,
    });
  };

  useEffect(() => {
    if (formResume) {
      console.log(formResume);
      setFormValues({ ...formResume, step: 1 });
      console.log(formValues);
      const initializeData = async () => {
        const loadedData = await assignFindedObjects();
      };
      initializeData();
    }
  }, [formResume]);

  const assignFindedObjects = async () => {
    try {
      const ciiu = await findCiiu();
      const personType = await findPersonType();
      const docTypeShop = await findDocType(formValues.shop_document_type);
      const bank = await findBank();
      const typeAccount = await findTypeAccount();
      const docTypeAccount = await findDocType(formValues.documentTypeId);
      const paymentMethods = await findPaymentMethods();
      const { matchingCiiu1, matchingCiiu2 } = ciiu !== undefined && ciiu;
      const values = [
        personType,
        docTypeShop,
        matchingCiiu1,
        matchingCiiu2,
        paymentMethods,
        docTypeAccount,
      ];
      const valuesIsEmpty = await objListIsUndefined(values);
      if (!valuesIsEmpty) {
        saveSelectedValues({
          ...selectedValues,
          selectedPersonType: personType,
          selectedDocumentTypeShop: docTypeShop,
          selectedMainActivity: matchingCiiu1,
          selectedSecondaryActivity: matchingCiiu2,
          selectedBank: bank,
          selectedAccountType: typeAccount,
          selectedDocumentType: docTypeAccount,
          selectedPaymentMethods: paymentMethods,
        });
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log(error);
    } finally {
      setLoadingFormData(false);
    }
    // return true;
  };

  const objListIsUndefined = async (listObj) => {
    return await listObj.some((element) => element === undefined);
  };

  const findCiiu = async () => {
    if (!isObjEmpty(ciiuList) && formValues.ciiuId_1 !== "") {
      const matchingCiiu1 = await findCiiuByID(formValues.ciiuId_1);
      const matchingCiiu2 = await findCiiuByID(formValues.ciiuId_2);
      return { matchingCiiu1, matchingCiiu2 };
    }
  };

  const findPersonType = async () => {
    if (!isObjEmpty(personTypeList) && formValues.shop_person_type !== "") {
      const matchingPersonType = await findPersonTypeByCode(
        formValues.shop_person_type
      );
      return matchingPersonType !== undefined && matchingPersonType;
    }
  };

  const findDocType = async (docTypeId) => {
    if (!isObjEmpty(documentTypes) && docTypeId !== "") {
      const matchingDocType = await findDocTypeById(docTypeId);
      return matchingDocType;
    }
  };

  const findBank = async () => {
    if (!isObjEmpty(bankList) && formValues.bankId !== "") {
      const matchingBank = await findBankById(formValues.bankId);
      return matchingBank;
    }
  };

  const findTypeAccount = async () => {
    if (!isObjEmpty(accountTypeList) && formValues.typeAccount !== "") {
      const matchingAccountType = await findTypeAccountByValue(
        formValues.typeAccount
      );
      // console.log(matchingAccountType);
      return matchingAccountType;
    }
  };

  const findPaymentMethods = async () => {
    if (!isObjEmpty(paymentMethodsList) && formValues.payment_methods !== "") {
      const matchingPaymentMethods = await findPaymentMethodsByValues(
        formValues.payment_methods
      );
      return matchingPaymentMethods;
    }
  };

  const findDocTypeById = async (docTypeId) => {
    if (!isObjEmpty(personTypeList)) {
      return await documentTypes.find((docType) => docType.id === docTypeId);
    }
  };

  const findPersonTypeByCode = async (personCode) => {
    if (!isObjEmpty(personTypeList)) {
      return await personTypeList.find(
        (personType) => personType.code === personCode
      );
    }
  };

  const findCiiuByID = async (ciiuId) => {
    if (!isObjEmpty(ciiuList)) {
      return await ciiuList.find((ciiu) => ciiu.id === ciiuId);
    }
  };

  const findBankById = async (bankId) => {
    if (!isObjEmpty(bankList)) {
      return await bankList.find((bank) => bank.code === bankId);
    }
  };

  const findTypeAccountByValue = async (typeAccount) => {
    if (!isObjEmpty(accountTypeList)) {
      return accountTypeList.find((type) => type.value === typeAccount);
    }
  };

  const findPaymentMethodsByValues = async (paymentsMethods) => {
    if (!isObjEmpty(paymentMethodsList)) {
      return await paymentMethodsList.filter((type) =>
        paymentsMethods.includes(type.code)
      );
    }
  };

  const isObjEmpty = (obj) => {
    return Object.values(obj).length === 0;
  };

  // useEffect(() => {
  //   console.log("Loading Form data", loadingFormData);
  // }, [loadingFormData]);

  return (
    <Stack
      // mt={{ xs: 0, md: 2 }}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        elevation={0}
        sx={{
          p: "1rem",
          mr: { xs: "0.4rem", sm: "10rem", md: "10rem" },
          mt: { xs: "2rem", md: "0" },
          maxWidth: "800px",
        }}
      >
        <CardContent>
          <Box mb={2}>
            <Typography variant="h6" fontWeight={600} textAlign={"center"}>
              {t("step.four.label")}
            </Typography>
          </Box>
          <Box my={4}>
            <Divider variant="middle">
              <Chip label={t("businessData.form.subtitle-1")} />
            </Divider>
          </Box>
          <Snackbar
            open={isShopUpdated}
            autoHideDuration={6000}
            onClose={handleCloseConfirmationUserUpdated}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("updateCommerce.form.success")}</Alert>
          </Snackbar>
          <Snackbar
            open={error}
            autoHideDuration={6000}
            onClose={handleCloseError}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form onSubmit={handleSubmit}>
            {!loadingFormData ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="name_shop"
                    label={t("businessData.form.comercial_name")}
                    size="small"
                    value={formValues.name_shop}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="description"
                    label={t("businessData.form.product_description")}
                    size="small"
                    value={formValues.description}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="ciiu-1"
                    options={
                      loadingCiius ? [{ description: "" }] : memoizedCiiuList
                    }
                    value={selectedValues.selectedMainActivity}
                    loading={loadingCiius}
                    loadingText="Loading..."
                    onChange={handleCiiuChange1}
                    autoHighlight
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      option.code + "  " + option.description
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <Stack direction="row" gap={1}>
                          <Typography fontWeight={500}>
                            {option.code}
                          </Typography>
                          <Typography>{option.description}</Typography>
                        </Stack>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleCiiuChange1}
                        name="selectedCiiu1"
                        size="small"
                        value={selectedValues.selectedMainActivity}
                        label={t("locationandbankinfo.form.mainActivity")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="ciiu-2"
                    options={
                      loadingCiius ? [{ description: "" }] : memoizedCiiuList
                    }
                    value={selectedValues.selectedSecondaryActivity}
                    loading={loadingCiius}
                    loadingText="Loading..."
                    onChange={handleCiiuChange2}
                    autoHighlight
                    isOptionEqualToValue={(option, value) =>
                      option.description === value.description
                    }
                    getOptionLabel={(option) =>
                      option.code + "  " + option.description
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <Stack direction="row" gap={1}>
                          <Typography fontWeight={500}>
                            {option.code}
                          </Typography>
                          <Typography>{option.description}</Typography>
                        </Stack>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleCiiuChange2}
                        name="selectedCiiu2"
                        size="small"
                        value={selectedValues.selectedSecondaryActivity}
                        label={t("locationandbankinfo.form.secondaryActivity")}
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="personType-select"
                    options={
                      loadingPersonType
                        ? [{ name: "Loading..." }]
                        : personTypeList
                    }
                    loading={loadingPersonType}
                    value={selectedValues.selectedPersonType}
                    loadingText="Loading..."
                    onChange={handlePersonTypeChange}
                    autoHighlight
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handlePersonTypeChange}
                        size="small"
                        name="shop_person_type"
                        value={selectedValues.selectedPersonType}
                        label={t("locationandbankinfo.form.personType")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="web"
                    label={t("businessData.form.web_shop")}
                    size="small"
                    value={formValues.web}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="documentTypeId"
                    options={
                      loadingDocTypes
                        ? [{ description: "Loading..." }]
                        : documentTypes
                    }
                    loading={loadingDocTypes}
                    value={selectedValues.selectedDocumentTypeShop}
                    loadingText="Loading..."
                    onChange={handleDocTypeShopChange}
                    autoHighlight
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleDocTypeShopChange}
                        size="small"
                        name="documentTypeId"
                        value={selectedValues.selectedDocumentTypeShop}
                        label={t("locationandbankinfo.form.docType")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="shop_document_number"
                    label={t("locationandbankinfo.form.numDocument")}
                    size="small"
                    value={formValues.shop_document_number}
                    onChange={handleShopDocumentNumberChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Box my={4}>
              <Divider variant="middle">
                <Chip label={t("locationandbankinfo.title-2")} />
              </Divider>
            </Box>
            <Box my={4}>
              <Typography
                variant="caption"
                color="gray"
                display="flex"
                justifyContent="center"
              >
                {t("locationandbankinfo.caption-1")}
              </Typography>
            </Box>
            {!loadingFormData ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="bank"
                    options={loadingBanks ? [{ name: "Loading..." }] : bankList}
                    value={selectedValues.selectedBank}
                    loading={loadingBanks}
                    loadingText="Loading..."
                    onChange={handleBankChange}
                    autoHighlight
                    getOptionLabel={(option) => option.bank_name}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleBankChange}
                        size="small"
                        name="bankId"
                        value={selectedValues.selectedBank}
                        label={t("locationandbankinfo.form.bank")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="paymentMethods-select"
                    multiple
                    value={selectedValues.selectedPaymentMethods}
                    options={
                      loadingPaymentMethods
                        ? [{ name: "loading", url_image: "" }]
                        : paymentMethodsList
                    }
                    loading={loadingPaymentMethods}
                    loadingText="Loading..."
                    onChange={handlePaymentMethodChange}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="50"
                          src={`${option.url_image}`}
                          alt=""
                        />
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handlePaymentMethodChange}
                        size="small"
                        name="paymentMethod"
                        value={selectedValues.selectedPaymentMethods}
                        label={t("paymenthmethods.title")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="typeAccount"
                    options={accountTypeList}
                    onChange={handleAccountTypeChange}
                    autoHighlight
                    value={selectedValues.selectedAccountType}
                    getOptionLabel={(option) => option.value}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleAccountTypeChange}
                        size="small"
                        name="typeAccount"
                        value={selectedValues.selectedAccountType}
                        label={t("locationandbankinfo.form.accountType")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="accountNumber"
                    label={t("locationandbankinfo.form.accountNumber")}
                    size="small"
                    value={formValues.accountNumber}
                    onChange={handleAccountNumberChange}
                    fullWidth
                    inputProps={{ inputMode: "numeric" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="shop_document_type"
                    options={
                      loadingDocTypes
                        ? [{ description: "Loading..." }]
                        : documentTypes
                    }
                    loading={loadingDocTypes}
                    value={selectedValues.selectedDocumentType}
                    loadingText="Loading..."
                    onChange={handleDocTypeChange}
                    autoHighlight
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleDocTypeChange}
                        size="small"
                        name="documentTypeId"
                        value={selectedValues.selectedDocumentType}
                        label={t("locationandbankinfo.form.docType")}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="documentNumber"
                    label={t("locationandbankinfo.form.numDocument")}
                    size="small"
                    value={formValues.documentNumber}
                    onChange={handleDocumentNumberChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{ fontSize: "1rem" }}
                      width={"95%"}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Box mt={2} sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                  ) : (
                    <SaveIcon sx={{ color: "white" }} />
                  )
                }
              >
                {mode !== "edit" ? t("save.button") : t("update.button")}
              </Button>
            </Box>
            {/* Show Validation Dialog */}
            <ValidationStepFourModal
              isOpen={showConfirmation}
              handleClose={handleCloseConfirmation}
            />
          </form>
        </CardContent>
      </Card>
    </Stack>
  );
}
