import { createContext, useState, useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from "@mui/material/styles";

export const CreateMenuOpenContext = createContext();

export const useMenu = () => useContext(CreateMenuOpenContext);

export const MenuProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(!isMobile ? true : false);

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CreateMenuOpenContext.Provider value={{ isOpen, setIsOpen, handleOpenMenu }}>
      {children}
    </CreateMenuOpenContext.Provider>
  );
};