
import axios from 'axios';

export const AxiosInterceptor = () => {

  // const handleVisibilityChange = () => {
  //   if (document.hidden) {
  //     axios.interceptors.request.eject(interceptorRequest);
  //     throw new Error('Request was not complete.');
  //   }
  // };
  
  // const interceptorRequest = axios.interceptors.request.use((config) => {
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  
  //   return config;
  // });

  const interceptor = axios.interceptors.response.use(
    (response) => {
      // console.log('response', response);
      return response;
    },
    (error) => {
      // if (error.response.status === 401) {
      //   localStorage.removeItem("refreshToken");
      //   localStorage.removeItem("refreshTokenTime");
      //   window.location.reload();
      // }
      return Promise.reject(`Error: ${error.response.data.message}`);
    }
  );

  // axios.interceptors.response.eject(interceptor);
};