import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  FormControl,
  Alert,
  Card,
  CardContent,
  Stack,
  Divider,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';

function SelectViaTwoFactorNotificaction() {
  const [error, setError] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const goVerification_code_Link = async (event) => {
    navigate("/forgetPassword", { state: { mode: "link" } });
  };

  const goVerification_code_email = async (event) => {
    navigate("/forgetPassword", { state: { mode: "code" } });
  };

  return (
    <Box className="login">
      <Stack
        mt={{ xs: 0, md: 2 }}
        sx={{
          justifyContent: "center",
          alignItems: { xs: "center" },
        }}
      >
        <Card
          elevation={4}
          sx={{
            borderRadius: "20px",
            p: "1rem",
            mx: { xs: "0.4rem", sm: "10rem", md: "0" },
            mt: { xs: "2rem", md: 0 },
            maxWidth: "500px",
          }}
        >
          <CardContent>
            <Typography variant="h6" fontWeight={600} textAlign={"center"}>
              {t("select_two_factor.title")}
            </Typography>
            <Divider sx={{ my: "1rem" }} />
            <Typography variant="p" textAlign={"start"}>
              {t("select_two_factor.text")}
            </Typography>
            <form>
              <Box my={2}>
                {passwordChanged && (
                  <Alert severity="success">
                    {t("changePassword.alert.success")}
                  </Alert>
                )}
                {error && (
                  <Alert severity="error">
                    {t("changePassword.alert.error")}
                  </Alert>
                )}
              </Box>
              <FormControl
                sx={{ m: 1, width: "98%" }}
                variant="standard"
                fullWidth
              >
                <Button
                  variant="contained"
                  onClick={goVerification_code_Link}
                  size="large"
                  color="secondary"
                  sx={{ borderRadius: "20px" }}
                  startIcon={<LinkIcon />}
                >
                 {t("select_two_factor.optionLink")}
                </Button>
              </FormControl>
              <FormControl
                sx={{ m: 1, width: "98%" }}
                variant="standard"
                fullWidth
              >
                <Button
                  variant="contained"
                  onClick={goVerification_code_email}
                  size="large"
                  color="secondary"
                  sx={{ borderRadius: "20px" }}
                  startIcon={<EmailIcon />}
                >
                 {t("select_two_factor.optionEmail")}
                </Button>
              </FormControl>
              <Stack
                mt={4}
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: { xs: "center" },
                }}
              >
                <Link
                  href="/"
                  sx={{
                    fontSize: "15px",
                    display: "flex",
                    textAlign: "center",
                    color: "secondary.main",
                  }}
                  underline="hover"
                >
                  {t("backToHome.button")}
                </Link>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default React.memo(SelectViaTwoFactorNotificaction);