import axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export const GetTaxesListService = (token, page, query, pagination) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url;
      if (query === "") {
        url = `${URL_MERCHANTS}/api/brain/v1/tax/list?page=${page}&pagination=${pagination}`;
      } else {
        console.log("URL with params inside");
        url = `${URL_MERCHANTS}/api/brain/v1/tax/list?${query}&page=${page}&pagination=${pagination}`;
      }
      const response = await axios.get(url, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Response taxes", response);
      resolve(response.data);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};
