import axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export const ApproveDocumentService = (
  token,
  docId,
  comment,
  documentListBy
) => {
  return new Promise(async (resolve, reject) => {
    let url = "";
    if (documentListBy === "client") {
      url = `${URL_AUTH}/api/admin/v1/client/document/${docId}/approve`;
    } else if (documentListBy === "agent") {
      url = `${URL_AUTH}/api/admin/v1/agent/document/${docId}/approve`;
    }
    try {
      const response = await axios.put(url, comment, {
        headers: {
          "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      resolve(response);
    } catch (error) {
      console.log("error:", error);
      reject(error);
    }
  });
};
