import { useState, useEffect, forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Slide,
  Typography,
  Grid,
} from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getToken } from "../../utils/GetToken";
import SuspendCommerce from "../accounts-and-commerces-components/SuspendCommerce";
import { SuspendCommerceService } from "../../services/SuspendCommerceService";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuspendCommerceLayout({
  shopId,
  setClickSuspendCommerce,
  handleSuspend,
}) {
  const [openDialog, setOpenDialog] = useState(true);
  const { t } = useTranslation();
  const { isOpen } = useMenu();

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
      setClickSuspendCommerce(false);
    }
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        open={openDialog}
        TransitionComponent={Transition}
        onClose={handleCloseDialog}
        sx={{ display: "block", justifyContent: "center" }}
      >
        <DialogTitle>{t("suspendCommerce.title")}</DialogTitle>
        <DialogContent>
          <Grid item xs={12} display="flex" textAlign="center">
            {t("suspendCommerce.text")}
          </Grid>
          <SuspendCommerce
            id={shopId}
            handleCloseDialog={handleCloseDialog}
            handleSuspend={handleSuspend}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseDialog();
            }}
          >
            {t("cancel.button")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
