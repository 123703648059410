import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Tabs,
  Tab,
  Container,
} from "@mui/material";
import TransactionList from "../transactions-components/TransactionsList";
import { useTranslation } from "react-i18next";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import ReportList from "../report-components/ReportList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box sx={{ p: { xs: "0.2rem", sm: 3 }, mt: 2, mb: 8 }}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TransactionsLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabUrl = searchParams.get("tab");
  const [currentTab, setCurrentTab] = useState(
    tabUrl !== null && tabUrl !== 0 ? parseInt(tabUrl, 10) : 0
  );
  const [value, setValue] = useState(parseInt(currentTab, 10));
  const [tabHasChanged, setTabHasChanged] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();
  const { shopId, accountId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setCurrentTab(value);
  }, [value]);

  useEffect(() => {
    const tabParam = extractTabParamFromURL();
    if (tabParam) {
      setValue(tabParam);
    }
  }, []);

  useEffect(() => {
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  }, [currentTab]);

  const extractTabParamFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam !== null) {
      const tabValue = parseInt(tabParam, 10);
      if ([0, 1].includes(tabValue)) {
        console.log("tab param", tabParam);
        return tabValue;
      }
    }
    return 0;
  };

  return (
    <Content open={isOpen}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pl: 0 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("transactions.title")} {...a11yProps(0)} />
          {/* <Tab label={t("reports.title")} {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container direction="column">
          <Grid item>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("transactions.title")}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" textAlign={"flex-start"}>
                {t("transactions.description")}
              </Typography>
            </Box>
          </Grid>
          <Box
            mt={2}
            sx={{
              height: 600,
              width: "100%",
            }}
          >
            <TransactionList
              accountId={accountId !== null ? accountId : null}
              shopId={shopId !== null ? shopId : null}
              currentTab={currentTab}
              tabHasChanged={tabHasChanged}
              setTabHasChanged={setTabHasChanged}
            />
          </Box>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item>
            <Box>
              <Typography
                variant="h4"
                color={"primary"}
                textAlign={"flex-start"}
                sx={{ textTransform: "uppercase" }}
                fontWeight={800}
              >
                {t("reports.title")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {/* <Box>
              <Button
                variant="contained"
                color={"secondary"}
                size="large"
                startIcon={<SupervisedUserCircleIcon sx={{ color: "white" }} />}
                onClick={handleButtonNewRoleClick}
              >
                {t("roles.createNewRole.button")}
              </Button>
            </Box> */}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box
              mt={2}
              sx={{
                height: 600,
                width: "100%",
              }}
            >
              <ReportList
                currentTab={currentTab}
                tabHasChanged={tabHasChanged}
                setTabHasChanged={setTabHasChanged}
              />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Content>
  );
}
