import Axios from "axios"

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function ChangePasswordService(token, formValue) {
    return new Promise(async(resolve, reject) => {
        try { 
            const response = await Axios.put( `${URL_AUTH}/api/admin/v1/user/password/change`, formValue, {
                headers: {
                    "X-localization":`${window.localStorage.getItem("i18nextLng")}`,
                    Authorization: `Bearer ${token}`,
                }
            })
            resolve(response)
        } catch (error) {
            reject(error)
        }
    })    
}
