import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/GetToken";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FrecuencyTranslator from "../FrecuencyTranslator";
import { ExcelDownloadReportService } from "../../services/Reports/ExcelDownloadReportService";
import { CsvDownloadReportService } from "../../services/Reports/CsvDownloadReportService";
import { PdfDownloadReportService } from "../../services/Reports/PdfDownloadReportService";
import { WindowSharp } from "@mui/icons-material";
import axios from "axios";

const URL_TRANSACTIONS = import.meta.env.VITE_TRANSACTIONS_URL;

const HandleDownloadActions = (id, token) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [clickedExcel, setClickedExcel] = useState(false);
  const [clickedCsv, setClickedCsv] = useState(false);
  const [clickedPdf, setClickedPdf] = useState(false);

  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDownloadExcel = () => {
    setClickedExcel(true);
  };

  const handleClickDownloadCsv = () => {
    setClickedCsv(true);
  };

  const handleClickDownloadPdf = () => {
    setClickedPdf(true);
  };

  const handleDownloadPdf = async () => {
    try {
      // const token = await getToken();

      // const response = await PdfDownloadReportService(token, id.reportId);
      // const blob = new Blob([response.data], { type: 'mimeType' });
      // console.log(response);
      const url = `${URL_TRANSACTIONS}/api/brain/v1/report/${id.reportId}/download/pdf`

      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleDownloadCsv = async () => {
    try {
      // const token = await getToken();

      // const response = await PdfDownloadReportService(token, id.reportId);
      // const blob = new Blob([response.data], { type: 'mimeType' });
      // console.log(response);
      const url = `${URL_TRANSACTIONS}/api/brain/v1/report/${id.reportId}/download/csv`

      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleDownloadExcel = async () => {
    try {
      // const token = await getToken();

      // const response = await PdfDownloadReportService(token, id.reportId);
      // const blob = new Blob([response.data], { type: 'mimeType' });
      // console.log(response);
      const url = `${URL_TRANSACTIONS}/api/brain/v1/report/${id.reportId}/download/excel`

      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  // useEffect(() => {
  //   const Actions = async () => {
  //     try {
  //       const token = await getToken();
  //       if (token) {
  //         if (clickedExcel) {
  //           const excelFile = await ExcelDownloadReportService(
  //             token,
  //             id.reportId
  //           );
  //           console.log(excelFile);
  //           // handleDownloadPdf(pdf);
  //           state: {
  //             reportId: id;
  //           }
  //         }
  //         if (clickedCsv) {
  //           const csvFile = await CsvDownloadReportService(token, id.reportId);
  //           console.log(csvFile);
  //         }
  //         // if (clickedPdf) {
  //         //   const pdf = await PdfDownloadReportService(token, id.reportId);
  //         //   console.log(pdf.data);
  //         //   handleDownloadPdf(pdf);
  //         //   state: {
  //         //     reportId: id;
  //         //   }
  //         // }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       handleClose();
  //     }
  //   };
  //   Actions();
  // }, [clickedExcel, clickedCsv, clickedPdf]);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 172,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleDownloadExcel}>
          <ListItemIcon>
            <WysiwygIcon color="succes" />
          </ListItemIcon>
          <ListItemText>{t("report.table.option-1")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDownloadCsv}>
          <ListItemIcon>
            <TextSnippetIcon />
          </ListItemIcon>
          <ListItemText>{t("report.table.option-2")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDownloadPdf}>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText>{t("report.table.option-3")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export const HeadersReportList = () => {
  const currentLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 60,
      renderCell: (params) => (
        <HandleDownloadActions reportId={params.row.id} />
      ),
    },
    {
      field: "id",
      headerName: "Id",
      width: 350,
    },
    {
      field: "dateCreation",
      headerName: t("report.table.header-1"),
      width: 180,
    },
    {
      field: "dateFrom",
      headerName: t("report.table.header-2"),
      width: 180,
    },
    {
      field: "dateTo",
      headerName: t("report.table.header-3"),
      width: 180,
    },
    {
      field: "frecuency",
      headerName: t("report.table.header-4"),
      width: 120,
      renderCell: (params) => {
        const { value } = params;
        const translatedValue = FrecuencyTranslator(value);
        return (
          <Chip
            label={translatedValue}
            variant="outlined"
            sx={{ border: "none" }}
          />
        );
      },
    },
  ];
  return columns;
};
