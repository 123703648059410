import { useState, useEffect } from "react";
import { Grid, Typography, Box, Snackbar, Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useTranslation } from "react-i18next";
import DocumentList from "../documents-components/DocumentList";
import { getToken } from "../../utils/GetToken";
import { ClientInfoByIdService } from "../../services/ClientInfoByIdService";
import { AgentInfoByIdService } from "../../services/AgentInfoByIdService";
import { useParams, Link } from "react-router-dom";
import ReviewDocumentLayout from "./ReviewDocumentLayout";
import { useReviewDoc } from "../../context/ReviewDocumentContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function DocumentListLayout() {
  const [showDialog, setShowDialog] = useState(false);
  const [docId, setDocId] = useState("");
  const [userName, setUserName] = useState("");
  const [showAlertApproved, setShowAlertApproved] = useState(false);
  const [showAlertRejected, setShowAlertRejected] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();
  const { docApproved, docRejected } = useReviewDoc();
  const { state } = useLocation();
  const { documentListBy } = state;

  const { userId } = useParams();

  const handleShowDialog = (id) => {
    setShowDialog(!showDialog);
  };

  useEffect(() => {
    getClientData();
    // setShowAlertApproved(true);

    console.log();
  }, []);

  const getClientData = async () => {
    try {
      const token = await getToken();
      if (token) {
        if (documentListBy === "client") {
          const data = await ClientInfoByIdService(token, userId);
          console.log("Client Data", data);
          const { fullName } = data;
          handleUserName(fullName);
        } else if (documentListBy === "agent") {
          const data = await AgentInfoByIdService(token, userId);
          console.log("Agent Data", data);
          const { fullName } = data;
          handleUserName(fullName);
        }
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
      console.log("error:", error);
    }
  };

  const handleUserName = (name) => {
    setUserName(name);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
  };

  const handleErrorMessage = (message) => {
    setErrorMessage(message);
  };

  const handleCloseDocApproved = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertApproved(false);
  };

  const handleCloseDocRejected = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertRejected(false);
  };

  useEffect(() => {
    docApproved && setShowAlertApproved(true);
    docRejected && setShowAlertRejected(true);
  }, [docApproved, docRejected]);

  return (
    <Content open={isOpen}>
      <Grid container spacing={1} ml={{ xs: 0, sm: 2 }} mb={2}>
        <Link
          to={
            documentListBy === "client"
              ? "/usersManagement?tab=1"
              : "/usersManagement?tab=2"
          }
          className="nav-link_back"
          underline="none"
        >
          <ArrowBackIcon sx={{ marginRight: "5px" }} />
          {t("back.button")}
        </Link>
      </Grid>
      <Grid container direction="column">
        <Grid item xs paddingX={"20px"}>
          <Snackbar
            open={showAlertApproved}
            autoHideDuration={6000}
            onClose={handleCloseDocApproved}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("approveDoc.message")}</Alert>
          </Snackbar>
          <Snackbar
            open={showAlertRejected}
            autoHideDuration={6000}
            onClose={handleCloseDocRejected}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="info">{errorMessage}</Alert>
          </Snackbar>
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"flex-start"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("approveDocuments.title")}
          </Typography>
          <Box my={2}>
            <Typography variant="body1" textAlign={"flex-start"}>
              {t("approveDocuments.description")} {` ${userName}`}
            </Typography>
          </Box>
        </Grid>
        <Box
          mt={2}
          sx={{
            height: 400,
            width: "100%",
            paddingInline: { xs: 0, sm: "1rem" },
          }}
        >
          <DocumentList documentListBy={documentListBy} />
        </Box>
      </Grid>
      {showDialog && <ReviewDocumentLayout docId={docId} />}
    </Content>
  );
}
