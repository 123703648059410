import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import TimeFilter from "../common/TimeFilter";
import MovingIcon from "@mui/icons-material/Moving";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { getToken } from "../../utils/GetToken";
import { GetUserStatsServices } from "../../services/Stats/GetUserStatsServices.js";
import { useState } from "react";

export default function UserAccountsResume() {
  const { t } = useTranslation();

  const [selectedMonth, setSelectedMonth] = useState("");

  const getUserStats = async () => {
    const token = await getToken();
    const response = await GetUserStatsServices(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["userStats"],
    queryFn: () => getUserStats(),
  });
  const totaluser = response?.data?.totalUsers || [];

  const totalCount = totaluser.reduce((total, user) => total + user.count, 0);

  const percentage = (totalCount * totalCount) / 100;

  const availableMonths = [...new Set(totaluser.map((user) => user.month))];
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const getCountForSelectedMonth = () => {
    const selectedUserStats = totaluser.find(
      (user) => user.month === parseInt(selectedMonth)
    );
    return selectedUserStats ? selectedUserStats.count : 0;
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("accountsCard.title-1")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        {/* <TimeFilter />
         */}
        <Typography>{t("accountsCard.subtitle-4")}</Typography>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Stack direction="row" spacing={1} alignItems="baseline" my={1}>
            <Typography variant="h5" fontWeight={600}>
              {totalCount}
            </Typography>
            <Typography variant="p">{t("registered")}</Typography>
          </Stack>
        )}
        <Stack direction="row" spacing={0.5} alignItems="center">
          <MovingIcon color="success" />
          <Typography variant="subtitle1" color={"success.main"}>
            ({percentage}%)
          </Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography mt={"1.5rem"}>{t("accountsCard.subtitle-5")}</Typography>

        <Stack direction="row" spacing={2} alignItems="center" mt="1rem">
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel>{t("months")}</InputLabel>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              displayEmpty
              label={t("months")}
            >
              {availableMonths.map((month) => (
                <MenuItem key={month} value={month}>
                  {t("months") + " " + t(month)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            selectedMonth && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <GroupAddIcon color="success" />
                <Typography variant="h5" color={"success.main"}>
                  {getCountForSelectedMonth()}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
