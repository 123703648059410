import Axios from "axios";

const URL_MERCHANTS = import.meta.env.VITE_MERCHANTS_URL;

export function CreateCompanyService(data, accountId, token ) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(data);
      const response = await Axios.post(
        `${URL_MERCHANTS}/api/brain/v1/account/${accountId}/create/company`,
        data,
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log("Create Company Service Response: ", response);
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}