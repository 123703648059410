import { Avatar, Stack, Typography, Skeleton } from "@mui/material";
import React, { Suspense, useEffect, useState, useContext } from "react";
import { GetUserData } from "../../services/GetUserService";
import { AuthContext } from "../../context/AuthContext";
import { getToken } from "../../utils/GetToken";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function UserAvatarInfo() {
  const [user, setUser] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const { isLoggedIn, isUserUpdated, resetUserUpdated } =
    useContext(AuthContext);

  useEffect(() => {
    if (!dataLoaded && isLoggedIn) {
      const getUser = async () => {
        const token = await getToken();
        try {
          const response = await GetUserData(token);
          setUser(response);
          setDataLoaded(true);
        } catch (error) {
          console.log(error);
        }
      };
      getUser();
      resetUserUpdated();
    }
  }, [isUserUpdated]);

  const userName = `${user.forename} ${user.surname}`;

  return (
    <Suspense>
      <Stack
        direction="row"
        gap="1rem"
        my="2rem"
        ml="0.5rem"
        alignItems="center"
      >
        {!dataLoaded ? (
          <Skeleton
            variant="circular"
            animation="wave"
            width={40}
            height={40}
          />
        ) : (
          <Avatar alt="user-avatar" {...stringAvatar(`${userName}`)} />
        )}
        <Stack>
          {!dataLoaded ? (
            <>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ fontSize: "1rem" }}
                width={200}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ fontSize: "1rem" }}
                width={200}
              />
            </>
          ) : (
            <>
              <Typography variant="subtitle1">{userName}</Typography>
              <Typography variant="caption">{user.email}</Typography>
            </>
          )}
        </Stack>
      </Stack>
    </Suspense>
  );
}

export default React.memo(UserAvatarInfo);
