import Axios from "axios";

const URL_AUTH = import.meta.env.VITE_AUTH_URL;

export function ResendSmsCodeService(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `${URL_AUTH}/api/brain/v1/admin/signup/resend/movilcode`,
        {},
        {
          headers: {
            "X-localization": `${window.localStorage.getItem("i18nextLng")}`,
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log("Resend sms response: ", response);
      resolve(response);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}