import axios from "axios";

const CALLS_API_KEY = import.meta.env.VITE_CALLS_API_KEY;
const CALLS_API_PROJECT_ID = import.meta.env.VITE_CALLS_API_PROJECT_ID;

function CreateRequest(number) {
  return [
    {
      Token_v: CALLS_API_KEY,
      IdProyecto_i: CALLS_API_PROJECT_ID,
      Extension_v: "101",
      Numero_v: number,
    },
  ];
}

export function MakeCall(number) {
  const noAreaCode = number.replace("+57", "");
  const callRequest = CreateRequest(noAreaCode);
  return new Promise(async (resolve, reject) => {
    try {
      console.log(callRequest);
      const response = await axios.post(
        "https://irispbxcloud.com/manager/api/public/llamadas/generar",
        callRequest,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
}
