import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FrecuencyTranslator(frecuency) {

  const { t } = useTranslation();

  const getFrecuencyFromCode = (frecuency) => {
    const stateMap = {
      D: "diary",
      W: "weekly",
      M: "monthly",
    };

    const stateKey = stateMap[frecuency];
    if (!stateKey) {
      return "Unknown";
    }
    return t(stateKey);
  };

  return getFrecuencyFromCode(frecuency);
}