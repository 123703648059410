import { useEffect } from "react";

const PHONE_PLUGIN_API_KEY = import.meta.env.VITE_PHONE_PLUGIN_API_KEY;

const style = `
      #boton-flotante {
          bottom: 43px;
          right: 16px;
          z-index: 10000;
          background-color: #00000000;
          color: white;
          padding: 0px;
          font-size: 20px;
          border: none;
          cursor: pointer;
          height: 15px;
          width: 15px;
          position: absolute;
        }
        #div-draggable {
          position: fixed;
          bottom: 1px;
          right: 5px;
          z-index: 1000;
          height: 1px;
          width: 1px;
        }
        #div-flotante {
          // display: none;
          bottom: 1px;
          right: 0px;
          z-index: 1;
          background-color: #edf2f9;
          padding: 2px;
          border-radius: 5px;
        }
      `;
console.log("PHONE", PHONE_PLUGIN_API_KEY);
const htmlContent = `<button id="boton-flotante">
      <svg xmlns="http://www.w3.org/2000/svg" height="13px" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#dae2ef}</style><path d="M448 344v112a23.94 23.94 0 0 1-24 24H312c-21.39 0-32.09-25.9-17-41l36.2-36.2L224 295.6 116.77 402.9 153 439c15.09 15.1 4.39 41-17 41H24a23.94 23.94 0 0 1-24-24V344c0-21.4 25.89-32.1 41-17l36.19 36.2L184.46 256 77.18 148.7 41 185c-15.1 15.1-41 4.4-41-17V56a23.94 23.94 0 0 1 24-24h112c21.39 0 32.09 25.9 17 41l-36.2 36.2L224 216.4l107.23-107.3L295 73c-15.09-15.1-4.39-41 17-41h112a23.94 23.94 0 0 1 24 24v112c0 21.4-25.89 32.1-41 17l-36.19-36.2L263.54 256l107.28 107.3L407 327.1c15.1-15.2 41-4.5 41 16.9z"/></svg>
    </button>
    <div id="div-flotante" style="position: absolute; background: #00000000;">
        <iframe id="iframePhone" style="width: 200px; height: 155px;"
          src="https://irispbxcloud.com/plugin_webphone/?ruta=inicio&apikey=${PHONE_PLUGIN_API_KEY}"
          allow="microphone; speaker" frameborder="0"></iframe>
    </div>`;

const containerId = "phone-container";

export const usePhone = () => {
  useEffect(() => {
    loadPhonePlugin();
    return () => {
      const phoneContainer = document.getElementById(containerId);
      if (phoneContainer) {
        phoneContainer.remove();
      }
    };
  }, []);
};

const loadPhonePlugin = () => {
  const dragDiv = document.getElementById(containerId);
  if (dragDiv) return;
  const estilo_wp = document.createElement("style");
  estilo_wp.innerHTML = style;
  document.body.appendChild(estilo_wp);

  const btn_flotante_wp = document.createElement("div");
  btn_flotante_wp.innerHTML = htmlContent;
  btn_flotante_wp.id = containerId;

  document.body.appendChild(btn_flotante_wp);
};
