import React, { useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { GetAlertStatsServices } from "../../services/Stats/GetAlertStatsService";
import TimeFilter from "../common/TimeFilter";
import { getToken } from "../../utils/GetToken";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function AlertStatsPerPeriod() {
  const [selectedMonth, setSelectedMonth] = useState("");
  const { t } = useTranslation();

  const [Loading, setLoading] = useState(false);

  const getAlertsStats = async () => {
    const token = await getToken();
    const response = await GetAlertStatsServices(token);
    console.log(response.data);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["alertStats"],
    queryFn: () => getAlertsStats(),
  });

  const alertStats = response?.data || [];
  const totalAlert = alertStats.reduce(
    (total, alert) => total + alert.count,
    0
  );
  const renderMonthOptions = () => {
    if (!response) return null;

    const months = response.data.reduce((acc, curr) => {
      const monthYear = `${curr.month}/${curr.year}`;
      if (!acc.includes(monthYear)) {
        acc.push(monthYear);
      }
      return acc;
    }, []);

    return months.map((month, index) => (
      <MenuItem key={index} value={month}>
        {month}
      </MenuItem>
    ));
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredAlertStats = alertStats.filter((alert) => {
    const monthYear = `${alert.month}/${alert.year}`;
    return monthYear === selectedMonth;
  });

  const alertsByStatus = filteredAlertStats.reduce(
    (acc, alert) => {
      const estado = JSON.parse(alert.state_name).es;
      acc[estado] = (acc[estado] || 0) + alert.count;
      return acc;
    },
    {
      "en gestion": 0,
      validada: 0,
      finalizada: 0,
      iniciada: 0,
    }
  );

  const data = {
    labels: [
      t("alert.state-1"),
      t("alert.state-2"),
      t("alert.state-3"),
      t("alert.state-4"),
    ],
    datasets: [
      {
        data: Object.values(alertsByStatus),
        backgroundColor: ["#1D417A", "#6BE58A", "#FF6660", "#A054D2"],
        borderColor: ["#1D417A", "#6BE58A", "#FF6660", "#A054D2"],
        hoverBackgroundColor: ["#3388FF", "#5DD362", "#FF6660", "#A054D2"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("resumecard.title-1")}</Typography>
          <TimeFilter />
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountsCard.subtitle-7")}</Typography>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Stack direction="row" spacing={1} alignItems="center" my={1}>
            <NotificationAddIcon
              sx={{ fontSize: 30, color: "success.main", marginRight: "1rem" }}
            />
            <Typography variant="h5" fontWeight={600} sx={{ mt: "2rem" }}>
              {isLoading || isError ? "" : totalAlert}
            </Typography>
            <Typography variant="body1">{t("registered-1")}</Typography>
          </Stack>
        )}
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("alertsCard.subtitle-1")}</Typography>
        <Stack sx={{ height: "3rem", alignItems: "center", mt: "1rem" }}>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            displayEmpty
          >
            <MenuItem value="">{t("cardMonth.options")}</MenuItem>
            {renderMonthOptions()}
          </Select>
        </Stack>

        <Stack sx={{ height: "12rem", alignItems: "center", mt: "2rem" }}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Bar data={data} options={options} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
