import React from "react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const PublicRoutes = () => {
  const { isLoggedIn, setLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  const [activeToken, setActiveToken] = useState(
    localStorage.getItem("refreshToken") || null
  );

  useEffect(() => {
    const token = activeToken;
    !token || token.trim() === "" ? setLoggedIn(false) : setLoggedIn(true);
  }, [activeToken]);
  
  return !isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};
