import React from 'react'
import TaxesList from '../taxes-management-components/TaxesList'

export default function TaxesLayout() {
  return (
    <>
      <TaxesList />
    </>
  )
}
