import { Grid, Stack, Typography, Box, useMediaQuery } from "@mui/material";
import Logo from "../../assets/icons/logoNegro.png";
import { styled, useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import MuiGrid from "@mui/material/Grid";
import { Content } from "../../utils/Content";
import { useMenu } from "../../context/MenuOpenContext";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function FooterBar() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isLoggedIn, setLoggedIn } = useContext(AuthContext);
  const { isOpen } = useMenu();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerWidth = 300;

  const Footer = styled(MuiGrid, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: `${!isMobile ? (isLoggedIn ? "0px" : "0px") : "0px"}`,
    width: `${
      !isMobile
        ? isLoggedIn && localStorage.getItem("IdShop") !== null
          ? `100%`
          : "100%"
        : "100%"
    }`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      zIndex: theme.zIndex.drawer + 1,
      marginLeft: isLoggedIn ? drawerWidth : 0,
      width: `${isLoggedIn ? `calc(100% - ${drawerWidth}px)` : "100%"}`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <Footer
      open={isOpen}
      className={!isLoggedIn ? "footerLogin" : "footer"}
      sx={!isLoggedIn ? { bottom: { sm: "-5rem", xl: 0 } } : {}}
    >
      <Grid
        container
        position="relative"
        sx={{
          height: { xs: "120px", sm: "100px", lg: "129.99px" },
          justifyContent: { sm: "center", lg: "space-around" },
          alignItems: "center",
          flexWrap: { sm: "nowrap" },
        }}
        flexDirection="row"
      >
        <Grid
          container
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={{ xs: "center", sm: "space-around" }}
          ml={{ xs: 0, md: 14 }}
        >
          <Grid
            item
            md={3}
            display="flex"
            justifyContent="center"
            sx={{
              width: "150px",
            }}
          >
            <img
              lazyloading="true"
              // className="imageFooter"
              src={Logo}
              width="100%"
              height="100%"
              alt="Mi Ángel Logo"
            />
          </Grid>
          <Grid item xs={8}>
            <Grid
              container
              display="flex"
              justifyContent="space-around"
              flexDirection={{ xs: "row" }}
              spacing={{ xs: 0.7, md: 2 }}
              // mt={{ xs: "10px" }}
            >
              <Grid
                item
                display="flex"
                justifyContent={{ sm: "center", lg: "end" }}
              >
                <Link
                  href="#"
                  color="#fff"
                  fontSize={{ xs: "14px" }}
                  fontWeight={500}
                >
                  {t("footer.termsAndConditions")}
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                alignItems="center"
                justifyContent={{ sm: "center", lg: "center" }}
              >
                <Link
                  href="#"
                  color="#fff"
                  fontSize={{ xs: "14px" }}
                  fontWeight={500}
                >
                  {t("footer.privacyPolicies")}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={{ xs: "center", lg: "flex-end" }}
          mr={{ xs: 0, md: 2 }}
        >
          <Grid item textAlign={{ sm: "center" }}>
            <Typography
              fontSize={{ xs: "14px" }}
              fontWeight="400"
              color={{ xs: "#fff" }}
            >
              Copyright © 2023 Mi Ángel - Smart Tech.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Footer>
  );
}
